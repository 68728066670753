import React, { useContext } from "react";
import { FormattedDate } from "react-intl";
import { Paragraph2 } from "baseui/typography";
import moment from "moment";
import "moment-timezone";
import { useSelector } from "react-redux";
import _ from "lodash";

function Date({ value, alignment, accessor, row, ...rest }) {
  value = _.get(row.original, accessor, "");

  let timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );

  let dateFormat = useSelector(
    (e) =>
      e && e.layoutReduceData && e.layoutReduceData?.organization.date_format
  );
  let date = moment.utc(value).tz(timeZone).format(dateFormat);

  // const changedData = value == null ? new Date() : new Date(value);

  // let date = utcTimstampToLocalDate(value);
  // const dateValue = value && value.value;

  return value && value ? (
    <Paragraph2
      $style={{
        width: "100%",
        textAlign: alignment,
        margin: "5px",
      }}
    >
      {/* <FormattedDate
        value={date}
        year="numeric"
        month="2-digit"
        day="2-digit"
      /> */}
      {date ? date : "-"}
    </Paragraph2>
  ) : (
    <></>
  );
}
export default Date;
