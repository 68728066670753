import { ParagraphMedium } from "baseui/typography";
import React from "react";
import { useSelector } from "react-redux";
import { invoiceDownload } from "../../../services/customerPortal";

const DownloadInvoice = (props) => {
  let { row, value } = props;
  const { organization, invoice } = useSelector((s) => s.layoutReduceData);
  return (
    <>
      {(organization &&
        organization.accounting_book !== "CSV" &&
        invoice?.is_allow_invoice_download === true) ||
      (organization.accounting_book === "CSV" &&
        row?.original?.is_system_generated &&
        invoice?.is_allow_invoice_download === true) ||
      (organization.accounting_book === "CSV" &&
        row?.original?.invoive_file_doc_id &&
        invoice?.is_allow_invoice_download === true) ? (
        <ParagraphMedium
          $style={{ color: "#516beb", textDecoration: "underline",cursor: "pointer" }}
          onClick={() => {
            invoiceDownload({
              invoiceId: row?.original?._id,
            });
          }}
        >
          {value}
        </ParagraphMedium>
      ) : (
        <ParagraphMedium>{value}</ParagraphMedium>
      )}
    </>
  );
};

export default DownloadInvoice;
