import React, { useContext } from "react";

import _ from "lodash";
import { useSelector } from "react-redux";

function User(props) {
  let {
    value,
    column,
    alignment,
    accessor,
    row: { original },
    ...rest
  } = props;
  value = _.get(original, accessor, "");
  const {
    default_nav_color,
    organization,
    customer,
    tabMotion,
    referenceData,
  } = useSelector((s) => s.layoutReduceData);

  const user = _.get(organization, "custom_field_list", []).find(({ id }) => {
    return id === value;
  });

  return (
    <div
      style={{
        // display: "flex",
        // flexDirection: "row",
        // gap: "5px",
        // flex: "1",
        // justifyContent: alignment,
        // alignItems: "center",
        textAlign: alignment,
        width: "100%",
      }}
    >
      {user?.displayName || ""}
    </div>
  );
}

export default User;
