import React from "react";

const CrosssSvg = ({ size = 18, color = "#787878" }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9992 11.9661L6.34891 16.6166C6.22195 16.7434 6.06237 16.8083 5.87018 16.8113C5.67814 16.8142 5.51566 16.7493 5.38274 16.6166C5.24998 16.4836 5.18359 16.3226 5.18359 16.1335C5.18359 15.9443 5.24998 15.7833 5.38274 15.6504L10.0332 11.0001L5.38274 6.34988C5.25593 6.22293 5.191 6.06335 5.18795 5.87115C5.18505 5.67911 5.24998 5.51663 5.38274 5.38372C5.51566 5.25095 5.67668 5.18457 5.86582 5.18457C6.05496 5.18457 6.21599 5.25095 6.34891 5.38372L10.9992 10.0342L15.6494 5.38372C15.7764 5.25691 15.9359 5.19198 16.1281 5.18892C16.3202 5.18602 16.4827 5.25095 16.6156 5.38372C16.7483 5.51663 16.8147 5.67766 16.8147 5.8668C16.8147 6.05594 16.7483 6.21697 16.6156 6.34988L11.9651 11.0001L16.6156 15.6504C16.7424 15.7773 16.8073 15.9369 16.8104 16.1291C16.8133 16.3212 16.7483 16.4836 16.6156 16.6166C16.4827 16.7493 16.3216 16.8157 16.1325 16.8157C15.9434 16.8157 15.7823 16.7493 15.6494 16.6166L10.9992 11.9661Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default CrosssSvg;
