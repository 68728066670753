import { ParagraphMedium } from "baseui/typography";
import React, { useContext } from "react";
import { invoiceDownload } from "../../../services/customerPortal";
import { IconButton, KIND, SIZE } from "../../IconButton";
import { StatefulTooltip } from "baseui/tooltip";
import CommentSvg from "../../../assets/img/svg/CommentSvg";

const InvoiceDownload = (props) => {
  let {
    value,
    column,
    alignment,
    accessor,
    row: { original },
    ...rest
  } = props;
  return (
    <div
      style={{
        display: "flex",
        gap: "5px",
        flex: "1",
        alignItems: props.alignment,
      }}
    >
      <ParagraphMedium>{value}</ParagraphMedium>
      {original &&
        original.invoice_description &&
        original.invoice_description.length >= 1 && (
          <StatefulTooltip
            content={() => (
              <div
                style={{
                  maxWidth: "220px",
                }}
              >
                {original && original.invoice_description}
              </div>
            )}
            returnFocus
            autoFocus
          >
            {original &&
            original.invoice_description &&
            original.invoice_description.length >= 1 ? (
              <div>
                <CommentSvg />
              </div>
            ) : (
              ""
            )}
          </StatefulTooltip>
        )}
    </div>
  );
};

export default InvoiceDownload;
