import React from "react";
import UnsubscribeView from "../../containers/UnSubscribe/UnsubscribeView";

const UnSubscribe = () => {
  return (
    <UnsubscribeView/>
  );
};

export default UnSubscribe;
