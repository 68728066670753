import { combineReducers } from "redux";
import layoutReduce from "./Layout/reducer";
import invoiceReducer from "./Invoice/reducer";
import customizeReducer from "./CustomizeUI/reducer";

const rootReducer = combineReducers({
  layoutReduceData: layoutReduce,
  invoiceReducer: invoiceReducer,
  customizeReducer: customizeReducer,
});

export default rootReducer;
