import React from "react";
import ToolTip from "../ToolTip/ToolTip";
import { FormattedNumber } from "react-intl";
import { Paragraph2 } from "baseui/typography";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { currencyList } from "../../../utils/Currency/CurrencyToSymbol";

function Currency({ currency, value, alignment }) {
  const currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  return (
    <>
      {value && value.value && value.currency ? (
        <NumericFormat
          displayType="text"
          value={value && value.value}
          thousandsGroupStyle={currencyGroupStyle}
          decimalScale={2}
          fixedDecimalScale={true}
          thousandSeparator={currencySeparator}
          decimalSeparator={currencyDecimalSeparator}
          renderText={(values) => (
            <>
              <Paragraph2 $style={{ width: "100%", textAlign: alignment }}>
                {currencyList[value && value.currency]
                  ? currencyList[value && value.currency]
                  : value && value.currency}{" "}
                {values}
              </Paragraph2>
            </>
          )}
        />
      ) : (
        <></>
      )}
    </>
  );
}
export default Currency;
