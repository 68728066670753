import {
  LAYOUT_DATA,
  REFETCH_LAYOUT,
  DEFAULT_COLOR,
  DEFAULT_NAV_BAR_COLOR,
  TAB_MOTION,
  MODAL_OPEN,
  PAYMENT_AMOUNT,
  DOCUMENT_DATA,
  DEBTOR_RIGHTS_MODAL,
} from "./action";

const initialState = {
  tabMotion: "0",
  userData: "",
  organization: {},
  is_document_authorize_configured: false,
  document_authorize_configurations: [],
  invoice: {},
  payment: {},
  time: [],
  customer: {},
  requestBack: {},
  callBack: {},
  callBackData: {},
  requestTimeData: {},
  isLoading: false,
  refetchData: 0,
  dateFormat: "",
  amountFormat: "",
  referenceData: [],
  selectedInvoice: [],
  default_color: "#1A73E8",
  default_nav_color: "#08083A",
  org_logo: null,
  modalState: true,
  retriveDocument: [],
  paymentAmount: "",
  is_mini_miranda_configured: false,
  debtor_rights_modal: false,
};

const layoutReduce = (state = initialState, { type, payload }) => {
  switch (type) {
    case DEFAULT_COLOR: {
      return {
        ...state,
        default_color: payload,
      };
    }
    case TAB_MOTION: {
      return {
        ...state,
        tabMotion: payload,
      };
    }
    case DEFAULT_NAV_BAR_COLOR: {
      return {
        ...state,
        default_nav_color: payload,
      };
    }

    case LAYOUT_DATA:
      //   let org = payload.data?.doc.payment;

      if (
        payload.organization &&
        payload.organization.customer_portal_ui &&
        payload.organization.customer_portal_ui.primary_color
      ) {
        document.documentElement.style.setProperty(
          "--primary",
          payload.organization.customer_portal_ui.primary_color
        );
      }

      return {
        ...state,
        organization: payload.organization,
        is_document_authorize_configured:
          payload.is_document_authorize_configured,
        document_authorize_configurations:
          payload.document_authorize_configurations,
        invoice: payload.invoice,
        payment: payload.payment,
        paymentReceiptConfigured: payload,
        time: payload.rd.time,
        customer: payload.customer,
        // requestBack: payload.request_back,
        // callBack: payload.callback,
        // callBackData: payload.callback_data,
        is_mini_miranda_configured: payload.is_mini_miranda_configured,
        requestTimeData: payload.request_time_data,
        accessData: payload,
        dateFormat: payload.organization.date_format,
        amountFormat: payload.organization.amount_format,
        isLoading: true,
        referenceData: payload.rd,
        default_color:
          payload.organization &&
          payload.organization.customer_portal_ui &&
          payload.organization.customer_portal_ui.primary_color
            ? payload.organization.customer_portal_ui.primary_color
            : "#1A73E8",
        default_nav_color:
          payload.organization &&
          payload.organization.customer_portal_ui &&
          payload.organization.customer_portal_ui.header_color
            ? payload.organization.customer_portal_ui.header_color
            : "#08083A",
        org_logo:
          payload.organization &&
          payload.organization.customer_portal_ui &&
          payload.organization.customer_portal_ui.logo
            ? payload.organization.customer_portal_ui.logo
            : null,
      };

    case REFETCH_LAYOUT:
      return {
        ...state,
        refetchData: state.refetchData + 1,
      };

    case MODAL_OPEN:
      return {
        ...state,
        modalState: payload,
      };

    case PAYMENT_AMOUNT:
      return {
        ...state,
        paymentAmount: payload,
      };

    case DEBTOR_RIGHTS_MODAL:
      return {
        ...state,
        debtor_rights_modal: payload,
      };

    default:
      return state;
  }
};

export default layoutReduce;
