import React from "react";
import { ParagraphMedium, LabelLarge, HeadingXSmall } from "baseui/typography";
import { FormattedDate, FormattedNumber } from "react-intl";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";

const AvailableAmount = ({
  value,
  column,
  cell: {
    row: { original },
  },
  alignment,
  ...rest
}) => {
  const currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  let NextActionDaysDiff = () => {
    let daysLeft = original.issued_days;

    if (daysLeft > 1) {
      return <span>(Overdue {daysLeft} days)</span>;
    }

    if (daysLeft === 1) {
      return <span>(Overdue {daysLeft} day)</span>;
    }

    if (daysLeft === 0) {
      return <span>(Due Today)</span>;
    }

    if (daysLeft === -1) {
      daysLeft = daysLeft * -1;
      return <span>(Due in {daysLeft} day)</span>;
    }

    if (daysLeft < -1) {
      daysLeft = daysLeft * -1;
      return <span>(Due in {daysLeft} days)</span>;
    }
    return <></>;
  };
  return (
    <>
      <div className="table-cell__type-amount">
        <NumericFormat
          displayType="text"
          value={value && value.value}
          thousandsGroupStyle={currencyGroupStyle}
          decimalScale={2}
          fixedDecimalScale={true}
          thousandSeparator={currencySeparator}
          decimalSeparator={currencyDecimalSeparator}
          renderText={(values) => (
            <>
              <LabelLarge $style={{ color: "#333860" }}>
                {currencyList[value && value.currency]
                  ? currencyList[value && value.currency]
                  : value && value.currency}{" "}
                {values}
              </LabelLarge>
            </>
          )}
        />
        {/* <LabelLarge $style={{ color: "#333860" }}>
          <FormattedNumber
            value={value && value.value}
            style="currency"
            currency={value && value.value.currency}
          />
        </LabelLarge> */}
        {/* <HeadingXSmall $style={{ color: "#FD372A" }}>
          <NextActionDaysDiff />
        </HeadingXSmall> */}
      </div>
    </>
  );
};

export default AvailableAmount;
