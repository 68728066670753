import React from "react";

const InvoicesSvg = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7069 5.70708C17.8946 5.89463 18 6.14906 18 6.41436V18.993C18.0009 19.1243 17.976 19.2545 17.9266 19.3762C17.8772 19.4979 17.8043 19.6087 17.7121 19.7022C17.6199 19.7957 17.5101 19.8701 17.3892 19.9212C17.2682 19.9723 17.1383 19.9991 17.007 20H0.993C0.729813 20 0.477391 19.8955 0.291196 19.7095C0.105001 19.5235 0.000265042 19.2712 0 19.008V0.992C0 0.455 0.449 0 1.002 0H11.5829C11.8481 0 12.1023 0.105288 12.2899 0.292717L17.7069 5.70708ZM16 8C16 7.44771 15.5523 7 15 7H12C11.4477 7 11 6.55228 11 6V3C11 2.44772 10.5523 2 10 2H3C2.44772 2 2 2.44772 2 3V17C2 17.5523 2.44772 18 3 18H15C15.5523 18 16 17.5523 16 17V8ZM5 6C5 5.44772 5.44772 5 6 5H7C7.55228 5 8 5.44772 8 6C8 6.55228 7.55228 7 7 7H6C5.44772 7 5 6.55228 5 6ZM5 10C5 9.44771 5.44772 9 6 9H12C12.5523 9 13 9.44771 13 10C13 10.5523 12.5523 11 12 11H6C5.44772 11 5 10.5523 5 10ZM5 14C5 13.4477 5.44772 13 6 13H12C12.5523 13 13 13.4477 13 14C13 14.5523 12.5523 15 12 15H6C5.44772 15 5 14.5523 5 14Z"
        fill="#6A7187"
      />
    </svg>
  );
};

export default InvoicesSvg;
