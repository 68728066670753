import React from "react";
import { IconButton, KIND } from "../IconButton";
import { useSelector } from "react-redux";
import PdfSvg from "../../assets/img/svg/PdfSvg";
import DownloadLogoSvg from "../../assets/img/svg/DownloadLogoSvg";
import DocumentType from "./DocumentType";
import { useState } from "react";
import moment from "moment";
 
export const DownloadTextBox = ({
  fileName,
  type,
  onDownload,
  lastDownloadDate,
}) => {
  const { default_color } = useSelector((s) => s.layoutReduceData);
  const [isLoading, setIsLoading] = useState(false);
  const formattedDate = moment(lastDownloadDate).format("DD-MM-YYYY");
  const modifiedFileName = fileName.split(".")[0];
  return (
    <div
      className="DownloadTextframe"
      disabled={isLoading}
      onClick={onDownload}
    >
      <div className="downloadTextBox">
        <DocumentType type={type} />
        <div className="download-text-wrapper">
          {lastDownloadDate && (
            <div className="document-file-Name">{modifiedFileName}</div>
          )}
 
          {!lastDownloadDate && (
            <div className=" document-file-Name-without-last-downoad">
              {modifiedFileName}
            </div>
          )}
 
          <div>
            {lastDownloadDate && (
              <div className="last-Download-Date">
                Downloaded on {formattedDate}
              </div>
            )}{" "}
          </div>
        </div>
      </div>
      <DownloadLogoSvg />
    </div>
  );
};