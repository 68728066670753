export const einRegex = new RegExp(
  /^(?:01|02|03|04|05|06|10|11|12|13|14|15|16|20|21|22|23|24|25|26|27|30|31|32|33|34|35|36|37|38|39|40|41|42|43|44|45|46|46|47|48|50|51|52|53|54|55|56|57|58|59|60|61|62|63|64|65|66|67|68|71|72|73|74|75|76|77|80|81|82|83|84|85|86|87|88|90|91|92|93|94|95|98|99)\d\d\d\d\d\d\d(?<!0000000|111111111|222222222|333333333|444444444|555555555|666666666|777777777|888888888|999999999|123456789|987654321)$/
);

export const newEinRegex = new RegExp(/^[a-zA-Z0-9-]{7,25}$/);

export const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export const unallowedSpecialCharacter = new RegExp(/^[\\_'#.0-9a-zA-Z\s]+$/);
export const address = new RegExp(/^[0-9a-zA-Z\s\#\'\,\.\-\_\/]+$/);
export const contactDtlEmail = new RegExp(
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/
);

export const domainReg = new RegExp(/^[a-z0-9]{1,}[a-z0-9\s][a-z0-9]{1,}$/i);

// ?:[Pp][Oo]\s[Bb][Oo][Xx]|[0-9]+)\s(?:[0-9A-Za-z\.'#]|[^\S\r\n]

export const alloweSpecialCharacter = new RegExp(/^[\\_'#&.0-9a-zA-Z\s,-]+$/);
export const paymentInstructionCharacter = new RegExp(
  /^[\\_':#.0-9a-zA-Z\s,-]+$/
);
export const paymentInfoReg = new RegExp(
  /^[0-9a-zA-Z\s\#\'\,\.\-\_\/\\\&\(\)\@]+$/
);
// export const nameRegex = new RegExp(/^[a-z',.-_]{1,}$/);
export const nameRegex = new RegExp(/^[a-zA-z',._-\s]+$/);
export const businessEmailRegex = new RegExp(
  /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)([a-zA-Z]|[a-zA-Z0-9]?[a-zA-Z0-9-]+[a-zA-Z0-9])\.[a-zA-Z0-9]{2,10}(?:\.[a-zA-Z]{2,10})?$/
);
export const cityAndState = new RegExp(/^['a-zA-Z\s,-]+$/);

export const creditNoteAmount = new RegExp(/^[0-9,.]*$/);

export const passwordRegex = new RegExp(
  /^(?=.{9,})(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w\d]).*$/
);

export const orgReg = new RegExp(/^[\\_'&#.0-9a-zA-Z\s,-]+$/);

export const removeElement = new RegExp(/(<([^>]+)>)/gi);

export const webUrlReg = new RegExp(
  "((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)"
);

export const customerName = new RegExp(/^[\\_'\-&#.0-9a-zA-Z\s,-]+$/);

export const businessUnitRegex = new RegExp(/^[\\_'.0-9a-zA-Z\s,-/&]+$/);

export const businessUnitCodeRegex = new RegExp(/^[_0-9a-zA-Z\s,-]+$/);

export const constactEmailRegex = new RegExp(
  /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
);
