import React from "react";

const Success = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7823 27.3074L14.8541 22.3792C14.4474 21.9725 13.9689 21.7811 13.3708 21.7811C12.7727 21.7811 12.2703 21.9964 11.8397 22.4031C11.433 22.8098 11.2416 23.3122 11.2416 23.8864C11.2416 24.4845 11.4569 24.9629 11.8397 25.3696L18.2512 31.829C18.6818 32.2596 19.1603 32.451 19.7344 32.451C20.3086 32.451 20.7871 32.2357 21.2177 31.829L34.0167 19.006C34.4474 18.5754 34.6627 18.073 34.6627 17.4988C34.6627 16.9247 34.4474 16.3983 34.0167 15.9916C33.5861 15.5849 33.0837 15.3936 32.4856 15.4175C31.8876 15.4414 31.3852 15.6328 30.9785 16.0395L19.7823 27.3074ZM23.012 45.4893C19.9258 45.4893 17.0072 44.8912 14.256 43.7189C11.5287 42.5467 9.13636 40.9438 7.10287 38.8864C5.06938 36.8529 3.46651 34.4606 2.27033 31.7333C1.09808 29.006 0.5 26.0873 0.5 22.9773C0.5 19.8672 1.09808 16.9247 2.27033 14.1974C3.44258 11.4701 5.04546 9.07777 7.10287 7.0682C9.16029 5.05864 11.5287 3.43184 14.256 2.25959C16.9833 1.08734 19.9019 0.489258 23.012 0.489258C26.122 0.489258 29.0646 1.08734 31.7919 2.25959C34.5191 3.43184 36.9115 5.03471 38.9211 7.0682C40.9545 9.1017 42.5574 11.4701 43.7297 14.1974C44.9019 16.9247 45.5 19.8672 45.5 22.9773C45.5 26.0634 44.9019 28.9821 43.7297 31.7333C42.5574 34.4606 40.9545 36.8529 38.9211 38.8864C36.8876 40.9199 34.5191 42.5228 31.7919 43.7189C29.0646 44.9151 26.1459 45.4893 23.012 45.4893Z"
        fill="#32B50D"
      />
    </svg>
  );
};

export default Success;
