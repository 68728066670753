import React from "react";

const Info = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0632 14.3958C10.2993 14.3958 10.5007 14.316 10.6673 14.1563C10.834 13.9965 10.9173 13.7917 10.9173 13.5417V10C10.9173 9.77778 10.834 9.58333 10.6673 9.41667C10.5007 9.25 10.2993 9.16667 10.0632 9.16667C9.82704 9.16667 9.62565 9.24653 9.45899 9.40625C9.29232 9.56597 9.20899 9.77083 9.20899 10.0208V13.5625C9.20899 13.7986 9.29232 13.9965 9.45899 14.1563C9.62565 14.316 9.82704 14.3958 10.0632 14.3958ZM10.0007 7.9375C10.2923 7.9375 10.5319 7.84375 10.7194 7.65625C10.9069 7.46875 11.0007 7.23611 11.0007 6.95833C11.0007 6.65278 10.9034 6.40625 10.709 6.21875C10.5145 6.03125 10.2784 5.9375 10.0007 5.9375C9.70899 5.9375 9.4694 6.03125 9.2819 6.21875C9.0944 6.40625 9.00065 6.64583 9.00065 6.9375C9.00065 7.22917 9.09787 7.46875 9.29232 7.65625C9.48676 7.84375 9.72287 7.9375 10.0007 7.9375ZM10.0215 18.8542C8.7576 18.8542 7.58399 18.6285 6.50065 18.1771C5.41732 17.7257 4.47982 17.1042 3.68815 16.3125C2.89648 15.5208 2.27496 14.5833 1.82357 13.5C1.37218 12.4167 1.14648 11.2431 1.14648 9.97917C1.14648 8.74306 1.37218 7.58681 1.82357 6.51042C2.27496 5.43403 2.89648 4.49653 3.68815 3.69792C4.47982 2.89931 5.41732 2.27083 6.50065 1.8125C7.58399 1.35417 8.7576 1.125 10.0215 1.125C11.2576 1.125 12.4138 1.35417 13.4902 1.8125C14.5666 2.27083 15.5041 2.89931 16.3027 3.69792C17.1013 4.49653 17.7298 5.43403 18.1882 6.51042C18.6465 7.58681 18.8757 8.75 18.8757 10C18.8757 11.25 18.6465 12.4167 18.1882 13.5C17.7298 14.5833 17.1013 15.5208 16.3027 16.3125C15.5041 17.1042 14.5666 17.7257 13.4902 18.1771C12.4138 18.6285 11.2576 18.8542 10.0215 18.8542Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default Info;
