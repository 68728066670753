import { FOOTER_POP_UP_CLOSE, SELECTED_INVOICES } from "./action";

const initialState = { popUp: false, selectedInvoice: [] };

const invoiceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FOOTER_POP_UP_CLOSE:
      return {
        ...state,
        popUp: payload,
      };

    case SELECTED_INVOICES:
      let currentSelectedInvoices = payload.map((i) => {
        return {
          _id: i.original._id,
          invoiceCurrency: i.original.invoice_currency,
          invoiceTotalAmount:
            i &&
            i.original &&
            i.original.not_paid_amount &&
            i.original.not_paid_amount.value,
        };
      });
      return {
        ...state,
        selectedInvoice: currentSelectedInvoices,
      };

    default:
      return state;
  }
};

export default invoiceReducer;
