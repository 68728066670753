import { LabelLarge } from "baseui/typography";
import moment from "moment";
import { useContext } from "react";
import { NumericFormat, useNumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { currencyList } from "../Currency/CurrencyToSymbol";

const useFormat = () => {
  const { referenceData, organization } = useSelector(
    (s) => s.layoutReduceData
  );
  console.log(organization, "sdksjhdjkshdjkshdj");
  let currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );
  let dateFormat = useSelector(
    (e) =>
      e && e.layoutReduceData && e.layoutReduceData?.organization.date_format
  );
  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  const numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });
  const customDateFormat = "ddd, MMM D, YYYY [at] hh:mmA";
  return {
    dateTime: ({
      isOrg = true,
      includeTime = false,
      month = false,
      value = 0,
      customFormat,
      customCase,
    }) => {
      let dateInst = isOrg
        ? moment.tz(value, currentDefaultFormatDetails.time_zone)
        : moment(value);

      if (customFormat) {
        dateInst = dateInst.format(customFormat);
      } else if (customCase === "customDateFormat") {
        dateInst = dateInst.format(customDateFormat);
      } else {
        if (month) {
          dateInst = dateInst.format("MMMM D, YYYY");
        } else {
          dateInst = dateInst.format(currentDefaultFormatDetails.date_format);
        }
      }

      return `${dateInst}`;
    },

    date: ({ isOrg = true, includeTime = false, value = 0, customFormat }) => {
      let dateInst = value
        ? moment.utc(value).tz(organization.time_zone).format(dateFormat)
        : "-";
      return `${dateInst}`;
    },
    rd: ({ id = "", name }) => {
      const rd = name && referenceData[name] ? referenceData[name] : null;
      const reference =
        rd && Array.isArray(rd) ? rd.find((e) => e.id === id) : null;

      return reference && reference.label ? reference.label : "";
    },
    currency: ({ amount = 0, currency = "$", color, styleCurrency }) => {
      const amountStr =
        typeof amount === "string" || typeof amount === "number"
          ? String(amount)
          : "0";
      return (
        <NumericFormat
          displayType="text"
          value={amountStr}
          thousandsGroupStyle={currencyGroupStyle}
          decimalScale={2}
          fixedDecimalScale={true}
          thousandSeparator={currencySeparator}
          decimalSeparator={currencyDecimalSeparator}
          renderText={(values) => (
            <>
              <LabelLarge
                $style={
                  styleCurrency
                    ? styleCurrency
                    : { color: color ? color : "#333860", fontSize: "16px" }
                }
              >
                {currencyList[currency] ? currencyList[currency] : currency}{" "}
                {values}
              </LabelLarge>
            </>
          )}
        />
      );
    },
  };
};

export default useFormat;
