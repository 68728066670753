import React from "react";

const SendSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.55 15.3004L15.6375 9.69038C16.245 9.42788 16.245 8.57288 15.6375 8.31038L2.55 2.70038C2.055 2.48288 1.5075 2.85038 1.5075 3.38288L1.5 6.84038C1.5 7.21538 1.7775 7.53788 2.1525 7.58288L12.75 9.00038L2.1525 10.4104C1.7775 10.4629 1.5 10.7854 1.5 11.1604L1.5075 14.6179C1.5075 15.1504 2.055 15.5179 2.55 15.3004V15.3004Z"
        fill="white"
      />
    </svg>
  );
};

export default SendSvg;
