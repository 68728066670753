import React from "react";

const LeftArrowSvg = () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.71 9.88047L2.83 6.00047L6.71 2.12047C7.1 1.73047 7.1 1.10047 6.71 0.710469C6.32 0.320469 5.69 0.320469 5.3 0.710469L0.710003 5.30047C0.320003 5.69047 0.320003 6.32047 0.710003 6.71047L5.3 11.3005C5.69 11.6905 6.32 11.6905 6.71 11.3005C7.09 10.9105 7.1 10.2705 6.71 9.88047Z"
        fill="#333860"
      />
    </svg>
  );
};

export default LeftArrowSvg;
