import React from "react";

const Add = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0C4.928 0 0 4.928 0 11C0 17.072 4.928 22 11 22C17.072 22 22 17.072 22 11C22 4.928 17.072 0 11 0ZM15.4 12.1H12.1V15.4C12.1 16.005 11.605 16.5 11 16.5C10.395 16.5 9.9 16.005 9.9 15.4V12.1H6.6C5.995 12.1 5.5 11.605 5.5 11C5.5 10.395 5.995 9.9 6.6 9.9H9.9V6.6C9.9 5.995 10.395 5.5 11 5.5C11.605 5.5 12.1 5.995 12.1 6.6V9.9H15.4C16.005 9.9 16.5 10.395 16.5 11C16.5 11.605 16.005 12.1 15.4 12.1Z"
        fill="#333860"
      />
    </svg>
  );
};

export default Add;
