import React from "react";
import ToolTip from "../ToolTip/ToolTip";
import { FormattedDate } from "react-intl";
import { Paragraph2 } from "baseui/typography";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";

function Date({ value, alignment, accessor, row, ...rest }) {
  value = _.get(row.original, accessor, "");


  let dateFormat = useSelector(
    (e) =>
      e && e.layoutReduceData && e.layoutReduceData?.organization.date_format
  );
  let date = value ? moment.utc(value).format(dateFormat) : "-";
 
  return (
    <Paragraph2
      $style={{
        width: "100%",
        textAlign: alignment,
        margin: "5px",
      }}
    >
      {date ? date : "-"}
      {/* <FormattedDate value={value && value.value} /> */}
    </Paragraph2>
  );
}
export default Date;
