import { useContext } from "react";
import { RBACContext } from "./RBACProvider";

export const useRBAC = () => {
  const permissions = useContext(RBACContext);

  const isAllowed = (permission) => {
    return permissions.includes(permission);
  };

  return { isAllowed };
};
