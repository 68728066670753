import React from "react";
import { useSelector } from "react-redux";

const PaymentAmountImg = ({ width, height, color }) => {
  const { default_nav_color, default_color } = useSelector(
    (s) => s.layoutReduceData
  );

  return (
    <svg
      width="199"
      height="226"
      viewBox="0 0 199 226"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.2031 225.079C81.0341 225.079 104.406 218.829 104.406 211.119C104.406 203.41 81.0341 197.16 52.2031 197.16C23.3721 197.16 0 203.41 0 211.119C0 218.829 23.3721 225.079 52.2031 225.079Z"
        fill="#E4F1FF"
      />
      <path
        d="M27.9199 216.428L71.3552 216.486C72.7396 216.486 73.8933 215.332 73.8933 213.948L73.951 170.974L27.9776 170.916L27.9199 216.428Z"
        fill={default_nav_color}
      />
      <path
        d="M27.9778 170.916L73.9512 170.974L59.4727 159.437C58.4921 158.629 57.2231 158.226 55.9541 158.226L17.4795 158.168C16.4989 158.168 16.0374 159.322 16.7296 159.956L27.9778 170.916Z"
        fill={default_nav_color}
      />
      <path
        d="M15.0559 201.372L14.8828 161.802C14.8828 158.802 16.844 158.283 16.844 158.283L16.4979 159.033C16.3249 159.437 16.671 159.841 17.0171 160.129L27.9769 170.858L27.9192 216.37L16.4403 204.776C15.5173 203.853 15.0559 202.641 15.0559 201.372Z"
        fill={default_nav_color}
      />
      <path
        d="M40.7256 170.916H29.8235C28.7852 170.916 27.9199 171.781 27.9199 172.82V184.298"
        stroke="white"
        stroke-width="0.5"
        stroke-miterlimit="10"
      />
      <path
        d="M27.9199 191.566V214.467"
        stroke="white"
        stroke-width="0.5"
        stroke-miterlimit="10"
      />
      <path
        d="M21.1699 164.34L27.9188 170.916"
        stroke="white"
        stroke-width="0.5"
        stroke-miterlimit="10"
      />
      <path
        d="M16.7867 158.34L16.4406 159.09C16.2675 159.493 16.6136 159.897 16.9597 160.186L20.0169 163.185"
        stroke="white"
        stroke-width="0.5"
        stroke-miterlimit="10"
      />
      <path
        d="M135.44 183.893H75.1039C69.6817 183.893 65.3555 179.509 65.3555 174.145V41.7621C65.3555 36.3399 69.7394 32.0137 75.1039 32.0137H135.44C140.863 32.0137 145.189 36.3976 145.189 41.7621V174.087C145.189 179.509 140.805 183.893 135.44 183.893Z"
        fill="#1C004F"
      />
      <path
        d="M136.017 180.142H74.527C71.3545 180.142 68.8164 177.547 68.8164 174.432V41.4723C68.8164 38.2998 71.4121 35.7617 74.527 35.7617H136.017C139.19 35.7617 141.728 38.3575 141.728 41.4723V174.374C141.728 177.547 139.19 180.142 136.017 180.142Z"
        fill="white"
      />
      <path
        d="M117.386 44.4154H93.1019C90.1024 44.4154 87.6797 41.9927 87.6797 38.9932V34.1478C87.6797 33.9747 87.7951 33.8594 87.9681 33.8594H122.809V38.9932C122.809 41.9927 120.386 44.4154 117.386 44.4154Z"
        fill="#1C004F"
      />
      <path
        d="M86.9286 27.0542C86.9863 36.6296 79.2568 44.4745 69.6814 44.5321C60.106 44.5898 52.2611 36.8603 52.2034 27.2849C52.1458 17.7095 59.8753 9.86464 69.4507 9.80696C79.0261 9.74928 86.8709 17.4788 86.9286 27.0542Z"
        fill={default_nav_color}
      />
      <path
        d="M82.4876 26.824C82.5453 34.0921 76.6616 40.0334 69.3359 40.0911C62.0678 40.1488 56.1265 34.2651 56.0688 26.9394C56.0111 19.6713 61.8948 13.73 69.2205 13.6723C76.4886 13.6146 82.4299 19.4983 82.4876 26.824Z"
        fill="#71B7E2"
      />
      <path
        d="M82.8915 27.342C82.9492 34.5523 77.1232 40.3783 69.9128 40.436C62.7025 40.4937 56.8765 34.6677 56.8188 27.4573C56.7611 20.247 62.5871 14.421 69.7975 14.3633C76.9502 14.3633 82.8338 20.1893 82.8915 27.342Z"
        fill="#FF725E"
      />
      <path
        d="M82.8915 27.342C82.9492 34.5523 77.1232 40.3783 69.9128 40.436C62.7025 40.4937 56.8765 34.6677 56.8188 27.4573C56.7611 20.247 62.5871 14.421 69.7975 14.3633C76.9502 14.3633 82.8338 20.1893 82.8915 27.342Z"
        fill={default_color}
      />
      <path
        d="M70.3734 26.3035V22.9579C70.9502 23.0155 71.527 23.1886 72.1616 23.4193C72.6807 23.6501 72.9691 23.9385 72.9691 23.9385L73.0268 23.8231L73.5459 22.6118L73.6613 22.3234L73.0845 21.6888L72.7961 21.8042C72.0462 21.4581 71.2386 21.1697 70.3734 21.112V19.2662L69.7965 18.8047V19.2662H68.989V21.112C66.3932 21.4004 65.1819 22.8425 65.1819 24.5153C65.1819 26.8226 67.1431 27.3995 68.989 27.8032V31.0912C67.72 30.9758 66.5086 30.5143 65.7011 29.8798L64.9512 31.5526C65.8164 32.3025 67.3739 32.8217 68.989 32.8793V34.2637H68.3545L68.989 34.7252H70.3157V32.8793C72.9114 32.5909 74.1228 31.1489 74.1228 29.4761C74.1805 27.2841 72.1615 26.7649 70.3734 26.3035ZM68.989 25.9574C68.0084 25.669 67.3162 25.3229 67.3162 24.5153C67.3162 23.7654 67.7776 23.1886 68.989 23.0155V25.9574ZM70.3734 31.2065V28.2647C71.354 28.5531 72.1039 28.8992 72.1039 29.7068C72.1039 30.4567 71.5847 31.0335 70.3734 31.2065Z"
        fill="#71B7E2"
      />
      <path
        d="M69.7962 32.5917V34.4376H68.4695V32.5917C66.8543 32.4763 65.2969 31.9572 64.4316 31.265L65.1815 29.5922C65.9891 30.2267 67.2581 30.7459 68.4695 30.8035V27.5156C66.6236 27.0541 64.6624 26.4773 64.6624 24.2277C64.6624 22.5549 65.8737 21.0551 68.4695 20.8244V18.9785H69.7962V20.8244C71.0075 20.8821 72.2765 21.2282 73.1418 21.805L72.4496 23.4778C71.5843 22.9586 70.6614 22.6702 69.7962 22.6125V25.9582C71.642 26.4196 73.6609 26.9388 73.6609 29.2461C73.6609 30.8612 72.3919 32.3033 69.7962 32.5917ZM68.4118 25.4967V22.5549C67.2581 22.7279 66.739 23.3624 66.739 24.0546C66.739 24.8622 67.4888 25.266 68.4118 25.4967ZM71.5267 29.3038C71.5267 28.4962 70.7768 28.0924 69.7962 27.8617V30.8035C71.0075 30.5728 71.5267 29.996 71.5267 29.3038Z"
        fill="white"
      />
      <path
        d="M116.635 10.9598C116.693 17.0165 111.79 21.9772 105.675 22.0349C99.6185 22.0926 94.6578 17.1895 94.6001 11.0751C94.5424 5.01842 99.4455 0.057683 105.56 0C111.617 0 116.577 4.84537 116.635 10.9598Z"
        fill={default_nav_color}
      />
      <path
        d="M113.808 10.7875C113.808 15.4021 110.116 19.1515 105.502 19.2092C100.887 19.2092 97.1378 15.5175 97.0801 10.9028C97.0801 6.2882 100.772 2.53881 105.386 2.48113C110.001 2.42344 113.808 6.17284 113.808 10.7875Z"
        fill="#71B7E2"
      />
      <path
        d="M114.038 11.1331C114.038 15.69 110.404 19.3817 105.847 19.4394C101.29 19.4394 97.5987 15.8054 97.541 11.2484C97.541 6.69147 101.175 2.99976 105.732 2.94207C110.289 2.88439 114.038 6.5761 114.038 11.1331Z"
        fill="#FF725E"
      />
      <path
        d="M114.038 11.1331C114.038 15.69 110.404 19.3817 105.847 19.4394C101.29 19.4394 97.5987 15.8054 97.541 11.2484C97.541 6.69147 101.175 2.99976 105.732 2.94207C110.289 2.88439 114.038 6.5761 114.038 11.1331Z"
        fill={default_color}
      />
      <path
        d="M106.137 10.4995V8.36527C106.483 8.42295 106.887 8.48063 107.233 8.65368C107.521 8.82673 107.752 8.99978 107.752 8.99978V8.9421L108.098 8.19222L108.156 8.01917L107.752 7.61539L107.579 7.67307C107.117 7.44234 106.598 7.26929 106.021 7.21161V6.05795L105.675 5.76953V6.05795H105.156V7.21161C103.541 7.38466 102.733 8.30758 102.791 9.40356C102.791 10.8456 104.06 11.2494 105.214 11.4802V13.5567C104.406 13.4991 103.656 13.2106 103.137 12.7492L102.676 13.7875C103.195 14.2489 104.233 14.595 105.272 14.6527V15.518H104.868L105.272 15.8064H106.137V14.6527C107.752 14.4797 108.559 13.5567 108.559 12.4608C108.559 11.1341 107.29 10.7303 106.137 10.4995ZM105.272 10.2688C104.637 10.0958 104.233 9.86503 104.233 9.34588C104.233 8.88442 104.522 8.48063 105.272 8.36527V10.2688ZM106.137 13.6144V11.7686C106.771 11.9416 107.233 12.1723 107.233 12.6915C107.233 13.0953 106.887 13.4414 106.137 13.6144Z"
        fill="#71B7E2"
      />
      <path
        d="M105.791 14.4209V15.5746H104.926V14.4209C103.888 14.3633 102.907 14.0172 102.33 13.5557L102.792 12.5174C103.311 12.9212 104.118 13.2096 104.868 13.325V11.2484C103.714 10.96 102.445 10.6139 102.445 9.17179C102.445 8.07581 103.195 7.15288 104.868 6.97983V5.82617H105.733V6.97983C106.483 7.03752 107.291 7.26825 107.868 7.61435L107.464 8.65264C106.887 8.30654 106.31 8.13349 105.791 8.07581V10.2101C106.945 10.4985 108.214 10.8446 108.214 12.2867C108.214 13.325 107.464 14.2479 105.791 14.4209ZM104.926 9.97935V8.13349C104.176 8.24886 103.888 8.65264 103.888 9.11411C103.83 9.57557 104.291 9.8063 104.926 9.97935ZM106.887 12.402C106.887 11.8829 106.426 11.6522 105.791 11.4791V13.325C106.541 13.2096 106.887 12.8058 106.887 12.402Z"
        fill="white"
      />
      <path
        d="M169.357 163.878H195.834C197.103 163.878 198.199 162.84 198.199 161.513V134.172C198.199 132.903 197.16 131.807 195.834 131.807H169.357C168.088 131.807 166.992 132.845 166.992 134.172V161.513C167.05 162.84 168.088 163.878 169.357 163.878Z"
        fill={default_nav_color}
      />
      <path
        d="M195.834 131.807H169.357C168.088 131.807 166.992 132.845 166.992 134.172V141.036H198.141V134.172C198.199 132.845 197.16 131.807 195.834 131.807Z"
        fill={default_color}
      />
      <path
        d="M189.604 149.055H193.757C193.988 149.055 194.219 148.824 194.219 148.593V144.44C194.219 144.209 193.988 143.979 193.757 143.979H189.604C189.373 143.979 189.143 144.209 189.143 144.44V148.593C189.143 148.824 189.373 149.055 189.604 149.055Z"
        fill="#E4F1FF"
      />
      <path
        d="M183.663 149.055H187.816C188.047 149.055 188.277 148.824 188.277 148.593V144.44C188.277 144.209 188.047 143.979 187.816 143.979H183.663C183.432 143.979 183.201 144.209 183.201 144.44V148.593C183.201 148.824 183.432 149.055 183.663 149.055Z"
        fill={"white"}
      />
      <path
        d="M175.183 134.229C174.606 134.229 174.145 133.768 174.145 133.191V129.499C174.145 128.922 174.606 128.461 175.183 128.461C175.76 128.461 176.221 128.922 176.221 129.499V133.191C176.221 133.768 175.76 134.229 175.183 134.229Z"
        fill="#8EC3F4"
      />
      <path
        d="M180.433 134.229C179.856 134.229 179.395 133.768 179.395 133.191V129.499C179.395 128.922 179.856 128.461 180.433 128.461C181.01 128.461 181.471 128.922 181.471 129.499V133.191C181.471 133.768 181.01 134.229 180.433 134.229Z"
        fill="#8EC3F4"
      />
      <path
        d="M185.45 134.229C184.874 134.229 184.412 133.768 184.412 133.191V129.499C184.412 128.922 184.874 128.461 185.45 128.461C186.027 128.461 186.489 128.922 186.489 129.499V133.191C186.489 133.768 186.027 134.229 185.45 134.229Z"
        fill="#8EC3F4"
      />
      <path
        d="M177.663 149.053H181.816C182.047 149.053 182.277 148.822 182.277 148.591V144.438C182.277 144.207 182.047 143.977 181.816 143.977H177.663C177.432 143.977 177.201 144.207 177.201 144.438V148.591C177.201 148.822 177.432 149.053 177.663 149.053Z"
        fill="#E4F1FF"
      />
      <path
        d="M171.721 149.053H175.874C176.105 149.053 176.336 148.822 176.336 148.591V144.438C176.336 144.207 176.105 143.977 175.874 143.977H171.721C171.49 143.977 171.26 144.207 171.26 144.438V148.591C171.26 148.822 171.49 149.053 171.721 149.053Z"
        fill="#E4F1FF"
      />
      <path
        d="M189.545 154.707H193.699C193.929 154.707 194.16 154.476 194.16 154.246V150.092C194.16 149.862 193.929 149.631 193.699 149.631H189.545C189.315 149.631 189.084 149.862 189.084 150.092V154.246C189.142 154.476 189.315 154.707 189.545 154.707Z"
        fill="#E4F1FF"
      />
      <path
        d="M183.604 154.707H187.757C187.988 154.707 188.219 154.476 188.219 154.246V150.092C188.219 149.862 187.988 149.631 187.757 149.631H183.604C183.373 149.631 183.143 149.862 183.143 150.092V154.246C183.143 154.476 183.373 154.707 183.604 154.707Z"
        fill="white"
      />
      <path
        d="M177.606 154.707H181.759C181.99 154.707 182.221 154.476 182.221 154.246V150.092C182.221 149.862 181.99 149.631 181.759 149.631H177.606C177.375 149.631 177.145 149.862 177.145 150.092V154.246C177.202 154.476 177.375 154.707 177.606 154.707Z"
        fill="#E4F1FF"
      />
      <path
        d="M171.665 154.707H175.818C176.048 154.707 176.279 154.476 176.279 154.246V150.092C176.279 149.862 176.048 149.631 175.818 149.631H171.665C171.434 149.631 171.203 149.862 171.203 150.092V154.246C171.203 154.476 171.434 154.707 171.665 154.707Z"
        fill="#E4F1FF"
      />
      <path
        d="M189.545 160.359H193.699C193.929 160.359 194.16 160.129 194.16 159.898V155.745C194.16 155.514 193.929 155.283 193.699 155.283H189.545C189.315 155.283 189.084 155.514 189.084 155.745V159.898C189.084 160.186 189.257 160.359 189.545 160.359Z"
        fill="#E4F1FF"
      />
      <path
        d="M183.604 160.359H187.757C187.988 160.359 188.219 160.129 188.219 159.898V155.745C188.219 155.514 187.988 155.283 187.757 155.283H183.604C183.373 155.283 183.143 155.514 183.143 155.745V159.898C183.143 160.186 183.316 160.359 183.604 160.359Z"
        fill="white"
      />
      <path
        d="M177.606 160.359H181.759C181.99 160.359 182.221 160.129 182.221 159.898V155.745C182.221 155.514 181.99 155.283 181.759 155.283H177.606C177.375 155.283 177.145 155.514 177.145 155.745V159.898C177.145 160.186 177.318 160.359 177.606 160.359Z"
        fill="#E4F1FF"
      />
      <path
        d="M171.665 160.359H175.818C176.048 160.359 176.279 160.129 176.279 159.898V155.745C176.279 155.514 176.048 155.283 175.818 155.283H171.665C171.434 155.283 171.203 155.514 171.203 155.745V159.898C171.203 160.186 171.376 160.359 171.665 160.359Z"
        fill="#E4F1FF"
      />
      <path
        d="M190.47 134.229C189.893 134.229 189.432 133.768 189.432 133.191V129.499C189.432 128.922 189.893 128.461 190.47 128.461C191.047 128.461 191.508 128.922 191.508 129.499V133.191C191.508 133.768 191.047 134.229 190.47 134.229Z"
        fill="#8EC3F4"
      />
      <path
        opacity="0.35"
        d="M163.992 63.3945H88.0236C85.5433 63.3945 83.582 65.4134 83.582 67.8361V68.5283V107.349C83.582 109.829 85.6009 111.791 88.0236 111.791H163.992C166.473 111.791 168.434 109.772 168.434 107.349V100.312V67.7784C168.434 65.4134 166.473 63.3945 163.992 63.3945Z"
        fill={default_nav_color}
      />
      <path
        d="M162.031 110.348H86.0627C83.5823 110.348 81.6211 108.329 81.6211 105.907V66.3361C81.6211 63.8558 83.64 61.8945 86.0627 61.8945H162.031C164.512 61.8945 166.473 63.9134 166.473 66.3361V105.907C166.473 108.329 164.512 110.348 162.031 110.348Z"
        fill={default_nav_color}
      />
      <path
        d="M100.829 88.891H90.2158C89.2929 88.891 88.543 88.1411 88.543 87.2182V81.6806C88.543 80.7577 89.2929 80.0078 90.2158 80.0078H100.829C101.752 80.0078 102.502 80.7577 102.502 81.6806V87.2182C102.56 88.1411 101.81 88.891 100.829 88.891Z"
        fill="white"
      />
      <path
        d="M160.647 70.5469H145.304C144.9 70.5469 144.611 70.2585 144.611 69.8547V66.9129C144.611 66.5091 144.9 66.2207 145.304 66.2207H160.647C161.051 66.2207 161.339 66.5091 161.339 66.9129V69.8547C161.339 70.2008 161.051 70.5469 160.647 70.5469Z"
        fill={default_color}
      />
      <path
        d="M102.675 96.4468H89.5232C88.7156 96.4468 88.0234 95.7546 88.0234 94.947C88.0234 94.1395 88.7156 93.4473 89.5232 93.4473H102.675C103.482 93.4473 104.175 94.1395 104.175 94.947C104.175 95.8123 103.482 96.4468 102.675 96.4468Z"
        fill="white"
      />
      <path
        d="M121.308 96.4468H108.156C107.348 96.4468 106.656 95.7546 106.656 94.947C106.656 94.1395 107.348 93.4473 108.156 93.4473H121.308C122.115 93.4473 122.807 94.1395 122.807 94.947C122.75 95.8123 122.115 96.4468 121.308 96.4468Z"
        fill="white"
      />
      <path
        d="M140.054 96.4468H126.902C126.095 96.4468 125.402 95.7546 125.402 94.947C125.402 94.1395 126.095 93.4473 126.902 93.4473H140.054C140.861 93.4473 141.554 94.1395 141.554 94.947C141.496 95.8123 140.861 96.4468 140.054 96.4468Z"
        fill="white"
      />
      <path
        d="M158.628 96.4468H145.476C144.669 96.4468 143.977 95.7546 143.977 94.947C143.977 94.1395 144.669 93.4473 145.476 93.4473H158.628C159.436 93.4473 160.128 94.1395 160.128 94.947C160.128 95.8123 159.436 96.4468 158.628 96.4468Z"
        fill="white"
      />
      <path
        d="M106.829 106.483H89.755C89.2358 106.483 88.832 106.079 88.832 105.56C88.832 105.04 89.2358 104.637 89.755 104.637H106.829C107.348 104.637 107.752 105.04 107.752 105.56C107.752 106.079 107.348 106.483 106.829 106.483Z"
        fill={default_color}
      />
      <path
        d="M158.571 106.483H150.149C149.63 106.483 149.227 106.079 149.227 105.56C149.227 105.04 149.63 104.637 150.149 104.637H158.571C159.09 104.637 159.494 105.04 159.494 105.56C159.494 106.079 159.09 106.483 158.571 106.483Z"
        fill={default_color}
      />
      <mask
        id="mask0_13931_8272"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="88"
        y="80"
        width="15"
        height="9"
      >
        <path
          d="M100.829 88.891H90.2158C89.2929 88.891 88.543 88.1411 88.543 87.2182V81.6806C88.543 80.7577 89.2929 80.0078 90.2158 80.0078H100.829C101.752 80.0078 102.502 80.7577 102.502 81.6806V87.2182C102.56 88.1411 101.81 88.891 100.829 88.891Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_13931_8272)">
        <path
          d="M91.0813 79.7773C92.3503 81.3925 94.3115 84.9688 90.9082 89.122"
          stroke="#8EC3F4"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </g>
      <mask
        id="mask1_13931_8272"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="88"
        y="80"
        width="15"
        height="9"
      >
        <path
          d="M100.829 88.891H90.2158C89.2929 88.891 88.543 88.1411 88.543 87.2182V81.6806C88.543 80.7577 89.2929 80.0078 90.2158 80.0078H100.829C101.752 80.0078 102.502 80.7577 102.502 81.6806V87.2182C102.56 88.1411 101.81 88.891 100.829 88.891Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_13931_8272)">
        <path
          d="M87.6191 84.334H92.8106"
          stroke="#8EC3F4"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
      </g>
      <mask
        id="mask2_13931_8272"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="88"
        y="80"
        width="15"
        height="9"
      >
        <path
          d="M100.829 88.891H90.2158C89.2929 88.891 88.543 88.1411 88.543 87.2182V81.6806C88.543 80.7577 89.2929 80.0078 90.2158 80.0078H100.829C101.752 80.0078 102.502 80.7577 102.502 81.6806V87.2182C102.56 88.1411 101.81 88.891 100.829 88.891Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_13931_8272)">
        <path
          d="M99.6178 79.7773C98.3488 81.3925 96.3876 84.9688 99.7909 89.122"
          stroke="#8EC3F4"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </g>
      <mask
        id="mask3_13931_8272"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="88"
        y="80"
        width="15"
        height="9"
      >
        <path
          d="M100.829 88.891H90.2158C89.2929 88.891 88.543 88.1411 88.543 87.2182V81.6806C88.543 80.7577 89.2929 80.0078 90.2158 80.0078H100.829C101.752 80.0078 102.502 80.7577 102.502 81.6806V87.2182C102.56 88.1411 101.81 88.891 100.829 88.891Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_13931_8272)">
        <path
          d="M103.079 84.334H97.9453"
          stroke="#8EC3F4"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
      </g>
      <path
        d="M45.1659 47.7034C45.1659 46.6074 44.3007 45.7422 43.2047 45.7422H14.7093C13.6133 45.7422 12.748 46.6074 12.748 47.7034V63.1048C12.748 64.2008 13.6133 65.066 14.7093 65.066H38.2439L40.4359 68.0655C41.1281 68.9884 42.3394 68.9308 42.8586 67.8925L44.7621 64.2584C44.9929 63.9123 45.1659 63.5086 45.1659 63.1048V47.7034Z"
        fill={default_color}
      />
      <path
        d="M39.7444 52.6636H17.8826C17.5365 52.6636 17.248 52.3751 17.248 52.029C17.248 51.6829 17.5365 51.3945 17.8826 51.3945H39.6867C40.0328 51.3945 40.3213 51.6829 40.3213 52.029C40.3789 52.3751 40.0905 52.6636 39.7444 52.6636Z"
        fill="white"
      />
      <path
        d="M33.1109 55.5483H17.8826C17.5365 55.5483 17.248 55.2599 17.248 54.9138C17.248 54.5677 17.5365 54.2793 17.8826 54.2793H33.0532C33.3993 54.2793 33.6877 54.5677 33.6877 54.9138C33.7454 55.2599 33.457 55.5483 33.1109 55.5483Z"
        fill="white"
      />
      <path
        d="M36.8026 58.4897H17.8826C17.5365 58.4897 17.248 58.2013 17.248 57.8552C17.248 57.5091 17.5365 57.2207 17.8826 57.2207H36.8026C37.1487 57.2207 37.4371 57.5091 37.4371 57.8552C37.3794 58.259 37.1487 58.4897 36.8026 58.4897Z"
        fill="white"
      />
      <g opacity="0.35">
        <path
          d="M124.712 147.266C124.77 156.668 117.156 164.398 107.753 164.398C98.3509 164.455 90.6214 156.841 90.6214 147.439C90.5637 138.036 98.1779 130.307 107.58 130.307C116.983 130.249 124.654 137.863 124.712 147.266Z"
          fill={default_nav_color}
        />
      </g>
      <path
        d="M123.426 147.11C123.953 137.599 116.67 129.462 107.159 128.935C97.6475 128.408 89.5101 135.691 88.9833 145.202C88.4564 154.714 95.7396 162.851 105.251 163.378C114.762 163.905 122.899 156.621 123.426 147.11Z"
        fill={default_nav_color}
      />
      <path
        d="M119.001 145.822C119.059 153.032 113.233 158.974 105.965 158.974C98.7543 159.031 92.8129 153.205 92.8129 145.937C92.7552 138.727 98.5812 132.786 105.849 132.786C113.06 132.728 119.001 138.554 119.001 145.822Z"
        fill="#71B7E2"
      />
      <path
        d="M119.405 146.342C119.463 153.495 113.694 159.321 106.542 159.321C99.3889 159.378 93.5629 153.61 93.5629 146.457C93.5052 139.305 99.2736 133.479 106.426 133.479C113.521 133.479 119.347 139.247 119.405 146.342Z"
        fill="#FF725E"
      />
      <path
        d="M119.405 146.342C119.463 153.495 113.694 159.321 106.542 159.321C99.3889 159.378 93.5629 153.61 93.5629 146.457C93.5052 139.305 99.2736 133.479 106.426 133.479C113.521 133.479 119.347 139.247 119.405 146.342Z"
        fill={default_color}
      />
      <path
        d="M107.002 145.304V142.016C107.579 142.074 108.156 142.247 108.79 142.478C109.309 142.709 109.598 142.997 109.598 142.997L109.656 142.882L110.175 141.67L110.29 141.382L109.713 140.747L109.425 140.863C108.675 140.517 107.867 140.228 107.002 140.171V138.325L106.425 137.863V138.325H105.675V140.171C103.137 140.459 101.926 141.901 101.926 143.574C101.926 145.824 103.887 146.458 105.733 146.862V150.15C104.464 150.034 103.253 149.573 102.445 148.938L101.695 150.611C102.561 151.361 104.118 151.88 105.733 151.938V153.322H105.099L105.733 153.726H107.06V151.88C109.656 151.592 110.867 150.15 110.867 148.477C110.809 146.285 108.79 145.766 107.002 145.304ZM105.618 144.958C104.637 144.67 103.945 144.324 103.945 143.516C103.945 142.824 104.406 142.189 105.618 142.016V144.958ZM107.002 150.207V147.323C107.983 147.612 108.733 147.958 108.733 148.765C108.675 149.458 108.213 150.034 107.002 150.207Z"
        fill="#71B7E2"
      />
      <path
        d="M106.484 151.533V153.379H105.157V151.533C103.542 151.418 101.984 150.956 101.119 150.206L101.869 148.534C102.677 149.168 103.888 149.63 105.157 149.745V146.457C103.311 146.053 101.408 145.419 101.35 143.169C101.35 141.496 102.561 140.054 105.099 139.766V137.92H106.426V139.766C107.637 139.823 108.849 140.17 109.772 140.746L109.137 142.419C108.272 141.9 107.349 141.669 106.484 141.554V144.842C108.33 145.246 110.291 145.823 110.291 148.13C110.233 149.803 109.022 151.245 106.484 151.533ZM105.099 144.553V141.612C103.946 141.785 103.426 142.419 103.426 143.111C103.426 143.919 104.119 144.265 105.099 144.553ZM108.156 148.303C108.156 147.495 107.407 147.149 106.426 146.861V149.745C107.637 149.572 108.156 148.995 108.156 148.303Z"
        fill="white"
      />
      <path
        d="M119.231 140.805C123.117 140.805 126.268 137.654 126.268 133.768C126.268 129.881 123.117 126.73 119.231 126.73C115.344 126.73 112.193 129.881 112.193 133.768C112.193 137.654 115.344 140.805 119.231 140.805Z"
        fill={default_color}
      />
      <path
        d="M116.865 133.651L117.788 135.785C117.904 136.016 118.192 136.074 118.307 135.901L121.826 132.094"
        stroke={default_nav_color}
        stroke-width="1.5236"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M119.23 139.593C122.448 139.593 125.056 136.985 125.056 133.767C125.056 130.55 122.448 127.941 119.23 127.941C116.013 127.941 113.404 130.55 113.404 133.767C113.404 136.985 116.013 139.593 119.23 139.593Z"
        stroke="white"
        stroke-width="0.5"
        stroke-miterlimit="10"
      />
      <path
        d="M26.4775 79.8913C26.4775 79.8913 28.6118 74.0076 37.5526 76.5457C43.5517 78.2185 47.0126 83.0062 46.6089 89.9858C46.2051 97.3692 50.8197 95.0619 48.1086 105.733C46.2628 112.944 54.9729 118.885 47.0703 130.191C40.6675 139.362 4.38488 134.056 4.73098 118.308C4.90402 110.867 11.1338 114.328 11.4799 102.33C11.6529 97.1962 21.3437 98.9267 20.0747 91.1972C19.4978 87.217 22.6127 79.4298 26.4775 79.8913Z"
        fill="#1B2E35"
      />
      <path
        d="M37.4941 102.791C37.4941 102.791 39.9745 104.291 39.34 107.636C38.7055 110.924 38.4748 114.039 41.3012 116.462"
        stroke="#38393F"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M44.0711 95.1191C44.7056 97.7726 45.8016 99.5607 44.2442 103.137C40.8985 110.751 47.5898 112.309 44.3595 119.288C43.2059 121.769 42.7444 123.961 42.5137 125.807"
        stroke="#38393F"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M45.5137 132.787H56.9349L53.4163 128.922L45.9175 130.076L45.5137 132.787Z"
        fill="#F7C2A0"
      />
      <path
        d="M24.6895 99.3867C24.6895 99.3867 21.4016 101.233 21.8631 104.636C22.4399 109.308 17.8253 110 18.9789 112.942"
        stroke="#38393F"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M23.1895 86.9277C23.1895 86.9277 21.8628 89.8119 22.2089 92.7537C22.9011 98.7528 14.7678 98.8681 14.7101 104.925C14.6524 108.732 11.5375 111.039 9.46094 113.231"
        stroke="#38393F"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M79.1414 201.488L79.0837 208.468L72.6809 208.526L71.6426 202.873L79.1414 201.488Z"
        fill="#E8977B"
      />
      <path
        d="M59.8752 203.68L59.2407 211.294L52.2611 211.928L51.1074 206.218L59.8752 203.68Z"
        fill="#E8977B"
      />
      <path
        d="M31.0926 152.11C38.476 156.09 53.935 163.993 62.7029 165.55C63.3951 165.666 63.9719 166.243 64.0873 166.992L69.3941 203.333C69.5095 203.967 69.9709 204.544 70.6054 204.717C72.6243 205.294 77.239 206.102 80.7576 202.294C81.1037 201.948 81.2768 201.487 81.2191 201.025C80.873 195.661 78.3926 161.051 76.6622 156.494C74.874 151.88 49.8972 147.034 46.84 146.457C46.667 146.4 46.4362 146.4 46.2055 146.457L31.6117 148.938C29.9966 149.226 29.7082 151.36 31.0926 152.11Z"
        fill="#1B2E35"
      />
      <path
        d="M20.3628 154.419C20.4205 154.419 14.3638 165.782 44.532 168.436C45.2242 168.493 45.6857 169.07 45.7434 169.762L49.4928 206.391C49.5505 206.968 49.9542 207.487 50.4734 207.66C52.3769 208.295 56.8762 209.275 60.914 205.757C61.2601 205.468 61.4909 205.007 61.4909 204.545C61.3178 199.181 60.3372 164.456 58.7798 159.899C57.28 155.457 35.0143 149.977 30.0536 148.766C29.4768 148.65 28.8423 148.881 28.4962 149.343L20.3628 154.419Z"
        fill="#1B2E35"
      />
      <path
        d="M18.9795 107.925C28.3819 105.098 39.8031 107.579 41.7067 108.098C43.8409 108.732 46.4943 111.328 49.3785 117.039C52.2627 122.807 52.2627 122.807 52.2627 122.807L46.4943 124.538V146.515C41.3606 150.899 35.1308 153.841 28.5549 155.11L20.7677 156.494C20.0178 156.609 19.3256 156.032 19.3256 155.283L20.1909 127.306L10.3848 127.825C10.4424 127.825 9.98098 110.578 18.9795 107.925Z"
        fill={default_color}
      />
      <path
        d="M38.4749 128.23L20.9393 133.075L20.2471 127.307L12.0561 127.653C12.0561 127.653 10.2103 141.843 15.0556 142.131C22.3814 142.593 41.3591 133.306 41.3591 133.306L38.4749 128.23Z"
        fill="#F7C2A0"
      />
      <path
        d="M35.6497 84.0455C36.6304 83.6418 38.0724 83.9879 38.7646 84.7377C40.7835 86.8143 43.2639 90.3907 42.3987 95.0053C41.418 100.312 40.149 103.658 37.2072 103.6C36.2266 103.6 35.8228 104.35 35.8228 105.215L35.6497 107.984C34.7845 112.829 27.6318 113.579 25.6129 108.965L25.2668 108.215C25.0361 107.638 25.0361 107.061 25.2668 106.484C26.132 104.696 28.2086 101.639 25.9013 94.8323C25.7283 94.3708 25.0361 92.7557 25.4975 91.6597C25.9013 90.6791 27.1127 90.5637 27.9202 91.2559C28.0933 91.429 28.324 91.429 28.7855 91.1406C30.2852 90.0446 30.4583 86.1221 35.6497 84.0455Z"
        fill="#F7C2A0"
      />
      <path
        d="M36.2827 83.8729C36.2827 83.8729 36.3404 85.661 39.3399 87.2185C42.9739 89.122 40.7243 91.3717 42.4548 94.0251C44.2429 96.8515 42.6855 91.487 42.6855 91.487L42.1663 85.3726C41.128 81.3925 39.6283 78.7968 35.8212 79.6043C33.8023 80.0081 31.7834 80.8157 30.7451 82.1424C27.9763 85.7764 27.3418 93.9674 27.3418 93.9674C27.3418 93.9674 30.399 94.0251 32.0141 90.1603C33.86 85.8341 33.6292 85.1419 36.2827 83.8729Z"
        fill="#1B2E35"
      />
      <path
        d="M53.6468 133.191H46.6672C46.0904 133.191 45.6289 132.729 45.6289 132.152V116.982C45.6289 116.405 46.0904 115.943 46.6672 115.943H53.6468C54.2237 115.943 54.6851 116.405 54.6851 116.982V132.152C54.6851 132.729 54.2237 133.191 53.6468 133.191Z"
        fill="#1C004F"
      />
      <path
        d="M59.645 127.481C57.7414 124.828 55.088 119.867 54.2227 119.751C53.7613 119.694 53.5882 120.098 53.819 121.136C54.4535 124.135 55.4918 124.712 52.6653 124.251C52.2615 124.193 50.1849 123.789 49.089 123.559C48.2814 123.385 47.5315 124.193 47.8776 125.001C47.993 125.231 48.1083 125.462 48.3391 125.693C48.3391 125.693 47.7046 127.539 49.4351 128C49.4351 128 49.6658 130.134 50.9925 130.192C50.9925 130.192 52.4346 131.057 55.3764 132.326C56.7608 133.249 58.549 132.961 59.5873 131.692L59.818 131.403C60.7986 130.25 60.6833 128.577 59.645 127.481Z"
        fill="#F7C2A0"
      />
      <path
        d="M48.627 125.691C49.6076 125.98 51.1073 126.441 52.0303 126.441"
        stroke="#F18884"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M49.666 128C49.666 128 51.3965 128.635 52.6078 128.577"
        stroke="#F18884"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M38.4746 128.229L40.6089 126.672C42.1663 123.615 44.9928 118.077 45.5119 118.308C46.0888 118.596 46.2041 119.231 45.858 120.442C44.9351 123.384 45.3966 124.768 47.0694 124.653C47.6462 124.595 52.5493 124.595 52.5493 124.595C52.5493 124.595 53.7029 125.634 52.1455 126.845C52.1455 126.845 52.4916 128.806 50.6457 128.921C50.6457 128.921 50.0689 130.94 48.7422 130.825C48.7422 130.825 47.1847 131.46 44.0699 132.152L41.4164 133.305L38.4746 128.229Z"
        fill="#F7C2A0"
      />
      <path
        d="M51.7433 126.846C50.705 126.961 49.1475 127.192 48.2246 126.961"
        stroke="#F18884"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M50.3003 128.98C50.3003 128.98 48.4544 129.327 47.3008 129.038"
        stroke="#F18884"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M45.4557 118.367C46.0326 118.656 46.1479 119.29 45.8018 120.501C44.8789 123.443 45.3404 124.828 47.0132 124.712C47.59 124.655 52.4931 124.655 52.4931 124.655C52.4931 124.655 53.6467 125.693 52.0893 126.904C52.0893 126.904 52.4354 128.866 50.5895 128.981C50.5895 128.981 50.0127 131 48.686 130.884C48.686 130.884 47.1286 131.519 44.0137 132.211"
        stroke="#F18884"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M50.9922 130.191C51.6844 130.538 54.2224 131.807 55.3761 132.326"
        stroke="#F18884"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M79.7769 207.256C79.7769 207.256 77.7003 208.929 71.8743 208.006C71.8743 208.006 71.4706 211.64 69.8554 213.543C68.298 215.447 72.8549 217.754 76.3159 216.658C79.7769 215.562 82.315 218.158 85.7183 217.696C89.1216 217.293 94.0246 213.601 92.2364 212.447C90.3906 211.351 83.9301 211.409 82.315 208.871C81.392 207.487 79.7769 207.256 79.7769 207.256Z"
        fill="#192D34"
      />
      <path
        d="M84.1035 216.369C87.2184 217.292 90.7371 213.658 90.7371 213.658"
        stroke="#485076"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M71.2969 214.234C71.2969 214.234 72.5082 215.734 75.3924 215.446C78.2188 215.157 80.9876 215.503 84.1025 216.426"
        stroke="#485076"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M60.0497 209.91C60.0497 209.91 56.8195 212.217 51.0512 211.006C51.0512 211.006 51.1089 214.467 49.4361 216.255C47.7633 218.043 52.2048 220.639 55.7235 219.774C59.2422 218.909 61.6649 221.562 65.0682 221.331C68.4715 221.101 73.6053 217.697 71.8748 216.486C70.1443 215.275 62.9916 214.467 61.5495 211.871C60.6266 210.372 60.0497 209.91 60.0497 209.91Z"
        fill="#192D34"
      />
      <path
        d="M63.4531 219.889C66.5103 220.985 70.202 217.582 70.202 217.582"
        stroke="#485076"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M50.7617 217.004C50.7617 217.004 51.9154 218.561 54.7995 218.388C57.6837 218.273 60.3948 218.734 63.452 219.888"
        stroke="#485076"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M59.7614 166.763C59.473 163.417 59.1269 160.879 58.7808 159.898C57.9156 157.36 50.2437 154.476 42.918 152.227"
        stroke="#2F3346"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M59.5869 184.815C59.3561 179.566 59.0677 174.029 58.7793 169.414"
        stroke="#2F3346"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M53.4727 206.966C55.3762 206.85 57.5682 206.273 59.6447 204.485C59.9908 204.197 60.2216 203.735 60.2216 203.274C60.1639 201.543 60.0485 196.756 59.8178 190.93"
        stroke="#2F3346"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M51.2812 159.666C51.2812 159.666 51.4543 164.454 57.0496 166.184"
        stroke="#2F3346"
        stroke-width="0.5"
        stroke-miterlimit="10"
      />
      <path
        d="M70.375 155.57C70.375 155.57 71.5287 160.012 76.201 162.435"
        stroke="#2F3346"
        stroke-width="0.5"
        stroke-miterlimit="10"
      />
      <path
        d="M71.6426 202.871C71.6426 202.871 76.0265 202.986 79.6028 200.275"
        stroke="#2F3346"
        stroke-width="0.5"
        stroke-miterlimit="10"
      />
      <path
        d="M24.1699 108.848C24.1699 108.848 25.1505 114.616 32.5916 113.116"
        stroke="#315782"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M36.5141 109.252C36.5141 109.252 36.5718 110.867 34.4375 112.136"
        stroke="#315782"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M11.7676 126.499L17.882 126.441"
        stroke="#315782"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M20.248 127.191L20.8826 123.096"
        stroke="#315782"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M29.5354 152.457C27.1704 153.092 24.5746 153.611 21.6328 153.957"
        stroke="#315782"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M44.2447 145.361C44.2447 145.361 40.726 148.592 33.5156 151.187"
        stroke="#315782"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M34.8413 147.727C33.2839 148.303 31.6688 148.707 30.1113 148.765"
        stroke="#315782"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M44.1292 142.016C44.1292 142.016 41.4181 144.381 37.8418 146.342"
        stroke="#315782"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M30.6314 156.438C30.6314 156.438 32.5926 160.706 29.3047 163.821"
        stroke="#2F3346"
        stroke-width="0.5"
        stroke-miterlimit="10"
      />
    </svg>
  );
};
export default PaymentAmountImg;
