import { useSelector } from "react-redux";

const RequestCallBackSvg = () => {
  let { default_color } = useSelector((s) => s.layoutReduceData);

  return (
    <svg
      width="188"
      height="199"
      viewBox="0 0 188 199"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_512_10726)">
        <path
          d="M178.8 0H174C178.8 0 182.6 3.9 182.3 8.8L174.7 155C174.4 159.8 170.3 163.8 165.4 163.8H170.2C175 163.8 179.2 159.9 179.5 155L187.1 8.7C187.4 3.9 183.7 0 178.8 0Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.1"
          d="M178.8 0H174C178.8 0 182.6 3.9 182.3 8.8L174.7 155C174.4 159.8 170.3 163.8 165.4 163.8H170.2C175 163.8 179.2 159.9 179.5 155L187.1 8.7C187.4 3.9 183.7 0 178.8 0Z"
          fill="white"
        />
        <path
          d="M168.8 163.8H109.8C105 163.8 101.2 159.9 101.5 155L109.1 8.70039C109.4 3.90039 113.5 -0.0996094 118.4 -0.0996094H177.4C182.2 -0.0996094 186 3.80039 185.7 8.70039L178 155C177.8 159.9 173.6 163.8 168.8 163.8Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.4"
          d="M168.8 163.8H109.8C105 163.8 101.2 159.9 101.5 155L109.1 8.70039C109.4 3.90039 113.5 -0.0996094 118.4 -0.0996094H177.4C182.2 -0.0996094 186 3.80039 185.7 8.70039L178 155C177.8 159.9 173.6 163.8 168.8 163.8Z"
          fill="white"
        />
        <path
          d="M118.6 2.80078C115.2 2.80078 112.3 5.60078 112.2 8.90078L104.6 155.201C104.5 156.801 105 158.201 106.1 159.301C107.2 160.401 108.5 161.001 110.1 161.001H169.1C172.5 161.001 175.4 158.201 175.5 154.901L183.1 8.60078C183.2 7.00078 182.7 5.60078 181.6 4.50078C180.5 3.40078 179.2 2.80078 177.6 2.80078H118.6Z"
          fill={`${default_color}`}
        />
        <path
          d="M148.2 143.099C148 147.199 144.5 150.499 140.4 150.499C136.3 150.499 133.2 147.199 133.4 143.099C133.6 138.999 137.1 135.699 141.2 135.699C145.2 135.699 148.4 138.999 148.2 143.099Z"
          fill="white"
        />
        <path
          d="M145.2 142.5C143 141 138.5 141 136.1 142.5C135.3 142.6 134.6 143.4 134.5 144.3C134.4 145.3 138.5 145.3 138.6 144.3C138.6 143.9 138.5 143.5 138.2 143.2C139.7 142.9 141.4 142.9 142.8 143.2C142.5 143.5 142.4 143.9 142.4 144.3C142.3 145.3 146.4 145.3 146.5 144.3C146.6 143.4 146 142.7 145.2 142.5Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.2"
          d="M145.2 142.5C143 141 138.5 141 136.1 142.5C135.3 142.6 134.6 143.4 134.5 144.3C134.4 145.3 138.5 145.3 138.6 144.3C138.6 143.9 138.5 143.5 138.2 143.2C139.7 142.9 141.4 142.9 142.8 143.2C142.5 143.5 142.4 143.9 142.4 144.3C142.3 145.3 146.4 145.3 146.5 144.3C146.6 143.4 146 142.7 145.2 142.5Z"
          fill="black"
        />
        <path
          d="M140.4 57.1008C140.4 57.7008 140 58.0008 139.3 58.0008C139 58.0008 138.5 57.9008 138.4 57.4008V57.3008L138 57.6008V57.7008C138.2 58.2008 138.7 58.5008 139.3 58.5008C140.2 58.5008 140.8 58.0008 140.9 57.1008L141.1 53.8008H140.6L140.4 57.1008Z"
          fill="white"
        />
        <path
          d="M143.1 55C142.2 55 141.3 55.8 141.3 56.7C141.3 57.6 142 58.4 142.9 58.4C143.8 58.4 144.7 57.6 144.7 56.7C144.7 55.8 144.1 55 143.1 55ZM143 58C142.3 58 141.8 57.4 141.8 56.7C141.9 56 142.4 55.4 143.1 55.4C143.8 55.4 144.3 56 144.3 56.7C144.3 57.5 143.7 58 143 58Z"
          fill="white"
        />
        <path
          d="M147.2 55.1C146.8 55.1 146.4 55.3 146.1 55.6L146.2 54H145.7L145.5 58.5H146L146.1 56.7C146.2 55.9 146.5 55.5 147.2 55.5C147.7 55.5 148 55.8 148 56.4L147.9 58.4H148.4L148.5 56.4C148.4 55.6 148 55.1 147.2 55.1Z"
          fill="white"
        />
        <path
          d="M150.5 55C150.1 55 149.7 55.2 149.4 55.5V55.1H149L148.8 58.4H149.3L149.4 56.6C149.5 55.8 149.8 55.4 150.5 55.4C151 55.4 151.3 55.7 151.3 56.3L151.2 58.3H151.7L151.8 56.3C151.9 55.5 151.4 55 150.5 55Z"
          fill="white"
        />
        <path
          d="M155.3 44.7992C160.7 39.4992 161.1 30.9992 156 25.7992C151 20.5992 142.4 20.6992 137 25.9992C131.6 31.2992 131.2 39.7992 136.3 44.9992C141.3 50.1992 149.8 50.0992 155.3 44.7992Z"
          fill="white"
        />
        <path
          d="M154.6 44.1009C159.6 39.2009 159.9 31.4009 155.3 26.6009C150.6 21.8009 142.8 21.9009 137.8 26.8009C132.8 31.7009 132.5 39.5009 137.1 44.3009C141.7 49.1009 149.6 49.0009 154.6 44.1009Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.7"
          d="M154.6 44.1009C159.6 39.2009 159.9 31.4009 155.3 26.6009C150.6 21.8009 142.8 21.9009 137.8 26.8009C132.8 31.7009 132.5 39.5009 137.1 44.3009C141.7 49.1009 149.6 49.0009 154.6 44.1009Z"
          fill="white"
        />
        <path
          opacity="0.7"
          d="M145.6 47.7998C149.9 47.7998 153.9 45.4998 156.2 42.1998C153.4 39.7998 147.7 42.0998 144.7 42.7998C142.4 43.2998 140.6 44.9998 139.6 46.1998C141.4 47.0998 143.4 47.7998 145.6 47.7998Z"
          fill="white"
        />
        <path
          d="M142.3 29.4996C142.3 29.4996 138.8 28.3996 137.1 31.2996L139.7 30.6996C139.7 30.6996 137.6 32.0996 139.6 35.6996C139.6 35.6996 139 32.5996 141.4 31.8996C143.8 31.1996 142.3 29.4996 142.3 29.4996Z"
          fill="#263238"
        />
        <path
          opacity="0.3"
          d="M142.3 29.4996C142.3 29.4996 138.8 28.3996 137.1 31.2996L139.7 30.6996C139.7 30.6996 137.6 32.0996 139.6 35.6996C139.6 35.6996 139 32.5996 141.4 31.8996C143.8 31.1996 142.3 29.4996 142.3 29.4996Z"
          fill="black"
        />
        <path
          d="M144.1 44.5004C144.2 43.9004 144.5 43.4004 144.9 42.9004C144.9 42.3004 144.8 41.8004 144.4 41.4004L149.5 37.4004C149.7 39.4004 149.8 41.0004 150.9 41.7004C150 42.8004 148.4 44.8004 146.1 45.6004C143.8 46.5004 144 45.4004 144.1 44.5004Z"
          fill="#B55B52"
        />
        <path
          opacity="0.1"
          d="M144.6 42.6996C147.8 42.1996 148.7 38.5996 148.7 38.5996L145.7 40.1996L144.1 41.4996C144.4 41.7996 144.6 42.1996 144.6 42.6996Z"
          fill="black"
        />
        <path
          d="M149.6 32.6992C149.8 35.7992 149.9 37.7992 148.2 39.4992C144.8 42.8992 142.3 41.6992 141.1 38.8992C140 36.2992 139.9 31.7992 142.9 29.8992C146 27.9992 149.4 29.5992 149.6 32.6992Z"
          fill="#B55B52"
        />
        <path
          d="M143.3 34.7002C143.2 34.4002 143.4 34.2002 143.6 34.1002C143.8 34.0002 144 34.2002 144 34.5002C144.1 34.8002 143.9 35.0002 143.7 35.1002C143.5 35.2002 143.3 35.0002 143.3 34.7002Z"
          fill="#263238"
        />
        <path
          d="M141 35.0996C141.1 35.3996 140.9 35.5996 140.7 35.5996C140.5 35.5996 140.3 35.4996 140.3 35.1996C140.2 34.8996 140.4 34.6996 140.5 34.5996C140.8 34.5996 141 34.7996 141 35.0996Z"
          fill="#263238"
        />
        <path
          d="M141.9 35.3008L142.3 37.5008C142 37.6008 141.8 37.7008 141.5 37.7008C141.3 37.7008 141.2 37.6008 141.2 37.4008C141.2 37.3008 141.2 37.3008 141.3 37.2008C141.6 36.6008 141.8 35.9008 141.9 35.3008Z"
          fill="#A02724"
        />
        <path
          d="M145.3 33.4993C145.4 33.3993 145.4 33.2993 145.4 33.2993C145 32.8993 144.5 32.7993 144 32.8993C143.9 32.8993 143.9 32.9993 143.9 33.0993C143.9 33.1993 144 33.1993 144.1 33.1993C144.5 33.0993 144.9 33.2993 145.2 33.4993C145.1 33.4993 145.2 33.4993 145.3 33.4993Z"
          fill="#263238"
        />
        <path
          d="M139.9 34.7C140 34.7 140 34.6 140 34.6C140.1 34.2 140.5 33.9 140.9 33.8C141 33.8 141 33.7 141 33.6C141 33.5 140.9 33.5 140.8 33.5C140.3 33.6 139.9 34 139.7 34.5C139.6 34.6 139.7 34.7 139.8 34.7H139.9Z"
          fill="#263238"
        />
        <path
          d="M141.5 38.7004C141.5 38.7004 142.6 38.2004 145.7 37.4004C145.5 38.4004 144.9 39.3004 143.4 39.7004C142.6 40.0004 141.8 39.6004 141.6 38.8004C141.5 38.8004 141.5 38.8004 141.5 38.7004Z"
          fill="#263238"
        />
        <path
          d="M144 38.6996C143.4 38.7996 142.8 39.0996 142.4 39.5996C142.7 39.6996 143.1 39.6996 143.5 39.6996C144.1 39.5996 144.7 39.2996 145.1 38.7996C144.7 38.6996 144.3 38.5996 144 38.6996Z"
          fill="#A02724"
        />
        <path
          d="M141.7 38.6004C141.8 38.8004 142 39.0004 142.3 38.9004C143.2 38.6004 144.1 38.4004 145 38.0004C145.3 37.9004 145.5 37.7004 145.6 37.4004C144.3 37.8004 143 38.1004 141.7 38.6004Z"
          fill="white"
        />
        <path
          d="M148.7 36.4998C148.7 36.4998 144.8 34.3998 146.9 31.8998C149.1 29.3998 151.1 32.1998 150.7 34.0998C150.4 35.9998 148.7 36.4998 148.7 36.4998Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M148.7 36.4998C148.7 36.4998 144.8 34.3998 146.9 31.8998C149.1 29.3998 151.1 32.1998 150.7 34.0998C150.4 35.9998 148.7 36.4998 148.7 36.4998Z"
          fill="black"
        />
        <path
          d="M151.1 35.7008C150.8 36.5008 150.2 37.1008 149.3 37.5008C148.2 37.9008 147.7 37.1008 147.9 36.1008C148.1 35.2008 148.8 33.8008 149.9 33.8008C150.7 33.8008 151.3 34.4008 151.2 35.2008C151.2 35.4008 151.2 35.6008 151.1 35.7008Z"
          fill="#B55B52"
        />
        <path
          d="M144.2 27.1007C144.2 27.1007 139.8 25.5007 139.6 28.9007C139.4 32.3007 145.8 33.7007 148.1 33.6007C150.5 33.5007 151.4 34.2007 151.4 34.2007C151.4 34.2007 151.6 31.4007 150.8 30.3007C150.3 29.1007 150.1 25.6007 144.2 27.1007Z"
          fill="#263238"
        />
        <path
          d="M138 13.7004C138.1 12.7004 138.9 11.9004 139.9 11.9004C140.5 11.9004 141.1 12.2004 141.3 12.7004L141.1 12.8004C140.9 12.4004 140.4 12.0004 139.8 12.0004C138.9 12.0004 138.2 12.7004 138.2 13.6004C138.1 14.5004 138.8 15.2004 139.7 15.2004C140.3 15.2004 140.8 14.9004 141 14.4004L141.3 14.5004C141 15.0004 140.4 15.4004 139.7 15.4004C138.6 15.5004 137.9 14.7004 138 13.7004Z"
          fill="white"
        />
        <path
          d="M144.3 12.9008L144.2 15.4008H144V14.8008C143.7 15.2008 143.4 15.4008 142.9 15.4008C142.2 15.4008 141.6 14.8008 141.6 14.1008C141.7 13.3008 142.2 12.8008 143 12.8008C143.5 12.8008 143.9 13.1008 144.1 13.4008V12.8008H144.3V12.9008ZM144 14.1008C144 13.5008 143.6 13.0008 143 13.0008C142.4 13.0008 141.9 13.5008 141.9 14.1008C141.9 14.7008 142.3 15.2008 142.9 15.2008C143.5 15.2008 144 14.7008 144 14.1008Z"
          fill="white"
        />
        <path
          d="M145.1 11.8008H145.4L145.2 15.4008H144.9L145.1 11.8008Z"
          fill="white"
        />
        <path
          d="M146.1 11.8008H146.3L146.1 15.4008H146L146.1 11.8008Z"
          fill="white"
        />
        <path
          d="M147.2 12.1004C147.2 12.0004 147.3 11.9004 147.4 11.9004C147.5 11.9004 147.6 12.0004 147.6 12.1004C147.6 12.2004 147.5 12.3004 147.4 12.3004C147.2 12.3004 147.2 12.2004 147.2 12.1004ZM147.2 12.9004H147.4L147.3 15.4004H147.1L147.2 12.9004Z"
          fill="white"
        />
        <path
          d="M150.1 14L150 15.6H149.8L149.9 14C149.9 13.6 149.6 13.2 149.2 13.2C148.7 13.2 148.3 13.5 148.3 14.2L148.2 15.5H147.9L148 13H148.2V13.5C148.4 13.1 148.8 13 149.1 13C149.8 13 150.2 13.4 150.1 14Z"
          fill="white"
        />
        <path
          d="M153.4 12.9004L153.3 15.3004C153.2 16.1004 152.7 16.5004 152 16.5004C151.4 16.5004 151 16.2004 150.9 15.9004L151.2 15.8004C151.3 16.1004 151.6 16.3004 152.1 16.3004C152.7 16.3004 153.1 15.9004 153.2 15.4004V15.0004C153 15.4004 152.6 15.6004 152.1 15.6004C151.4 15.6004 150.8 15.0004 150.8 14.3004C150.9 13.5004 151.4 13.0004 152.2 13.0004C152.7 13.0004 153.1 13.3004 153.3 13.6004V13.0004H153.4V12.9004ZM153.1 14.1004C153.1 13.5004 152.7 13.0004 152.1 13.0004C151.5 13.0004 151 13.5004 151 14.1004C151 14.7004 151.4 15.2004 152 15.2004C152.6 15.2004 153.1 14.7004 153.1 14.1004Z"
          fill="white"
        />
        <path
          d="M154.1 15.2004C154.1 15.1004 154.2 14.9004 154.4 14.9004C154.6 14.9004 154.6 15.0004 154.6 15.2004C154.6 15.3004 154.5 15.5004 154.3 15.5004C154.1 15.4004 154.1 15.4004 154.1 15.2004Z"
          fill="white"
        />
        <path
          d="M155.1 15.2004C155.1 15.1004 155.2 14.9004 155.3 14.9004C155.4 14.9004 155.6 15.0004 155.5 15.2004C155.5 15.3004 155.4 15.5004 155.2 15.5004C155.2 15.4004 155.1 15.4004 155.1 15.2004Z"
          fill="white"
        />
        <path
          d="M156.1 15.2004C156.1 15.1004 156.2 14.9004 156.3 14.9004C156.4 14.9004 156.6 15.0004 156.5 15.2004C156.5 15.3004 156.4 15.5004 156.3 15.5004C156.2 15.4004 156.1 15.4004 156.1 15.2004Z"
          fill="white"
        />
        <path
          d="M117.2 93.6008H116.5L116.4 94.5008H116.2L116.3 92.3008H117.2C117.6 92.3008 117.8 92.6008 117.8 92.9008C117.8 93.2008 117.5 93.5008 117.3 93.5008L117.8 94.4008H117.6L117.2 93.6008ZM116.5 93.4008H117.2C117.5 93.4008 117.7 93.1008 117.7 92.9008C117.7 92.6008 117.5 92.4008 117.3 92.4008H116.5V93.4008Z"
          fill="white"
        />
        <path
          d="M119.6 93.5007L118.2 93.6007C118.2 94.0007 118.4 94.2007 118.8 94.2007C119.1 94.2007 119.2 94.1007 119.4 93.9007L119.5 94.0007C119.4 94.2007 119.1 94.4007 118.8 94.4007C118.3 94.4007 118 94.0007 118 93.6007C118 93.2007 118.4 92.8007 118.9 92.8007C119.4 92.7007 119.7 93.1007 119.6 93.5007ZM118.2 93.4007H119.5C119.5 93.0007 119.2 92.8007 118.9 92.8007C118.6 92.9007 118.2 93.1007 118.2 93.4007Z"
          fill="white"
        />
        <path
          d="M119.8 93.4992C119.8 93.0992 120.2 92.6992 120.7 92.6992C121 92.6992 121.3 92.8992 121.4 93.0992L121.3 93.1992C121.2 92.9992 121 92.8992 120.7 92.8992C120.3 92.8992 120 93.1992 120 93.5992C120 93.9992 120.2 94.2992 120.6 94.2992C120.8 94.2992 121 94.1992 121.2 93.9992L121.3 94.0992C121.2 94.3992 120.9 94.4992 120.6 94.4992C120.2 94.3992 119.8 93.9992 119.8 93.4992Z"
          fill="white"
        />
        <path
          d="M121.7 93.4992C121.7 93.0992 122.1 92.6992 122.6 92.6992C123 92.6992 123.4 93.0992 123.4 93.4992C123.4 93.8992 123 94.2992 122.5 94.2992C122 94.3992 121.7 93.9992 121.7 93.4992ZM123.2 93.4992C123.2 93.0992 122.9 92.7992 122.6 92.7992C122.2 92.7992 121.9 93.0992 121.9 93.4992C121.9 93.8992 122.1 94.1992 122.5 94.1992C122.9 94.1992 123.2 93.8992 123.2 93.4992Z"
          fill="white"
        />
        <path
          d="M124.4 92.8008V92.9008C124.2 92.9008 123.9 93.1008 123.8 93.5008L123.7 94.4008H123.5L123.6 92.8008H123.8V93.1008C123.9 92.8008 124.1 92.8008 124.4 92.8008Z"
          fill="white"
        />
        <path
          d="M126.3 92.1992L126.2 94.3992H126.1V93.9992C126 94.2992 125.7 94.3992 125.4 94.3992C125 94.3992 124.6 93.9992 124.6 93.5992C124.6 93.1992 125 92.7992 125.5 92.7992C125.8 92.7992 126.1 92.9992 126.1 93.1992L126.2 92.1992H126.3ZM126.1 93.5992C126.1 93.1992 125.8 92.8992 125.5 92.8992C125.1 92.8992 124.8 93.1992 124.8 93.5992C124.8 93.9992 125 94.2992 125.4 94.2992C125.8 94.2992 126.1 93.8992 126.1 93.5992Z"
          fill="white"
        />
        <g opacity="0.6">
          <path
            d="M138.8 92.1992L138.7 94.3992H138.5L138.6 93.2992H137.3L137.2 94.3992H137L137.1 92.1992H137.3L137.2 93.1992H138.5L138.6 92.1992H138.8Z"
            fill="white"
          />
          <path
            d="M139 93.4992C139 93.0992 139.4 92.6992 139.9 92.6992C140.3 92.6992 140.7 93.0992 140.7 93.4992C140.7 93.8992 140.3 94.2992 139.8 94.2992C139.3 94.3992 139 93.9992 139 93.4992ZM140.5 93.4992C140.5 93.0992 140.2 92.7992 139.9 92.7992C139.5 92.7992 139.2 93.0992 139.2 93.4992C139.2 93.8992 139.4 94.1992 139.8 94.1992C140.2 94.1992 140.5 93.8992 140.5 93.4992Z"
            fill="white"
          />
          <path d="M141.2 92H141.4L141.3 94.3H141.1L141.2 92Z" fill="white" />
          <path
            d="M143.5 92.1992L143.4 94.3992H143.3V93.9992C143.2 94.2992 142.9 94.3992 142.6 94.3992C142.2 94.3992 141.8 93.9992 141.8 93.5992C141.8 93.1992 142.2 92.7992 142.7 92.7992C143 92.7992 143.3 92.9992 143.3 93.1992L143.4 92.1992H143.5ZM143.3 93.5992C143.3 93.1992 143.1 92.8992 142.7 92.8992C142.3 92.8992 142 93.1992 142 93.5992C142 93.9992 142.3 94.2992 142.6 94.2992C143 94.2992 143.2 93.9992 143.3 93.5992Z"
            fill="white"
          />
          <path
            d="M144.7 93.4992C144.7 93.0992 145.1 92.6992 145.6 92.6992C145.9 92.6992 146.2 92.8992 146.3 93.0992L146.2 93.1992C146.1 92.9992 145.9 92.8992 145.6 92.8992C145.2 92.8992 144.9 93.1992 144.9 93.5992C144.9 93.9992 145.2 94.2992 145.5 94.2992C145.7 94.2992 145.9 94.1992 146.1 93.9992L146.2 94.0992C146.1 94.3992 145.8 94.4992 145.5 94.4992C145 94.3992 144.7 93.9992 144.7 93.4992Z"
            fill="white"
          />
          <path
            d="M147.9 92.8008L147.8 94.4008H147.6V94.0008C147.5 94.3008 147.2 94.4008 146.9 94.4008C146.5 94.4008 146.1 94.0008 146.1 93.6008C146.1 93.2008 146.5 92.8008 147 92.8008C147.3 92.8008 147.6 92.9008 147.6 93.2008V92.8008H147.9ZM147.7 93.5008C147.7 93.1008 147.5 92.8008 147.1 92.8008C146.7 92.8008 146.4 93.1008 146.4 93.5008C146.4 93.9008 146.7 94.2008 147 94.2008C147.4 94.2008 147.7 93.9008 147.7 93.5008Z"
            fill="white"
          />
          <path d="M148.5 92H148.7L148.6 94.3H148.4L148.5 92Z" fill="white" />
          <path d="M149.2 92H149.4L149.3 94.3H149.1L149.2 92Z" fill="white" />
        </g>
        <path
          d="M159.9 93.7992C159.9 94.0992 159.6 94.3992 159.3 94.3992H158.4L158.5 92.1992H159.4C159.7 92.1992 160 92.4992 160 92.7992C160 92.9992 159.9 93.1992 159.7 93.2992C159.8 93.3992 159.9 93.4992 159.9 93.7992ZM158.6 92.3992L158.5 93.1992H159.2C159.4 93.1992 159.6 92.9992 159.6 92.7992C159.6 92.5992 159.4 92.3992 159.2 92.3992H158.6ZM159.7 93.7992C159.7 93.4992 159.5 93.3992 159.3 93.3992H158.5L158.4 94.2992H159.2C159.5 94.1992 159.7 93.9992 159.7 93.7992Z"
          fill="white"
        />
        <path d="M160.3 92H160.5L160.4 94.3H160.2L160.3 92Z" fill="white" />
        <path
          d="M162.3 92.7992L162.2 94.3992H162V93.9992C161.9 94.2992 161.7 94.2992 161.4 94.2992C161 94.2992 160.8 93.9992 160.8 93.6992L160.9 92.6992H161.1L161 93.6992C161 93.9992 161.1 94.1992 161.4 94.1992C161.7 94.1992 162 93.9992 162 93.5992L162.1 92.7992H162.3Z"
          fill="white"
        />
        <path
          d="M164 93.6008L162.6 93.7008C162.6 94.1008 162.9 94.3008 163.2 94.3008C163.5 94.3008 163.6 94.2008 163.8 94.0008L163.9 94.1008C163.8 94.3008 163.5 94.5008 163.2 94.5008C162.7 94.5008 162.4 94.1008 162.4 93.7008C162.4 93.3008 162.8 92.9008 163.3 92.9008C163.8 92.7008 164.1 93.1008 164 93.6008ZM162.7 93.4008H164C164 93.0008 163.8 92.8008 163.4 92.8008C163 92.9008 162.7 93.1008 162.7 93.4008Z"
          fill="white"
        />
        <path
          d="M164.9 92.9008L164.8 93.9008C164.8 94.2008 164.9 94.2008 165.2 94.2008V94.3008C164.8 94.4008 164.6 94.2008 164.6 93.9008L164.7 92.9008H164.3V92.8008H164.7V92.4008L164.9 92.3008V92.7008H165.3V92.8008H164.9V92.9008Z"
          fill="white"
        />
        <path
          d="M165.3 93.4992C165.3 93.0992 165.7 92.6992 166.2 92.6992C166.6 92.6992 167 93.0992 167 93.4992C167 93.8992 166.6 94.2992 166.1 94.2992C165.6 94.3992 165.3 93.9992 165.3 93.4992ZM166.8 93.4992C166.8 93.0992 166.5 92.7992 166.2 92.7992C165.8 92.7992 165.5 93.0992 165.5 93.4992C165.5 93.8992 165.8 94.1992 166.1 94.1992C166.4 94.1992 166.7 93.8992 166.8 93.4992Z"
          fill="white"
        />
        <path
          d="M167.5 93.4992C167.5 93.0992 167.9 92.6992 168.4 92.6992C168.8 92.6992 169.2 93.0992 169.2 93.4992C169.2 93.8992 168.8 94.2992 168.3 94.2992C167.9 94.3992 167.5 93.9992 167.5 93.4992ZM169 93.4992C169 93.0992 168.8 92.7992 168.4 92.7992C168 92.7992 167.7 93.0992 167.7 93.4992C167.7 93.8992 168 94.1992 168.3 94.1992C168.7 94.1992 169 93.8992 169 93.4992Z"
          fill="white"
        />
        <path
          d="M169.6 92.9008L169.5 93.9008C169.5 94.2008 169.6 94.2008 169.9 94.2008V94.3008C169.5 94.4008 169.3 94.2008 169.3 93.9008L169.4 92.9008H169V92.8008H169.4V92.4008L169.6 92.3008V92.7008H170V92.8008H169.6V92.9008Z"
          fill="white"
        />
        <path
          d="M171.8 93.3996L171.7 94.3996H171.5L171.6 93.3996C171.6 93.0996 171.5 92.8996 171.2 92.8996C170.9 92.8996 170.6 93.0996 170.6 93.4996L170.5 94.2996H170.3L170.4 92.0996H170.6L170.5 92.9996C170.6 92.6996 170.8 92.6996 171.1 92.6996C171.7 92.7996 171.9 92.9996 171.8 93.3996Z"
          fill="white"
        />
        <path
          d="M114.1 118.499L114.2 118.399C114.3 118.699 114.4 118.799 114.8 118.799C115.2 118.799 115.4 118.599 115.4 118.399C115.4 118.199 115.2 117.999 114.8 117.899C114.4 117.799 114.1 117.699 114.1 117.299C114.1 116.899 114.4 116.699 114.8 116.699C115.2 116.699 115.4 116.899 115.5 117.099L115.4 117.199C115.3 116.999 115.2 116.799 114.8 116.799C114.5 116.799 114.3 116.999 114.3 117.199C114.3 117.399 114.5 117.499 114.8 117.599C115.2 117.699 115.5 117.899 115.5 118.199C115.5 118.599 115.2 118.799 114.7 118.799C114.5 118.999 114.2 118.799 114.1 118.499Z"
          fill="white"
        />
        <path
          d="M117.7 118.101C117.7 118.501 117.3 118.901 116.8 118.901C116.5 118.901 116.2 118.701 116.2 118.501L116.1 119.501H115.9L116 117.301H116.2V117.701C116.3 117.401 116.6 117.301 116.9 117.301C117.4 117.301 117.7 117.601 117.7 118.101ZM117.5 118.101C117.5 117.701 117.3 117.401 116.9 117.401C116.5 117.401 116.2 117.701 116.2 118.101C116.2 118.501 116.5 118.801 116.8 118.801C117.2 118.801 117.5 118.501 117.5 118.101Z"
          fill="white"
        />
        <path
          d="M119.6 118.2L118.2 118.3C118.2 118.7 118.5 118.9 118.8 118.9C119 118.9 119.2 118.8 119.4 118.6L119.5 118.7C119.4 118.9 119.1 119.1 118.8 119.1C118.3 119.1 118 118.7 118 118.3C118 117.9 118.4 117.5 118.9 117.5C119.4 117.3 119.7 117.7 119.6 118.2ZM118.3 118.1H119.6C119.6 117.7 119.4 117.5 119 117.5C118.6 117.5 118.3 117.7 118.3 118.1Z"
          fill="white"
        />
        <path
          d="M121.3 117.301L121.2 118.901H121V118.501C120.9 118.701 120.6 118.901 120.3 118.901C119.9 118.901 119.5 118.501 119.5 118.101C119.5 117.701 119.9 117.301 120.4 117.301C120.7 117.301 121 117.401 121 117.701V117.301H121.3ZM121.1 118.201C121.1 117.801 120.8 117.501 120.5 117.501C120.1 117.501 119.8 117.801 119.8 118.201C119.8 118.601 120 118.901 120.4 118.901C120.8 118.801 121.1 118.501 121.1 118.201Z"
          fill="white"
        />
        <path
          d="M122 118.099L121.9 118.899H121.7L121.8 116.699H122L121.9 117.999L122.7 117.299H122.9L122 118.099Z"
          fill="white"
        />
        <path
          d="M124.8 118.2L123.4 118.3C123.4 118.7 123.7 118.9 124 118.9C124.3 118.9 124.4 118.8 124.6 118.6L124.7 118.7C124.6 118.9 124.3 119.1 124 119.1C123.5 119.1 123.2 118.7 123.2 118.3C123.2 117.9 123.6 117.5 124.1 117.5C124.6 117.3 124.8 117.7 124.8 118.2ZM123.4 118.1H124.7C124.7 117.7 124.4 117.5 124.1 117.5C123.7 117.5 123.4 117.7 123.4 118.1Z"
          fill="white"
        />
        <path
          d="M125.7 117.301V117.401C125.4 117.401 125.2 117.601 125.1 118.001L125 118.901H124.8L124.9 117.301H125.1V117.601C125.3 117.301 125.6 117.301 125.7 117.301Z"
          fill="white"
        />
        <g opacity="0.6">
          <path
            d="M140.4 118.899H140.2L140.3 116.999L139.4 118.399L138.6 116.999L138.5 118.899H138.4L138.5 116.699H138.7L139.4 117.999L140.3 116.699H140.5L140.4 118.899Z"
            fill="white"
          />
          <path
            d="M142.4 117.299L142.3 118.899H142.1V118.599C142 118.899 141.8 118.899 141.5 118.899C141.1 118.899 140.9 118.699 140.9 118.299L141 117.299H141.2L141.1 118.299C141.1 118.599 141.2 118.799 141.5 118.799C141.8 118.799 142.1 118.599 142.1 118.199L142.2 117.399H142.4V117.299Z"
            fill="white"
          />
          <path
            d="M143.1 117.498L143 118.498C143 118.798 143.1 118.798 143.4 118.798V118.898C143 118.998 142.8 118.798 142.8 118.498L142.9 117.498H142.5V117.398H142.9V116.998L143.1 116.898V117.298H143.5V117.398H143.1V117.498Z"
            fill="white"
          />
          <path
            d="M145.5 118.2L144.1 118.3C144.1 118.7 144.4 118.9 144.7 118.9C145 118.9 145.1 118.8 145.3 118.6L145.4 118.7C145.3 118.9 145 119.1 144.7 119.1C144.2 119.1 143.9 118.7 143.9 118.3C143.9 117.9 144.3 117.5 144.8 117.5C145.2 117.3 145.5 117.7 145.5 118.2ZM144.1 118.1H145.4C145.4 117.7 145.2 117.5 144.8 117.5C144.4 117.5 144.1 117.7 144.1 118.1Z"
            fill="white"
          />
        </g>
        <path
          d="M158.7 117.901L158.6 119.001H158.4L158.5 116.801H158.7L158.6 117.801L159.7 116.801H159.9L158.7 117.901Z"
          fill="white"
        />
        <path
          d="M161.8 118.1L160.4 118.2C160.4 118.6 160.6 118.8 161 118.8C161.2 118.8 161.4 118.7 161.6 118.5L161.7 118.6C161.6 118.8 161.3 119 161 119C160.5 119 160.2 118.6 160.2 118.2C160.2 117.8 160.6 117.4 161.1 117.4C161.5 117.3 161.8 117.7 161.8 118.1ZM160.4 118H161.7C161.7 117.6 161.5 117.4 161.1 117.4C160.7 117.4 160.5 117.7 160.4 118Z"
          fill="white"
        />
        <path
          d="M163.2 117.301L162.4 119.001C162.3 119.301 162 119.501 161.8 119.501V119.401C162 119.401 162.2 119.301 162.3 119.001L162.4 118.901L161.8 117.301H162L162.6 118.701L163.2 117.301Z"
          fill="white"
        />
        <path
          d="M165.1 118.2C165.1 118.6 164.7 119 164.2 119C163.9 119 163.6 118.8 163.6 118.6L163.5 119.6H163.4L163.5 117.4H163.7V117.8C163.8 117.6 164.1 117.4 164.4 117.4C164.8 117.4 165.1 117.7 165.1 118.2ZM165 118.2C165 117.8 164.7 117.5 164.4 117.5C164 117.5 163.7 117.8 163.7 118.2C163.7 118.6 163.9 118.9 164.3 118.9C164.6 118.8 164.9 118.6 165 118.2Z"
          fill="white"
        />
        <path
          d="M167.1 117.301L167 118.901H166.8V118.501C166.7 118.701 166.4 118.901 166.1 118.901C165.7 118.901 165.3 118.501 165.3 118.101C165.3 117.701 165.7 117.301 166.2 117.301C166.5 117.301 166.8 117.401 166.8 117.701V117.301H167.1ZM166.9 118.101C166.9 117.701 166.7 117.401 166.3 117.401C165.9 117.401 165.6 117.701 165.6 118.101C165.6 118.501 165.9 118.801 166.2 118.801C166.6 118.701 166.9 118.501 166.9 118.101Z"
          fill="white"
        />
        <path
          d="M169.3 116.699L169.2 118.899H169.1V118.499C169 118.799 168.7 118.899 168.4 118.899C168 118.899 167.6 118.499 167.6 118.099C167.6 117.699 168 117.299 168.5 117.299C168.8 117.299 169.1 117.499 169.1 117.699L169.2 116.699H169.3ZM169.1 118.199C169.1 117.799 168.8 117.499 168.5 117.499C168.1 117.499 167.8 117.799 167.8 118.199C167.8 118.599 168 118.899 168.4 118.899C168.8 118.799 169.1 118.599 169.1 118.199Z"
          fill="white"
        />
        <path
          d="M120.2 87.0996C119.8 87.0996 119.4 87.3996 119.4 87.7996C119.4 88.1996 119.7 88.4996 120.1 88.4996C120.5 88.4996 120.9 88.1996 120.9 87.7996C120.9 87.3996 120.6 87.0996 120.2 87.0996Z"
          fill="white"
        />
        <path
          d="M123 87.0996C122.6 87.0996 122.2 87.3996 122.2 87.7996C122.2 88.1996 122.4 88.4996 122.9 88.4996C123.3 88.4996 123.7 88.1996 123.7 87.7996C123.7 87.3996 123.5 87.0996 123 87.0996Z"
          fill="white"
        />
        <path
          d="M124.4 85.8008H119.1C118.8 85.8008 118.5 86.1008 118.5 86.4008L118.3 89.4008C118.3 89.7008 118.5 90.0008 118.8 90.0008H124.1C124.4 90.0008 124.7 89.7008 124.7 89.4008L124.9 86.4008C124.9 86.0008 124.6 85.8008 124.4 85.8008ZM122.8 89.0008H120.2C119.6 89.0008 119.1 88.5008 119.1 87.9008C119.2 87.3008 119.7 86.8008 120.3 86.8008C120.9 86.8008 121.4 87.3008 121.4 87.9008C121.4 88.2008 121.3 88.4008 121.1 88.6008H122C121.9 88.4008 121.8 88.2008 121.8 87.9008C121.9 87.3008 122.4 86.8008 123 86.8008C123.6 86.8008 124.1 87.3008 124.1 87.9008C123.9 88.5008 123.4 89.0008 122.8 89.0008Z"
          fill="white"
        />
        <path
          d="M167.3 86.8004L165.1 85.4004L165 87.5004L163.7 86.6004L163.5 87.0004L164.8 87.9004L163.4 88.8004L163.6 89.2004L165 88.3004L164.9 90.4004L167.2 89.0004L165.6 87.9004L167.3 86.8004ZM165.5 86.1004L166.5 86.8004L165.4 87.5004L165.5 86.1004ZM165.4 89.6004L165.5 88.2004L166.5 88.9004L165.4 89.6004Z"
          fill="white"
        />
        <g opacity="0.6">
          <path
            d="M142.2 89.9992H142.8L143.1 85.6992H142.5L142.2 89.9992Z"
            fill="white"
          />
          <path
            d="M145 85.6992L144.8 89.9992H145.4L145.7 85.6992H145Z"
            fill="white"
          />
        </g>
        <path
          d="M120.1 110.4L118.8 111.5H117.6L117.5 113.2H118.7L120 114.3C120.1 114.4 120.3 114.3 120.3 114.2L120.5 110.6C120.4 110.4 120.3 110.4 120.1 110.4Z"
          fill="white"
        />
        <path
          d="M121.1 111.301C121.7 111.901 121.6 112.901 121 113.501C120.8 113.801 121.1 114.101 121.3 113.801C122.1 113.001 122.2 111.701 121.4 110.901C121.3 110.801 120.9 111.101 121.1 111.301Z"
          fill="white"
        />
        <path
          d="M121.8 110.099C123 111.499 122.9 113.399 121.6 114.699C121.4 114.999 121.7 115.299 121.9 114.999C123.4 113.399 123.5 111.199 122.2 109.699C122 109.499 121.6 109.899 121.8 110.099Z"
          fill="white"
        />
        <g opacity="0.6">
          <path
            d="M144.7 113.1C144.9 112.8 144.9 112.5 145 112.2C145.1 111.8 144.5 111.8 144.4 112.2C144.4 112.4 144.3 112.6 144.3 112.8L144.7 113.1Z"
            fill="white"
          />
          <path
            d="M143.9 113.2C143 114.3 140.9 114 140.9 112.2C140.9 111.8 140.3 111.8 140.3 112.2C140.3 113.2 140.9 114 141.7 114.3L141.6 115H143L143.1 114.3C143.5 114.2 143.9 113.9 144.2 113.5L143.9 113.2Z"
            fill="white"
          />
          <path
            d="M142 110.6C142.1 110.3 142.4 110.2 142.7 110.2C143.1 110.2 143.5 110.5 143.4 111L143.3 112.1L143.7 112.5C143.7 112.4 143.8 112.3 143.8 112.3L143.9 111.1C144 110.4 143.4 109.9 142.8 109.9C142.4 109.9 142 110.1 141.8 110.4L142 110.6Z"
            fill="white"
          />
          <path
            d="M143.6 112.9L143.3 112.6L142 111.2L141.6 110.8L140.9 110L140.5 110.3L141.5 111.4L141.4 112.2C141.3 112.9 141.9 113.4 142.5 113.4C142.8 113.4 142.9 113.3 143.1 113.2L144.5 114.7L144.9 114.4L143.6 112.9ZM142.7 112.9C142.3 112.9 141.9 112.6 142 112.1V111.8L142.9 112.8C142.8 112.9 142.7 112.9 142.7 112.9Z"
            fill="white"
          />
        </g>
        <path
          d="M163 109.9C162.8 109.9 162.6 110.1 162.6 110.3C162.6 110.5 162.7 110.7 162.9 110.7C163.1 110.7 163.3 110.5 163.3 110.3C163.3 110.1 163.2 109.9 163 109.9Z"
          fill="white"
        />
        <path
          d="M162.9 111.4C162.7 111.4 162.5 111.6 162.5 111.8C162.5 112 162.6 112.2 162.8 112.2C163 112.2 163.2 112 163.2 111.8C163.2 111.5 163 111.4 162.9 111.4Z"
          fill="white"
        />
        <path
          d="M162.6 112.9C162.4 112.9 162.2 113.1 162.2 113.3C162.2 113.5 162.3 113.7 162.5 113.7C162.7 113.7 162.9 113.5 162.9 113.3C163 113 162.9 112.9 162.6 112.9Z"
          fill="white"
        />
        <path
          d="M164.3 109.9C164.1 109.9 163.9 110.1 163.9 110.3C163.9 110.5 164 110.7 164.2 110.7C164.4 110.7 164.6 110.5 164.6 110.3C164.6 110.1 164.5 109.9 164.3 109.9Z"
          fill="white"
        />
        <path
          d="M164.2 111.4C164 111.4 163.8 111.6 163.8 111.8C163.8 112 163.9 112.2 164.1 112.2C164.3 112.2 164.5 112 164.5 111.8C164.5 111.5 164.3 111.4 164.2 111.4Z"
          fill="white"
        />
        <path
          d="M163.9 112.9C163.7 112.9 163.5 113.1 163.5 113.3C163.5 113.5 163.6 113.7 163.8 113.7C164 113.7 164.2 113.5 164.2 113.3C164.2 113 164.1 112.9 163.9 112.9Z"
          fill="white"
        />
        <path
          d="M163.8 114.301C163.6 114.301 163.4 114.501 163.4 114.701C163.4 114.901 163.5 115.101 163.7 115.101C163.9 115.101 164.1 114.901 164.1 114.701C164.2 114.501 164 114.301 163.8 114.301Z"
          fill="white"
        />
        <path
          d="M165.5 110.499C165.7 110.499 165.9 110.299 165.9 110.099C165.9 109.899 165.8 109.699 165.6 109.699C165.4 109.699 165.2 109.899 165.2 110.099C165.2 110.399 165.3 110.499 165.5 110.499Z"
          fill="white"
        />
        <path
          d="M165.3 111.4C165.1 111.4 164.9 111.6 164.9 111.8C164.9 112 165 112.2 165.2 112.2C165.4 112.2 165.6 112 165.6 111.8C165.6 111.6 165.6 111.4 165.3 111.4Z"
          fill="white"
        />
        <path
          d="M165.2 112.9C165 112.9 164.8 113.1 164.8 113.3C164.8 113.5 164.9 113.7 165.1 113.7C165.3 113.7 165.5 113.5 165.5 113.3C165.6 113 165.4 112.9 165.2 112.9Z"
          fill="white"
        />
        <g opacity="0.1">
          <path
            d="M122.1 161.001H145.6L137.3 2.80078H118.4C116.6 2.80078 115.1 3.60078 113.9 4.70078L122.1 161.001Z"
            fill="white"
          />
          <path
            d="M149.7 161.001H155L146.7 2.80078H141.4L149.7 161.001Z"
            fill="white"
          />
        </g>
        <path
          d="M63.6004 166.001H76.4004L69.6004 111.001C69.3004 108.701 67.2004 106.801 64.8004 106.801H60.4004H8.90039L17.1004 166.001H63.6004Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.1"
          d="M63.6004 166.001H76.4004L69.6004 111.001C69.3004 108.701 67.2004 106.801 64.8004 106.801H60.4004H8.90039L17.1004 166.001H63.6004Z"
          fill="white"
        />
        <path
          d="M22.9 166.001H10.1L3.29999 111.001C2.99999 108.701 4.69999 106.801 6.99999 106.801H11.4C13.7 106.801 15.8 108.701 16.2 111.001L22.9 166.001Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.2"
          d="M22.9 166.001H10.1L3.29999 111.001C2.99999 108.701 4.69999 106.801 6.99999 106.801H11.4C13.7 106.801 15.8 108.701 16.2 111.001L22.9 166.001Z"
          fill="black"
        />
        <path
          d="M30.3003 173.5L28.0003 146.9C27.9003 145.7 27.0003 144.8 25.8003 144.6L24.9003 144.5C23.3003 144.3 22.1003 143 22.0003 141.4L21.6003 136.6C21.4003 134.6 23.0003 132.9 25.0003 132.9H3.40031C1.40031 132.9 -0.199687 134.6 0.000313291 136.6L0.400313 141.4C0.500313 143 1.80031 144.3 3.30031 144.5L4.20031 144.6C5.40031 144.7 6.30031 145.7 6.40031 146.9L8.70031 173.5C8.90031 175.4 10.5003 176.9 12.5003 176.9H34.0003C32.1003 176.9 30.5003 175.5 30.3003 173.5Z"
          fill={`${default_color}`}
        />
        <g opacity="0.3">
          <path
            d="M30.3003 173.5L28.0003 146.9C27.9003 145.7 27.0003 144.8 25.8003 144.6L24.9003 144.5C23.3003 144.3 22.1003 143 22.0003 141.4L21.6003 136.6C21.4003 134.6 23.0003 132.9 25.0003 132.9H3.40031C1.40031 132.9 -0.199687 134.6 0.000313291 136.6L0.400313 141.4C0.500313 143 1.80031 144.3 3.30031 144.5L4.20031 144.6C5.40031 144.7 6.30031 145.7 6.40031 146.9L8.70031 173.5C8.90031 175.4 10.5003 176.9 12.5003 176.9H34.0003C32.1003 176.9 30.5003 175.5 30.3003 173.5Z"
            fill="black"
          />
        </g>
        <path
          d="M89.2998 140.3C89.6998 136.1 93.1998 132.9 97.3998 132.9H86.9998H78.6998C74.4998 132.9 70.9998 136.1 70.5998 140.3L68.7998 161.4H87.4998L89.2998 140.3Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.3"
          d="M89.2998 140.3C89.6998 136.1 93.1998 132.9 97.3998 132.9H86.9998H78.6998C74.4998 132.9 70.9998 136.1 70.5998 140.3L68.7998 161.4H87.4998L89.2998 140.3Z"
          fill="black"
        />
        <path
          d="M38.1996 189.1H37.2996C36.3996 189.1 35.5996 189 35.5996 188V177H39.9996V188C39.7996 188.9 39.0996 189.1 38.1996 189.1Z"
          fill="#263238"
        />
        <path
          d="M93.6 189.1H92.7C91.8 189.1 91 189 91 188V177H95.4V188C95.3 188.9 94.6 189.1 93.6 189.1Z"
          fill="#263238"
        />
        <path
          d="M16.3002 189.1H15.4002C14.5002 189.1 13.7002 189 13.7002 188V177H18.1002V188C18.0002 188.9 17.3002 189.1 16.3002 189.1Z"
          fill="#263238"
        />
        <path
          d="M71.8002 189.1H70.9002C70.0002 189.1 69.2002 189 69.2002 188V177H73.6002V188C73.5002 188.9 72.7002 189.1 71.8002 189.1Z"
          fill="#263238"
        />
        <path
          d="M46.8002 148.801H39.1002C37.2002 148.801 35.7002 150.301 35.7002 152.201V158.001C35.7002 159.901 37.2002 161.401 39.1002 161.401H46.8002V148.801Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.3"
          d="M46.8002 148.801H39.1002C37.2002 148.801 35.7002 150.301 35.7002 152.201V158.001C35.7002 159.901 37.2002 161.401 39.1002 161.401H46.8002V148.801Z"
          fill="black"
        />
        <path
          d="M84.3002 161.401H47.1002C45.2002 161.401 43.7002 159.901 43.7002 158.001V152.201C43.7002 150.301 45.2002 148.801 47.1002 148.801H84.2002C86.1002 148.801 87.6002 150.301 87.6002 152.201V158.001C87.6002 159.901 86.1002 161.401 84.3002 161.401Z"
          fill={`${default_color}`}
        />
        <path
          d="M105.7 132.9H97.3999C93.1999 132.9 89.6999 136.1 89.2999 140.3L87.4999 161.4H43.0999L41.2999 140.3C40.8999 136.1 37.3999 132.9 33.1999 132.9H24.9999C22.9999 132.9 21.3999 134.6 21.5999 136.6L21.9999 141.4C22.0999 143 23.3999 144.3 24.8999 144.5L25.7999 144.6C26.9999 144.7 27.8999 145.7 27.9999 146.9L30.2999 173.5C30.4999 175.4 32.0999 176.9 34.0999 176.9H96.4999C98.3999 176.9 100.1 175.4 100.3 173.5L102.6 146.9C102.7 145.7 103.6 144.8 104.8 144.6L105.7 144.5C107.3 144.3 108.5 143 108.6 141.4L109 136.6C109.3 134.6 107.7 132.9 105.7 132.9Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.1"
          d="M105.7 132.9H97.3999C93.1999 132.9 89.6999 136.1 89.2999 140.3L87.4999 161.4H43.0999L41.2999 140.3C40.8999 136.1 37.3999 132.9 33.1999 132.9H24.9999C22.9999 132.9 21.3999 134.6 21.5999 136.6L21.9999 141.4C22.0999 143 23.3999 144.3 24.8999 144.5L25.7999 144.6C26.9999 144.7 27.8999 145.7 27.9999 146.9L30.2999 173.5C30.4999 175.4 32.0999 176.9 34.0999 176.9H96.4999C98.3999 176.9 100.1 175.4 100.3 173.5L102.6 146.9C102.7 145.7 103.6 144.8 104.8 144.6L105.7 144.5C107.3 144.3 108.5 143 108.6 141.4L109 136.6C109.3 134.6 107.7 132.9 105.7 132.9Z"
          fill="black"
        />
        <path
          d="M107.5 184L113.1 181.9L114.4 186.7L109.4 188.3L107.5 184Z"
          fill="#FAB27B"
        />
        <path
          d="M116 186.8C115.9 186.8 115.9 186.8 115.9 186.7C115.9 186.6 115.9 186.6 115.9 186.5C116 186.4 117.7 184.6 118.7 184.6C118.9 184.6 119.1 184.7 119.3 184.8C119.5 184.9 119.5 185.2 119.5 185.4C119.4 185.8 118.6 186.2 117.9 186.4C117.2 186.7 116.6 186.8 116 186.8ZM116.4 186.5C117.5 186.2 119 185.8 119.2 185.3C119.2 185.2 119.3 185.2 119.1 185C119 184.9 118.9 184.9 118.8 184.9C118.7 184.9 118.6 184.9 118.6 185C117.6 185.4 117 185.9 116.4 186.5Z"
          fill={`${default_color}`}
        />
        <path
          d="M115.8 186.9C115.7 186.9 115.7 186.8 115.7 186.8C115.7 186.7 115.3 185.1 115.9 184.2C116 184 116.3 183.8 116.6 183.7C117 183.6 117.2 183.8 117.2 184C117.5 184.6 116.6 186.3 115.9 186.9H115.8ZM116.5 184.1C116.3 184.2 116.2 184.3 116.1 184.4C115.9 185 115.8 185.8 115.9 186.5C116.5 185.8 117.1 184.6 116.9 184.2C116.9 184.1 116.8 184 116.5 184.1Z"
          fill={`${default_color}`}
        />
        <path
          d="M114.9 186.2L108.5 188.3C108.3 188.4 108.1 188.6 108.1 188.9L108.7 194.5C108.8 195 109.2 195.4 109.7 195.3C109.8 195.3 109.8 195.3 109.9 195.2C112.2 194.4 114.2 193.6 117 192.7C120.4 191.6 119.6 192.3 123.6 191.1C126 190.3 125.5 187.5 124.5 187.7C119.7 188.6 118.7 187.4 116.4 186.3C116 186 115.4 186 114.9 186.2Z"
          fill="#263238"
        />
        <path
          opacity="0.1"
          d="M113.1 182L107.5 184.1L108.3 186L113.6 184.1L113.1 182Z"
          fill="black"
        />
        <path
          d="M44.7997 67.0005C44.7997 67.0005 44.3997 59.5005 55.2997 59.3005C66.1997 59.0005 64.8997 67.7005 64.0997 70.4005C63.1997 73.1005 67.2997 76.6005 67.2997 76.6005C67.2997 76.6005 64.6997 76.7005 63.8997 75.3005C63.0997 73.9005 66.4997 79.1005 65.6997 82.5005C64.8997 86.0005 63.5997 89.4005 65.3997 92.2005C65.3997 92.2005 62.0997 90.6005 62.1997 87.4005C62.1997 87.4005 62.4997 90.0005 59.7997 89.4005C57.1997 88.8005 49.6997 86.3005 44.6997 87.7005C42.2997 88.4005 41.2997 87.1005 41.1997 85.4005C40.9997 83.6005 40.3997 81.1005 40.5997 84.6005C40.5997 85.1005 40.6997 85.7005 40.7997 86.3005C40.7997 86.3005 36.3997 83.2005 39.4997 79.4005C40.3997 78.3005 42.8997 76.8005 42.8997 74.6005C42.9997 72.4005 41.5997 69.6005 44.7997 67.0005Z"
          fill="#263238"
        />
        <path
          d="M45.7002 67.5992C45.7002 67.5992 43.4002 65.7992 44.9002 62.1992C44.9002 62.1992 41.1002 67.0992 46.3002 68.5992L45.7002 67.5992Z"
          fill="#263238"
        />
        <path
          d="M89.0998 110.4C89.0998 110.4 73.5998 121.3 69.6998 117.6C65.7998 113.9 62.7998 104.1 62.7998 104.1L69.4998 100.8L73.1998 109.9C73.5998 110.8 74.5998 111.2 75.4998 110.9L87.1998 106.8C87.1998 106.8 87.2998 104.5 88.2998 102.7C88.8998 101.6 90.3998 101 91.1998 100C93.4998 97.5001 92.9998 101.2 92.9998 101.2L93.5998 105.7C93.6998 106.1 93.5998 106.5 93.3998 106.8L92.5998 108.6C92.2998 109.1 91.8998 109.5 91.3998 109.7L89.0998 110.4Z"
          fill="#FAB27B"
        />
        <path
          d="M62.1004 103.6C62.1004 103.6 58.7004 94.9996 61.5004 90.7996C64.4004 86.6996 70.5004 98.9996 70.5004 98.9996L71.0004 99.2996L71.9004 100.9C71.9004 100.9 65.7004 103.3 62.7004 105.8L61.8004 103.8L62.1004 103.6Z"
          fill="#263238"
        />
        <g opacity="0.2">
          <path
            d="M62.1004 103.6C62.1004 103.6 58.7004 94.9996 61.5004 90.7996C64.4004 86.6996 70.5004 98.9996 70.5004 98.9996L71.0004 99.2996L71.9004 100.9C71.9004 100.9 65.7004 103.3 62.7004 105.8L61.8004 103.8L62.1004 103.6Z"
            fill="white"
          />
        </g>
        <path
          d="M57.9998 133.599C57.9998 133.599 94.2998 128.899 99.3998 136.499C104.5 144.099 113.6 182.399 113.6 182.399L106.2 185.299C106.2 185.299 93.8998 162.099 90.1998 147.699L81.1998 147.299L56.4998 149.199C56.4998 149.199 46.5998 137.699 57.9998 133.599Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.4"
          d="M57.9998 133.599C57.9998 133.599 94.2998 128.899 99.3998 136.499C104.5 144.099 113.6 182.399 113.6 182.399L106.2 185.299C106.2 185.299 93.8998 162.099 90.1998 147.699L81.1998 147.299L56.4998 149.199C56.4998 149.199 46.5998 137.699 57.9998 133.599Z"
          fill="white"
        />
        <path
          d="M34.3003 97.9002C34.4003 101.6 37.4003 108.4 38.6003 114.9C40.1003 123.5 40.0003 130.4 40.0003 130.4C40.0003 130.4 59.9003 135.5 68.9003 130.3C68.9003 130.3 67.6003 124 67.3003 116.4C66.8003 106.2 67.0003 93.7002 64.7003 91.2002C60.8003 86.8002 52.1003 86.4002 43.7003 87.0002C35.2003 87.6002 34.2003 94.3002 34.3003 97.9002Z"
          fill="#263238"
        />
        <g opacity="0.1">
          <path
            d="M34.3003 97.9002C34.4003 101.6 37.4003 108.4 38.6003 114.9C40.1003 123.5 40.0003 130.4 40.0003 130.4C40.0003 130.4 59.9003 135.5 68.9003 130.3C68.9003 130.3 67.6003 124 67.3003 116.4C66.8003 106.2 67.0003 93.7002 64.7003 91.2002C60.8003 86.8002 52.1003 86.4002 43.7003 87.0002C35.2003 87.6002 34.2003 94.3002 34.3003 97.9002Z"
            fill="white"
          />
        </g>
        <path
          d="M104.3 183.1L113.6 179.5C113.6 179.5 115.4 180 114.7 182.3L105.4 185.7C105.4 185.6 104.6 185.6 104.3 183.1Z"
          fill={`${default_color}`}
        />
        <path
          d="M31.9997 100.301C31.9997 100.301 28.4997 110.701 29.5997 116.401C30.6997 122.101 51.5997 122.501 51.5997 122.501C51.5997 122.501 54.4997 123.401 56.0997 123.101C57.1997 122.901 58.9997 123.201 60.0997 123.501C60.6997 123.601 61.1997 123.401 61.5997 123.001L62.4997 121.901C62.8997 121.501 62.9997 121.001 62.7997 120.401C62.3997 119.001 61.3997 116.201 59.4997 116.301C56.9997 116.401 51.9997 118.701 51.9997 118.701L38.6997 114.001C37.6997 113.801 37.0997 112.701 37.2997 111.701L39.6997 101.401L31.9997 100.301Z"
          fill="#FAB27B"
        />
        <path
          d="M30.4 99.8995C30.4 99.8995 33.3 89.4995 39 87.6995C44.6 85.8995 41.5 101.7 41.5 101.7L41.8 102.3L41.5 104.4C41.5 104.4 34.1 102.1 29.5 102.5L30 99.9995L30.4 99.8995Z"
          fill="#263238"
        />
        <g opacity="0.2">
          <path
            d="M30.4 99.8995C30.4 99.8995 33.3 89.4995 39 87.6995C44.6 85.8995 41.5 101.7 41.5 101.7L41.8 102.3L41.5 104.4C41.5 104.4 34.1 102.1 29.5 102.5L30 99.9995L30.4 99.8995Z"
            fill="white"
          />
        </g>
        <path
          d="M58.7002 87.7004L58.3002 87.6004C55.0002 86.5004 55.3002 84.1004 56.0002 81.8004L50.6002 76.4004C50.9002 79.5004 51.3002 86.7004 48.7002 86.8004L49.8002 87.7004L51.7002 90.0004L56.4002 94.8004L57.1002 94.7004L58.2002 93.8004L59.1002 91.4004L58.7002 87.7004Z"
          fill="#FAB27B"
        />
        <path
          d="M49.4002 86.5C49.4002 86.5 54.4002 90.4 56.5002 94.3C56.5002 94.3 57.8002 92.3 57.9002 90.2C58.0002 88.8 56.7002 87.6 56.7002 86.6L59.1002 87.6C59.1002 87.6 63.0002 93.5 61.2002 96.9C61.2002 96.9 60.0002 93.9 57.1002 95.1C57.1002 95.1 56.3002 95.5 55.8002 94.9C55.7002 94.7 55.4002 94.6 55.2002 94.6C54.4002 94.5 52.6002 94.5 51.1002 96.9C51.1002 96.9 50.0002 90 47.2002 86.6L49.4002 86.5Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M52.8002 78.5996L56.0002 81.8996C55.8002 82.4996 55.7002 82.9996 55.6002 83.4996C54.3002 83.1996 52.5002 81.8996 52.5002 80.4996C52.4002 79.7996 52.6002 79.0996 52.8002 78.5996Z"
          fill="black"
        />
        <path
          d="M48.2001 71.4002C49.5001 76.3002 50.0001 78.5002 53.0001 80.5002C57.6001 83.6002 63.4001 80.9002 63.6001 75.7002C63.8001 71.0002 61.7001 63.7002 56.3001 62.7002C52.5001 61.9002 48.8001 64.4002 48.1001 68.1002C47.8001 69.3002 47.9001 70.4002 48.2001 71.4002Z"
          fill="#FAB27B"
        />
        <path
          d="M56.3996 71.2999C56.4996 71.6999 56.7996 71.9999 56.9996 71.8999C57.2996 71.7999 57.3996 71.4998 57.2996 71.0998C57.1996 70.6998 56.8996 70.3999 56.6996 70.4999C56.4996 70.4999 56.2996 70.8999 56.3996 71.2999Z"
          fill="#263238"
        />
        <path
          d="M61.1996 70.3995C61.2996 70.7995 61.5996 71.0995 61.7996 70.9995C62.0996 70.8995 62.1996 70.5995 62.0996 70.1995C61.9996 69.7995 61.6996 69.4995 61.4996 69.5995C61.1996 69.5995 61.0996 69.9995 61.1996 70.3995Z"
          fill="#263238"
        />
        <path
          d="M61.2002 69.5996L62.1002 69.0996C62.1002 69.0996 61.8002 69.9996 61.2002 69.5996Z"
          fill="#263238"
        />
        <path
          d="M59.5996 71.4004C60.1996 72.3004 60.7996 73.0004 61.4996 73.7004C61.0996 74.1004 60.5996 74.3004 60.0996 74.3004L59.5996 71.4004Z"
          fill="#ED893E"
        />
        <path
          d="M53.6004 69.6992C53.5004 69.6992 53.5004 69.6992 53.5004 69.6992C53.4004 69.5992 53.4004 69.4992 53.4004 69.3992C53.8004 68.6992 54.5004 68.2992 55.2004 68.1992C55.3004 68.1992 55.4004 68.2992 55.4004 68.3992C55.4004 68.4992 55.3004 68.5992 55.2004 68.5992C54.6004 68.6992 54.0004 68.9992 53.7004 69.5992C53.7004 69.6992 53.6004 69.6992 53.6004 69.6992Z"
          fill="#263238"
        />
        <path
          d="M62.3996 68.001C62.2996 68.001 62.2996 68.001 62.2996 68.001C61.6996 67.701 61.0996 67.601 60.4996 67.901C60.3996 68.001 60.1996 67.901 60.1996 67.801C60.0996 67.701 60.1996 67.501 60.2996 67.501C60.9996 67.201 61.7996 67.201 62.4996 67.601C62.5996 67.701 62.5996 67.801 62.5996 67.901C62.4996 68.001 62.4996 68.001 62.3996 68.001Z"
          fill="#263238"
        />
        <path
          d="M56.7998 70.5L57.6998 70C57.6998 70.1 57.2998 70.9 56.7998 70.5Z"
          fill="#263238"
        />
        <path
          d="M60.2001 77.3007C60.4001 77.2007 60.6001 77.2007 60.8001 77.1007C60.9001 77.1007 60.9001 77.0007 60.9001 77.0007C60.9001 76.9007 60.8001 76.9007 60.7001 76.9007C59.8001 77.3007 58.7001 77.3007 57.8001 76.7007C57.7001 76.6007 57.7001 76.7007 57.6001 76.7007C57.5001 76.8007 57.6001 76.8007 57.6001 76.9007C58.4001 77.4007 59.3001 77.5007 60.2001 77.3007Z"
          fill="#263238"
        />
        <path
          d="M52.4995 64.4002C52.4995 64.4002 53.8995 65.8002 57.9995 67.0002L56.7995 65.3002C56.7995 65.3002 61.3995 67.6002 64.2995 67.0002C64.2995 67.0002 59.9995 58.3002 50.9995 61.9002C50.9995 61.9002 41.4995 70.7002 46.6995 80.7002C46.6995 80.7002 49.4995 82.3002 50.0995 79.3002C50.0995 79.3002 51.3995 81.3002 50.0995 82.6002C50.0995 82.6002 52.6995 80.5002 51.4995 77.1002C50.3995 73.7002 50.9995 72.2002 52.3995 69.5002C53.8995 66.6002 52.4995 64.4002 52.4995 64.4002Z"
          fill="#263238"
        />
        <path
          d="M89.7998 187.401L91.5998 192.601L96.4998 191.101L95.6998 185.301L89.7998 187.401Z"
          fill="#FAB27B"
        />
        <path
          d="M98.3002 191C98.2002 191 98.2002 190.9 98.2002 190.9C98.2002 190.8 98.2002 190.8 98.3002 190.7C98.4002 190.6 100.2 188.9 101.2 189C101.4 189 101.6 189.1 101.7 189.3C101.9 189.4 102 189.7 101.8 189.9C101.6 190.3 100.9 190.6 100.1 190.7C99.6002 191 98.9002 191 98.3002 191ZM98.7002 190.8C99.8002 190.7 101.3 190.4 101.6 189.9C101.6 189.8 101.7 189.8 101.5 189.6C101.4 189.5 101.3 189.5 101.2 189.5C101.1 189.5 101 189.5 101 189.5C100.1 189.7 99.4002 190.2 98.7002 190.8Z"
          fill={`${default_color}`}
        />
        <path
          d="M98.3 190.999C98.2 190.999 98.2 190.899 98.2 190.899C98.2 190.799 98 189.099 98.6 188.399C98.8 188.099 99 187.999 99.4 187.999C99.8 187.899 100 188.099 100 188.299C100.3 188.899 99.1 190.599 98.5 191.099H98.4C98.3 191.099 98.3 191.099 98.3 190.999ZM99.3 188.199C99.1 188.299 99 188.299 98.9 188.499C98.6 189.099 98.4 189.799 98.5 190.499C99.1 189.899 99.9 188.699 99.8 188.199C99.8 188.099 99.7 188.099 99.5 188.099L99.3 188.199Z"
          fill={`${default_color}`}
        />
        <path
          d="M97.5 190.4L90.9 191.8C90.6 191.9 90.5 192.1 90.5 192.3V197.9C90.5 198.4 90.9 198.8 91.4 198.8C91.5 198.8 91.5 198.8 91.6 198.8C94 198.2 96 197.7 99 197C102.4 196.2 101.7 196.9 105.8 196C108.2 195.5 108.1 192.6 107.1 192.7C102.2 193.1 101.4 191.8 99.1 190.5C98.6 190.3 98 190.2 97.5 190.4Z"
          fill="#263238"
        />
        <path
          opacity="0.1"
          d="M95.6998 185.301L89.7998 187.401L90.5998 189.801L95.9998 187.801L95.6998 185.301Z"
          fill="black"
        />
        <path
          d="M40.8999 130.4C40.8999 130.4 39.0999 149.2 48.4 149.7C57.5 150.2 77.3 145.3 77.3 145.3C77.3 145.3 78.2 161 83 172.8C87.8 184.6 88.3 187.1 88.3 187.1L96.4 184.3C96.4 184.3 93.3 142.1 87.4 135C80.1 126.4 46.4999 131.4 46.4999 131.4L40.8999 130.4Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.5"
          d="M40.8999 130.4C40.8999 130.4 39.0999 149.2 48.4 149.7C57.5 150.2 77.3 145.3 77.3 145.3C77.3 145.3 78.2 161 83 172.8C87.8 184.6 88.3 187.1 88.3 187.1L96.4 184.3C96.4 184.3 93.3 142.1 87.4 135C80.1 126.4 46.4999 131.4 46.4999 131.4L40.8999 130.4Z"
          fill="white"
        />
        <path
          d="M86.9 186.7L97 183.1C97 183.1 98.3 184.2 97.6 185.7L87.8 189.3C87.9 189.3 86.8 189 86.9 186.7Z"
          fill={`${default_color}`}
        />
        <path
          d="M48.2998 75.1001C48.6998 76.0001 49.5998 76.6001 50.4998 76.9001C51.7998 77.3001 52.3998 76.1001 52.0998 75.0001C51.7998 73.9001 50.7998 72.4001 49.4998 72.6001C48.5998 72.7001 47.8998 73.6001 48.0998 74.5001C48.0998 74.6001 48.1998 74.8001 48.2998 75.1001Z"
          fill="#FAB27B"
        />
        <path
          d="M87.8997 95L92.1997 95.8C92.5997 95.9 92.7997 96.2 92.5997 96.6L89.8997 106.3C89.7997 106.7 89.4997 106.9 89.0997 106.9L84.7998 106.1C84.3998 106 84.1997 105.7 84.3997 105.3L87.0997 95.6C87.0997 95.2 87.4997 95 87.8997 95Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.5"
          d="M87.8997 95L92.1997 95.8C92.5997 95.9 92.7997 96.2 92.5997 96.6L89.8997 106.3C89.7997 106.7 89.4997 106.9 89.0997 106.9L84.7998 106.1C84.3998 106 84.1997 105.7 84.3997 105.3L87.0997 95.6C87.0997 95.2 87.4997 95 87.8997 95Z"
          fill="white"
        />
        <path
          d="M84.8996 106.1L89.1996 106.9C89.2996 106.9 89.4996 106.9 89.5996 106.8L85.5996 106.1C85.1996 106 84.9996 105.7 85.1996 105.3L87.8996 95.5998C87.9996 95.2998 88.1996 95.1998 88.2996 95.0998L87.9996 94.9999C87.5996 94.8999 87.2996 95.1998 87.1996 95.5998L84.4996 105.3C84.3996 105.6 84.5996 106 84.8996 106.1Z"
          fill={`${default_color}`}
        />
        <path
          d="M89.0996 95.9C89.0996 96.1 88.8996 96.3 88.6996 96.3C88.4996 96.3 88.2996 96.2 88.3996 95.9C88.4996 95.6 88.5996 95.5 88.7996 95.5C88.8996 95.6 89.0996 95.8 89.0996 95.9Z"
          fill="#263238"
        />
        <path
          d="M70.1998 125.6C62.5998 125.6 61.1998 111.7 60.5998 105C60.4998 104 60.3998 103.1 60.2998 102.6C59.5998 98.0004 56.3998 91.0004 54.4998 87.6004C52.4998 84.2004 50.2998 77.5004 50.2998 77.5004L50.4998 77.4004C50.4998 77.5004 52.6998 84.0004 54.6998 87.5004C56.5998 90.9004 59.8998 98.0004 60.4998 102.6C60.5998 103.2 60.6998 104 60.7998 105C61.4998 111.6 62.8998 125.4 70.1998 125.4C70.1998 125.4 70.1998 125.4 70.2998 125.4C78.7998 125.3 85.5998 106.2 85.6998 106.1L85.8998 106.2C85.8998 106.3 84.0998 111.1 81.3998 115.9C77.4998 122.2 73.7998 125.6 70.1998 125.6Z"
          fill={`${default_color}`}
        />
        <path
          d="M50.4998 75.1008C50.5998 75.5008 50.9998 75.7008 50.5998 75.9008C50.1998 76.0008 49.7998 75.8008 49.6998 75.5008C49.5998 75.1008 49.7998 74.7008 50.0998 74.6008C50.5998 74.3008 50.3998 74.7008 50.4998 75.1008Z"
          fill="white"
        />
        <path
          d="M49.8 75.1992C49.8 75.1992 49.5 75.1992 50.4 77.6992L50.7 77.5992C50.7 77.5992 50.1 75.7992 50.5 75.5992C50.5 75.5992 50.2 75.1992 49.8 75.1992Z"
          fill="white"
        />
        <path
          d="M58.9998 85.7004C59.4998 84.0004 59.5998 82.4004 59.5998 81.4004C59.4998 81.4004 59.4998 81.5004 59.3998 81.5004C59.3998 82.6004 59.2998 84.0004 58.7998 85.7004C57.7998 89.1004 60.0998 100.8 60.0998 101.3L60.2998 101.2C60.2998 101.1 57.9998 89.1004 58.9998 85.7004Z"
          fill={`${default_color}`}
        />
        <path
          d="M85.1998 108.2L85.9998 106.1L85.5998 106L84.7998 108.1L85.1998 108.2Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.3"
          d="M85.1998 108.2L85.9998 106.1L85.5998 106L84.7998 108.1L85.1998 108.2Z"
          fill="black"
        />
        <path
          d="M61 130.2H81.1L90.5 113.4H79.5L72.9 125.1L70.2 129.9L61.8 129.7L61 130.2Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.6"
          d="M61 130.2H81.1L90.5 113.4H79.5L72.9 125.1L70.2 129.9L61.8 129.7L61 130.2Z"
          fill="white"
        />
        <path
          d="M79.0004 113.4L69.8004 129.4H50.4004V129.8C50.4004 130.1 50.6004 130.3 50.9004 130.3H70.5004L79.9004 113.5H79.0004V113.4Z"
          fill={`${default_color}`}
        />
        <path
          d="M77.7 38.5H55.2C54.2 38.5 53.3 39.3 53.3 40.4L52.7 50.8C52.6 51.8 53.5 52.7 54.5 52.7H62L65.4 56.9L69.3 52.7H77C78 52.7 78.9 51.9 78.9 50.8L79.5 40.4C79.5 39.4 78.8 38.5 77.7 38.5Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.7"
          d="M77.7 38.5H55.2C54.2 38.5 53.3 39.3 53.3 40.4L52.7 50.8C52.6 51.8 53.5 52.7 54.5 52.7H62L65.4 56.9L69.3 52.7H77C78 52.7 78.9 51.9 78.9 50.8L79.5 40.4C79.5 39.4 78.8 38.5 77.7 38.5Z"
          fill="white"
        />
        <path
          d="M61.5996 45.4992C61.5996 45.0992 61.9996 44.6992 62.3996 44.6992C62.7996 44.6992 63.1996 45.0992 63.1996 45.4992C63.1996 45.8992 62.7996 46.2992 62.3996 46.2992C61.9996 46.2992 61.5996 45.8992 61.5996 45.4992Z"
          fill={`${default_color}`}
        />
        <path
          d="M65.2998 45.4992C65.2998 45.0992 65.6998 44.6992 66.0998 44.6992C66.4998 44.6992 66.8998 45.0992 66.8998 45.4992C66.8998 45.8992 66.4998 46.2992 66.0998 46.2992C65.6998 46.2992 65.2998 45.8992 65.2998 45.4992Z"
          fill={`${default_color}`}
        />
        <path
          d="M69 45.4992C69 45.0992 69.4 44.6992 69.8 44.6992C70.2 44.6992 70.6 45.0992 70.6 45.4992C70.6 45.8992 70.2 46.2992 69.8 46.2992C69.4 46.2992 69 45.8992 69 45.4992Z"
          fill={`${default_color}`}
        />
      </g>
      <defs>
        <clipPath id="clip0_512_10726">
          <rect width="188" height="199" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RequestCallBackSvg;
