import React from "react";
import { Paragraph2 } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import _ from "lodash";

function HyperLink({ value, column, alignment, accessor, row, ...rest }) {
  console.log("PORTT", rest.portt);
  value = _.get(row.original, accessor, "");

  const [visible, setVisible] = React.useState(false);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  return visible ? (
    <StatefulTooltip accessibilityType={"tooltip"} content={value}>
      <Paragraph2
        ref={ref}
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          textAlign: alignment,
          margin: "5px",
          color: "#0000ff",
          textDecoration: "underline",
        }}
      >
        <a href={value}>
          <div className="elip" ref={ref}>
            {value}
          </div>
        </a>
      </Paragraph2>
    </StatefulTooltip>
  ) : (
    <Paragraph2
      ref={ref}
      $style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        textAlign: alignment,
        margin: "5px",
        color: "#0000ff",
        textDecoration: "underline",
      }}
    >
      <a href={value}>{value}</a>
    </Paragraph2>
  );
}
export default HyperLink;
