import React from "react";

function EmailSvg({ color = "#1A73E8" }) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.94905 17.875C3.48601 17.875 3.09408 17.7145 2.77324 17.3937C2.45241 17.0729 2.29199 16.6809 2.29199 16.2179V5.78206C2.29199 5.31902 2.45241 4.92708 2.77324 4.60625C3.09408 4.28542 3.48601 4.125 3.94905 4.125H18.0516C18.5146 4.125 18.9065 4.28542 19.2274 4.60625C19.5482 4.92708 19.7086 5.31902 19.7086 5.78206V16.2179C19.7086 16.6809 19.5482 17.0729 19.2274 17.3937C18.9065 17.7145 18.5146 17.875 18.0516 17.875H3.94905ZM18.3336 6.82206L11.4463 11.2309C11.3758 11.2708 11.3029 11.3023 11.2277 11.3252C11.1525 11.3481 11.0767 11.3596 11.0003 11.3596C10.9239 11.3596 10.8481 11.3481 10.7729 11.3252C10.6977 11.3023 10.6248 11.2708 10.5543 11.2309L3.66697 6.82206V16.2179C3.66697 16.3002 3.69342 16.3677 3.74631 16.4206C3.7992 16.4735 3.86678 16.5 3.94905 16.5H18.0516C18.1338 16.5 18.2014 16.4735 18.2543 16.4206C18.3072 16.3677 18.3336 16.3002 18.3336 16.2179V6.82206ZM11.0003 10.0833L18.1926 5.49998H3.808L11.0003 10.0833ZM3.66697 7.03358V5.98563V6.01294V5.98386V7.03358Z"
        fill={color}
      />
    </svg>
  );
}

export default EmailSvg;
