import React from "react";
import CreditNote from "../../containers/CreditNote/CreditNote";
import Layout from "../Layout/Layout";

const CreditNotesView = () => {
  return (
    // <Layout>
      <CreditNote />
    // </Layout>
  );
};

export default CreditNotesView;
