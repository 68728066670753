import React, { useState, useMemo, useEffect, useRef } from "react";
import { Input } from "baseui/input";
import { SIZE, KIND } from "baseui/button";
import { IconButton } from "../../components/IconButton";
import ChipFilter from "../../components/ChipFilter";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { ParagraphMedium } from "baseui/typography";
import TextButton from "../../components/TextButton/TextButton";
import TableSelectedFooter from "../../components/TableSelectedFooter/TableSelectedFooter";
import InvoicePhoneCard from "../../components/InvoicePhoneCard/InvoicePhoneCard";
import TableSelectedPhoneFooter from "../../components/TableSelectedFooter/TableSelectedPhoneFooter";
import DownloadCusPortal from "../../components/DownloadCSV/DownloadCusPortal";
import { getInvoice } from "../../services/customerPortal";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { LoanBroker } from "../../constants/IndustrialSector";
import { useDebouncedCallback } from "use-debounce";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import { useNumericFormat } from "react-number-format";

const TableBody = ({
  page,
  prepareRow,
  getTableBodyProps,
  setIsPerformance,
}) => {
  return (
    <>
      <tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row);
          const rowProps = row.getRowProps();
          return (
            <tr
              key={index}
              {...row.getRowProps()}
              // style={{ gap: "15px" }}
            >
              {row.cells.map((cell, i) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={i === row.cells.length - 1 ? "col-fixed" : ""}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

const InvoiceTable = ({
  menuName,
  setFooter,
  setInvoiceDetails,
  isInvoiceDetails,
  columnResizing,
  visibleColumns,
  updateQueryFilter,
  getTableProps,
  headerGroups,
  getTableBodyProps,
  page,
  prepareRow,
  setWidth,
  selectedFlatRows,
  default_color,
  queryFilter,
  setQuerySortBy,
  activeKey,
  isLoading,
  columnData,
  tabledata,
  showActiveUser,
}) => {
  let ref = useRef();
  const {
    default_nav_color,
    organization,
    customer,
    tabMotion,
    referenceData,
  } = useSelector((s) => s.layoutReduceData);
  const currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );
  let tabledataLength = tabledata && tabledata.length;
  const [sorting, setSorting] = useState(
    organization.industry_sector == LoanBroker
      ? "Loan Account No Asc"
      : "Invoice Date Asc"
  );
  // SORTING LIST DATA
  let sortingList = [
    {
      id: "invoice_due_date",
      sortName:
        organization.industry_sector == LoanBroker
          ? "Loan Account No Asc"
          : "Invoice Date Asc",
    },
    {
      id: "-invoice_due_date",
      sortName:
        organization.industry_sector == LoanBroker
          ? "Loan Account No Dsc"
          : "Invoice Date Dsc",
    },
  ];
  const intl = useIntl();
  useEffect(() => {
    if (ref.current) {
      setWidth(Math.round(ref.current?.offsetWidth));
    }
  }, [ref]);
  const debounceFilter = useDebouncedCallback((filter) => {
    updateQueryFilter({
      ...queryFilter,
      invoice_number: filter,
    });
  }, 1000);
  const [isValue, setIsValue] = useState();
  const isDeskView = window.innerWidth >= 500;

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  const numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });
  const TableBodyMemo = React.memo(TableBody, (prevProps, nextProps) => {
    return nextProps.isColumnResizing;
  });
  const isColumnResizing = !!columnResizing.isResizingColumn;

  return (
    <div>
      <div className="table-filter-area">
        {/* SEARCH INVOICES INPUT ENDS*/}
        <div style={{ width: "245px" }}>
          {showActiveUser ? (
            <></>
          ) : (
            <Input
              // id={props.name}
              placeholder={intl.formatMessage({ id: "invoice_number" })}
              size={SIZE.compact}
              autoComplete="off"
              autoFill="off"
              overrides={{
                Root: {
                  style: ({ $theme, $isFocused, $error }) => ({
                    borderBottomRightRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                    borderWidth: "1px",
                    borderColor: $isFocused
                      ? $error
                        ? "#FD372A"
                        : "#516BEB"
                      : $error
                      ? "#FD372A"
                      : "#CDCED9",
                    backgroundColor: "#ffffff",
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }),
                },
                StartEnhancer: {
                  style: ({ $theme }) => ({
                    backgroundColor: "#ffffff",
                  }),
                },
                Input: {
                  style: ({ $theme, $isFocused }) => ({
                    backgroundColor: "#ffffff",
                    paddingLeft: "0px",

                    "::placeholder": {
                      // color: $isFocused ? "#cbd5e0" : "transparent",
                      color: !$isFocused ? "#cbd5e0" : "transparent",

                      transition: "color 0.4s ease",
                    },
                  }),
                },
                EndEnhancer: {
                  style: ({ $theme }) => ({
                    backgroundColor: "#FFFFFF",
                  }),
                },
              }}
              value={isValue}
              onChange={(evt) => {
                setIsValue(evt.target.value);
                debounceFilter(evt.target.value);
              }}
              startEnhancer={
                <i className="mx-icon-Group-14  font-icon-color" />
              }
            ></Input>
          )}
        </div>

        {/* SEARCH INVOICES INPUT ENDS*/}

        {/* DOWNLOAD CSV & DATE FILTER STARTS*/}
        {isDeskView && (
          <div style={{ width: "115px" }}>
            <DownloadCusPortal
              default_color={default_color}
              queryFilter={queryFilter}
              service={getInvoice}
              fileName={"Customer Portal"}
              isLoading={isLoading}
              columnData={columnData}
              data={tabledata}
              length={tabledataLength}
            />
          </div>
        )}

        {/* DOWNLOAD CSV & DATE FILTER ENDS*/}
      </div>
      {/* SORT */}
      {activeKey === "0" ? (
        <>
          <div
            style={{
              display: "flex",
              // padding: "15px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            className="due-sort"
          >
            <div style={{}}>
              <ChipFilter
                filters={[
                  {
                    label: `Due (${
                      currencyList[customer?.currency]
                        ? currencyList[customer?.currency]
                        : ""
                    } ${numberFormat.format(
                      `${customer?.due_amount ? customer?.due_amount : 0}`
                    )})`,
                    value: "due",
                  },
                  {
                    label: `OverDue (${
                      currencyList[customer?.currency]
                        ? currencyList[customer?.currency]
                        : ""
                    } ${numberFormat.format(
                      `${
                        customer?.overdue_amount ? customer?.overdue_amount : 0
                      }`
                    )})`,
                    value: "overdue",
                  },
                ]}
                color={`${default_color}`}
                setFilter={(filter) => updateQueryFilter({ type: filter })}
              />
            </div>
            <div className="sort-field">
              <StatefulPopover
                placement={PLACEMENT.bottomLeft}
                content={({ close }) => (
                  <div className="cp_email_attachement">
                    {sortingList.map((i) => (
                      <ParagraphMedium
                        onClick={() => {
                          setSorting(i.sortName);
                          setQuerySortBy(i.id);
                          close();
                        }}
                      >
                        {i.sortName}
                      </ParagraphMedium>
                    ))}
                    {/* <ParagraphMedium></ParagraphMedium> */}
                  </div>
                )}
                overrides={{
                  Body: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#ffffff",
                      padding: "10px 20px",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      borderBottomRightRadius: "5px",
                    }),
                  },
                  Inner: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                    }),
                  },
                }}
                returnFocus
                autoFocus
              >
                <div>
                  <TextButton
                    type="button"
                    kind={KIND.tertiary}
                    size={SIZE.compact}
                  >
                    {`${"Sort by:"} ${sorting}`}
                    <i className="mx-icon-arrow_drop_down_FILL0_wght400_GRAD0_opsz48-2" />
                  </TextButton>
                </div>
              </StatefulPopover>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {/* SORT  */}
      {/* TABLE STARTS */}
      <div className="invoice-table__wraper">
        {/* WEB VIEW */}
        {/* <div className="invoice-table-wraper"> */}
        <div
          className={`${
            selectedFlatRows && selectedFlatRows.length > 0 && activeKey === "1"
              ? "invoice-table-wraper-mobile-paid"
              : selectedFlatRows && selectedFlatRows.length > 0
              ? "invoice-table-wraper-mobile"
              : "invoice-table-wraper"
          }`}
        >
          <div
            className="customer-profile-tab__table"
            style={{
              gridColumn: "1/6",
              gridRow: "2/13",
              overflowY: "unset",
            }}
          >
            <div
              className={`${
                menuName === "customer_portal_custom_inv"
                  ? "invoice-summary-main-table --web-view"
                  : "paid-summary-main-table --web-view"
              }`}
            >
              <table>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps()}
                          className={
                            index === headerGroup.headers.length - 1
                              ? "col-fixed"
                              : ""
                          }
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {isLoading ? (
                  <Loader />
                ) : tabledata && tabledata.length === 0 ? (
                  <p
                    style={{
                      paddingTop: "10px",
                      textAlign: "center",
                      fontSize: "16px",
                      position: "relative",
                      left: "100%",
                    }}
                  >
                    No Records Found
                  </p>
                ) : (
                  <TableBodyMemo
                    isColumnResizing={isColumnResizing}
                    page={page}
                    prepareRow={prepareRow}
                    getTableBodyProps={getTableBodyProps}
                    visibleColumns={visibleColumns}
                  />
                )}
              </table>
            </div>
            {/* MOBILE CARD STARTS */}
            {/* MOBILE CARD STARTS */}
            <div className="new-mobile-view">
              {Array.isArray(page) && page.length === 0 && (
                <div
                  style={{
                    paddingTop: "10px",
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  No Records Found
                </div>
              )}
              {page.map((row, index) => {
                prepareRow(row);
                let RowDatas = row && row.original;
                return (
                  <div>
                    {isLoading ? (
                      <>
                        <Loader />
                      </>
                    ) : (
                      <div>
                        <InvoicePhoneCard
                          setInvoiceDetails={setInvoiceDetails}
                          isInvoiceDetails={isInvoiceDetails}
                          setFooter={setFooter}
                          row={row}
                          selectedFlatRows={selectedFlatRows}
                          rowDatas={RowDatas}
                          rowToggle={row.getToggleRowSelectedProps()}
                          activeKey={activeKey}
                          isLoading={isLoading}
                          tabledataLength={tabledataLength}
                          index={index}
                          columnData={columnData}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            {/* MOBILE CARD STOPS */}

            {activeKey === "0" ? (
              <div className="table-selected-web-view">
                {selectedFlatRows && selectedFlatRows.length > 0 ? (
                  <>
                    <TableSelectedFooter />
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {/* TABLE ENDS */}
    </div>
  );
};

export default InvoiceTable;
