import moment from "moment";

export function utcTimstampToLocalDate(timestamp) {
  let utcTime = moment.utc(timestamp).format("YYYY-MM-DD HH:mm:ss");

  let localTime = moment.utc(utcTime).toDate();
  localTime = moment(localTime);

  return localTime;
}
