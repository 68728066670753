import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "baseui/modal";
import popup from "../../assets/img/pop-up.png";
import { LabelMedium } from "baseui/typography";
import { NumericFormat } from "react-number-format";
import { useSelector, useDispatch } from "react-redux";
import { KIND, SIZE, TextButton } from "../TextButton";
import moment from "moment";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import PayNowSvg from "../../assets/img/svg/PayNowSvg";
import { modalSetFn, paymentAmount } from "../../redux/Layout/action";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import _ from "lodash";

const CustomerPortalModal = ({ PromisePopUp, setIsOpen, isOpen }) => {
  const location = useLocation();
  const hash = location.pathname.split("/")[1];
  let dispatch = useDispatch();

  function close() {
    setIsOpen(false);
  }

  let navigate = useNavigate();
  // window.onload = function () {
  // if (PromisePopUp.data?.data?.doc?.is_promise_pending) {

  let currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );
  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";
  let timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );
  let dateFormat = useSelector(
    (e) =>
      e && e.layoutReduceData && e.layoutReduceData?.organization.date_format
  );
  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let showActiveUser = addSearchParams.get("i");

  const isPaymentPlan =
    _.get(PromisePopUp, "data.data.doc.payment_details.type", "") ===
    "PAYMENT_PLAN"
      ? true
      : false;

  return (
    <Modal
      onClose={() => {
        setIsOpen(false);
        dispatch(modalSetFn(false));
      }}
      focusLock={false}
      isOpen={isOpen}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            textAlign: "center",
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: "none",
          }),
        },
        Dialog: {
          style: ({ $theme }) => ({
            padding: "22px 22px",
            width: "351px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            boxShadow: "0px 4px 4px 0px #33386050 ",
          }),
        },
      }}
    >
      <div>
        <PayNowSvg />
        {isPaymentPlan ? (
          <LabelMedium>
            You have an active Payment Plan with the next installment on{" "}
            {moment
              .tz(
                PromisePopUp &&
                  PromisePopUp?.data &&
                  PromisePopUp?.data?.data &&
                  PromisePopUp?.data?.data?.doc &&
                  PromisePopUp?.data?.data?.doc?.payment_details &&
                  PromisePopUp?.data?.data?.doc?.payment_details?.promise_date,
                timeZone
              )
              .format(dateFormat)}
            . Would you like to pay?
          </LabelMedium>
        ) : (
          <LabelMedium>
            You have an active Promise to Pay for&nbsp;
            <NumericFormat
              displayType="text"
              value={
                PromisePopUp &&
                PromisePopUp?.data &&
                PromisePopUp?.data?.data &&
                PromisePopUp?.data?.data?.doc &&
                PromisePopUp?.data?.data?.doc?.payment_details?.promise_amount
                  ?.value
              }
              thousandsGroupStyle={currencyGroupStyle}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={currencySeparator}
              decimalSeparator={currencyDecimalSeparator}
              renderText={(values) => (
                <>
                  <span $style={{ fontSize: "18px", fontWeight: 600 }}>
                    {currencyList[
                      PromisePopUp?.data?.data?.doc?.payment_details
                        ?.promise_amount?.currency
                    ]
                      ? currencyList[
                          PromisePopUp?.data?.data?.doc?.payment_details
                            ?.promise_amount?.currency
                        ]
                      : PromisePopUp?.data?.data?.doc?.payment_details
                          ?.promise_amount?.currency}{" "}
                    {values}
                  </span>
                </>
              )}
            />
            &nbsp; by{" "}
            {moment
              .tz(
                PromisePopUp &&
                  PromisePopUp?.data &&
                  PromisePopUp?.data?.data &&
                  PromisePopUp?.data?.data?.doc &&
                  PromisePopUp?.data?.data?.doc?.payment_details &&
                  PromisePopUp?.data?.data?.doc?.payment_details?.promise_date,
                timeZone
              )
              .format(dateFormat)}
            . Would you like to pay ?
          </LabelMedium>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            paddingTop: "5px",
          }}
        >
          {/* <NavLink
            to={`/${hash}/payments${
              showActiveUser ? `?i=${showActiveUser}` : ""
            }`}
            state={{
              invoiceCurrency:
                PromisePopUp?.data?.data?.doc?.payment_details?.promise_amount
                  ?.currency,
              amount:
                PromisePopUp?.data?.data?.doc?.payment_details?.promise_amount
                  ?.value,
            }}
          > */}
          <div style={{ width: "100%", flex: 1 }}>
            <TextButton
              kind={KIND.primary}
              size={SIZE.mini}
              type="button"
              onClick={() => {
                if (
                  !_.get(
                    PromisePopUp,
                    "data.data.doc.is_auto_debit_payment_plan",
                    false
                  )
                ) {
                  dispatch(
                    paymentAmount(
                      PromisePopUp?.data?.data?.doc?.payment_details
                        ?.promise_amount?.value
                    )
                  );
                  dispatch(modalSetFn(false));
                  navigate(
                    `/${hash}/payments${
                      showActiveUser ? `?i=${showActiveUser}` : ""
                    }`
                  );
                } else {
                  window.location.replace(
                    _.get(
                      PromisePopUp,
                      "data.data.doc.auto_debit_redirect_url.url",
                      false
                    )
                  );
                }
              }}
            >
              {_.get(
                PromisePopUp,
                "data.data.doc.is_auto_debit_payment_plan",
                false
              )
                ? "Set Up Auto-Debit"
                : isPaymentPlan
                ? "Pay Installment"
                : "Pay Now"}
            </TextButton>
          </div>
          {/* </NavLink> */}
          <label>or</label>
          <TextButton
            kind={KIND.tertiary}
            size={SIZE.mini}
            type="button"
            onClick={() => {
              setIsOpen(false);
              dispatch(modalSetFn(false));
            }}
          >
            Continue with Portal
          </TextButton>
        </div>
      </div>
    </Modal>
  );
};
export default CustomerPortalModal;
