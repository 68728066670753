import React from "react";
import { StyledList, StyledListItem } from "baseui/menu";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import Edit from "../../assets/img/svg/EditSvg";
import useHover from "../../utils/HoverSvg/useHover";
import "../../../src/assets/css/table.css";
import { Label2 } from "baseui/typography";
import { IconButton, SIZE } from "../IconButton";

const ColumnToogle = ({ allColumns }) => {
  const [hoverRef, isHovered] = useHover();

  return (
    <>
      <StatefulPopover
        overrides={{
          Body: {
            style: ({ $theme }) => ({
              // zIndex: "99",
              borderRadius: "15px",
            }),
          },
          Inner: {
            style: ({ $theme }) => ({
              borderRadius: "15px",
            }),
          },
        }}
        focusLock
        placement={PLACEMENT.bottomLeft}
        content={({ close }) => {
          return (
            <>
              <div style={{}}>
                <StyledList
                  $style={{
                    backgroundColor: "#fff",
                    borderRadius: "15px",
                    // height: "250px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "15px",
                      borderBottom: "0.5px solid #D6D6D650",
                      marginBottom: "5px",
                    }}
                  >
                    <Label2
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#516BEB",
                      }}
                    >
                      Edit Columns
                    </Label2>
                  </div>
                  <div
                    style={{
                      overflowY: "scroll",
                      height: "300px",
                      borderRadius: "10px",
                    }}
                  >
                    {allColumns.map((column) => {
                      if (column.id === "selection" || column.id === "expander")
                        return null;
                      let Header = column.Header;
                      return (
                        <StyledListItem>
                          <CheckBoxBaseweb {...column.getToggleHiddenProps()}>
                            <Header></Header>
                          </CheckBoxBaseweb>
                        </StyledListItem>
                      );
                    })}
                  </div>
                </StyledList>
              </div>
            </>
          );
        }}
      >
        <div>
          <IconButton size={SIZE.compact}>
            <Edit />
          </IconButton>
        </div>
      </StatefulPopover>
    </>
  );
};

export default ColumnToogle;
