import lbMessages from "../locales/en_INSE225";

const lbLang = {
  messages: {
    ...lbMessages,
  },
  locale: "en_INSE225",
};

export default lbLang;
