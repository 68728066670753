import {
  ParagraphMedium,
  LabelSmall,
  LabelLarge,
  ParagraphLarge,
} from "baseui/typography";
import React, { useState, useEffect } from "react";
import TextButton from "../../components/TextButton/TextButton";
import { KIND, SIZE } from "baseui/button";
import CircleTick from "../../assets/img/svg/CircleTick";
import { TextBox, SIZE as size } from "../../components/TextBox";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { IconButton } from "../../components/IconButton";
import { Avatar } from "baseui/avatar";
import {
  getCallBackLater,
  getPayment,
  getPaymentList,
  postOnlineData,
} from "../../services/customerPortal";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { NumericFormat, useNumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import Payment from "./Payment";
import OtherBank from "../../assets/img/bank.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PaymentProcessing from "../../../src/assets/img/payment-processing.png";
import PaymentSuccess from "../../../src/assets/img/payment_success.png";
import PaymentCanceled from "../../../src/assets/img/payment-canceled.png";
import Paymentfailed from "../../../src/assets/img/payment-failed.png";
import PaymentProcess from "../../../src/assets/img/process.png";
import NoPaymentGateway from "../../../src/assets/img/no_gateway_configured.png";
import Opps from "../../../src/assets/img/opps.png";
import process from "../../../src/assets/img/process.png";
import { useNavigate, useParams } from "react-router-dom";
import PaymentContext from "../../components/PaymentContext/PaymentContext";
import { utcTimstampToLocalDate } from "../../utils/utcTimstampToLocalDate";
import moment from "moment";
import "moment-timezone";
import { useDispatch } from "react-redux";
import EditSvg from "../../assets/img/svg/EditSvg";
import Tick from "../../assets/img/svg/Tick";
import { async } from "q";
import OfflinePayment from "./OfflinePayment";
import CustomerPortalModal from "../../components/PortalModal/PortalModal";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import DebtorRight from "../../assets/img/svg/DebtorRight";
import { setDebtorRightsModal } from "../../redux/Layout/action";
import _ from "lodash";
import ConfirmationModel from "../../components/ConfirmationModel/ConfirmationModel";
const CheckTick = ({ type }) => {
  switch (type) {
    case "Tick":
      return <CircleTick />;
    default:
      return null;
  }
};

const PaymentOptionCard = ({
  paymentType,
  invoiceAmount,
  invoiceCount,
  currencyGroupStyle,
  currencySeparator,
  currencyDecimalSeparator,
  currency,
  onClick,
}) => {
  return (
    <div className={"payment-option__card"}>
      <div className="payment-option__card-content">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: "1",
            justifyContent: "space-between",
            // marginBottom: "5px",
          }}
        >
          <ParagraphLarge $style={{ color: "#757575", lineHeight: "10px" }}>
            <FormattedMessage id={paymentType}>{paymentType}</FormattedMessage>
          </ParagraphLarge>
        </div>
      </div>
      <div className="payment-option__card-content">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: "1",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <LabelLarge
            $style={{
              color: "#333860",
              lineHeight: "15px",
              marginTop: "5px",
              flex: "1",
            }}
          >
            {invoiceCount} invoice
          </LabelLarge>
          <NumericFormat
            displayType="text"
            value={invoiceAmount}
            thousandsGroupStyle={currencyGroupStyle}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={currencySeparator}
            decimalSeparator={currencyDecimalSeparator}
            renderText={(values) => (
              <>
                <LabelLarge
                  $style={{
                    width: "100%",
                    textAlign: "end",
                    margin: "5px",
                    flex: "1",
                  }}
                >
                  {currencyList[currency] ? currencyList[currency] : currency}{" "}
                  {values}
                </LabelLarge>
              </>
            )}
          />
        </div>
      </div>
      <div style={{ width: "100%", padding: "10px 20px" }}>
        <TextButton size={SIZE.compact} fullWidth onClick={onClick}>
          Pay Now
        </TextButton>
      </div>
    </div>
  );
};

const PaymentList = ({
  // hash,

  is_payment_configured,
  is_payment_initiated,

  is_pay_all_dues,
  is_pay_overdues,

  total_invoice_amount,
  total_invoice_count,
  overdue_invoice_amount,
  overdue_invoice_count,

  currency,
  setIsOutcome,
  setOutComeType,
  currencyGroupStyle,
  currencySeparator,
  currencyDecimalSeparator,
  initiated_payment,
}) => {
  const dispatch = useDispatch();
  const { hash } = useParams();
  const [initiateNewPayment, setInitiateNewPayment] = useState(false);
  const dateFormat = useSelector(
    (e) =>
      e && e.layoutReduceData && e.layoutReduceData?.organization.date_format
  );
  const timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );

  const numberFormat = useNumericFormat({
    value:
      initiated_payment &&
      initiated_payment.amount &&
      initiated_payment.amount.value
        ? initiated_payment.amount.value
        : 0,
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });

  useEffect(() => {
    setInitiateNewPayment(false);
  }, [currency]);

  if (!is_payment_configured) {
    return (
      <PaymentContext
        title={""}
        img={NoPaymentGateway}
        subText={`No Payment methods are available for ${currency}, Kindly pay as per the instructions provided in the Invoice.`}
        content={""}
        btnKind={KIND.secondary}
        hash={hash}
        isGoBackBtn={false}
      />
    );
  }

  if (is_payment_initiated && !initiateNewPayment) {
    let createdOn = utcTimstampToLocalDate(
      initiated_payment && initiated_payment.created_at
        ? initiated_payment.created_at
        : 0
    );

    return (
      <PaymentContext
        title={""}
        img={PaymentProcess}
        subText={`We are waiting for your recent transaction status done on ${moment
          .tz(createdOn, timeZone)
          .format(dateFormat)} and ${currency} ${numberFormat.value}`}
        content={""}
        btnKind={KIND.secondary}
        hash={hash}
        isGoBackBtn={false}
        Button={() => {
          return (
            <TextButton
              size={SIZE.compact}
              fullWidth
              type="button"
              kind="primary"
              onClick={() => {
                setIsOutcome("PAY_VIA");
                // setOutComeType({
                //   type: "overdue",
                //   currency: currency,
                // });
                // setInitiateNewPayment(true);
              }}
            >
              Initiate New Payment
            </TextButton>
          );
        }}
      />
    );
  }

  return (
    <>
      {is_pay_all_dues && (
        <PaymentOptionCard
          paymentType="due"
          invoiceAmount={total_invoice_amount}
          invoiceCount={total_invoice_count}
          currencyGroupStyle={currencyGroupStyle}
          currencySeparator={currencySeparator}
          currencyDecimalSeparator={currencyDecimalSeparator}
          currency={currency}
          onClick={() => {
            setIsOutcome("PAY_VIA");
            // setOutComeType({
            //   // amount: watchCurrency[0].total_invoice_amount,
            //   type: "due",
            //   currency: currency,
            // });
          }}
        />
      )}
      {is_pay_overdues && (
        <PaymentOptionCard
          paymentType="over_due"
          invoiceAmount={overdue_invoice_amount}
          invoiceCount={overdue_invoice_count}
          currencyGroupStyle={currencyGroupStyle}
          currencySeparator={currencySeparator}
          currencyDecimalSeparator={currencyDecimalSeparator}
          currency={currency}
          onClick={() => {
            setIsOutcome("PAY_VIA");
            // setOutComeType({
            //   type: "overdue",
            //   currency: currency,
            // });
          }}
        />
      )}
    </>
  );
};

const PaymentTypes = ({
  type,
  data,
  setIsOutcome,
  outComeType,
  paymentList,
  setOfflineData,
  offlineData,
  paymentId,
  setPaymentId,
  watch,
  promiseToPay,
  postOnline,
  referenceData,
  reset,
  setValue,
  payment_initiated,
  currency_separator,
  currencyStyle,
  currency_decimal_separator,
  valuePay,
  setIsOpen,
  setIsConfirmMessage,
  enablePayment,
  isConfirmMessage,
}) => {
  let layoutData = useSelector((s) => s.layoutReduceData);

  const { hash } = useParams();
  // State to manage modal visibility
  const openModal = (confirmMessage, paymentData) => {
    setIsOpen(true);
    setIsConfirmMessage({
      layoutData: confirmMessage,
      paymentData: paymentData,
    });
  };

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
  };

  // SELECTED INVOICES
  let { selectedInvoice } = useSelector((s) => s.invoiceReducer);
  // let paymentEnable = useSelector((e) => e.layoutReduceData?.payment);
  let customerDefaultCurrency = useSelector(
    (e) => e.layoutReduceData?.customer
  );

  let paymentAmount = useSelector((e) => e.layoutReduceData?.paymentAmount);

  let watchCurrency = watch("currency");

  console.log("watchCurrency", watchCurrency);
  useEffect(() => {
    if (data && data.data && data.data.docs && data.data.docs[0]) {
      let findDefaultCurrency = data.data.docs.find(
        ({ currency }) => currency == customerDefaultCurrency.currency
      );

      setValue("currency", [
        {
          label: findDefaultCurrency
            ? findDefaultCurrency.currency
            : data.data.docs[0].currency,
          id: findDefaultCurrency
            ? findDefaultCurrency.currency
            : data.data.docs[0].currency,
          ...(findDefaultCurrency ? findDefaultCurrency : data.data.docs[0]),
        },
      ]);
    }
  }, [data]);

  let totalAmount;

  if (selectedInvoice.length > 1) {
    totalAmount = selectedInvoice.reduce((acc, curr) => {
      if (!isNaN(curr.invoiceTotalAmount)) {
        return Number(acc) + Number(curr.invoiceTotalAmount);
      }
    }, 0);
  } else {
    totalAmount =
      selectedInvoice &&
      selectedInvoice[0] &&
      selectedInvoice[0].invoiceTotalAmount;
  }

  const [change, setChange] = useState(true);

  const [isText, setIsText] = useState(paymentAmount);

  const Handler = (e) => {
    if (e.key === "Enter") {
      if (isText > totalAmount) {
        setChange(false);
      } else if (isText < _.get(layoutData, "customer.min_payment", 0)) {
        setChange(false);
      } else {
        if (isText == 0) {
          setChange(false);
        } else {
          setChange(!change);
        }
      }
    }
  };

  const HandlerBlur = (e) => {
    if (isText > totalAmount) {
      setChange(false);
    } else if (
      isText < _.get(layoutData, "customer.min_payment") &&
      paymentAmount > _.get(layoutData, "customer.min_payment")
    ) {
      setChange(false);
    } else {
      if (isText == 0) {
        setChange(false);
      } else {
        setChange(!change);
      }
    }
  };

  const navigator = useNavigate();
  const [addSearchParams, setAddSearchParams] = useSearchParams();

  if (isText == undefined) {
    navigator(`/${hash}`);
  }
  let iserrmessage;

  if (isText > totalAmount) {
    iserrmessage = "Payment Amount Exceeds Invoice Due Amount";
  } else if (
    isText < _.get(layoutData, "customer.min_payment") &&
    paymentAmount > _.get(layoutData, "customer.min_payment")
  ) {
    iserrmessage = `Minimum Payment amount is ${_.get(
      layoutData,
      "customer.min_payment",
      0
    )} `;
  } else {
    if (isText == 0) {
      iserrmessage = "Enter the Valid Amount";
    }
  }

  let dateFormat = useSelector(
    (e) =>
      e && e.layoutReduceData && e.layoutReduceData?.organization.date_format
  );
  let timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );
  const [files, setFiles] = useState([]);
  console.log(type, "type");
  switch (type) {
    case "PAY_VIA":
      let listDatas = paymentList;
      let showActiveUser = addSearchParams.get("i");
      return (
        <>
          <div
            className="payment_title --mobl_title"
            style={{
              justifyContent: "center",
            }}
          >
            <>
              {/* BACK BUTTON STARTS */}
              <div className="payment_back_btn">
                <NavLink
                  to={`/${hash}${showActiveUser ? `?i=${showActiveUser}` : ""}`}
                >
                  <div
                    style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                      border: "0.5px solid #cdced9",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <i className="mx-icon-Group-16969" />
                  </div>
                </NavLink>
              </div>
              {/* BACK BUTTON ENDS */}
            </>

            <div>
              <LabelLarge
                $style={{
                  textAlign: "center",
                }}
                // className="org_name"
                className="payment-list-org_name"
              >
                {/* Pay Seshavalli Vaishnava matriculation school */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    height: "67px",
                    gap: 9,
                    alignItems: "center",
                  }}
                >
                  <div>
                    <span
                      style={{
                        color: "#333860",
                        position: "relative",
                        left: "5px",
                      }}
                    >
                      {currencyList[
                        selectedInvoice &&
                          selectedInvoice[0] &&
                          selectedInvoice[0].invoiceCurrency
                      ]
                        ? currencyList[
                            selectedInvoice &&
                              selectedInvoice[0] &&
                              selectedInvoice[0].invoiceCurrency
                          ]
                        : selectedInvoice &&
                          selectedInvoice[0] &&
                          selectedInvoice[0].invoiceCurrency}
                    </span>
                  </div>
                  {change ? (
                    <div>
                      <h1 style={{ color: "#333860" }}>
                        {Number(isText).toFixed(2)}
                      </h1>
                    </div>
                  ) : (
                    <div
                      style={{
                        textAlign: "left",
                        marginTop: "20px",
                        height: "56px",
                      }}
                    >
                      <TextBox
                        type="number"
                        error={iserrmessage}
                        size={size.mini}
                        onChange={(e) => {
                          let values = e.target.value;
                          setIsText(values);
                        }}
                        value={isText}
                        onKeyPress={(e) => Handler(e)}
                        onBlur={(e) => HandlerBlur(e)}
                        autoFocus={true}
                      />
                    </div>
                  )}
                  <div>
                    {change ? (
                      <IconButton
                        type="submit"
                        size={SIZE.mini}
                        onClick={() => setChange(!change)}
                        kind={KIND.tertiary}
                      >
                        <EditSvg />
                      </IconButton>
                    ) : (
                      <div>
                        <IconButton
                          type="submit"
                          size={SIZE.mini}
                          onClick={() => {
                            HandlerBlur();
                          }}
                          kind={KIND.tertiary}
                          disabled={
                            _.get(layoutData, "customer.min_payment", 0)
                              ? isText <
                                  _.get(
                                    layoutData,
                                    "customer.min_payment",
                                    0
                                  ) &&
                                paymentAmount >
                                  _.get(layoutData, "customer.min_payment")
                              : false
                          }
                        >
                          <Tick />
                        </IconButton>
                      </div>
                    )}
                  </div>
                </div>
                {/* {orgName ? orgName : ""} */}
              </LabelLarge>
              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                {/* Need additional time to make payment? */}
                Make Instant Payment / Find Payment Instruction
              </ParagraphMedium>
            </div>
          </div>

          {/* MAPPING DIFFERENT PAYMENT LIST */}
          <div style={{ height: "calc(100vh - 269px)", overflow: "scroll" }}>
            {watchCurrency &&
              watchCurrency[0] &&
              watchCurrency[0].payment_configurations.map((i) => {
                let findLogo =
                  referenceData && referenceData.payment_logo
                    ? referenceData.payment_logo.find(
                        ({ id }) => id == i?.payment_gateway
                      )
                    : {};
                //   overflow-y: auto; /* Enable vertical scrolling if needed */
                //   // overflow-x: hidden; /* Disable horizontal scrolling */
                //   white-space: pre-wrap;
                //   word-wrap: break-word;
                console.log("fjsdhshhhh", i);
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "20px",
                      }}
                    >
                      {change || i.is_wht_lb ? (
                        <div
                          className="payment-list"
                          style={{
                            justifyContent: i.is_wht_lb ? "center" : "",
                          }}
                          onClick={async () => {
                            if (
                              (_.get(
                                layoutData,
                                "customer.isEnableCPConfirmation",
                                false
                              ) &&
                                _.get(
                                  layoutData,
                                  "customer.cpConfirmationTypes",
                                  []
                                )?.includes("PAY")) ||
                              (i?.pay_now_info && i?.pay_now_info?.length)
                            ) {
                              openModal(
                                _.get(
                                  layoutData,
                                  "customer.cpConfirmationMessages",
                                  []
                                ),
                                { isText: isText, ...i }
                              );
                            } else {
                              setPaymentId(i);
                              setOfflineData(i?.offline_payment_info);
                              i.payment_mode === "offline"
                                ? setIsOutcome("ACC_DTL")
                                : setIsOutcome("PAY_VIA");
                              // let values = {
                              //   payment_id: i._id,
                              //   type: outComeType.type,
                              //   // amount: promiseToPayData
                              //   //   ? parseInt(promiseToPayData)
                              //   //   : 0,
                              //   currency: outComeType.currency,
                              // };
                              let selectedInvoiceID = selectedInvoice.map(
                                (i) => i._id
                              );
                              let values = {
                                payment_id: i._id,
                                allocated_to: selectedInvoiceID,
                                currency:
                                  selectedInvoice &&
                                  selectedInvoice[0] &&
                                  selectedInvoice[0].invoiceCurrency,
                                ...(i?.payment_type
                                  ? { payment_type: i?.payment_type }
                                  : {}),

                                amount: Number(isText),
                              };

                              if (_.get(i, "payment_mode", "") === "CLP") {
                                window.location.href = _.get(
                                  i,
                                  "redirect_url",
                                  i.redirect_url
                                );
                                return null;
                              }

                              if (
                                i.payment_mode === "online" &&
                                postOnline &&
                                !postOnline.isLoading &&
                                (_.get(layoutData, "customer.min_payment")
                                  ? isText <
                                      _.get(
                                        layoutData,
                                        "customer.min_payment",
                                        0
                                      ) ||
                                    paymentAmount >
                                      _.get(layoutData, "customer.min_payment")
                                  : true)
                              ) {
                                console.log("AJSGJHAGHJS", values);
                                await postOnline.mutateAsync(values);
                              }
                            }
                          }}
                        >
                          {!i.is_wht_lb ? (
                            <div className="payment_logo_wraper">
                              <img
                                src={
                                  i && i.payment_mode == "online"
                                    ? findLogo && findLogo.label
                                    : i && i.logo
                                    ? i && i.logo
                                    : OtherBank
                                }
                              />
                            </div>
                          ) : (
                            <></>
                          )}

                          <div>
                            <LabelSmall
                              $style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                maxWidth: "180px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {i.ref_name}
                            </LabelSmall>
                            <ParagraphMedium
                              $style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                maxWidth: "150px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {i.help_text ? i.help_text : ""}
                            </ParagraphMedium>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            // style={{
                            //   display: "flex",
                            //   gap: "20px",
                            //   alignItems: "center",
                            //   backgroundColor: "#ffffff",
                            //   boxShadow: "0px 3px 4px 0px #516beb25",
                            //   borderRadius: "5px",
                            //   width: "250px",
                            //   padding: "20px",
                            //   marginBottom: "15px",
                            //   marginTop: "15px",
                            //   cursor: "pointer",
                            // }}
                            className="payment-list"
                          >
                            <div className="payment_logo_wraper">
                              <img
                                src={
                                  i && i.payment_mode == "online"
                                    ? findLogo && findLogo.label
                                    : i && i.logo
                                    ? i && i.logo
                                    : OtherBank
                                }
                              />
                            </div>
                            <div>
                              <LabelSmall
                                $style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  maxWidth: "150px",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {i.ref_name}
                              </LabelSmall>
                              <ParagraphMedium
                                $style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  maxWidth: "150px",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {i.help_text ? i.help_text : "-"}
                              </ParagraphMedium>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                );
              })}
          </div>
        </>
      );

    case "ACC_DTL":
      let offlineDatas = offlineData;
      let accDtlActiveUser = addSearchParams.get("i");
      return (
        <>
          <div
            className="payment_title"
            style={{
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <>
              <div className="payment_back_btn">
                <div
                  style={{
                    height: "30px",
                    width: "30px",
                    borderRadius: "50%",
                    border: "0.5px solid #cdced9",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setIsOutcome("PAY_VIA")}
                >
                  <i className="mx-icon-Group-16969" />
                </div>
                {/* </NavLink> */}
              </div>
            </>
            {paymentId && paymentId.logo ? (
              <>
                <div className="img_wraper_content">
                  <img
                    src={paymentId && paymentId.logo ? paymentId.logo : ""}
                    style={{ width: "100%" }}
                  />
                </div>
              </>
            ) : (
              <></>
            )}

            <div>
              <LabelLarge
                $style={{
                  textAlign: "center",
                }}
              >
                {paymentId && paymentId.ref_name ? paymentId.ref_name : ""}
              </LabelLarge>
              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                {paymentId && paymentId.help_text ? paymentId.help_text : ""}
              </ParagraphMedium>
            </div>
          </div>
          <Payment
            offlineDatas={offlineDatas}
            outComeType={outComeType}
            paymentId={paymentId}
            setIsOutcome={setIsOutcome}
            promiseToPay={promiseToPay}
            setFiles={setFiles}
            reset={reset}
            type={type}
            isText={isText}
          />
        </>
      );

    case "PAYMENT_PROCESSING":
      return (
        <div>
          <PaymentContext
            title={"Payment Processing..."}
            img={PaymentProcessing}
            subText={"Thank you for the confirmation."}
            content={"We will validate and update the payment status"}
            btnKind={KIND.secondary}
            hash={hash}
          />
        </div>
      );

    case "PAYMENT_SUCCESS":
      return (
        <PaymentContext
          title={"Payment is Success"}
          img={PaymentSuccess}
          subText={"Thank you for the confirmation."}
          content={"We will Process the Payment against your Invoice shortly"}
          btnKind={KIND.secondary}
        />
      );

    case "PAYMENT_FAILD":
      return (
        <PaymentContext
          title={"Payment is Failed"}
          img={Paymentfailed}
          subText={"Transaction failed! If your money was debited, "}
          content={
            "Kindly share the confirmation of debit to reconcile the Payment"
          }
          btnKind={KIND.secondary}
        />
      );

    case "PAYMENT_CANCELED":
      return (
        <PaymentContext
          title={"Payment is Success"}
          img={PaymentCanceled}
          subText={"Transaction Canceled! Choose Another,"}
          content={
            "If the payment was canceled, Select the relevant Payment Option to initiate payment."
          }
          btnKind={KIND.secondary}
        />
      );

    case "OOPS":
      return (
        <PaymentContext
          title={"Oops!"}
          img={Opps}
          subText={"It is taking longer than usual"}
          content={"Kindly recheck the Payment Tab after 30 Minutes."}
          btnKind={KIND.secondary}
        />
      );

    case "INITIATE_PAYMENT":
      let date = moment(
        payment_initiated &&
          payment_initiated?.payment_data &&
          payment_initiated?.payment_data?.data
      )
        .tz(timeZone)
        .format(dateFormat);

      return (
        <div className="invoices-header-container --payment__modifer ">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LabelLarge>Payment Processing...</LabelLarge>
            <div className="payment__processing">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "70%" }}>
                  <img src={process} />
                </div>
              </div>

              <ParagraphLarge
                $style={{ textAlign: "center", color: "#333860" }}
              >
                We are waiting for your recent transaction status done on{" "}
                {moment
                  .tz(
                    payment_initiated &&
                      payment_initiated?.payment_data &&
                      payment_initiated?.payment_data?.data,
                    timeZone
                  )
                  .format(dateFormat)}
                &nbsp;for {payment_initiated?.payment_data?.amount?.currency}{" "}
                <NumericFormat
                  displayType="text"
                  value={payment_initiated?.payment_data?.amount?.value}
                  thousandsGroupStyle={currencyStyle}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={currency_separator}
                  decimalSeparator={currency_decimal_separator}
                  renderText={(values) => <>{values}</>}
                />
              </ParagraphLarge>
              {/* <ParagraphLarge
                $style={{ textAlign: "center", color: "#787878" }}
              >
                We will validate and update the payment status
              </ParagraphLarge> */}
            </div>

            <div style={{ width: "250px", paddingTop: "10px" }}>
              <TextButton
                size={SIZE.compact}
                fullWidth
                type="button"
                kind={KIND.secondary}
                startEnhancer={
                  <i className="mx-icon-Group-16179  font-icon-color" />
                }
                onClick={() => {
                  setIsOutcome("PAY_VIA");
                }}
              >
                Initiate New Payment
              </TextButton>
            </div>
          </div>
        </div>
      );

    case "OfflinePayment":
      let showActiveUsers = addSearchParams.get("i");
      return (
        <>
          <div
            className="payment_title --mobl_title"
            style={{
              justifyContent: "center",
            }}
          >
            <>
              {/* BACK BUTTON STARTS */}
              <div className="payment_back_btn">
                {/* <NavLink
                  to={`/${hash}${showActiveUsers ? `?i=${showActiveUsers}` : ""
                    }`}
                > */}
                <div
                  style={{
                    height: "30px",
                    width: "30px",
                    borderRadius: "50%",
                    border: "0.5px solid #cdced9",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setIsOutcome("ACC_DTL")}
                >
                  <i className="mx-icon-Group-16969" />
                </div>
                {/* </NavLink> */}
              </div>
              {/* BACK BUTTON ENDS */}
            </>

            <div>
              <LabelLarge
                $style={{
                  textAlign: "center",
                }}
                // className="org_name"
                className="payment-list-org_name"
              >
                {/* Pay Seshavalli Vaishnava matriculation school */}
                Submit Payment Proof
                {/* {orgName ? orgName : ""} */}
              </LabelLarge>
              <ParagraphMedium
                $style={{ color: "#ADADAD", textAlign: "center" }}
              >
                {/* Need additional time to make payment? */}
                Share details of the payment
              </ParagraphMedium>
            </div>
          </div>
          <OfflinePayment datavalues={files} />
        </>
      );

    default:
      return null;
  }
};

const PageInHold = () => {
  const intl = useIntl();
  const navigator = useNavigate();
  const dispatch = useDispatch();

  // const [isOutcome, setIsOutcome] = useState("PAYMENTDAYS");

  const [outComeType, setOutComeType] = useState("");

  const [paymentData, setPaymentData] = useState([]);

  const [isTotalAmount, setIsTotalAmount] = useState("");

  const [isFocus, setIsFocus] = useState(false);

  const [offlineData, setOfflineData] = useState([]);

  const [paymentId, setPaymentId] = useState({});

  const [promiseToPay, setPromiseToPay] = useState("");

  const [url, setUrl] = useState("");

  const location = useLocation();

  const hash = location.pathname.split("/")[1];
  const { selectedInvoice } = useSelector((s) => s.layoutReduceData);
  // let currency =
  //   selectedInvoice && selectedInvoice[0] && selectedInvoice[0].invoiceCurrency;

  const { data, isFetched, isError } = useQuery("paymentData", async () => {
    let is_internal = localStorage.getItem("is_internal_call");
    let is_internal_call;
    if (is_internal) {
      is_internal_call = is_internal;
    }

    return await getPayment({
      ...(is_internal ? { is_internal_call } : {}),
    });
  });
  const Payment_seprate = useQuery("PAYMENTAPI", async () => {
    return await getCallBackLater({ entity: "PAYMENT" });
  });

  const paymentList = useQuery("paymentList", async () => {
    return await getPaymentList({ currency: currency });
  });
  let payment_initiated =
    Payment_seprate && Payment_seprate?.data && Payment_seprate.data.data.doc;

  const [isOutcome, setIsOutcome] = useState(
    `${
      payment_initiated && payment_initiated?.is_payment_initiated
        ? "INITIATE_PAYMENT"
        : "PAY_VIA"
    }`
  );
  useEffect(() => {
    if (payment_initiated && payment_initiated?.is_payment_initiated) {
      setIsOutcome("INITIATE_PAYMENT");
    }
  }, [payment_initiated && payment_initiated?.is_payment_initiated]);
  let paymentListData =
    paymentList &&
    paymentList.data &&
    paymentList.data.data &&
    paymentList.data.data.docs;

  const currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );
  const {
    customer: { currency = "" },
  } = useSelector((s) => s?.layoutReduceData);
  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  let validationSchema = Yup.object({
    promise_to_pay_amount: Yup.number()
      .typeError("Required")
      .min(1, "Enter Valid Amount")
      // .max(
      //   data &&
      //     data.data &&
      //     data.data.total_due &&
      //     data.data.total_due.amount &&
      //     data.data.total_due.amount.value
      //     ? data.data.total_due.amount.value
      //     : 1,
      //   `Can't be Greater than total outstanding amount`
      // )
      .required("Required"),
  });

  const {
    default_color,
    customer: { total_outstanding_amount = 0 },
  } = useSelector((s) => s.layoutReduceData);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const onSubmit = (data) => {
    if (data && payment_initiated?.is_payment_initiated) {
      setIsOutcome("INITIATE_PAYMENT");
      setOutComeType("adhoc");
      setPromiseToPay(promise_to_pay_amount);
    } else if (data) {
      setIsOutcome("PAY_VIA");
      setOutComeType("adhoc");
      setPromiseToPay(promise_to_pay_amount);
    }
  };

  let { promise_to_pay_amount } = watch();

  // useEffect(() => {
  //   if (promise_to_pay_amount < 0) {

  //     setError("promise_to_pay_amount", { message: "Enter Valid Amount" });
  //   }
  // }, [promise_to_pay_amount]);

  const postOnline = useMutation((data) => postOnlineData(data), {
    onError: (error, variables, context) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;

        // if (typeof data.error === "object") {
        //   errorData = Object.keys(data.error).map(
        //     (e) => `${e}: ${data.error[e]}`
        //   );
        //   errorData = errorData.toString();
        // }
        toast.error(errorData);
      }
    },
    onSuccess: (data, variables, context) => {
      toast.success(data.data.message);
      setUrl(data?.data?.url);
      window.location.href = `${data?.data?.url}`;
      setIsOutcome("PAYMENTDAYS");
      // dispatch(refetachLayoutData());
      // reset();
    },
  });

  let { payment, referenceData } = useSelector((e) => e.layoutReduceData);
  let customerData = useSelector((e) => e.layoutReduceData?.customer);
  let organizationData = useSelector((e) => e.layoutReduceData.organization);

  let accessValue = useSelector((e) => e.layoutReduceData.accessData);
  const customizedData = useSelector((state) => state?.customizeReducer?.data);

  function sendEmail() {
    if (_.get(customizedData, "em_conf", "")) {
      window.location.assign(`mailto:${_.get(customizedData, "em_conf", "")}`);
    } else {
      window.location.assign(
        `mailto:${organizationData && organizationData.email}`
      );
    }
  }
  let totalAmtLength = total_outstanding_amount;
  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let showActiveUser = addSearchParams.get("i");
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmMessage, setIsConfirmMessage] = useState({});
  const [enablePayment, setEnablePayment] = useState(false);
  const widths = window.innerWidth;
  return (
    <form className="invoices-container" onSubmit={handleSubmit(onSubmit)}>
      <div className="invoices-header-container">
        <div className="invoices-header___message-conatiner">
          <div>
            {/* <HeadingXLarge>{customerData?.name}</HeadingXLarge> */}
            <div className="sidebar-top">
              <NavLink
                to={`/${hash}${showActiveUser ? `?i=${showActiveUser}` : ""}`}
              >
                <div
                  style={{
                    textAlign: "left",
                    width: "100%",
                    paddingLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {widths <= 500 ? (
                      <Avatar
                        overrides={{
                          Avatar: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                            }),
                          },
                          Root: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                              backgroundColor: "#F8F8FB",
                              width: "48px",
                            }),
                          },
                          Initials: {
                            style: () => ({
                              color: default_color,
                              fontSize: "14px",
                              fontWeight: "700",
                            }),
                          },
                          Avatar: {
                            style: () => ({
                              objectFit: "contain",
                            }),
                          },
                        }}
                        name={organizationData?.name}
                        src={
                          organizationData &&
                          organizationData.customer_portal_ui &&
                          organizationData.customer_portal_ui.logo
                            ? organizationData.customer_portal_ui.logo
                            : null
                        }
                        size="scale1200"
                      />
                    ) : organizationData &&
                      organizationData.customer_portal_ui &&
                      organizationData.customer_portal_ui.logo ? (
                      <div
                        style={{
                          maxWidth: `95px`,
                          minWidth: `18px`,
                          maxHeight: `48px`,
                        }}
                      >
                        <img
                          src={
                            organizationData &&
                            organizationData.customer_portal_ui &&
                            organizationData.customer_portal_ui.logo
                              ? organizationData.customer_portal_ui.logo
                              : null
                          }
                          alt="customer_Logo"
                          className="customer_portal_logo"
                        />
                      </div>
                    ) : (
                      <Avatar
                        overrides={{
                          Avatar: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                            }),
                          },
                          Root: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                              backgroundColor: "#F8F8FB",
                              width: "48px",
                              objectFit: "contain",
                            }),
                          },
                          Initials: {
                            style: () => ({
                              color: default_color,
                              fontSize: "14px",
                              fontWeight: "700",
                              objectFit: "contain",
                            }),
                          },
                          Avatar: {
                            style: () => ({
                              objectFit: "contain",
                              width: "100px",
                            }),
                          },
                        }}
                        name={organizationData?.name}
                        src={
                          organizationData &&
                          organizationData.customer_portal_ui &&
                          organizationData.customer_portal_ui.logo
                            ? organizationData.customer_portal_ui.logo
                            : null
                        }
                        // size="scale1200"
                      />
                    )}
                    {/* <Avatar
                      overrides={{
                        Avatar: {
                          style: ({ $theme }) => ({
                            borderTopLeftRadius: $theme.borders.radius100,
                            borderTopRightRadius: $theme.borders.radius100,
                            borderBottomRightRadius: $theme.borders.radius100,
                            borderBottomLeftRadius: $theme.borders.radius100,
                          }),
                        },
                        Root: {
                          style: ({ $theme }) => ({
                            borderTopLeftRadius: $theme.borders.radius100,
                            borderTopRightRadius: $theme.borders.radius100,
                            borderBottomRightRadius: $theme.borders.radius100,
                            borderBottomLeftRadius: $theme.borders.radius100,
                            backgroundColor: "#F8F8FB",
                            width: "48px",
                          }),
                        },
                        Initials: {
                          style: () => ({
                            color: default_color,
                            fontSize: "14px",
                            fontWeight: "700",
                          }),
                        },
                      }}
                      name={organizationData?.name}
                      src={
                        organizationData &&
                        organizationData.customer_portal_ui &&
                        organizationData.customer_portal_ui.logo
                          ? organizationData.customer_portal_ui.logo
                          : null
                      }
                      size="scale1200"
                    /> */}

                    <div
                      // style={{
                      //   display: "flex",
                      //   flexDirection: "column",
                      //   width: "100%",
                      // }}
                      className="org-name"
                    >
                      <div>
                        <div
                          className="org_name"
                          // style={{
                          //   overflow: "hidden",
                          //   textOverflow: "ellipsis",
                          //   whiteSpace: "nowrap",
                          //   width: "182px",
                          // }}
                          $style={{ color: "#333860" }}
                        >
                          {/* Seshavalli Vaishnava matriculation school */}
                          {organizationData?.name}
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        {/* <div
                          style={{
                            color: "#787878",
                            display: "flex",
                            gap: "2px",
                            // hap: "10px",
                            flexDirection: `${
                              totalAmtLength &&
                              totalAmtLength.toString().length > 15
                                ? "column"
                                : "row"
                            }`,
                          }}
                        >
                          <div className="totl-ots-mob"> Total O/S: </div>
                          <div className="totl_ots-web">
                            {" "}
                            Total Outstanding:{" "}
                          </div>
                          <NumericFormat
                            displayType="text"
                            value={
                              total_outstanding_amount
                                ? total_outstanding_amount
                                : 0
                            }
                            thousandsGroupStyle={currencyGroupStyle}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currencySeparator}
                            decimalSeparator={currencyDecimalSeparator}
                            renderText={(values) => (
                              <div style={{ paddingLeft: "3px" }}>
                                <sup style={{ fontSize: "9px" }}>
                                  {currency}
                                </sup>{" "}
                                {values}
                              </div>
                            )}
                          />
                        </div> */}
                        <div
                          style={{
                            color: "#787878",
                            display: "flex",
                            gap: "2px",
                            // hap: "10px",
                            flexDirection: `${
                              totalAmtLength &&
                              totalAmtLength.toString().length > 15
                                ? "column"
                                : "row"
                            }`,
                          }}
                        >
                          <div className="totl-ots-mob"> Total O/S: </div>
                          <div className="totl_ots-web">
                            {" "}
                            Total Outstanding:{" "}
                          </div>
                          {/* <>Total Outstanding</> */}
                          <NumericFormat
                            displayType="text"
                            value={
                              total_outstanding_amount
                                ? total_outstanding_amount
                                : 0
                            }
                            thousandsGroupStyle={currencyGroupStyle}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currencySeparator}
                            decimalSeparator={currencyDecimalSeparator}
                            renderText={(values) => (
                              <div style={{ paddingLeft: "3px" }}>
                                {currencyList[currency]
                                  ? currencyList[currency]
                                  : currency}{" "}
                                {values}
                              </div>
                            )}
                          />
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>

          {/* <div>
            <Link to="/messages">
            <MessageHeaderSvg />
            </Link>
          </div> */}
          <div className="invoices-header___nav-buttons">
            {accessValue?.is_mini_miranda_configured && (
              <div>
                <div
                  class="button-tilt"
                  onClick={() => {
                    dispatch(setDebtorRightsModal(true));
                  }}
                >
                  <IconButton kind={KIND.tertiary}>
                    <DebtorRight size={18} />
                    <span class="label-hidden">Consumer Rights</span>
                  </IconButton>
                </div>
              </div>
            )}

            <div>
              <div
                class="button-tilt email-btn"
                onClick={() => {
                  sendEmail();
                }}
              >
                <IconButton kind={KIND.tertiary}>
                  <i className="mx-icon-Vector-2" />
                  <span class="label-hidden">Email us</span>
                </IconButton>
              </div>
            </div>

            {accessValue && accessValue.is_callback && (
              <div>
                <NavLink
                  to={`/${hash}/requesting-calls${
                    showActiveUser ? `?i=${showActiveUser}` : ""
                  }`}
                >
                  <div class="button-tilt">
                    <IconButton kind={KIND.tertiary}>
                      <i className="mx-icon-call-back-2" />
                      <span class="label-hidden">Call Back Request</span>
                    </IconButton>
                  </div>
                </NavLink>
              </div>
            )}
          </div>
        </div>

        <div className="payment-section">
          <PaymentTypes
            setIsOpen={setIsOpen}
            enablePayment={enablePayment}
            setIsConfirmMessage={setIsConfirmMessage}
            isConfirmMessage={isConfirmMessage}
            isOpen={isOpen}
            data={data}
            payment_initiated={payment_initiated}
            type={isOutcome}
            setIsOutcome={setIsOutcome}
            currencyGroupStyle={currencyGroupStyle}
            currencySeparator={currencySeparator}
            currencyDecimalSeparator={currencyDecimalSeparator}
            control={control}
            errors={errors}
            intl={intl}
            setIsFocus={setIsFocus}
            isFocus={isFocus}
            setOutComeType={setOutComeType}
            outComeType={outComeType}
            paymentList={paymentListData}
            setOfflineData={setOfflineData}
            offlineData={offlineData}
            setPaymentId={setPaymentId}
            paymentId={paymentId}
            watch={watch}
            setPromiseToPay={setPromiseToPay}
            promiseToPay={promiseToPay}
            postOnline={postOnline}
            url={url}
            payment={payment}
            setError={setError}
            referenceData={referenceData}
            reset={reset}
            navigator={navigator}
            setValue={setValue}
            currencyStyle={currencyGroupStyle}
            currency_separator={currencySeparator}
            currency_decimal_separator={currencyDecimalSeparator}
            valuePay={location.state}
          />
        </div>
      </div>
      {/* mobile view old UI remoview 23/12/2022 */}

      <ConfirmationModel
        setIsOpen={setIsOpen}
        modelName={"PAYMENT"}
        setEnablePayment={setEnablePayment}
        isConfirmMessage={isConfirmMessage}
        setIsConfirmMessage={setIsConfirmMessage}
        isOpen={isOpen}
        postOnline={postOnline}
        setIsOutcome={setIsOutcome}
        setOfflineData={setOfflineData}
        setPaymentId={setPaymentId}
      />
    </form>
  );
};

export default PageInHold;
