import { ParagraphMedium } from "baseui/typography";
import React from "react";
import _ from "lodash";

export const StatusType = ({ type, alignment }) => {
  switch (type) {
    case "overdue":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: `${alignment}`,
            // flex: "1",
            width: "100%",
            // textAlign: "center",
            // margin: "5px",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FD372A",
            }}
          ></div>
          <ParagraphMedium>Overdue</ParagraphMedium>
        </div>
      );
    case "due":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: `${alignment}`,

            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FBBD04",
            }}
          ></div>
          <ParagraphMedium>Due</ParagraphMedium>
        </div>
      );
    case "paid":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: `${alignment}`,
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#4CAF50",
            }}
          ></div>
          <ParagraphMedium>Paid</ParagraphMedium>
        </div>
      );
    case "stl":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: `${alignment}`,
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#4CAF50",
            }}
          ></div>
          <ParagraphMedium>Settled</ParagraphMedium>
        </div>
      );
    default:
      return null;
  }
};

const InvoiceStatus = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;
  //   value = _.get(row.original, accessor, "");


  return <StatusType type={value} alignment={alignment} />;
};

export default InvoiceStatus;
