import React from "react";
import RequestingCall from "../../containers/RequestingCall/RequestingCall";
import Layout from "../Layout/Layout";

const RequestingCallView = () => {
  return (
    // <Layout>
      <RequestingCall />
    // </Layout>
  );
};

export default RequestingCallView;
