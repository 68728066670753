import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import {
  getCallBackLater,
  getPromiseToPay,
} from "../../services/customerPortal";
import { toast } from "react-toastify";
import { refetachLayoutData } from "../../redux/Layout/action";
import { utcTimstampToLocalDate } from "../../utils/utcTimstampToLocalDate";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { TextBox } from "../../components/TextBox";
import {
  HeadingXLarge,
  HeadingXSmall,
  LabelMedium,
  ParagraphMedium,
  LabelSmall,
  LabelLarge,
  ParagraphSmall,
} from "baseui/typography";
import { NumericFormat, useNumericFormat } from "react-number-format";
import Info from "../../assets/img/svg/Info";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { KIND, SIZE } from "baseui/button";
import { TextButton } from "../../components/TextButton";
import PromiseToPaySvg from "../../assets/img/svg/PromiseToPaySvg";
import { TextArea } from "../../components/TextArea/TextArea";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import _, { values } from "lodash";
import ConfirmationModel from "../../components/ConfirmationModel/ConfirmationModel";
const SinglePayment = ({ setIsSelect, data, setCount, count }) => {
  const intl = useIntl();
  const { hash } = useParams();

  let dispatch = useDispatch();

  let organizationData = useSelector((e) => e.layoutReduceData.organization);
  const [isOpen, setIsOpen] = useState(false);
  // const hash = location.pathname.split("/")[1];

  const [selectCard, setSelectCard] = useState(true);

  const [isFocus, setIsFocus] = useState(false);

  let customerData = useSelector((e) => e.layoutReduceData?.customer);
  let accessValue = useSelector((e) => e.layoutReduceData.accessData);

  let requestTimeData = useSelector((e) => e.layoutReduceData?.requestBack);
  let requestTimeDataList = useSelector(
    (e) => e.layoutReduceData?.requestTimeData
  );
  let orgData = useSelector((e) => e.layoutReduceData?.organization);

  const { default_color } = useSelector((s) => s.layoutReduceData);

  let timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );

  let timeDelay =
    data && data.request_back && data.request_back.restrict_date
      ? data.request_back.restrict_date
      : "";

  console.log("timeDelay>>", timeDelay);

  let requestDataValied = false;

  if (data?.data?.doc.length == 0) {
    requestDataValied = true;
  }
  const customizedData = useSelector((state) => state?.customizeReducer?.data);
  function sendEmail() {
    if (_.get(customizedData, "em_conf", "")) {
      window.location.assign(`mailto:${_.get(customizedData, "em_conf", "")}`);
    } else {
      window.location.assign(
        `mailto:${organizationData && organizationData.email}`
      );
    }
  }
  const currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );
  const {
    customer: { total_outstanding_amount = 0, currency = "" },
  } = useSelector((s) => s.layoutReduceData);

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  let splitData = timeDelay && timeDelay?.split("_");

  const requestTime = useMutation((data) => getPromiseToPay(data), {
    onError: (error, variables, context) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;

        toast.error(errorData);
      }
    },
    onSuccess: (data, variables, context) => {
      toast.success(data.data.message);
      dispatch(refetachLayoutData());
      setCount(count + 1);
      setIsOpen(false);
      reset();
    },
  });

  useEffect(() => {
    if (requestTimeData) {
      setValue(
        "promise_to_pay_amount",
        Number(data?.request_back?.maximum_amount).toFixed(2)
      );
    }
  }, [data]);

  let dateFormat = useSelector(
    (e) =>
      e && e.layoutReduceData && e.layoutReduceData?.organization.date_format
  );

  let localDate = utcTimstampToLocalDate(
    requestTimeDataList?.promise_to_pay_date
  );
  let layoutData = useSelector((s) => s.layoutReduceData);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({});
  const [isConfirmMessage, setIsConfirmMessage] = useState({});
  let watchInput = watch(["promise_to_pay_amount", "follow_up_date"]);

  const maxInstallmentAmt = useMemo(() => {
    let findMaxAmt;
    let findPercentageAmt;
    if (
      layoutData &&
      layoutData.customer &&
      layoutData.customer.total_outstanding_amount &&
      data &&
      data.request_back &&
      data.request_back.amount_percentage
    ) {
      findPercentageAmt =
        (+data.request_back.amount_percentage / 100) *
        +layoutData.customer.total_outstanding_amount;
    }
    if (findPercentageAmt && data?.request_back?.minimum_amount) {
      findMaxAmt = Math.max(
        findPercentageAmt,
        data?.request_back?.minimum_amount
      );
    }

    return findMaxAmt;
  }, [
    layoutData &&
      layoutData.customer &&
      layoutData.customer.total_outstanding_amount,
  ]);

  // else if (data.promise_to_pay_amount > requestTimeData.maximum_amount) {
  //   return setError("promise_to_pay_amount", {
  //     type: "custom",
  //     message: `The Minimum Amount For Request Time is ${requestTimeData.minimum_amount} `,
  //   });
  // }
  console.log("watchInput", watchInput, data);
  const openModal = (confirmMessage, promiseData) => {
    setIsOpen(true);
    setIsConfirmMessage({
      layoutData: confirmMessage,
      promiseData: promiseData,
    });
  };

  useEffect(() => {
    if (watchInput && watchInput[0] < maxInstallmentAmt) {
      setError("promise_to_pay_amount", {
        type: "custom",
        message: `The Minimum Amount For Request Time is ${
          currencyList[customerData.currency]
            ? currencyList[customerData.currency]
            : customerData?.currency
        }${maxInstallmentAmt} `,
      });
    } else if (
      watchInput &&
      watchInput[0] > _.get(data, "request_back.maximum_amount")
    ) {
      setError("promise_to_pay_amount", {
        type: "custom",
        message: `The entered amount exceeds Maximum Amount ${
          currencyList[customerData.currency]
            ? currencyList[customerData.currency]
            : customerData?.currency
        }${_.get(data, "request_back.maximum_amount")} `,
      });
    } else {
      clearErrors("promise_to_pay_amount");
    }
  }, [watchInput && watchInput[0]]);
  const { follow_up_date } = watch();
  const onSubmit = async (items) => {
    let values = {
      promise_to_pay_date: moment
        .tz(
          `${items.follow_up_date.getFullYear()}-${
            items.follow_up_date.getMonth() + 1
          }-${items.follow_up_date.getDate()}`,
          "YYYY-MM-DD",
          timeZone
        )
        .startOf("day")
        .utc()
        .valueOf(),
      promise_to_pay_amount: Number(items.promise_to_pay_amount),
      comment: items.comment,
    };

    if (
      Number(items.promise_to_pay_amount) >
      Number(data && data?.request_back && data?.request_back?.maximum_amount)
    ) {
      return setError("promise_to_pay_amount", {
        type: "custom",
        message: `The entered amount exceeds Maximum Amount  ${
          currencyList[customerData.currency]
            ? currencyList[customerData.currency]
            : customerData?.currency
        }${data && data?.request_back && data?.request_back?.maximum_amount}`,
      });
    } else if (
      Number(items.promise_to_pay_amount) < Number(maxInstallmentAmt)
    ) {
      return setError("promise_to_pay_amount", {
        type: "custom",
        message: ` The entered amount is less than the minimum amount ${
          currencyList[customerData.currency]
            ? currencyList[customerData.currency]
            : customerData?.currency
        }${maxInstallmentAmt} `,
      });
    }

    if (
      _.get(layoutData, "customer.isEnableCPConfirmation", false) &&
      _.get(layoutData, "customer.cpConfirmationTypes", [])?.includes("PTP")
    ) {
      openModal(
        _.get(layoutData, "customer.cpConfirmationMessages", []),
        values
      );
    } else {
      await requestTime.mutateAsync(values);
    }
  };

  let dateNew = new Date();
  // new Date(dateNew.setDate(dateNew.getDate() + 21
  let totalAmtLength = total_outstanding_amount;

  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let showActiveUser = addSearchParams.get("i");
  const widths = window.innerWidth;
  const symbol = currencyList[currency];
  return (
    <>
      <ConfirmationModel
        requestTime={requestTime}
        isOpen={isOpen}
        modelName={"SINGLE_PAYMENT"}
        setIsOpen={setIsOpen}
        isConfirmMessage={isConfirmMessage}
        setIsConfirmMessage={setIsConfirmMessage}
      />
      {!isOpen && (
        <div className="invoices-container">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="rq__call__form__wraper"
            >
              <div style={{ height: "100%", overflow: "scroll" }}>
                <div style={{ padding: "6px 20px" }}>
                  <div
                    className="req-time__input-wrap"
                    style={{ flexDirection: "column", gap: "0px" }}
                  >
                    <div style={{ flex: "1" }}>
                      <Controller
                        name="promise_to_pay_amount"
                        control={control}
                        rules={{ required: "Required" }}
                        render={({ field }) => (
                          <TextBox
                            {...field}
                            fullWidth
                            requiredAstric={true}
                            name={field.name}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            disabled={requestDataValied}
                            type="number"
                            label={intl.formatMessage(
                              {
                                id: `${
                                  currencyList[customerData.currency]
                                    ? currencyList[customerData.currency]
                                    : customerData?.currency
                                }`,
                                defaultMessage: "Amount in {name}",
                              },
                              {
                                name: `${symbol}`,
                              }
                            )}
                            placeholder={intl.formatMessage({
                              id: `amount `,
                            })}
                            value={field.value}
                            onFocus={(e) => {
                              setIsFocus(true);
                            }}
                            onBlur={() => {
                              setIsFocus(false);
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key == "ArrowUp" ||
                                e.key == "ArrowDown" ||
                                e.key == "-"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        )}
                      />
                    </div>
                    {isFocus ? (
                      <>
                        <NumericFormat
                          displayType="text"
                          value={maxInstallmentAmt}
                          thousandsGroupStyle={currencyGroupStyle}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={currencySeparator}
                          decimalSeparator={currencyDecimalSeparator}
                          renderText={(values) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                marginBottom: "15px",
                              }}
                            >
                              <Info />
                              <LabelSmall
                                $style={{
                                  color: "#ADADAD",
                                  fontWeight: "400",
                                }}
                              >
                                Minimum Promise Amount is {symbol} {values}
                              </LabelSmall>
                            </div>
                          )}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <div style={{ flex: "1" }}>
                      <Controller
                        control={control}
                        name="follow_up_date"
                        rules={{ required: "Required" }}
                        render={({ field }) => (
                          <DateRangeSelect
                            size={SIZE.default}
                            {...field}
                            name={field.name}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            label={intl.formatMessage({
                              id: "date_of_payment",
                            })}
                            // placeholder={intl.formatMessage({
                            //   id: "date_of_payment",
                            // })}
                            disabled={requestDataValied}
                            value={field.value}
                            onChange={(e) => field.onChange(e.date)}
                            minDate={new Date()}
                            maxDate={
                              timeDelay != "no_validation" &&
                              moment()
                                .add(
                                  splitData && splitData[0],
                                  splitData &&
                                    splitData?.[1] &&
                                    splitData?.[1] === "m"
                                    ? splitData[1].toUpperCase()
                                    : splitData[1]
                                )
                                .toDate()
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                  <Controller
                    control={control}
                    name="comment"
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        fullWidth
                        requiredAstric={true}
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "your_message_or_note",
                        })}
                        placeholder={intl.formatMessage({
                          id: "your_message_or_note2",
                        })}
                        value={field.value}
                        disabled={requestDataValied}
                      />
                    )}
                  />
                  <div className="time-slot">
                    <NumericFormat
                      displayType="text"
                      value={watchInput && watchInput[0]}
                      thousandsGroupStyle={currencyGroupStyle}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={currencySeparator}
                      decimalSeparator={currencyDecimalSeparator}
                      renderText={(values) => (
                        <LabelSmall>
                          I Promise to pay {symbol} {values ? values : "-"} by{" "}
                          {watchInput && watchInput[1] ? (
                            moment(watchInput[1]).format(dateFormat)
                          ) : (
                            <>- </>
                          )}
                        </LabelSmall>
                      )}
                    />{" "}
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "150px" }}>
                      <TextButton
                        fullWidth
                        size={SIZE.compact}
                        type="submit"
                        isLoading={isSubmitting}
                        disabled={requestDataValied || isSubmitting}
                      >
                        Submit Promise
                      </TextButton>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
export default SinglePayment;
