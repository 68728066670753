import MinMaxFilter from "./MinMaxFilter/MinMaxFilter";
import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";
import DateRangeFilter from "./DateRangeFilter/DateRangeFilter";
import DefaultFilter from "./DefaultFilter/DefaultFilter";
const tableGetFilter = (filterType) => {
  switch (filterType) {
    case "NUMBER":
      return MinMaxFilter;

    case "LOV":
      return MultiSelectFilter;

    case "CURRENCY":
      return MinMaxFilter;

    case "DATE":
      return DateRangeFilter;

    case "STRING":
      return DefaultFilter;

    default:
      return DefaultFilter;
  }
};

export default tableGetFilter;
