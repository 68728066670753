export default function ChevronDown({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <g clip-path="url(#clip0_252_2630)">
        <g clip-path="url(#clip1_252_2630)">
          <path
            d="M9.24374 11.8625C9.16041 11.8625 9.08332 11.849 9.0125 11.8219C8.94166 11.7948 8.87291 11.7479 8.80625 11.6813L5.18124 8.05624C5.06041 7.93542 5 7.78959 5 7.61875C5 7.44791 5.06041 7.30208 5.18124 7.18126C5.30208 7.06042 5.44999 7 5.62499 7C5.79999 7 5.94791 7.06042 6.06873 7.18126L9.24374 10.3563L12.4188 7.18126C12.5396 7.06042 12.6854 7 12.8563 7C13.0271 7 13.1729 7.06042 13.2937 7.18126C13.4146 7.30208 13.475 7.44999 13.475 7.62499C13.475 7.79999 13.4146 7.94791 13.2937 8.06875L9.68124 11.6813C9.61458 11.7479 9.54583 11.7948 9.47499 11.8219C9.40416 11.849 9.32708 11.8625 9.24374 11.8625Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_252_2630">
          <rect width="18" height="18" fill="white" />
        </clipPath>
        <clipPath id="clip1_252_2630">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
