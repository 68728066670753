export default function ChevronUp({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M9.00011 7.82927L5.94529 10.8841C5.84145 10.9879 5.71092 11.0411 5.55371 11.0435C5.39651 11.0459 5.26359 10.9927 5.15494 10.8841C5.04628 10.7754 4.99194 10.6437 4.99194 10.4889C4.99194 10.3341 5.04628 10.2024 5.15494 10.0937L8.52561 6.72306C8.59579 6.65287 8.66983 6.60336 8.74772 6.57451C8.82559 6.54567 8.90972 6.53125 9.00011 6.53125C9.0905 6.53125 9.17463 6.54567 9.25251 6.57451C9.33039 6.60336 9.40443 6.65287 9.47462 6.72306L12.8453 10.0937C12.9491 10.1976 13.0023 10.3281 13.0047 10.4853C13.0071 10.6425 12.9539 10.7754 12.8453 10.8841C12.7366 10.9927 12.6049 11.0471 12.4501 11.0471C12.2953 11.0471 12.1636 10.9927 12.0549 10.8841L9.00011 7.82927Z"
        fill={color}
      />
    </svg>
  );
}
