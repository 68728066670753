function getSelectValues(values = [], isMulti = false) {
  let result = [];

  for (let val of values) {
    if (val.id) {
      result.push(val.id);
    }
  }

  return isMulti ? result : result && result[0];
}

export default getSelectValues;
