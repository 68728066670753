import React from "react";
import { ParagraphMedium, LabelLarge, HeadingXSmall } from "baseui/typography";
import { FormattedDate, FormattedNumber } from "react-intl";

const CreditNoteAmount = ({
  value,
  column,
  cell: {
    row: { original },
  },
  alignment,
  ...rest
}) => {

  return (
    <>
      <div className="table-cell__type-amount --hide-amount">
        <ParagraphMedium $style={{ color: "#333860" }}>
          <FormattedNumber
            value={value && value.value}
            style="currency"
            currency={value && value.currency}
          />
        </ParagraphMedium>
      </div>
    </>
  );
};

export default CreditNoteAmount;
