import React, { useEffect } from "react";
import { Input, SIZE } from "baseui/input";
import TextBox from "../../TextBox/TextBox";
import { FilterInputBox } from "../../FilterInputBox";
import { useIntl } from "react-intl";
import useThrottle from "../../../utils/useThrottle";

const DefaultFilter = ({ column: { filterValue = "", setFilter } }) => {
  const [value, setValue] = React.useState(filterValue);
  const throttledValue = useThrottle(value, 500);
  const intl = useIntl();

  useEffect(() => {
    setFilter(throttledValue);
  }, [throttledValue]);

  return (
    <FilterInputBox
      size={SIZE.compact}
      // value={filterValue}
      value={value}
      onChange={(e) => {
        // setFilter(e.target.value);
        setValue(e.target.value);
      }}
      placeholder={intl.formatMessage({ id: "search_here" })}
    />
  );
};

export default DefaultFilter;
