import React, { useState } from "react";
import { Textarea } from "baseui/textarea";
import { FormControl } from "baseui/form-control";
import "../../assets/scss/components/input.scss";
import { useSelector } from "react-redux";

export const TextArea = (props) => {

  const { error = "", children, value = "", label = "" } = props;

  const [isFocused, setFocused] = useState(false);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };
  let { default_color } = useSelector((s) => s.layoutReduceData);
  return (
    <FormControl
      error={error}
      overrides={{
        Caption: {
          style: ({ $theme }) => ({
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "12px",
            textAlign: "left",
          }),
        },
      }}
    >
      <div className="mx-input">
        <label
          htmlFor={props.name}
          className={`ta-input__label ${isFocused ? "ta-input__label--active text-primary" : "vig"
            } ${!isFocused && value.length > 0 ? "ta-input__label--active" : ""
            } ${error ? "text-error" : ""}`}
        >
          {label}
        </label>
        <Textarea
          placeholder={props.name}
          id={props.name}
          autoComplete="off"
          overrides={{
            Root: {
              style: ({ $isFocused, $error }) => ({
                borderRadius: "5px",
                borderWidth: "1px",
                borderColor: $isFocused
                  ? $error
                    ? "#FD372A"
                    : { default_color }
                  : $error
                    ? "#FD372A"
                    : "#CDCED9",
                backgroundColor: "#ffffff",
                borderBottomRightRadius: "5px",
                borderBottomLeftRadius: "5px",
                borderTopRightRadius: "5px",
                borderTopLeftRadius: "5px",
              }),
            },
            Input: {
              style: ({ $isFocused }) => ({
                backgroundColor: "#ffffff",
                "::placeholder": {
                  color: $isFocused ? "#cbd5e0" : "transparent",
                  transition: "color 0.4s ease",

                },
              }),
            },
          }}
          {...props}
          onFocus={() => {
            if (props.onFocus) {
              props.onFocus();
            }
            onFocus();
          }}
          onBlur={() => {
            if (props.onBlur) {
              props.onBlur();
            }
            onBlur();
          }}
        >
          {children}
        </Textarea>
      </div>
    </FormControl>
  );
};

export const inputOverrides = {
  Root: {
    style: () => ({
      borderRadius: "5px",
      borderWidth: "1px",
      paddingTop: "1px",
      paddingBottom: "1px",
      paddingRight: "0px",
    }),
  },
};
