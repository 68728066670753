import React, { useEffect, useState } from "react";
import TextButton from "../../components/TextButton/TextButton";
import { KIND, SIZE } from "baseui/button";
import { useSelector } from "react-redux";
import {
  paymentDocument,
  postTransferMade,
  uploaddocument,
} from "../../services/customerPortal";
import FileUpload from "../../components/FileUpload/FIleUpload";
import { ParagraphMedium } from "baseui/typography";
import CopyToClipboard from "react-copy-to-clipboard";
import CopySvg from "../../assets/img/svg/CopySvg";
import { IconButton } from "../../components/IconButton";
import { useMutation, useQueries } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { TextArea } from "../../components/TextArea/TextArea";
import Success from "../../assets/img/svg/Success";
import Error from "../../assets/img/svg/Error";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
const OfflinePayment = ({ datavalues }) => {
  const intl = useIntl();
  const [files, setFiles] = useState([]);
  const { hash, paymentId } = useParams();
  const [isCheck, setIsCheck] = useState();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm();
  let updatedata = new FormData();

  const { comments } = watch();
  let navigator = useNavigate();
  // const paymentVerify = useMutation((data) =>

  //   paymentDocument({ data }), {
  //   onError: (error, variables, context) => {
  //     if (error.response) {
  //       let { data } = error.response;
  //       let errorData = data.message;
  //       toast.error(errorData)
  //       reset();
  //     }
  //   },
  //   onSuccess: (data, variables, context) => {
  //     toast.success(data?.data?.message)
  //     reset();
  //     setValue("comments", '')
  //     navigator(`/${hash && hash}`)
  //   },
  // }
  // );  //

  // API
  const [isState, setIsState] = useState();
  const uploadingFile = useMutation((data) => uploaddocument({ data }), {
    onError: (error, variables, context) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;
        toast.error(errorData);
        setIsState("Error");
        setIsCheck(data);
      }
    },
    onSuccess: (data, variables, context) => {
      toast.success(data?.data?.message);
      setIsState("Succeess");
      setIsCheck(data);
      navigator(`/${hash && hash}`);
      reset();
    },
  });
  let formData = new FormData();
  // useEffect(() => {

  // }, [files])

  return (
    <>
      <div className="invoices-header-container --payment__modifer ">
        <div className="payment_list_container">
          <FileUpload
            setFiles={setFiles}
            files={files}
            setIsCheck={setIsCheck}
            IsCheck={isCheck}
          />

          <div className="payment-offline_comment">
            <Controller
              control={control}
              name="comments"
              render={({ field }) => (
                <>
                  <TextArea
                    {...field}
                    fullWidth
                    name={field.name}
                    label={intl.formatMessage({
                      id: "Comments (optional)",
                    })}
                    placeholder={intl.formatMessage({
                      id: "Comments (optional)",
                    })}
                    value={field.value}
                  />
                </>
              )}
            />
          </div>
          <div style={{ maxWidth: "250px", paddingTop: "0px" }}>
            <TextButton
              size={SIZE.compact}
              fullWidth
              type="button"
              onClick={async () => {
                if (files.length !== 0) {
                  updatedata.append("file", ("file", files[0] && files?.[0]));
                }
                if (comments) {
                  updatedata.append("comment", comments);
                }
                let id = datavalues?.data?.doc?.promiseId;
                uploadingFile.mutateAsync({ updatedata, id });
              }}
              disabled={
                (comments == undefined && files.length == 0) ||
                (comments == "" && files.length !== 1) ||
                uploadingFile.isLoading
              }
              isLoading={uploadingFile.isLoading}
            >
              Submit
            </TextButton>
          </div>
        </div>
      </div>
    </>
  );
};
export default OfflinePayment;
