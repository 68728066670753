import React from "react";
import { Input } from "baseui/input";

const FilterInputBox = ({ children, ...props }) => {
  return (
    <Input
      overrides={{
        Root: {
          style: () => ({
            borderRadius: "5px",
            borderColor: "#CDCED9",
            borderWidth: "0.5px",
            borderStyle: "solid",
            paddingRight: "0px",
          }),
        },
        Input: {
          style: ({ $theme, $isFocused }) => ({
            paddingLeft: "5px",
            paddingRight: "0px",
            backgroundColor: $isFocused ? "#516BEB10" : "#F5F6FA",
          }),
        },
        ClearIconContainer: {
          style: ({ $theme, $isFocused }) => ({
            paddingRight: "0",
            paddingLeft: "0",
            backgroundColor: $isFocused ? "#516BEB10" : "#F5F6FA",
          }),
        },
      }}
      clearable
      {...props}
    >
      {children}
    </Input>
  );
};

export default FilterInputBox;
