function setSelectValues(values = [], isMulti = false) {
  let result = [];

  if (!isMulti) {
    if (values && values[0])
      result.push({ id: values, label: values.toUpperCase() });
  } else {
    for (let val of values) {
      if (val) result.push({ id: val, label: val.toUpperCase() });
    }
  }
  return result;
}

export default setSelectValues;
