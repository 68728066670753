import { LabelLarge, LabelMedium, ParagraphMedium } from "baseui/typography";
import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { KIND, TextButton } from "../../components/TextButton";
import { SIZE } from "baseui/input";
import RightArrow from "../../assets/img/svg/RightArrow";
import { IconButton } from "../../components/IconButton";
import { FormattedMessage } from "react-intl";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import ArrowCircle from "../../assets/img/svg/ArrowCircle";
import DiscountTag from "../../assets/img/svg/DiscountTag";

const ChoosePlan = ({
  setIsSelect,
  data,
  setValue,
  setIsCount,
  is_allow_manual_payment_plan,
}) => {
  let currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );
  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  return (
    <>
      <div className="choose_plan_card">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "3%",
          }}
        >
          {data?.pre_defined_payment_plan.map((ite, i) => {
            return (
              <>
                {ite.payment_plan_frequency == "SINGLE" ? (
                  <div className="chooseplan_card">
                    {/* {ite.discount == 0 ? (
                      <></>
                    ) : (
                      <div className="discountCard">
                        <label style={{ fontSize: "10px" }}>
                          {ite.discount}% Discount
                        </label>
                      </div>
                    )} */}
                    <div
                      style={{
                        textAlign: "left",
                        padding: "19px 19px",
                      }}
                    >
                      <div>
                        <NumericFormat
                          displayType="text"
                          value={ite.single_instalment_amount}
                          thousandsGroupStyle={currencyGroupStyle}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={currencySeparator}
                          decimalSeparator={currencyDecimalSeparator}
                          renderText={(values) => (
                            <>
                              <div
                                style={{ display: "flex", gap: "3px" }}
                                className="card_header_text_wraper"
                              >
                                {/* <sup style={{ fontSize: "9px" }}>
                                  {ite.currency}
                                </sup>{" "} */}
                                <ParagraphMedium
                                  style={{
                                    color: "#333860",
                                    fontFamily: "Roboto",
                                    fontSize: "13px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "17px",
                                  }}
                                >
                                  <spa
                                    style={{
                                      color: "#333860",
                                      fontFamily: "Roboto",
                                      fontSize: "20px",
                                      fontStyle: "italic",
                                      fontWeight: 700,
                                      lineHeight: "24px",
                                    }}
                                  >
                                    <FormattedMessage
                                      id={ite.payment_plan_frequency}
                                    >
                                      {ite.payment_plan_frequency}
                                    </FormattedMessage>
                                  </spa>
                                  &nbsp; Payment{" "}
                                </ParagraphMedium>
                              </div>
                            </>
                          )}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <div
                            className="select_plan_amt_mobile"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              marginTop: "10px",
                            }}
                          >
                            {ite.discount == 0 ? (
                              <></>
                            ) : (
                              <NumericFormat
                                displayType="text"
                                value={ite.total_payment_amount}
                                thousandsGroupStyle={currencyGroupStyle}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={currencySeparator}
                                decimalSeparator={currencyDecimalSeparator}
                                renderText={(values) => (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "3px",
                                        // marginTop: "10px",
                                      }}
                                    >
                                      <LabelLarge
                                        $style={{
                                          color: "#333860",
                                          textDecoration: "line-through",
                                          fontWeight: 400,
                                          fontSize: "20px",
                                        }}
                                      >
                                        {currencyList[ite?.currency]
                                          ? currencyList[ite?.currency]
                                          : ite?.currency}
                                        {values}
                                      </LabelLarge>
                                    </div>
                                  </>
                                )}
                              />
                            )}

                            <NumericFormat
                              displayType="text"
                              value={ite.discounted_amount}
                              thousandsGroupStyle={currencyGroupStyle}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              thousandSeparator={currencySeparator}
                              decimalSeparator={currencyDecimalSeparator}
                              renderText={(values) => (
                                <>
                                  <LabelLarge
                                    $style={{
                                      // color: ite.discount ? "#0FB158" : "#333860",
                                      // fontSize: ite.discount ? "16px" : "20px",
                                      fontSize: "20px",
                                    }}
                                  >
                                    {currencyList[ite?.currency]
                                      ? currencyList[ite?.currency]
                                      : ite?.currency}

                                    {values}
                                  </LabelLarge>
                                </>
                              )}
                            />
                          </div>
                          {ite.discount == 0 ? (
                            <></>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "3px",
                                marginTop: "5px",
                              }}
                            >
                              <DiscountTag />
                              <ParagraphMedium $style={{ color: "#0FB158" }}>
                                Save {ite.discount}% {"("}
                                {currencyList[ite?.currency]
                                  ? currencyList[ite?.currency]
                                  : ite?.currency}{" "}
                                <NumericFormat
                                  displayType="text"
                                  value={
                                    Number(ite.total_payment_amount) -
                                    Number(ite.discounted_amount)
                                  }
                                  thousandsGroupStyle={currencyGroupStyle}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={currencySeparator}
                                  decimalSeparator={currencyDecimalSeparator}
                                  renderText={(values) => <>{values}</>}
                                />
                                {")"} to Settle
                              </ParagraphMedium>
                            </div>
                          )}
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          <TextButton
                            //   kind={KIND.secondary}
                            onClick={() => {
                              setIsCount({
                                id: ite._id,
                                days: ite.max_lead_time,
                              });
                              setValue("select_date");
                            }}
                            size={SIZE.mini}
                            endEnhancer={<RightArrow />}
                          >
                            Select Plan
                          </TextButton>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="chooseplan_card">
                      {/* {ite.discount == 0 ? (
                        <></>
                      ) : (
                        <div className="discountCard">
                          <label style={{ fontSize: "10px" }}>
                            {ite.discount}% Discount
                          </label>
                        </div>
                      )} */}
                      <div
                        style={{
                          textAlign: "left",
                          padding: "19px 19px",
                        }}
                      >
                        <div>
                          <NumericFormat
                            displayType="text"
                            value={ite.single_instalment_amount}
                            thousandsGroupStyle={currencyGroupStyle}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currencySeparator}
                            decimalSeparator={currencyDecimalSeparator}
                            renderText={(values) => (
                              <>
                                <div
                                  style={{ display: "flex", gap: "3px" }}
                                  className="card_header_text_wraper"
                                >
                                  {/* <sup style={{ fontSize: "9px" }}>
                                    {ite.currency}
                                  </sup>{" "} */}
                                  {/* <LabelMedium $style={{ color: "#333860" }}> */}
                                  <spa
                                    style={{
                                      color: "#333860",
                                      fontFamily: "Roboto",
                                      fontSize: "13px",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      lineHeight: "17px",
                                    }}
                                  >
                                    {ite.no_of_payments}{" "}
                                    <FormattedMessage
                                      id={ite.payment_plan_frequency}
                                    >
                                      {ite.payment_plan_frequency}
                                    </FormattedMessage>{" "}
                                    Payment of{" "}
                                    <spa className="choose_plan_headers">
                                      ${values}
                                    </spa>
                                    &nbsp;each
                                  </spa>
                                  {/* </LabelMedium> */}
                                </div>
                              </>
                            )}
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                marginTop: "5px",
                              }}
                              className="select_plan_amt_mobile"
                            >
                              {ite.discount == 0 ? (
                                <></>
                              ) : (
                                <NumericFormat
                                  displayType="text"
                                  value={ite.total_payment_amount}
                                  thousandsGroupStyle={currencyGroupStyle}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={currencySeparator}
                                  decimalSeparator={currencyDecimalSeparator}
                                  renderText={(values) => (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "3px",
                                          // marginTop: "10px",
                                        }}
                                      >
                                        <LabelLarge
                                          $style={{
                                            color: "#333860",
                                            textDecoration: "line-through",
                                            fontWeight: 400,
                                            fontSize: "20px",
                                          }}
                                        >
                                          {currencyList[ite?.currency]
                                            ? currencyList[ite?.currency]
                                            : ite?.currency}
                                          {values}
                                        </LabelLarge>
                                      </div>
                                    </>
                                  )}
                                />
                              )}

                              <NumericFormat
                                displayType="text"
                                value={ite.discounted_amount}
                                thousandsGroupStyle={currencyGroupStyle}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={currencySeparator}
                                decimalSeparator={currencyDecimalSeparator}
                                renderText={(values) => (
                                  <>
                                    <LabelLarge
                                      $style={{
                                        // color: ite.discount
                                        //   ? "#0FB158"
                                        //   : "#333860",
                                        fontSize: "20px",
                                      }}
                                    >
                                      {currencyList[ite?.currency]
                                        ? currencyList[ite?.currency]
                                        : ite?.currency}

                                      {values}
                                    </LabelLarge>
                                  </>
                                )}
                              />
                            </div>
                            {ite.discount == 0 ? (
                              <></>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "3px",
                                  marginTop: "5px",
                                }}
                              >
                                <DiscountTag />
                                <ParagraphMedium $style={{ color: "#0FB158" }}>
                                  Save {ite.discount}% {"("}
                                  {currencyList[ite?.currency]
                                    ? currencyList[ite?.currency]
                                    : ite?.currency}{" "}
                                  <NumericFormat
                                    displayType="text"
                                    value={
                                      Number(ite.total_payment_amount) -
                                      Number(ite.discounted_amount)
                                    }
                                    thousandsGroupStyle={currencyGroupStyle}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={currencySeparator}
                                    decimalSeparator={currencyDecimalSeparator}
                                    renderText={(values) => <>{values}</>}
                                  />
                                  {")"} to Settle
                                </ParagraphMedium>
                              </div>
                            )}
                          </div>

                          <div style={{ marginTop: "10px" }}>
                            <TextButton
                              //   kind={KIND.secondary}
                              onClick={() => {
                                setIsCount({
                                  id: ite._id,
                                  days: ite.max_lead_time,
                                });
                                setValue("select_date");
                              }}
                              size={SIZE.mini}
                              endEnhancer={<RightArrow />}
                            >
                              Select Plan
                            </TextButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* <TextButton
                  //   kind={KIND.secondary}
                  onClick={() => {
                    setValue("payment_plan_custom");
                  }}
                  size={SIZE.compact}
                  endEnhancer={<RightArrow />}
                >
                  Quick Setup
                </TextButton> */}
              </>
            );
          })}
          {is_allow_manual_payment_plan ? (
            <div
              className="chooseplan_card_quick_one"
              style={{ marginBottom: "30px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  flex: "1",
                }}
              >
                <div
                  className="buildyourplan"
                  onClick={() => setValue("payment_plan_custom")}
                >
                  <div style={{ textAlign: "left" }}>
                    <LabelMedium $style={{ color: "#333860" }}>
                      Build Your Own plan ...
                    </LabelMedium>
                    {/* <span className="buildyourplan_subhead">
                      Personalized your own Payment Plan Option
                    </span> */}
                  </div>
                  <div
                    style={{
                      height: "30px",
                      width: "30px",
                      // borderRadius: "50%",
                      // border: "0.5px solid #cdced9",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setValue("payment_plan_custom")}
                  >
                    <ArrowCircle />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
export default ChoosePlan;
