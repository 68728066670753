import React from "react";
import CustomizeUi from "../../containers/CustomizeUi/CustomizeUi";
import Layout from "../Layout/Layout";

const CustomizeUiView = () => {
  return (
    // <Layout>
      <CustomizeUi />
    // </Layout>
  );
};

export default CustomizeUiView;
