import axios from "axios";

const HOST = process.env.REACT_APP_HOST;
const AUTH = process.env.REACT_APP_HOST_AUTH;


// export const getToken = async ({ hashCode }) => {
//   return await axios.post(
//     `${AUTH}/auth/getCustomerPortalAccessToken/${hashCode}`
//   );
// };

// export const refreshAccessToken = async (token) => {
//   return await axios.post(
//     `${AUTH}/auth/refreshCustomerPortalAccessToken`,
//     new URLSearchParams({
//       token,
//     })
//   );
// };

export const getToken = async ({ hash }) => {
  return await axios.post(
    `${AUTH}customer-portal/access-token`,
    new URLSearchParams({
      hash,
    })
  );
};

export const refreshAccessToken = async (token) => {
  return await axios.post(
    `${AUTH}customer-portal/refresh-token`,
    new URLSearchParams({
      token,
    })
  );
};
