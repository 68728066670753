import React, { useState } from "react";
import {
  HeadingXLarge,
  LabelLarge,
  ParagraphLarge,
  ParagraphMedium,
  HeadingXSmall,
} from "baseui/typography";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import { useSelector } from "react-redux";
import moment from "moment";
import { KIND, TextButton } from "../TextButton";
import { SIZE } from "baseui/input";
import { Controller, useForm } from "react-hook-form";
import { getSelectedInvoice } from "../../redux/Invoice/action";
import { footerPopupClose } from "../../redux/Invoice/action";
import { useDispatch } from "react-redux";
import CheckBox from "../CheckBox/CheckBox";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { invoiceDownload } from "../../services/customerPortal";
import { FormattedMessage, FormattedNumber } from "react-intl";
import SelectionCheckbox from "../SelectionCheckbox/SelectionCheckbox";
import { NumericFormat } from "react-number-format";
import { paymentAmount } from "../../redux/Layout/action";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import _ from "lodash";

const InvoicePhoneCard = ({
  setFooter,
  setInvoiceDetails,
  rowDatas,
  rowToggle,
  row,
  selectedFlatRows,
  activeKey,
  index,
  columnData,
}) => {
  const [viewMore, setViewMore] = useState(false);
  let dispatch = useDispatch();
  console.log("rowDatas>>>>", rowDatas);
  let RowInvoiceNumber = _.get(rowDatas, "invoice_number", "");
  let clientRef = _.get(rowDatas, "client_reference", "");
  let customerId = _.get(rowDatas, "cus.customer_id", "");
  let InvoiceDueDate = rowDatas.invoice_due_date;
  let invoiceDate = rowDatas.invoice_date;
  let OverDue = rowDatas.inv_due_days;
  let invoiceStatus = _.get(row, "original.status", "");
  let InvoiceTotalAmount =
    activeKey === "1"
      ? rowDatas.invoice_total_amount
      : rowDatas.not_paid_amount;
  const { default_color, organization, invoice } = useSelector(
    (s) => s.layoutReduceData
  );

  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let showActiveUser = addSearchParams.get("i");

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });
  let check = watch("selected");

  let organizationData = useSelector((e) => e.layoutReduceData.organization);

  let dateFormat = useSelector(
    (e) =>
      e && e.layoutReduceData && e.layoutReduceData?.organization.date_format
  );
  let Duedate = InvoiceDueDate
    ? moment.utc(InvoiceDueDate).tz(organization.time_zone).format(dateFormat)
    : "-";
  let invDate = invoiceDate
    ? moment.utc(invoiceDate).tz(organization.time_zone).format(dateFormat)
    : "-";

  const invoiceDateEnable = _.get(
    organization,
    "customer_portal_ui.cuslb",
    []
  ).filter((inv) => inv?.syt === "invoice_date" && inv.ise === true);

  const dueDatesEnable = _.get(
    organization,
    "customer_portal_ui.cuslb",
    []
  ).filter((inv) => inv?.syt === "invoice_due_date" && inv.ise === true);

  const location = useLocation();
  const hash = location.pathname.split("/")[1];

  let currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );
  const { referenceData } = useSelector((s) => s.layoutReduceData);

  let findClient = row?.original?.business_unit
    ? referenceData &&
      referenceData["business_unit_list"].find(
        (e) => e.id === row?.original?.business_unit
      )
    : [];

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  // useEffect(() => {
  //   if (!check && ) {
  //     dispatch(getSelectedInvoice(selectedFlatRows));
  //   }
  //   // else {
  //   //   dispatch(getSelectedInvoice([]));
  //   // }
  // }, [selectedFlatRows]);

  let findDueLabel =
    Array.isArray(columnData) &&
    columnData.find((e) => e.accessor == "invoice_due_date");
  let findInvDateLabel =
    Array.isArray(columnData) &&
    columnData.find((e) => e.accessor == "invoice_date");

  return (
    <div
      style={{
        // height: "137px",
        width: "100%",
        backgroundColor: "#FFFFFF",
        marginBottom: "15px",
        border: "0.5px solid #cdced9",
        padding: "15px",
        borderRadius: "5px",
        paddingBottom: "5px",
        boxShadow: "0px 1px 2px 0px #cdded9",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "6px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          {findClient && findClient.label && (
            <ParagraphLarge style={{ maxWidth: "241px" }}>
              {findClient && findClient.label ? findClient.label : ""}{" "}
            </ParagraphLarge>
          )}

          {(organization &&
            organization.accounting_book !== "CSV" &&
            invoice?.is_allow_invoice_download === true) ||
          (organization.accounting_book === "CSV" &&
            row?.original?.is_system_generated &&
            invoice?.is_allow_invoice_download === true) ||
          (organization.accounting_book === "CSV" &&
            row?.original?.invoive_file_doc_id &&
            invoice?.is_allow_invoice_download === true) ? (
            <LabelLarge
              $style={{
                color: "#516beb",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                invoiceDownload({
                  invoiceId: row?.original?._id,
                });
              }}
            >
              {RowInvoiceNumber?.length
                ? RowInvoiceNumber
                : clientRef?.length
                ? clientRef
                : customerId?.length
                ? customerId
                : "-"}
            </LabelLarge>
          ) : (
            <LabelLarge>
              {RowInvoiceNumber?.length
                ? RowInvoiceNumber
                : clientRef?.length
                ? clientRef
                : customerId?.length
                ? customerId
                : "-"}
            </LabelLarge>
          )}
          {/* {findClient && findClient.label && (
            <ParagraphLarge>
              {findClient && findClient.label ? findClient.label : ""}
            </ParagraphLarge>
          )} */}

          {/* <LabelLarge>{RowInvoiceNumber}</LabelLarge> */}
          <ParagraphLarge>
            <span style={{ color: "#787878" }}>
              {findDueLabel?.portt
                ? `${findDueLabel?.portt}:`
                : findInvDateLabel?.portt
                ? `${findInvDateLabel?.portt}:`
                : ""}
            </span>{" "}
            {findInvDateLabel?.portt
              ? invDate
              : findDueLabel?.portt
              ? Duedate
              : ""}
            {/* {!_.get(organizationData, "customer_portal_ui.cuslb.[3].isd")
              ? invDate
              : Duedate} */}
          </ParagraphLarge>

          {activeKey === "1" ? (
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <span
                style={{
                  width: "7px",
                  height: "7px",
                  borderRadius: "50%",
                  backgroundColor: "#4CAF50",
                }}
              ></span>{" "}
              <ParagraphLarge>Paid</ParagraphLarge>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "5px",
              }}
            >
              {/* {invoiceStatus === "due" && value !== 0 ? "In " : ""}
      {value === 0 ? "Today" : Math.abs(value)}{" "}
      {invoiceStatus === "overdue" && value !== 0
        ? "days ago"
        : value === 0
        ? ""
        : "days"} */}
              {(Array.isArray(invoiceDateEnable) &&
                invoiceDateEnable.length > 0) ||
              (Array.isArray(dueDatesEnable) && dueDatesEnable.length > 0) ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ParagraphLarge
                    style={{ color: `#787878`, fontSize: "14px" }}
                  >
                    {
                      invoiceDateEnable[0]
                        ? `${invoiceDateEnable[0]?.portt}:`
                        : ""
                      //     dueDatesEnable[0]
                      // ? `${dueDatesEnable[0]?.portt}:`
                      //       : ""
                    }
                  </ParagraphLarge>
                  <ParagraphLarge
                    style={{ fontSize: "14px", color: "#08083A" }}
                  >
                    {
                      invoiceDateEnable[0] ? invDate : ""
                      //     dueDatesEnable[0]
                      // ? Duedate
                      //   : ""
                    }
                  </ParagraphLarge>
                </div>
              ) : (
                <></>
              )}

              {Array.isArray(invoiceDateEnable) &&
              invoiceDateEnable.length > 0 ? (
                <ParagraphLarge
                  style={{
                    color: invoiceStatus === "overdue" ? "#FD372A" : "#FBBD04",
                  }}
                >
                  {invoiceStatus === "due" && OverDue !== 0
                    ? "In "
                    : ` Overdue`}{" "}
                  {OverDue === 0 ? "Today" : Math.abs(OverDue)}{" "}
                  {invoiceStatus === "overdue" && OverDue !== 0
                    ? "days ago"
                    : OverDue === 0
                    ? ""
                    : "days"}
                </ParagraphLarge>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "6px",
          }}
        >
          {activeKey === "1" ? (
            <div style={{ height: "13px" }}></div>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <SelectionCheckbox
                  {...rowToggle}
                  row={row}
                  selectedFlatRows={selectedFlatRows}
                />
              </div>
            </>
          )}

          <div style={{ display: "flex" }}>
            <HeadingXLarge style={{ display: "flex", gap: "2px" }}>
              {/* <sup>
                <HeadingXSmall>{InvoiceTotalAmount.currency} </HeadingXSmall>
              </sup> */}
              {/* <FormattedNumber value={InvoiceTotalAmount.value} />{" "} */}

              <NumericFormat
                displayType="text"
                value={InvoiceTotalAmount && InvoiceTotalAmount.value}
                thousandsGroupStyle={currencyGroupStyle}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={currencySeparator}
                decimalSeparator={currencyDecimalSeparator}
                renderText={(values) => (
                  <>
                    <LabelLarge $style={{ color: "#333860" }}>
                      {currencyList[
                        InvoiceTotalAmount && InvoiceTotalAmount.currency
                      ]
                        ? currencyList[
                            InvoiceTotalAmount && InvoiceTotalAmount.currency
                          ]
                        : InvoiceTotalAmount &&
                          InvoiceTotalAmount.currency}{" "}
                      {values}
                    </LabelLarge>
                  </>
                )}
              />
            </HeadingXLarge>
          </div>
        </div>
      </div>
      {activeKey === "1" &&
      organization &&
      organization.accounting_book === "CSV" ? (
        <></>
      ) : (
        <>
          <div
            style={{
              textAlign: "center",
              paddingTop: "7px",
              borderTop: "0.5px solid #eeeeee",
            }}
          >
            {viewMore ? (
              <div style={{ paddingTop: "6px" }}>
                {invoice.is_display_invoice_description && (
                  <div style={{ display: "flex", textAlign: "left" }}>
                    <ParagraphLarge
                      $style={{
                        color: "#787878",
                        fontSize: "13px",
                        width: "115px",
                      }}
                    >
                      <FormattedMessage id="invoice_description">
                        invoice_description
                      </FormattedMessage>
                    </ParagraphLarge>
                    <ParagraphLarge
                      $style={{
                        color: "#787878",
                        fontSize: "13px",
                        width: "8px",
                      }}
                    >
                      :
                    </ParagraphLarge>
                    <ParagraphLarge $style={{ color: "#787878", flex: "1" }}>
                      {row.original.invoice_description}
                    </ParagraphLarge>
                  </div>
                )}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* {organization && organization.accounting_book !== "CSV" ? (
                    <>
                      <div style={{ width: "100%" }}>
                        <TextButton
                          kind={KIND.tertiary}
                          size={SIZE.compact}
                          fullWidth
                          onClick={() =>
                            invoiceDownload({
                              invoiceId: row.original._id,
                            })
                          }
                        >
                          Dowload Invoice
                        </TextButton>
                      </div>
                    </>
                  ) : (
                    <></>
                  )} */}
                  {/* {activeKey === "1" ? (
                    <></>
                  ) : (
                    <>
                      <div style={{ width: "100%" }}>
                        <NavLink
                          to={`/${hash}/payments${
                            showActiveUser ? `?i=${showActiveUser}` : ""
                          }`}
                        >
                          <TextButton
                            kind={KIND.secondary}
                            size={SIZE.compact}
                            fullWidth
                            onClick={() => {
                              if (row && row.original) {
                                dispatch(getSelectedInvoice([row]));
                                dispatch(
                                  paymentAmount(
                                    row &&
                                      row.original &&
                                      row.original.not_paid_amount &&
                                      row.original.not_paid_amount.value
                                  )
                                );
                              }
                              dispatch(footerPopupClose(true));
                              // dispatch(())
                            }}
                          >
                            Pay Now
                          </TextButton>
                        </NavLink>
                      </div>
                    </>
                  )} */}
                </div>
                <div style={{ paddingTop: "7px" }}>
                  <TextButton
                    onClick={() => {
                      setViewMore(!viewMore);
                    }}
                    kind={KIND.tertiary}
                    size={SIZE.compact}
                    endEnhancer={
                      <i className="mx-icon-view-less font-icon-color" />
                    }
                  >
                    View Less
                  </TextButton>
                </div>
              </div>
            ) : (
              <>
                <TextButton
                  onClick={() => {
                    if (row && row.original) {
                      dispatch(getSelectedInvoice([row]));
                      dispatch(
                        paymentAmount(
                          row &&
                            row.original &&
                            row.original.not_paid_amount &&
                            row.original.not_paid_amount.value
                        )
                      );
                    }
                    setFooter(false);
                    setInvoiceDetails({
                      ...rowDatas,
                      total_amount: InvoiceTotalAmount,
                    });

                    setViewMore(!viewMore);
                  }}
                  size={SIZE.compact}
                  kind={KIND.tertiary}
                  endEnhancer={
                    <i className="mx-icon-drop-down font-icon-color" />
                  }
                >
                  View More
                </TextButton>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default InvoicePhoneCard;
