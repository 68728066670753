import { HeadingXSmall } from "baseui/typography";
import React from "react";
import { useSelector } from "react-redux";
import SideBar from "./SideBar";
import SidebarMobileView from "./SideBar/SidebarMobileView";
import powerBy from "./SideBar/pw-by.png";
import ModalPopUp from "../views/ModalPopup/ModalPopup";

function Layout({ sideBar = true, children }) {
  const web = process.env.REACT_APP_WEB_LINK;
  let accessValue = useSelector((e) => e.layoutReduceData.accessData);

  // return (
  //   <div style={{ display: "flex", flexDirection: "column" }}>
  //     <div
  //       style={{
  //         flex: 1,
  //         width: "100%",
  //         backgroundColor: "#000000",
  //       }}
  //     >
  //       LAYOUT
  //     </div>
  //     <div
  //       style={{
  //         height: "100px",
  //         width: "100%",
  //         backgroundColor: "#ffffff",
  //       }}
  //     >
  //       SIDEBAR
  //     </div>
  //   </div>
  // );

  return (
    <>
      <div className="layout-container">
        {sideBar && (
          <>
            <SideBar />
          </>
        )}{" "}
        {children}
      </div>
      {/* <div className="mobile-view__footer">
        <div className="footer-wraper__text">
          <HeadingXSmall>Powered by </HeadingXSmall>{" "}
          <HeadingXSmall $style={{ width: "50px", paddingTop: "6px" }}>
            <a href={web} target="_blank" className="mobile-view__href">
              <img src={powerBy} />
            </a>
          </HeadingXSmall>
        </div>
      </div> */}

      <SidebarMobileView />
    </>
  );
}

export default Layout;
