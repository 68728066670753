import React, { useContext, useEffect, useState } from "react";
import { IconButton, SIZE, KIND } from "../IconButton";
import { useRBAC, permission } from "../../providers/RBACProvider";
import { useSelector } from "react-redux";
import { array, object } from "yup";
import _ from "lodash";
import { FormattedDate } from "react-intl";
import { useIntl } from "react-intl";
// import CsvDownload from "react-json-to-csv";
import csvDownload from "json-to-csv-export";
import { TextButton } from "../TextButton";

import moment from "moment";
import axios from "axios";
import { useNumericFormat } from "react-number-format";
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { LabelLarge, LabelMedium, ParagraphLarge } from "baseui/typography";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { isExpired } from "react-jwt";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";

function DownloadCusPortal(props) {
  const {
    default_nav_color,
    organization,
    customer,
    tabMotion,
    referenceData,
  } = useSelector((s) => s.layoutReduceData);
  let timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );
  const location = useLocation();
  const hash = location.pathname.split("/")[1];
  let dateFormat = useSelector(
    (e) =>
      e && e.layoutReduceData && e.layoutReduceData?.organization.date_format
  );

  //   const { organization } = useSelector((s) => s.layoutReducerData);

  const { isAllowed } = useRBAC();

  const currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";
  let is_document_authorize_configured = useSelector(
    (e) => e.layoutReduceData.is_document_authorize_configured
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const intl = useIntl();

  const numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });

  let filteredColumns = props.filteredColumns;
  const [isHovered, setHovered] = useState();
  const navigate = useNavigate();
  let AuthorizationToken = localStorage.getItem("AuthorizationToken");

  return (
    <div
      style={{ width: "145px" }}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <StatefulPopover
        content={() => {
          return (
            <>
              <div
                style={{
                  fontSize: "14px",
                  backgroundColor: "white",
                  cursor: "pointer", // Change cursor to pointer on hover
                  padding: "8px 16px", // Add padding for clickable area
                  color: isHovered ? props.hoverColor : props.defaultColor,
                  borderRadius: "5px", // Optional: Add rounded corners
                }}
                onClick={async () => {
                  setLoading(true);

                  let data = await props
                    .service({
                      //   organization: currentOrganization,
                      // pageIndex: 1,
                      // sortBy: props.querySortBy,
                      // pageSize: 0,
                      // filters: props.queryFilter,
                    })
                    .then((res) => {
                      // setData(res?.data?.docs);
                      setLoading(false);
                      // return res.data.docs;
                    })
                    .catch((error) => {
                      return [];
                    });
                  let mockData = props.data?.map((row) => {
                    let rsObj = {};
                    let key = {};
                    props.columnData?.forEach((k) => {
                      key = k.literal_id;
                      key = intl.formatMessage({
                        id: key,
                      });
                      if (props.defaultCurrency && k.cell_type === "CURRENCY") {
                        key = `${key} ${""} (in ${props.defaultCurrency})`;
                      }

                      let value = _.get(row, k.accessor, "");
                      // let value = _.get(row.original, accessor, "");

                      switch (k.cell_type) {
                        // Next Action Date
                        case "DATEORGTZ": {
                          let date = moment
                            .utc(value)
                            .tz(timeZone)
                            .format(dateFormat);
                          if (value) {
                            value = date;
                          }
                          break;
                        }

                        case "REFERENCE_VALUE": {
                          const rfValue = referenceData[
                            k?.rd_name || k?.rdName
                          ]?.find(({ id }) => {
                            return id === value;
                          });

                          if (value) {
                            value = rfValue?.label;
                          }
                          // value && value ? (value = user?.displayName) : <></>;

                          break;
                          //
                        }
                        // value && value ? (
                        //   (value = intl.formatDate(date, {
                        //     year: "numeric",
                        //     month: "numeric",
                        //     day: "numeric",
                        //   }))
                        // ) : (
                        //   <></>
                        // );

                        case "DATE": {
                          let dateOn = moment
                            .utc(value)
                            .format(organization.date_format);
                          if (value) {
                            value = dateOn;
                          }
                          // value && value ? (
                          //   (value = intl.formatDate(dateOn, {
                          //     year: "numeric",
                          //     month: "numeric",
                          //     day: "numeric",
                          //   }))
                          // ) : (
                          //   <></>
                          // );
                          break;
                        }

                        // case "USER": {

                        //   const user = users.find(({ id }) => {
                        //     return id === value;
                        //   });

                        //   if (value) {
                        //     value = user?.displayName;
                        //   }
                        //   // value && value ? (value = user?.displayName) : <></>;

                        //   break;
                        // }

                        case "CURRENCY": {
                          if (value) {
                            value = `${
                              currencyList[props?.defaultCurrency]
                                ? currencyList[value?.currency]
                                : currencyList[value?.currency]
                            } ${numberFormat.format(`${value?.value}`)}`;
                          }
                          break;
                        }
                      }
                      rsObj[key] = value;
                    });
                    return rsObj;
                  });

                  let keys = Object.keys(mockData && mockData[0]);
                  const dataToCovert = {
                    data: mockData,
                    filename: `${`${props.fileName}
            ${moment().format(organization.date_format)}${" "}${moment().format(
                      "hh:mm:ss a"
                    )}
            .csv`}`,
                    delimiter: ",",
                    headers: keys,
                  };

                  csvDownload(
                    // mockData,
                    // `${`${props.fileName} ${moment().format(
                    //   date_format
                    // )}${" "}${moment().format("hh:mm:ss a")}.csv`}`
                    dataToCovert
                    // `${`${props.fileName} ${moment().format(
                    //   date_format
                    // )}${" "}${moment().format("hh:mm:ss a")}.csv`}`
                  );
                }}
                isLoading={loading}
                disabled={loading || props.length === 0}
                overrides={{
                  LoadingSpinner: {
                    style: ({ $theme }) => ({
                      height: "18px",
                      width: "18px",
                    }),
                  },
                }}
              >
                List Data
              </div>
              {is_document_authorize_configured && (
                <NavLink to={`/${hash}/authorize/document`}>
                  <div
                    onMouseEnter={() => {
                      setHovered(true);
                    }}
                    onMouseLeave={() => {
                      setHovered(false);
                    }}
                    style={{
                      fontSize: "14px",
                      backgroundColor: "white",
                      cursor: "pointer", // Change cursor to pointer on hover
                      padding: "8px 16px", // Add padding for clickable area
                      color: isHovered ? props.hoverColor : props.defaultColor,
                      borderRadius: "4px", // Optional: Add rounded corners
                    }}
                  >
                    Document
                  </div>
                </NavLink>
              )}
            </>
          );
        }}
        placement={PLACEMENT.bottom}
        triggerType={TRIGGER_TYPE.click}
      >
        <div>
          <IconButton
            kind={KIND.tertiary}
            startEnhancer={
              <i className="mx-icon-Group-16230 font-icon-color" />
            }
            // style={{ color: "#1A73E8" }}
          >
            <span
              style={{
                fontSize: "12px",
                fontWeight: "500",
                color: props.default_color,
              }}
            >
              Download
            </span>
          </IconButton>
        </div>
      </StatefulPopover>
    </div>
  );
}

export default DownloadCusPortal;
