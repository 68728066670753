import React, { useState } from "react";
import { Avatar } from "baseui/avatar";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Success from "../../assets/img/svg/Success";
import { LabelMedium } from "baseui/typography";
import { KIND, SIZE, TextButton } from "../../components/TextButton";
import { TextBox } from "../../components/TextBox";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import EmailImgge from "../../../src/assets/img/emailImg.png";
import { emailRegex } from "../../utils/regexConfig";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { emailSubceribe, uploaddocument } from "../../services/customerPortal";

const UnsubscribeView = () => {
  const { hash } = useParams();
  const [addSearchParams] = useSearchParams();
  const { organization, default_color } = useSelector(
    (e) => e.layoutReduceData
  );
  const widths = window.innerWidth;
  const intl = useIntl();
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [isError, setIsError] = useState("");
  let showActiveUser = addSearchParams.get("i");
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  const eLinkUnsubscribe = useMutation((data) => emailSubceribe({ data }), {
    onError: (error) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;
        toast.error(errorData);
        setIsError(errorData);
      }
    },
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      if (isSubscribed) {
        setIsSubscribed(false);
      } else {
        setIsSubscribed(true);
      }
      setValue("email", "");
      setIsError("");
    },
  });

  const onSubmit = async (data) => {
    let value = {
      ...data,
      type: isSubscribed ? "UNSUBSCRIBE" : "SUBSCRIBE",
    };
    await eLinkUnsubscribe.mutateAsync(value);
  };
  return (
    <div className="invoices-container">
      <div className="invoices-header-container">
        <div className="invoices-header___message-conatiner">
          <div>
            <div className="sidebar-top">
              <NavLink
                to={`/${hash}${showActiveUser ? `?i=${showActiveUser}` : ""}`}
              >
                <div
                  style={{
                    textAlign: "left",
                    width: "100%",
                    paddingLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {widths <= 500 ? (
                      <Avatar
                        overrides={{
                          Avatar: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                            }),
                          },
                          Root: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                              backgroundColor: "#F8F8FB",
                              width: "48px",
                            }),
                          },
                          Initials: {
                            style: () => ({
                              color: default_color,
                              fontSize: "14px",
                              fontWeight: "700",
                            }),
                          },
                          Avatar: {
                            style: () => ({
                              objectFit: "contain",
                            }),
                          },
                        }}
                        name={organization?.name}
                        src={
                          organization &&
                          organization.customer_portal_ui &&
                          organization.customer_portal_ui.logo
                            ? organization.customer_portal_ui.logo
                            : null
                        }
                        size="scale1200"
                      />
                    ) : organization &&
                      organization.customer_portal_ui &&
                      organization.customer_portal_ui.logo ? (
                      <div
                        style={{
                          maxWidth: `95px`,
                          minWidth: `18px`,
                          maxHeight: `48px`,
                        }}
                      >
                        <img
                          src={
                            organization &&
                            organization.customer_portal_ui &&
                            organization.customer_portal_ui.logo
                              ? organization.customer_portal_ui.logo
                              : null
                          }
                          alt="customer_Logo"
                          className="customer_portal_logo"
                        />
                      </div>
                    ) : (
                      <Avatar
                        overrides={{
                          Avatar: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                            }),
                          },
                          Root: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                              backgroundColor: "#F8F8FB",
                              width: "48px",
                              objectFit: "contain",
                            }),
                          },
                          Initials: {
                            style: () => ({
                              color: default_color,
                              fontSize: "14px",
                              fontWeight: "700",
                              objectFit: "contain",
                            }),
                          },
                          Avatar: {
                            style: () => ({
                              objectFit: "contain",
                              width: "100px",
                            }),
                          },
                        }}
                        name={organization?.name}
                        src={
                          organization &&
                          organization.customer_portal_ui &&
                          organization.customer_portal_ui.logo
                            ? organization.customer_portal_ui.logo
                            : null
                        }
                      />
                    )}
                    <div className="org-name">
                      <div>
                        <div className="org_name" $style={{ color: "#333860" }}>
                          {organization?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="back__btn-comp">
          <div className="rq__call__form__wraper --modifier">
            {isSubscribed ? (
              <img
                src={EmailImgge}
                width={"150px"}
                height={"150px"}
                style={{ borderRadius: "50%", paddingBottom: "20px" }}
              />
            ) : (
              <Success />
            )}

            <LabelMedium
              $style={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                textAlign: "center",
              }}
            >
              {isSubscribed
                ? "Our emails contain important information about your account. Are you sure you want to OPT-OUT?"
                : "You've been unsubscribed ..."}
            </LabelMedium>
            <LabelMedium
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#787878",
                textAlign: "center",
                padding: "5px 15px",
              }}
            >
              {isSubscribed
                ? "Pls enter the email address you wish to Unsubscribe"
                : "If you wish to receive account information, Enter the Email ID and Click subscribe"}
            </LabelMedium>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ width: "320px" }}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Required",
                    pattern: {
                      message: "Enter valid email",
                      value: emailRegex,
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <TextBox
                        {...field}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "email_address",
                        })}
                        placeholder={intl.formatMessage({
                          id: "email_address",
                        })}
                        value={field.value}
                        error={errors[field.name] && errors[field.name].message}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                    </>
                  )}
                />
              </div>

              <TextButton
                kind={KIND.primary}
                size={SIZE.compact}
                fullWidth
                loading={isSubmitting}
              >
                {isSubscribed ? "Unsubscribe" : "Subscribe"}
              </TextButton>
              <div style={{ width: "320px", marginTop: "10px" }}>
                {isError && isError.length > 0 && (
                  <>
                    <div
                      style={{
                        backgroundColor: "#FFEDEC",
                        padding: "10px",
                        color: "red",
                        marginBottom: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      {isError}
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsubscribeView;
