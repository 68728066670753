import React from "react";

const Right = ({ width = 75, height = 75, color = '#0FB158' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.5 0C16.8233 0 0 16.8233 0 37.5C0 58.1767 16.8233 75 37.5 75C58.1767 75 75 58.1767 75 37.5C75 16.8233 58.1767 0 37.5 0ZM58.4586 27.6316L34.4925 51.4098C33.0827 52.8195 30.8271 52.9135 29.3233 51.5038L16.6353 39.9436C15.1316 38.5338 15.0376 36.1842 16.3534 34.6805C17.7632 33.1767 20.1128 33.0827 21.6165 34.4925L31.6729 43.703L53.1015 22.2744C54.6053 20.7707 56.9549 20.7707 58.4586 22.2744C59.9624 23.7782 59.9624 26.1278 58.4586 27.6316Z" fill={color} />
        </svg>

    )
}
export default Right