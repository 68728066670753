import React, { useContext } from "react";
import _ from "lodash";

function DayDifference(props) {
  let { value, column, alignment, accessor, row, ...rest } = props;

  const invoiceStatus = _.get(row, "original.status", "");
  value = _.get(row.original, accessor, null);

  const [visible, setVisible] = React.useState(false);

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  return (
    <div
      style={{
        marginBottom: "4px",
        position: "relative",
        bottom: "6px",
        color: invoiceStatus === "overdue" ? "#FD372A" : "#FBBD04",
        // color: `${
        //   dayDiff == 0 ? "#B79C15" : dayDiff > 0 ? "#757575" : "#FD372A"
        // }`,
        textAlign: `${alignment}`,
      }}
    >
      {invoiceStatus === "due" && value !== 0 ? "In " : ""}
      {value === 0 ? "Today" : Math.abs(value)}{" "}
      {invoiceStatus === "overdue" && value !== 0
        ? "days ago"
        : value === 0
        ? ""
        : "days"}
    </div>
  );
}

export default DayDifference;
