import React from 'react'

const TipSvg = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2367_162312)">
                <path d="M9.28099 2.21462C9.28099 2.36448 9.16443 2.49769 8.99791 2.49769C8.84805 2.49769 8.71484 2.36448 8.71484 2.21462V0.283071C8.71484 0.13321 8.84805 0 8.99791 0C9.14778 0 9.28099 0.13321 9.28099 0.283071V2.21462Z" fill="#787878" />
                <path d="M8.99791 5.0625C8.84805 5.0625 8.71484 5.19571 8.71484 5.34557C8.71484 5.49543 8.84805 5.62864 8.99791 5.62864C10.6797 5.62864 12.0284 6.99404 12.0284 8.65917C12.0284 8.80903 12.1617 8.94224 12.3115 8.94224C12.4614 8.94224 12.5946 8.80903 12.5946 8.65917C12.6112 6.67767 10.9961 5.0625 8.99791 5.0625Z" fill="#787878" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.01479 3.87891C6.46715 3.87891 4.38574 5.96031 4.38574 8.50795C4.38574 9.84005 4.96854 11.1222 5.95096 11.9881C6.68361 12.7207 6.61701 14.2859 6.60036 14.3026C6.60036 14.3858 6.61701 14.4524 6.68361 14.5191C6.73357 14.569 6.81682 14.6023 6.88343 14.6023H11.1295C11.2128 14.6023 11.2794 14.569 11.3293 14.5191C11.3793 14.4691 11.4126 14.3858 11.4126 14.3026L11.4124 14.2975C11.4085 14.1978 11.3501 12.6999 12.062 11.9881L12.1119 11.9381C13.0777 11.0556 13.6438 9.80675 13.6438 8.50795C13.6438 5.96031 11.5624 3.87891 9.01479 3.87891ZM11.679 11.5551C11.6623 11.5718 11.629 11.6051 11.629 11.6217C10.9796 12.3211 10.8631 13.47 10.8464 14.0195H7.1665C7.14985 13.47 7.03329 12.2711 6.33394 11.5551C5.45142 10.7892 4.93523 9.67354 4.93523 8.4913C4.93523 6.24338 6.75022 4.4284 8.99814 4.4284C11.2461 4.4284 13.061 6.24338 13.061 8.4913C13.061 9.67354 12.5615 10.7892 11.679 11.5551Z" fill="#787878" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.14955 14.9199H10.8461C11.2291 14.9199 11.5455 15.2363 11.5455 15.6193C11.5455 16.0023 11.2291 16.302 10.8295 16.3186H7.14955C6.76657 16.3186 6.4502 16.0023 6.4502 15.6193C6.4502 15.2363 6.76657 14.9199 7.14955 14.9199ZM7.14955 15.7358H10.8461C10.9127 15.7358 10.9627 15.6859 10.9627 15.6026C10.9627 15.5194 10.8961 15.4694 10.8295 15.4694H7.14955C7.06629 15.4694 7.01634 15.5194 7.01634 15.6026C7.01634 15.6859 7.08294 15.7358 7.14955 15.7358Z" fill="#787878" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2969 16.6016H7.69935C7.31637 16.6016 7 16.9179 7 17.3009C7 17.6839 7.31637 18.0003 7.69935 18.0003H10.2969C10.6799 18.0003 10.9963 17.6839 10.9963 17.3009C10.9963 16.9013 10.6799 16.6016 10.2969 16.6016ZM10.2969 17.4175H7.69935C7.63275 17.4175 7.56614 17.3675 7.56614 17.2843C7.56614 17.201 7.6161 17.1511 7.69935 17.1511H10.2969C10.3636 17.1511 10.4302 17.201 10.4302 17.2843C10.4302 17.3675 10.3636 17.4175 10.2969 17.4175Z" fill="#787878" />
                <path d="M13.7104 1.43247C13.5772 1.33257 13.4106 1.38252 13.3274 1.49908L12.2617 3.0976C12.1618 3.23081 12.1951 3.41397 12.3283 3.49723C12.3783 3.53053 12.4282 3.54718 12.4782 3.54718C12.5781 3.54718 12.6613 3.49723 12.7113 3.41397L13.777 1.81545C13.8769 1.69889 13.8436 1.51573 13.7104 1.43247Z" fill="#787878" />
                <path d="M5.76744 3.43056C5.71748 3.46386 5.66753 3.48051 5.61758 3.48051C5.51767 3.48051 5.43441 3.44721 5.36781 3.3473L4.33543 1.73213C4.25217 1.59892 4.28548 1.41576 4.41869 1.3325C4.5519 1.24924 4.73506 1.28255 4.81832 1.41576L5.85069 3.03093C5.93395 3.16414 5.90065 3.3473 5.76744 3.43056Z" fill="#787878" />
                <path d="M3.60291 5.24597L1.92113 4.33015C1.78792 4.26355 1.60476 4.3135 1.53815 4.44671C1.4549 4.57992 1.50485 4.76309 1.65471 4.82969L3.33649 5.74551C3.34691 5.74898 3.3566 5.75246 3.36587 5.75578C3.40104 5.76838 3.43017 5.77881 3.4697 5.77881C3.56961 5.77881 3.66951 5.72886 3.71947 5.62895C3.78607 5.49574 3.73612 5.31258 3.60291 5.24597Z" fill="#787878" />
                <path d="M16.0914 4.33015C16.2246 4.26355 16.4078 4.3135 16.4744 4.44671C16.541 4.57992 16.4911 4.76309 16.3578 4.82969L14.6594 5.74551C14.6261 5.76216 14.5762 5.77881 14.5262 5.77881C14.4263 5.77881 14.3264 5.72886 14.2764 5.62895C14.2098 5.49574 14.2598 5.31258 14.393 5.24597L16.0914 4.33015Z" fill="#787878" />
            </g>
            <defs>
                <clipPath id="clip0_2367_162312">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default TipSvg