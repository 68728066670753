import ColumnToogle from "../components/ColumnToggle/ColumnToggle";
import tableGetFilter from "../components/TableFilters";
import tableCellFilter from "../components/TableCell";
import CheckBox from "../components/CheckBox/CheckBox";
import { FormattedMessage } from "react-intl";
import DownArrow from "./DownArrow";
import UpArrow from "./UpArrow";
import { IconButton, SIZE, KIND } from "../components/IconButton";
import ToolTip from "../components/TableCell/ToolTip/ToolTip";
import { Label2 } from "baseui/typography";
import SortUp from "../assets/img/svg/SortUp";
import SortDown from "../assets/img/svg/SortDown";
import TextButton from "../components/TextButton/TextButton";
// import NestedRibbon from "../components/NestedRibbon/NestedRibbon";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedInvoice } from "../redux/Invoice/action";
import { invoiceDownload, paymentreceipt } from "../services/customerPortal";
import { rest } from "lodash";
import { paymentAmount } from "../redux/Layout/action";
import { useMemo } from "react";
const SelectionCheckbox = (props) => {
  const { row, selectedFlatRows } = props;
  const { original } = row;

  let isOtherCurrencySelected = false;
  const selectedCurrency =
    selectedFlatRows && selectedFlatRows[0]
      ? selectedFlatRows[0].original.invoice_currency
      : null;

  if (selectedCurrency && selectedCurrency !== original.invoice_currency) {
    isOtherCurrencySelected = true;
  }

  return (
    <CheckBox
      {...row.getToggleRowSelectedProps()}
      disabled={isOtherCurrencySelected}
    />
  );
};

const columnMapper = ({
  columns = [],
  isExpanded = true,
  isSelection = true,
  isDownload = true,
  isPay = true,
  viewSelect = [],
  isReceipt = true,
  width = 0,
}) => {
  let newColumns = [];
  let hiddenColumns = [];
  let filters = null;
  let sort = null;

  for (let column of columns) {
    let Filter = tableGetFilter(column.type);

    const tableHeaderAlignment = () => {
      return column.alignment || column.header_alignment;
    };
    let Cell;
    if (column.nested && Array.isArray(column.nested)) {
      Cell = (props) => (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {column.nested.map((nst) => {
            let InnerCell = tableCellFilter({
              filterType: nst.cell_type,
              alignment: nst.alignment,
              accessor: nst.accessor,
              suffix: nst.suffix,
              rdName: nst.rd_name,
              portt: nst.portt,
              // isCurrency: isCurrency,
            });

            return <InnerCell {...props} />;
          })}
        </div>
      );
    } else {
      Cell = tableCellFilter({
        filterType: column.cell_type,
        alignment: column.alignment,
        accessor: column.accessor,
        rdName: column.rd_name,
        portt: column.portt,
      });
    }

    let newCol = {
      id: column.accessor,
      col_id: column.col_id,
      Header: (props) => {
        return (
          <Label2
            $style={{
              width: "100%",
              textAlign: `
            ${tableHeaderAlignment(column.type)}`,
            }}
            {...props.column.getSortByToggleProps()}
            className={`table-main__head-cell_content table-main__head-cell_content--`}
          >
            {column?.portt ? (
              column.portt
            ) : (
              <FormattedMessage id={column.literal_id}>
                {column.literal_id}
              </FormattedMessage>
            )}

            {props.column.canSort ? (
              <div className="table-main__head-sort">
                <SortUp
                  className={
                    props.column.isSorted && !props.column.isSortedDesc
                      ? "active"
                      : ""
                  }
                />
                <SortDown
                  className={
                    props.column.isSorted && props.column.isSortedDesc
                      ? "active"
                      : ""
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </Label2>
        );
      },
      accessor: column.accessor,
      Cell,
      Filter,
      // width: column.width,
      width: (width - 80) / columns.length,
      disableSortBy: !column.sort_by,
      disableFilters: !column.filter_by,
      order: column.order,
    };

    let viewCol = viewSelect.find((e) => e.col_id === newCol.accessor);

    if (viewCol) {
      let { order, width } = viewCol;
      newCol = { ...newCol, order, width };

      if (viewCol.is_hidden) {
        hiddenColumns.push(newCol.id);
      }

      if (viewCol.sort) {
        sort = viewCol.sort;
      }
    } else {
      if (column.is_hidden) {
        hiddenColumns.push(newCol.id);
      }
    }

    newColumns.push(newCol);
  }

  newColumns = newColumns.sort((a, b) => a.order - b.order);

  if (isExpanded) {
    newColumns.push({
      Header: (props) => {
        return (
          <ColumnToogle
            style={{ backgroundColor: "" }}
            allColumns={props.allColumns}
          />
        );
      },
      id: "expander",
      width: 40,
      disableDND: true,
      disableResizing: true,
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <IconButton size={SIZE.compact} kind={KIND.tertiary}>
              <UpArrow />
            </IconButton>
          ) : (
            <IconButton size={SIZE.compact} kind={KIND.tertiary}>
              <DownArrow />
            </IconButton>
          )}
        </span>
      ),
    });
  }

  // if (isDownload) {
  //   newColumns.push({
  //     id: "download",
  //     disableDND: true,
  //     disableResizing: true,
  //     // Header: isDownload
  //     //   ? (props) => {
  //     //       return <div style={{ width: "300px" }}> </div>;
  //     //     }
  //     //   : () => null,

  //     width: 55,
  //     Cell: ({ row }) => {
  //       const { organization, invoice } = useSelector(
  //         (s) => s.layoutReduceData
  //       );
  //       return (
  //         <>
  //           {(organization &&
  //             organization.accounting_book !== "CSV" &&
  //             invoice?.is_allow_invoice_download === true) ||
  //           (organization.accounting_book === "CSV" &&
  //             row?.original?.is_system_generated &&
  //             invoice?.is_allow_invoice_download === true) ||
  //           (organization.accounting_book === "CSV" &&
  //             row?.original?.invoive_file_doc_id &&
  //             invoice?.is_allow_invoice_download === true) ? (
  //             <>
  //               <span {...row.getToggleRowExpandedProps()}>
  //                 <IconButton
  //                   size={SIZE.compact}
  //                   kind={KIND.tertiary}
  //                   onClick={() =>
  //                     invoiceDownload({
  //                       invoiceId: row.original._id,
  //                     })
  //                   }
  //                 >
  //                   <i className="mx-icon-download font-icon-color" />
  //                 </IconButton>
  //               </span>
  //             </>
  //           ) : (
  //             <></>
  //           )}
  //         </>
  //       );
  //     },
  //   });
  // }
  if (isReceipt) {
    newColumns.push({
      id: "receiptdownload",
      disableDND: true,
      disableResizing: true,
      // Header: isDownload
      //   ? (props) => {
      //       return <div style={{ width: "300px" }}> </div>;
      //     }
      //   : () => null,

      width: 135,
      Cell: ({ row }) => {
        const { organization, invoice, paymentReceiptConfigured } = useSelector(
          (s) => s.layoutReduceData
        );
        return (
          <>
            {invoice?.is_allow_payment_receipt_download === true &&
            paymentReceiptConfigured.is_payment_receipt_configured === true ? (
              <>
                <span {...row.getToggleRowExpandedProps()}>
                  <IconButton
                    size={SIZE.compact}
                    kind={KIND.tertiary}
                    onClick={() =>
                      paymentreceipt({
                        invoiceId: row.original._id,
                      })
                    }
                  >
                    {/* <i className="mx-icon-download font-icon-color" /> */}
                    Download Receipt
                  </IconButton>
                </span>
              </>
            ) : (
              <></>
            )}
          </>
        );
      },
    });
  }
  if (isPay) {
    newColumns.push({
      id: "pay_now",
      width: 100,
      disableDND: true,
      disableResizing: true,
      Cell: ({ row }) => {
        const [addSearchParams, setAddSearchParams] = useSearchParams();
        let showActiveUser = addSearchParams.get("i");
        const location = useLocation();
        const hash = location.pathname.split("/")[1];
        let dispatch = useDispatch();

        return (
          <span {...row.getToggleRowExpandedProps()} style={{ width: "90px" }}>
            <NavLink
              to={`/${hash}/payments${
                showActiveUser ? `?i=${showActiveUser}` : ""
              }`}
            >
              <TextButton
                size={SIZE.mini}
                kind={KIND.secondary}
                fullWidth
                onClick={() => {
                  if (row && row.original) {
                    dispatch(getSelectedInvoice([row]));
                    dispatch(
                      paymentAmount(
                        row &&
                          row.original &&
                          row.original.not_paid_amount &&
                          row.original.not_paid_amount.value
                      )
                    );
                  }
                  // dispatch(())
                }}
              >
                Pay Now
              </TextButton>
            </NavLink>
          </span>
        );
      },
    });
  }

  if (isSelection) {
    newColumns.unshift({
      id: "selection",
      disableResizing: true,
      width: 40,
      disableDND: true,
      // Header: ({ getToggleAllPageRowsSelectedProps }) => (
      //   <CheckBox {...getToggleAllPageRowsSelectedProps()} />
      // ),

      // Cell: ({ row }) => <CheckBox {...row.getToggleRowSelectedProps()} />,

      Header: ({ getToggleAllRowsSelectedProps, data }) => {
        const invoiceCurrencies =
          data && Array.isArray(data)
            ? data
                .map((inv) => inv.invoice_currency)
                .filter(
                  (item, index, arr) => item && arr.indexOf(item) === index
                )
            : [];
        const isMultiCurrency = invoiceCurrencies.length > 1;

        return (
          <CheckBox
            {...getToggleAllRowsSelectedProps()}
            disabled={isMultiCurrency}
          />
        );
      },
      Cell: SelectionCheckbox,
    });
  }

  return { columns: newColumns, hiddenColumns, filters, sort };
};
export default columnMapper;
