import React from "react";
import { TextBox } from "../../components/TextBox";
import DatePickerDownload from "../../components/DatePickerDownload/DatePickerDownload";

const InputComponent = (props) => {
  if (props.item.ft === "STR") {
    // Assuming the default input type is TextBox
    return (
      <TextBox
        {...props}
        label={props.item.lb}
        error={props.error}
        size="mini"
      />
    );
  } else if (props.item.ft === "DT") {
    return (
      <DatePickerDownload
        {...props}
        label={props.label}
        error={props.error}
        size="mini"
      />
    );
  }

  // Default case, render TextBox
  return (
    <TextBox {...props} label={props.label} error={props.error} size="mini" />
  );
};

export default InputComponent;
