import { ParagraphMedium, HeadingXSmall, LabelLarge } from "baseui/typography";
import { FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";

export const COLUMNS = [
  {
    Header:"Invoice Number",
    accessor: "invoice_number",
    // Cell: (props) => {
    //   return (
    //     <div className="table-cell__type-duble-value">
    //       <ParagraphMedium $style={{ color: "#333860" }}>
    //         {props.value}
    //       </ParagraphMedium>
    //       <ParagraphMedium $style={{ color: "#ADADAD" }}>
    //         {props.row.original.last_name}
    //       </ParagraphMedium>
    //     </div>
    //   );
    // },
  },
  {
    Header: "Due Amount",
    accessor: "invoice_outstanding",
    Cell: (props) => {
      return (
        // <div className="table-cell__type-amount">
        //   <LabelLarge $style={{ color: "#333860" }}>{props.value}</LabelLarge>
        //   <HeadingXSmall $style={{ color: "#FD372A" }}>
        //     {props.row.original.email}
        //   </HeadingXSmall>
        // </div>
        <FormattedNumber
          value={props.value.value}
          style="currency"
          currency="USD"
        />
      );
    },
  },
  // { Header: "Email", accessor: "email" },
];
