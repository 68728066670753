import {
  HeadingXLarge,
  HeadingXSmall,
  LabelMedium,
  ParagraphMedium,
  LabelSmall,
  LabelLarge,
} from "baseui/typography";
import React, { useState, useMemo, useEffect } from "react";
import MOCK_DATA from "../Invoices/MOCK_DATA.json";
import { COLUMNS } from "../Invoices/columns";
import TextButton from "../../components/TextButton/TextButton";
import { SIZE } from "baseui/button";
import CircleTick from "../../assets/img/svg/CircleTick";
import { IconButton, KIND } from "../../components/IconButton";
import ExpandArrowDown from "../../assets/img/svg/ExpandArrowDown";
import Add from "../../assets/img/svg/Add";
import { Input } from "baseui/input";
import SendSvg from "../../assets/img/svg/SendSvg";
import { TextBox } from "../../components/TextBox/TextBox";
import { useIntl } from "react-intl";
import LeftArrowSvg from "../../assets/img/svg/LeftArrowSvg";
import MessageHeaderSvg from "../../assets/img/svg/MessageHeaderSvg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Messages = () => {
  const intl = useIntl();
  const [expand, setExpand] = useState(false);

  function expandToggle() {
    setExpand(!expand);
  }

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => MOCK_DATA, []);

  let history = useNavigate();
  let customerData = useSelector((e) => e.layoutReduceData?.customer);

  return (
    <div className="invoices-container">
      <div className="invoices-header-container --hidden-top">
        <div className="invoices-header___message-conatiner">
          <HeadingXLarge>
            {customerData?.name ? customerData?.name : ""}
          </HeadingXLarge>
          {/* <MessageHeaderSvg /> */}
        </div>
        <div className="invoices-title">
          <LabelLarge>
            Your Payables for{" "}
            <span className="invoices-title__color">
              Thilash Services Pvt Limited
            </span>
          </LabelLarge>
        </div>
      </div>
      <div className="message-header__mobile-view">
        <div onClick={() => history(-1)}>
          <LeftArrowSvg />
        </div>
        <LabelLarge $style={{ color: "#516beb", marginLeft: "20px" }}>
          Thilash Services Pvt Limited
        </LabelLarge>
      </div>
      <div style={{ height: "95%", overflow: "scroll" }}>
        {[...Array(10)].map((e, i) => (
          <>
            <div key={i} className="message-container">
              <div className="message-content">
                <div>
                  <ParagraphMedium $style={{ color: "#333860" }}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </ParagraphMedium>
                </div>
              </div>
              <ParagraphMedium
                $style={{
                  color: "#ADADAD",
                  marginTop: "5px",
                  marginLeft: "20px",
                }}
              >
                07:32
              </ParagraphMedium>
            </div>
            <div key={i} className="message-container__sender-card">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="message-content --sender-bg">
                  <div>
                    <ParagraphMedium $style={{ color: "#333860" }}>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </ParagraphMedium>
                  </div>
                </div>
                <ParagraphMedium
                  $style={{
                    color: "#D6D6D6",
                    marginLeft: "20px",
                    textAlign: "right",
                  }}
                >
                  07:32
                </ParagraphMedium>
              </div>
            </div>
          </>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "75px",
          gap: "20px",
          padding: "0px 10px 0px 10px",
        }}
      >
        <IconButton kind={KIND.tertiary}>
          <Add />
        </IconButton>
        <div style={{ marginTop: "15px", width: "100%" }}>
          <TextBox
          // label={intl.formatMessage({
          //   id: "receiver",
          // })}
          // placeholder={intl.formatMessage({
          //   id: "receiver",
          // })}
          />
        </div>
        <div className="message-footer__send-btn">
          <SendSvg />
        </div>
      </div>
    </div>
  );
};

export default Messages;
