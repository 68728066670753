import React from "react";

const CopySvg = () => {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3327 0.708984H1.83268C1.05352 0.708984 0.416016 1.34648 0.416016 2.12565V12.0423H1.83268V2.12565H10.3327V0.708984ZM12.4577 3.54232H4.66602C3.88685 3.54232 3.24935 4.17982 3.24935 4.95898V14.8757C3.24935 15.6548 3.88685 16.2923 4.66602 16.2923H12.4577C13.2368 16.2923 13.8743 15.6548 13.8743 14.8757V4.95898C13.8743 4.17982 13.2368 3.54232 12.4577 3.54232ZM12.4577 14.8757H4.66602V4.95898H12.4577V14.8757Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default CopySvg;
