const MaxyfiLogo = () => {
  return (
    <svg
      width="56"
      height="20"
      viewBox="0 0 76 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_951_12932)">
        <path
          d="M17.4615 16.339H13.6526L15.3454 4.18128L10.134 16.339H7.30512L5.47866 4.20328L3.8082 16.339H0L2.27205 0H7.83955L9.48763 10.8021L14.1653 0H19.7335L17.4615 16.339V16.339Z"
          fill="#5B5B74"
          //   fill-opacity="0.5"
        />
        <path
          d="M45.6369 16.3398H41.7167L39.7119 12.8825L36.7277 16.3398H32.8076L38.0638 10.2379L34.8341 4.65625H38.7543L40.4506 7.57129L42.9864 4.65625H46.9065L42.0735 10.2379L45.6369 16.3398V16.3398Z"
          fill="#5B5B74"
          //   fill-opacity="0.5"
        />
        <path
          d="M48.9107 20.0007C48.4211 20.0007 48.0048 19.9631 47.6635 19.8878C47.3214 19.8126 47.0696 19.7452 46.9059 19.6848L46.6387 19.5493L46.973 17.1084C47.3592 17.1382 47.6187 17.1609 47.7523 17.1765C47.8859 17.1914 48.1454 17.1992 48.5316 17.1992C48.9618 17.1992 49.3109 17.1474 49.5781 17.0438C49.9341 16.9252 50.1797 16.7918 50.3133 16.6435C50.4322 16.4951 50.5434 16.3248 50.6476 16.1324L50.826 15.7101L47.8859 4.6582H51.6277L53.1198 11.6615L56.6391 4.6582H60.336L54.1669 15.9748C53.5877 17.0395 53.0232 17.865 52.4741 18.4498C51.9096 19.0496 51.3458 19.4549 50.7813 19.665C50.2314 19.89 49.6082 20.0028 48.9107 20.0028V20.0007Z"
          fill="#5B5B74"
          //   fill-opacity="0.5"
        />
        <path
          d="M25.6779 4.63228C24.8315 4.63228 24.0368 4.84308 23.2946 5.2654C22.537 5.70191 21.8991 6.34993 21.3793 7.20947C20.8449 8.0832 20.4881 9.16063 20.3104 10.4418C20.2363 10.9805 20.1992 11.483 20.1992 11.9486C20.1992 13.1865 20.4664 14.1624 21.0009 14.8757C21.7431 15.8659 22.7903 16.3606 24.141 16.3606C24.5817 16.3606 25.0133 16.3052 25.4359 16.1966L25.7667 13.8295C25.7667 13.8295 25.7653 13.8295 25.7646 13.8295C25.7353 13.8295 25.7101 13.8288 25.6898 13.8274C25.105 13.8025 24.6622 13.4917 24.3635 12.8926C24.1557 12.4951 24.0515 11.9983 24.0515 11.4021C24.0515 11.0898 24.0809 10.7491 24.1403 10.38C24.3033 9.32956 24.6153 8.53461 25.0756 7.99447C25.5359 7.45505 26.078 7.18533 26.7013 7.18533H28.576L28.2053 9.85124L28.0598 10.9116C28.0598 10.9116 27.9576 11.6484 27.8821 12.2013C27.8471 12.4547 27.8184 12.6683 27.8065 12.7556C27.8065 12.7556 27.8051 12.7578 27.8037 12.7606L27.3029 16.3371H31.1111L32.7368 4.65357L25.6765 4.63086L25.6779 4.63228Z"
          fill="#5B5B74"
          //   fill-opacity="0.5"
        />
        <path
          d="M65.1937 16.3395H61.3834L62.6531 7.29701H60.6699L61.0484 4.65168H63.0315L63.254 3.02417C63.4023 2.05958 63.8073 1.31361 64.4683 0.786249C65.1308 0.258177 65.966 -0.00585938 66.9761 -0.00585938H69.8281L69.4937 2.52591H68.0674C67.4882 2.52591 67.1391 2.69909 67.0202 3.04617C66.9013 3.39325 66.8117 3.92771 66.753 4.65097H69.0922L68.7137 7.2963H66.4634L65.1937 16.3388V16.3395Z"
          fill="#5B5B74"
          //   fill-opacity="0.5"
        />
        <path
          d="M73.2813 16.3382H69.4717L71.0981 4.65039H74.9077L73.2813 16.3382Z"
          fill="#5B5B74"
          //   fill-opacity="0.5"
        />
        <path
          d="M71.7228 0H75.5555L75.0742 3.45873L71.7228 0Z"
          fill="#5B5B74"
          //   fill-opacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_951_12932">
          <rect width="75.5556" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MaxyfiLogo;
