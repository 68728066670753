import React from "react";

const RequestingTimeSvg = () => {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0438 4.06138C11.5376 3.85349 11.4985 3.76416 11.4985 3.66061C11.4985 3.59448 11.5313 3.44072 11.8354 3.44072C12.1135 3.44072 12.4601 3.59686 12.7045 3.75776L13.0446 2.86645C12.8018 2.7119 12.5068 2.57912 12.2309 2.53666V1.95703H11.3243V2.59306C10.7857 2.76282 10.4538 3.19339 10.4538 3.72936C10.4538 4.43924 11.0217 4.75616 11.5764 4.97219C12.0196 5.1505 12.0568 5.28697 12.0568 5.40093C12.0568 5.57621 11.8952 5.68945 11.6452 5.68945C11.3192 5.68945 10.9402 5.50142 10.6748 5.29567L10.3477 6.20128C10.6182 6.41108 10.9315 6.54955 11.2839 6.60122V7.20182H12.1962V6.54804C12.7516 6.37116 13.1071 5.91101 13.1071 5.36081C13.1071 4.59065 12.5389 4.26185 12.0438 4.06138Z"
        fill="#6A7187"
      />
      <path
        d="M11.7509 9.1587C9.226 9.1587 7.17188 7.1044 7.17188 4.57933C7.17188 2.05431 9.226 0 11.7509 0C14.2758 0 16.3299 2.05431 16.3299 4.57933C16.3299 7.1044 14.2758 9.1587 11.7509 9.1587ZM11.7509 1.27111C9.928 1.27111 8.4451 2.75516 8.4451 4.57933C8.4451 6.4035 9.9281 7.88755 11.7509 7.88755C13.5738 7.88755 15.0568 6.40346 15.0568 4.57933C15.0568 2.7552 13.5737 1.27111 11.7509 1.27111Z"
        fill="#6A7187"
      />
      <path
        d="M16.0452 10.152C15.0038 9.77464 13.8485 9.85854 12.8717 10.3816L11.2793 11.0979C11.1507 10.3645 10.5874 9.81344 9.8922 9.79224C9.8881 9.79214 7.50465 9.76524 7.50465 9.76524C5.59691 9.24194 4.35734 9.91434 3.65021 10.577C3.50559 10.7125 3.3784 10.852 3.26647 10.9897C3.07566 10.7842 2.73884 10.7293 2.49321 10.8643L0.329627 12.0536C0.0490065 12.2079 -0.0730834 12.5463 0.0446066 12.8438L2.15905 18.189C2.29799 18.5402 2.72497 18.6954 3.05814 18.5123L5.22172 17.323C5.42453 17.2115 5.54392 17.0038 5.55048 16.7849H8.7796C9.3063 16.7849 9.8264 16.6465 10.2834 16.3848C10.2834 16.3848 16.3566 12.901 16.3909 12.8699C17.2414 12.0962 17.2676 10.5949 16.0452 10.152C16.5328 10.3287 15.0038 9.77464 16.0452 10.152ZM3.07749 17.0506L1.43452 12.8973L2.47375 12.326L4.11671 16.4793L3.07749 17.0506ZM15.5782 11.8872L9.6498 15.2821C9.3853 15.4337 9.0843 15.5138 8.7795 15.5138H5.10355L3.8693 12.3936C3.97503 12.1782 4.18329 11.8207 4.5215 11.5038C5.21417 10.8547 6.12717 10.6886 7.23531 11.01C7.29072 11.0261 7.34808 11.0346 7.40575 11.0353L9.8558 11.0628C9.9184 11.0682 10.0338 11.2073 10.0338 11.4181C10.0338 11.6352 9.9146 11.7735 9.8522 11.7735H7.44495V13.0447H9.8522C10.1505 13.0447 10.428 12.9438 10.659 12.771L13.4155 11.5311C13.4302 11.5245 13.4445 11.5174 13.4585 11.5097C14.1198 11.1502 14.9043 11.0908 15.6109 11.3468C15.8904 11.4482 15.6885 11.7752 15.5782 11.8872Z"
        fill="#6A7187"
      />
    </svg>
  );
};

export default RequestingTimeSvg;
