import React from "react";
import PageInHold from "../../containers/Payment/PageInHold";
import Payment from "../../containers/Payment/Payment";
import PaymentType from "../../containers/Payment/PaymentType";
import PaymentAmount from "../../containers/PaymentAmount/PaymentAmount";
import PaymentPlan from "../../containers/PaymentPlan/PaymentPlan";
import PlannedAmount from "../../containers/PlannedAmount/PlannedAmount";
import Layout from "../Layout/Layout";

const PaymentView = () => {
  return (
      <PageInHold />
  );
};

export default PaymentView;
