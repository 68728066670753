import { useSelector } from "react-redux";

const PromiseToPaySvg = () => {
  let { default_color } = useSelector((s) => s.layoutReduceData);
  return (
    <>
      <svg
        width="139"
        height="158"
        viewBox="0 0 139 158"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M78.1769 29.9947V35.3848C78.1769 36.1665 77.5186 36.8249 76.7368 36.8249H63.529C62.7472 36.8249 62.0889 36.1665 62.0889 35.3848V15.8405C62.0889 15.0587 62.7472 14.4004 63.529 14.4004H76.7368C77.5186 14.4004 78.1769 15.0587 78.1769 15.8405V24.3577"
          fill="white"
        />
        <path
          d="M76.7369 37.0724H63.5291C62.6239 37.0724 61.8833 36.3318 61.8833 35.4266V15.8411C61.8833 14.9359 62.6239 14.1953 63.5291 14.1953H76.7369C77.6422 14.1953 78.3828 14.9359 78.3828 15.8411V24.3583H77.9302V15.8411C77.9302 15.1828 77.3953 14.6068 76.6958 14.6068H63.488C62.8297 14.6068 62.2536 15.1417 62.2536 15.8411V35.3854C62.2536 36.0438 62.7885 36.6198 63.488 36.6198H76.6958C77.3541 36.6198 77.9302 36.0849 77.9302 35.3854V29.9953H78.3828V35.3854C78.3828 36.3318 77.6422 37.0724 76.7369 37.0724Z"
          fill={`${default_color}`}
        />
        <path
          d="M69.1251 34.151C69.4131 33.9865 69.66 33.8219 69.948 33.6984C70.0714 33.6161 70.236 33.575 70.3595 33.5339C70.524 33.4927 70.6886 33.4104 70.8532 33.4104C70.8121 33.3693 70.7298 33.3281 70.6886 33.3281C70.6475 33.287 70.6475 33.4104 70.6475 33.4104C70.6475 33.4516 70.6063 33.4927 70.6063 33.575C70.5652 33.6984 70.524 33.7807 70.4829 33.9042C70.4418 34.0688 70.5652 34.2333 70.7709 34.2333C71.2235 34.2333 71.5938 33.9453 71.9642 33.7396C71.923 33.7396 71.8819 33.7396 71.7996 33.7396C71.8407 33.7396 71.8819 33.7807 71.923 33.8219C72.0053 33.863 72.0464 33.863 72.1287 33.863C72.2933 33.863 72.4579 33.8219 72.6225 33.863C72.8694 33.9042 72.8694 33.4927 72.6225 33.4927C72.4579 33.4927 72.2933 33.5339 72.1287 33.4927C71.9642 33.4516 71.923 33.3281 71.7584 33.4104C71.5938 33.4927 71.4293 33.575 71.2647 33.6573C71.1824 33.6984 71.1001 33.6984 71.0178 33.7396C70.9767 33.7396 70.9355 33.7807 70.8532 33.7807C70.8121 33.7807 70.7298 33.7807 70.7709 33.863C70.7709 33.9042 70.8121 33.9453 70.8121 33.9865C70.8121 33.7396 71.1001 33.4927 71.0178 33.2047C70.9355 32.9167 70.6063 33.0401 70.4006 33.0813C69.8657 33.2458 69.3308 33.4927 68.8371 33.7807C68.7136 33.9453 68.9194 34.2745 69.1251 34.151Z"
          fill="#DEE0E1"
        />
        <path
          d="M76.6134 17.3223H63.6113V17.7749H76.6134V17.3223Z"
          fill="#DEE0E1"
        />
        <path
          d="M75.1733 20.3672H63.6113V20.8198H75.1733V20.3672Z"
          fill="#DEE0E1"
        />
        <path
          d="M76.6134 23.4531H63.6113V23.9057H76.6134V23.4531Z"
          fill="#DEE0E1"
        />
        <path
          d="M67.191 26.5391H63.6113V26.9917H67.191V26.5391Z"
          fill="#DEE0E1"
        />
        <path
          d="M73.4453 26.5391H68.6724V26.9917H73.4453V26.5391Z"
          fill="#DEE0E1"
        />
        <path
          d="M76.6134 29.584H63.6113V30.0366H76.6134V29.584Z"
          fill="#DEE0E1"
        />
        <path
          d="M65.3809 35.0557C64.27 35.0557 63.3647 34.1505 63.3647 33.0396C63.3647 31.9286 64.27 31.0234 65.3809 31.0234C66.4918 31.0234 67.397 31.9286 67.397 33.0396C67.397 34.1505 66.4918 35.0557 65.3809 35.0557ZM65.3809 31.476C64.5168 31.476 63.8174 32.1755 63.8174 33.0396C63.8174 33.9036 64.5168 34.6031 65.3809 34.6031C66.245 34.6031 66.9444 33.9036 66.9444 33.0396C66.9444 32.1755 66.245 31.476 65.3809 31.476Z"
          fill={`${default_color}`}
        />
        <path
          d="M66.574 33.0819C66.574 33.7402 66.0391 34.2751 65.3807 34.2751C64.7224 34.2751 64.1875 33.7402 64.1875 33.0819C64.1875 32.4236 64.7224 31.8887 65.3807 31.8887C66.0391 31.8887 66.574 32.4236 66.574 33.0819Z"
          fill={`${default_color}`}
        />
        <path
          d="M73.2808 32.7107C73.3631 32.0935 73.5688 31.5997 73.8568 31.1471C74.1448 30.6945 74.5151 30.3242 74.8443 29.8716C75.5026 29.0487 76.2021 28.2258 76.9016 27.4029C78.3005 25.7982 79.6995 24.1935 81.2631 22.6711C81.3453 22.5888 81.4688 22.5888 81.5511 22.6711C81.6334 22.7534 81.6334 22.8357 81.5922 22.918C80.4813 24.7695 79.2469 26.4977 77.9714 28.2258C77.3542 29.0898 76.6959 29.9539 76.0375 30.7768C75.7084 31.1883 75.4203 31.6409 75.05 32.0112C74.6797 32.3815 74.2271 32.7107 73.6511 32.9576C73.5276 32.9987 73.363 32.9164 73.3219 32.793C73.2808 32.793 73.2808 32.7518 73.2808 32.7107Z"
          fill={`${default_color}`}
        />
        <path
          d="M74.3499 47.292L71.6519 49.6445L71.9493 49.9857L74.6473 47.6331L74.3499 47.292Z"
          fill={`${default_color}`}
        />
        <path
          d="M71.1072 43.6412L69.9644 48.7402L70.406 48.8392L71.5488 43.7402L71.1072 43.6412Z"
          fill={`${default_color}`}
        />
        <path
          d="M67.1287 47.5187L66.7983 47.8281L68.2891 49.4198L68.6194 49.1104L67.1287 47.5187Z"
          fill={`${default_color}`}
        />
        <path
          d="M98.7913 144.957H95.2939L94.8413 136.852H98.3387L98.7913 144.957Z"
          fill="#813D3D"
        />
        <path
          d="M116.443 144.957H112.945L111.546 136.852H115.044L116.443 144.957Z"
          fill="#813D3D"
        />
        <path
          d="M112.699 144.547H116.649C116.772 144.547 116.895 144.629 116.937 144.794L117.472 147.921C117.513 148.25 117.266 148.538 116.937 148.538C115.579 148.497 114.92 148.415 113.192 148.415C112.123 148.415 109.242 148.538 107.802 148.538C106.362 148.538 106.198 147.098 106.815 146.974C109.53 146.398 111.258 145.576 112.164 144.794C112.287 144.629 112.493 144.547 112.699 144.547Z"
          fill="#263238"
        />
        <path
          d="M95.1707 144.547H98.9973C99.1207 144.547 99.2442 144.629 99.2853 144.794L99.8202 147.921C99.8614 148.25 99.6145 148.538 99.2853 148.538C97.9275 148.497 95.9113 148.415 94.1832 148.415C92.1671 148.415 91.6322 148.538 89.2869 148.538C87.8468 148.538 87.5176 147.098 88.1348 146.974C90.8916 146.357 91.8791 146.316 94.3478 144.835C94.5947 144.67 94.8827 144.547 95.1707 144.547Z"
          fill="#263238"
        />
        <path
          d="M95.0884 35.9615L90.2744 34.5625C90.2744 34.5625 85.4192 46.1245 83.7733 46.9062C81.5103 47.9349 75.5442 51.1031 72.9931 52.5844C72.8285 53.1604 72.8285 54.724 73.0754 55.1766C77.2311 54.8474 85.7483 52.0083 87.3942 50.4448C91.1384 46.8651 95.0884 35.9615 95.0884 35.9615Z"
          fill="#813D3D"
        />
        <path
          d="M96.5284 33.7823C97.8862 36.8271 88.5461 50.076 88.5461 50.076L84.02 45.3854C84.02 45.3854 88.0112 35.3458 90.1919 31.4781C92.4138 27.5281 95.0471 30.4495 96.5284 33.7823Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.4"
          d="M96.5284 33.7823C97.8862 36.8271 88.5461 50.076 88.5461 50.076L84.02 45.3854C84.02 45.3854 88.0112 35.3458 90.1919 31.4781C92.4138 27.5281 95.0471 30.4495 96.5284 33.7823Z"
          fill="white"
        />
        <path
          d="M83.6908 44.9738C83.4028 45.1384 82.7856 46.2493 82.6622 46.7842C82.5388 47.3191 85.707 51.5983 86.9414 51.4337C88.1757 51.2691 89.5747 50.0759 89.8627 49.4175C90.1096 48.7592 84.308 44.6446 83.6908 44.9738Z"
          fill={`${default_color}`}
        />
        <path
          d="M73.5278 52.2966L70.6064 51.7617L70.771 55.8763C70.771 55.8763 71.7997 56.2878 73.9804 54.9299L73.5278 52.2966Z"
          fill="#813D3D"
        />
        <path
          d="M67.1914 53.1605L67.6852 55.6704L70.8122 55.9173L70.6477 51.8027L67.1914 53.1605Z"
          fill="#813D3D"
        />
        <path
          opacity="0.2"
          d="M94.8413 136.852L95.047 141.007H98.5856L98.3387 136.852H94.8413Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M115.044 136.852H111.546L112.246 141.007H115.743L115.044 136.852Z"
          fill="black"
        />
        <path
          d="M92.0848 29.6674C92.0848 29.6674 87.4353 41.6819 90.3978 61.3908C96.0348 61.3908 106.362 61.3908 109.119 61.3908C109.366 58.5106 108.296 43.0398 111.711 29.5028C111.711 29.5028 108.543 28.8445 105.498 28.721C103.112 28.5976 99.6556 28.5153 97.5572 28.721C94.7181 28.9267 92.0848 29.6674 92.0848 29.6674Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.2"
          d="M92.0848 29.6674C92.0848 29.6674 87.4353 41.6819 90.3978 61.3908C96.0348 61.3908 106.362 61.3908 109.119 61.3908C109.366 58.5106 108.296 43.0398 111.711 29.5028C111.711 29.5028 108.543 28.8445 105.498 28.721C103.112 28.5976 99.6556 28.5153 97.5572 28.721C94.7181 28.9267 92.0848 29.6674 92.0848 29.6674Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M107.473 32.794C107.062 35.0159 107.515 40.3648 109.325 46.4956C109.572 41.7638 110.107 36.2503 111.382 30.7779V30.7367C110.148 30.6544 107.844 30.7367 107.473 32.794Z"
          fill="black"
        />
        <path
          d="M104.758 21.2324C104.141 23.7835 103.729 26.9517 105.457 28.6798C105.457 28.6798 104.305 31.272 99.5322 31.272C95.1707 31.272 97.4749 28.6798 97.4749 28.6798C100.355 27.9803 100.396 24.4829 99.9848 22.4668L104.758 21.2324Z"
          fill="#813D3D"
        />
        <path
          d="M99.5732 31.2708C102.371 32.1349 102.947 33.0401 102.947 33.0401C102.947 33.0401 106.445 31.6411 106.321 28.5552C106.321 28.5552 105.704 28.0615 104.963 27.7734C104.922 27.7734 104.634 30.0365 99.5732 31.2708Z"
          fill={`${default_color}`}
        />
        <path
          d="M99.5732 31.2715C98.0097 31.4361 96.693 32.7527 96.693 32.7527C96.693 32.7527 95.7055 31.7241 96.6519 28.6793C96.6519 28.6793 97.5983 28.3501 98.38 28.1855C98.3389 28.1855 97.7217 30.6132 99.5732 31.2715Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.2"
          d="M100.026 22.4669C100.272 23.619 100.355 25.3471 99.8198 26.6638C100.231 26.5815 100.684 26.4581 101.095 26.2523C102.741 25.5117 103.605 24.3185 104.51 22.5081C104.593 22.0555 104.675 21.6029 104.798 21.1914L100.026 22.4669Z"
          fill="black"
        />
        <path
          d="M99.4499 13.0443C98.339 13.4146 95.8291 15.5542 98.2156 16.6239C100.561 17.7349 101.384 12.4271 99.4499 13.0443Z"
          fill="#263238"
        />
        <path
          d="M107.021 18.1058C105.951 21.4798 105.457 23.4548 103.318 24.8538C100.108 26.9111 96.3639 24.4834 96.4462 20.9038C96.5284 17.6944 98.2977 12.7569 101.919 12.0985C105.498 11.4814 108.09 14.773 107.021 18.1058Z"
          fill="#813D3D"
        />
        <path
          d="M101.754 14.4429C99.9026 14.7721 98.339 14.6486 97.8041 13.1262C97.2281 11.6038 99.7791 9.95802 101.548 10.6986C102.865 9.34083 106.362 10.3695 106.856 11.6861C108.914 11.5627 110.847 13.908 109.448 15.1424C110.518 15.7596 111.259 17.6523 109.86 18.969C110.477 20.7382 108.296 22.5486 106.568 21.808C104.881 21.3143 103.976 21.7257 103.729 20.6148C103.523 19.5861 104.099 18.4752 105.498 18.2695C104.758 17.4054 104.593 16.0476 105.087 15.4716C103.4 16.2533 101.96 15.2658 101.754 14.4429Z"
          fill="#263238"
        />
        <path
          d="M99.5321 21.6854C100.643 21.7677 101.342 21.1505 101.342 21.1094C101.384 21.0682 101.384 21.0271 101.342 20.9859C101.301 20.9448 101.26 20.9448 101.219 20.9859C101.219 20.9859 100.437 21.7266 99.2029 21.4797C99.1618 21.4797 99.1206 21.4797 99.1206 21.562C99.1206 21.6031 99.1206 21.6443 99.2029 21.6443C99.3263 21.6443 99.4086 21.6443 99.5321 21.6854Z"
          fill="#263238"
        />
        <path
          d="M107.185 22.7539C106.444 23.3299 105.498 23.5356 104.716 23.4534C103.564 23.3299 103.482 22.1778 104.223 21.3549C104.881 20.5731 106.239 19.7091 107.226 20.2851C108.173 20.82 108.049 22.0544 107.185 22.7539Z"
          fill="#813D3D"
        />
        <path
          d="M102.7 61.3497C102.7 61.3497 102.206 65.7523 101.63 71.7185C100.931 78.4664 100.067 87.2716 99.5318 93.9372C99.5318 93.9784 99.5318 94.0195 99.5318 94.0195C99.4084 95.2951 99.3261 96.4883 99.2438 97.5581C98.462 108.462 99.861 140.185 99.861 140.185H93.936C93.936 140.185 88.4636 107.803 88.587 96.694C88.7105 86.0372 90.3974 61.3086 90.3974 61.3086H102.7V61.3497Z"
          fill={`${default_color}`}
        />
        <path
          d="M100.314 137.88C100.355 137.88 100.191 140.307 100.191 140.307H93.3604L93.1958 137.633L100.314 137.88Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M101.589 71.7186C100.89 78.4665 100.026 87.2717 99.4907 93.9374C99.4907 93.9785 99.4907 94.0197 99.4907 94.0197C98.1741 87.6832 97.7626 79.7832 98.2975 66.8223L101.589 71.7186Z"
          fill="black"
        />
        <path
          d="M97.022 61.3496C97.022 61.3496 99.7376 86.6543 101.466 96.6527C103.317 107.639 110.682 140.226 110.682 140.226H117.101C117.101 140.226 112.781 108.544 111.917 97.6814C111.012 85.8725 109.119 61.3908 109.119 61.3908H97.022V61.3496Z"
          fill={`${default_color}`}
        />
        <path
          d="M117.184 138.046C117.225 138.046 117.39 140.35 117.39 140.35H110.354L109.86 137.799L117.184 138.046Z"
          fill="#263238"
        />
        <path
          d="M101.178 17.9397C101.096 18.2277 100.89 18.3923 100.725 18.3512C100.561 18.31 100.479 18.0632 100.52 17.7751C100.602 17.4871 100.808 17.3225 100.972 17.3637C101.178 17.4048 101.26 17.6928 101.178 17.9397Z"
          fill="#263238"
        />
        <path
          d="M98.0922 17.2815C98.0099 17.5695 97.8041 17.7341 97.6396 17.693C97.475 17.6518 97.3927 17.405 97.4338 17.1169C97.5161 16.8289 97.7218 16.6643 97.8864 16.7055C98.0922 16.7466 98.1744 16.9935 98.0922 17.2815Z"
          fill="#263238"
        />
        <path
          d="M99.532 17.6523C99.532 17.6523 98.5034 19.1336 97.5981 19.7508C97.9685 20.3268 98.8325 20.2857 98.8325 20.2857L99.532 17.6523Z"
          fill="#630F0F"
        />
        <path
          d="M102.865 17.1593C102.824 17.1593 102.783 17.1181 102.742 17.077C102.454 16.4186 101.878 16.2952 101.878 16.2952C101.795 16.2952 101.713 16.2129 101.754 16.0895C101.754 16.0072 101.878 15.9249 101.96 15.966C102.001 15.966 102.742 16.0895 103.071 16.9124C103.112 16.9947 103.071 17.077 102.988 17.1181C102.947 17.1593 102.906 17.1593 102.865 17.1593Z"
          fill="#263238"
        />
        <path
          d="M97.5161 15.8822C97.4749 15.8822 97.4749 15.8822 97.4338 15.8411C97.3515 15.7999 97.3515 15.6765 97.4338 15.5942C98.0921 14.8947 98.8327 15.0182 98.8739 15.0182C98.9562 15.0182 99.0385 15.1005 98.9973 15.2239C98.9973 15.3062 98.8739 15.3885 98.7916 15.3473C98.7505 15.3473 98.1744 15.265 97.6807 15.7999C97.6395 15.8822 97.5572 15.8822 97.5161 15.8822Z"
          fill="#263238"
        />
        <path
          d="M113.768 34.2344L109.366 36.9089C109.366 36.9089 114.303 47.6068 114.262 49.4172C114.18 52.7911 115.62 60.074 115.25 63.9005C115.784 64.1063 117.307 64.1474 117.801 63.8594C118.665 60.4031 119.899 51.2688 119.529 49.0057C118.747 44.0271 113.768 34.2344 113.768 34.2344Z"
          fill="#813D3D"
        />
        <path
          d="M111.67 29.4616C114.962 29.9553 119.652 46.6605 119.652 46.6605L113.563 48.9647C113.563 48.9647 108.255 39.9126 106.938 35.8392C105.58 31.6012 108.172 28.9678 111.67 29.4616Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.4"
          d="M111.67 29.4616C114.962 29.9553 119.652 46.6605 119.652 46.6605L113.563 48.9647C113.563 48.9647 108.255 39.9126 106.938 35.8392C105.58 31.6012 108.172 28.9678 111.67 29.4616Z"
          fill="white"
        />
        <path
          d="M111.506 145.163C111.259 145.163 111.012 145.122 110.847 144.999C110.724 144.875 110.683 144.752 110.683 144.546C110.683 144.423 110.765 144.341 110.847 144.299C111.259 144.094 112.328 144.793 112.452 144.875C112.493 144.875 112.493 144.917 112.493 144.958C112.493 144.999 112.452 144.999 112.411 145.04C112.205 145.081 111.835 145.163 111.506 145.163ZM111.012 144.423C110.971 144.423 110.929 144.423 110.888 144.464C110.847 144.464 110.847 144.505 110.847 144.546C110.847 144.67 110.847 144.793 110.929 144.834C111.094 144.999 111.588 144.999 112.164 144.917C111.794 144.67 111.3 144.423 111.012 144.423Z"
          fill={`${default_color}`}
        />
        <path
          d="M112.411 145.04C111.999 144.834 111.341 144.053 111.464 143.682C111.464 143.6 111.547 143.477 111.752 143.477C111.876 143.477 111.999 143.477 112.082 143.6C112.452 143.929 112.493 144.958 112.493 144.999C112.493 145.04 112.493 145.04 112.452 145.081C112.452 145.04 112.411 145.04 112.411 145.04ZM111.794 143.6C111.752 143.6 111.752 143.6 111.794 143.6C111.629 143.6 111.629 143.682 111.629 143.682C111.588 143.929 111.999 144.505 112.328 144.752C112.287 144.464 112.205 143.888 111.958 143.641C111.917 143.641 111.835 143.6 111.794 143.6Z"
          fill={`${default_color}`}
        />
        <path
          d="M93.6893 145.164C93.3602 145.164 93.031 145.123 92.8664 144.958C92.743 144.876 92.7018 144.711 92.743 144.547C92.743 144.465 92.8253 144.382 92.9076 144.341C93.3602 144.094 94.718 144.835 94.8826 144.917C94.9237 144.917 94.9237 144.958 94.9237 144.999C94.9237 145.041 94.8826 145.082 94.8414 145.082C94.5122 145.082 94.1008 145.164 93.6893 145.164ZM93.1133 144.423C93.031 144.423 92.9898 144.423 92.9487 144.465C92.9076 144.465 92.9076 144.506 92.9076 144.547C92.9076 144.67 92.9076 144.753 92.9898 144.794C93.1956 144.999 93.7716 145 94.5534 144.876C94.0596 144.67 93.4425 144.423 93.1133 144.423Z"
          fill={`${default_color}`}
        />
        <path
          d="M94.8002 145.039C94.759 145.039 94.759 145.039 94.8002 145.039C94.3476 144.834 93.5658 144.093 93.6481 143.682C93.6481 143.599 93.7304 143.476 93.9773 143.435C94.1418 143.394 94.3064 143.476 94.4299 143.558C94.8413 143.929 94.9236 144.875 94.9236 144.916C94.9236 144.957 94.9236 144.957 94.8825 144.998C94.8413 145.039 94.8002 145.039 94.8002 145.039ZM94.0184 143.599H93.9773C93.8127 143.599 93.8127 143.682 93.8127 143.682C93.7715 143.929 94.3064 144.505 94.7179 144.793C94.6767 144.505 94.5945 143.97 94.3064 143.723C94.183 143.64 94.1007 143.599 94.0184 143.599Z"
          fill={`${default_color}`}
        />
        <path
          d="M128.005 84.3092L106.979 87.0659C106.074 87.1894 105.21 86.531 105.087 85.6258L103.276 71.8831C103.153 70.9779 103.811 70.1139 104.716 69.9904L125.742 67.2336C126.647 67.1102 127.511 67.7685 127.634 68.6737L129.445 82.4165C129.568 83.3628 128.91 84.1857 128.005 84.3092Z"
          fill="#263238"
        />
        <path
          opacity="0.3"
          d="M128.005 84.3092L106.979 87.0659C106.074 87.1894 105.21 86.531 105.087 85.6258L103.276 71.8831C103.153 70.9779 103.811 70.1139 104.716 69.9904L125.742 67.2336C126.647 67.1102 127.511 67.7685 127.634 68.6737L129.445 82.4165C129.568 83.3628 128.91 84.1857 128.005 84.3092Z"
          fill="black"
        />
        <path
          d="M114.262 71.8006C113.892 71.8006 113.563 71.6771 113.275 71.4714C112.946 71.2245 112.699 70.8542 112.657 70.4016L112.287 67.5625C112.164 66.6985 112.781 65.8756 113.686 65.7521L118.459 65.1349C118.871 65.0938 119.282 65.1761 119.652 65.4641C119.981 65.711 120.228 66.0813 120.269 66.5339L120.64 69.373C120.763 70.237 120.146 71.0599 119.241 71.1834L114.468 71.8006C114.386 71.8006 114.344 71.8006 114.262 71.8006ZM118.624 66.0813C118.582 66.0813 118.583 66.0813 118.541 66.0813L113.768 66.6985C113.398 66.7396 113.151 67.0688 113.192 67.4391L113.563 70.2782C113.604 70.4427 113.686 70.6073 113.81 70.7308C113.933 70.8542 114.139 70.8953 114.303 70.8542L119.076 70.237C119.447 70.1959 119.693 69.8667 119.652 69.4964L119.323 66.6573C119.282 66.4927 119.2 66.3282 119.076 66.2047C118.912 66.1224 118.788 66.0813 118.624 66.0813Z"
          fill="#263238"
        />
        <path
          d="M129.98 84.0611L108.954 86.8179C108.049 86.9413 107.185 86.283 107.062 85.3778L105.251 71.6351C105.128 70.7299 105.786 69.8658 106.691 69.7424L127.717 66.9856C128.622 66.8622 129.486 67.5205 129.61 68.4257L131.42 82.1684C131.585 83.0736 130.926 83.9377 129.98 84.0611Z"
          fill="#263238"
        />
        <path
          d="M115.414 63.3246L114.057 65.9579L118.007 66.1225C118.007 66.1225 119.035 64.3121 117.513 62.584L115.414 63.3246Z"
          fill="#813D3D"
        />
        <path
          d="M115.579 67.9322L117.924 67.603L118.007 66.0806L114.057 65.916L114.098 66.7801C114.18 67.5207 114.838 68.0556 115.579 67.9322Z"
          fill="#813D3D"
        />
        <path
          d="M112.658 48.0183C112.616 48.3474 113.028 49.5407 113.357 49.9933C113.686 50.4459 118.994 50.8573 119.652 49.8287C120.352 48.8001 120.352 46.9073 120.023 46.2902C119.735 45.673 112.781 47.3188 112.658 48.0183Z"
          fill={`${default_color}`}
        />
        <path
          d="M35.1386 35.182C34.4803 36.0461 33.7396 36.9924 33.0813 37.9388C32.423 38.8852 31.7646 39.8315 31.1474 40.7779C29.9131 42.6706 28.761 44.6456 27.9381 46.6206C27.8558 46.8674 27.7323 47.1143 27.6501 47.3612C27.6089 47.4846 27.5678 47.6081 27.5266 47.7315L27.4855 47.8961L27.4443 47.9784V48.0195C27.4032 48.143 27.362 48.5544 27.4032 48.9659C27.4855 49.8711 27.7735 50.9409 28.1438 52.0107C28.8433 54.1503 29.7896 56.331 30.736 58.4706L28.6376 59.7872C27.074 57.8122 25.7162 55.7961 24.5641 53.4919C23.9881 52.3398 23.4943 51.1466 23.1652 49.6654C23.0006 48.9247 22.8771 48.1018 23.0829 47.0732L23.124 46.8674L23.1652 46.744L23.2063 46.4971C23.2475 46.3326 23.2886 46.168 23.3297 46.0034C23.412 45.6742 23.5355 45.3451 23.6178 45.0159C24.4818 42.4648 25.6751 40.243 26.9506 38.1034C27.6089 37.0336 28.2672 36.0049 29.0079 34.9763C29.7485 33.9477 30.448 33.0013 31.2709 31.9727L35.1386 35.182Z"
          fill="#263238"
        />
        <path
          d="M30.1189 57.6875L32.2996 58.8396L29.7897 61.7609C29.7897 61.7609 28.2262 60.6911 28.1851 59.0865L30.1189 57.6875Z"
          fill="#FFC3BD"
        />
        <path
          d="M35.2208 60.6503L32.5463 62.7487L29.7896 61.7612L32.2994 58.8398L35.2208 60.6503Z"
          fill="#FFC3BD"
        />
        <path
          d="M67.5616 51.4336L70.1127 52.7503L69.9069 53.6555L67.8496 55.7128C67.8496 55.7128 66.3684 54.5607 66.4095 52.956L67.5616 51.4336Z"
          fill="#FFC3BD"
        />
        <path
          d="M67.9321 51.8042L70.2774 51.3516L71.7587 52.0099L70.7712 52.1745L70.1129 52.7505L67.9321 51.8042Z"
          fill="#FFC3BD"
        />
        <path
          d="M72.6638 56.0419L70.1128 56.5768C69.8659 56.618 69.5779 56.618 69.331 56.4945L67.8086 55.7539L69.8659 53.6966L71.2237 53.2852L72.9107 55.219C73.1987 55.507 73.0341 55.9596 72.6638 56.0419Z"
          fill="#FFC3BD"
        />
        <path
          d="M44.5198 18.4764C44.561 18.7645 44.7667 18.929 44.9313 18.929C45.0959 18.8879 45.2193 18.641 45.137 18.353C45.0547 18.065 44.8902 17.9004 44.7256 17.9004C44.561 17.9415 44.4376 18.2296 44.5198 18.4764Z"
          fill="#263238"
        />
        <path
          d="M44.7256 18.9707C44.7256 18.9707 45.6719 20.452 46.536 21.0691C46.1245 21.604 45.2605 21.604 45.2605 21.604L44.7256 18.9707Z"
          fill="#FF5652"
        />
        <path
          d="M43.2032 17.1602C43.1621 17.1602 43.1209 17.1602 43.0798 17.1191C42.9975 17.0368 42.9975 16.9545 43.0798 16.8722C43.697 16.2139 44.4376 16.3373 44.4787 16.3373C44.561 16.3373 44.6433 16.4196 44.6022 16.543C44.6022 16.6253 44.5199 16.7076 44.3965 16.6665C44.3553 16.6665 43.7793 16.5842 43.2855 17.1191C43.2855 17.1602 43.2444 17.1602 43.2032 17.1602Z"
          fill="#263238"
        />
        <path
          d="M37.0313 23.373C37.5251 25.8007 37.8131 29.1746 36.085 30.7793C36.085 30.7793 36.7433 33.248 41.2693 33.248C46.248 33.248 43.6558 31.0673 43.6558 31.0673C41.5985 30.409 41.5985 28.0637 42.0923 26.2121L37.0313 23.373Z"
          fill="#FFC3BD"
        />
        <path
          d="M35.3854 32.424C34.9739 31.7656 35.0974 29.7906 35.5911 29.4615C36.3729 28.9266 39.6234 29.0089 43.9437 30.2844C43.3677 32.1771 44.314 33.2469 44.314 33.2469L35.3854 32.424Z"
          fill={`${default_color}`}
        />
        <path
          opacity="0.2"
          d="M31.7647 35.9219C30.2423 37.4443 29.7897 40.5714 29.9543 42.6698C30.3246 42.0115 30.736 41.3943 31.1475 40.7359C31.7647 39.7896 32.423 38.8432 33.0814 37.8969C33.2871 37.65 33.4517 37.362 33.6574 37.1151C33.6985 36.9505 33.7397 36.7859 33.7808 36.6214L31.7647 35.9219Z"
          fill="black"
        />
        <path
          d="M20.8201 144.423C21.1493 144.505 21.4784 144.505 21.643 144.382C21.7253 144.341 21.7665 144.217 21.7253 144.012C21.6842 143.888 21.643 143.847 21.5196 143.765C21.067 143.559 20.0383 143.97 19.9972 143.97C19.9561 143.97 19.9561 144.012 19.9561 144.053C19.9561 144.094 19.9561 144.094 19.9972 144.135C20.1618 144.217 20.4909 144.341 20.8201 144.423ZM21.3962 143.888C21.4373 143.888 21.4785 143.888 21.4785 143.929C21.5607 143.97 21.5607 144.012 21.6019 144.053C21.643 144.176 21.6019 144.258 21.5607 144.258C21.3962 144.423 20.7378 144.258 20.2441 144.094C20.5732 143.97 21.067 143.806 21.3962 143.888Z"
          fill={`${default_color}`}
        />
        <path
          d="M20.0384 144.135C20.4498 144.011 21.2728 143.435 21.2728 143.065C21.2728 142.983 21.2316 142.859 21.067 142.818C20.9436 142.777 20.8202 142.777 20.7379 142.859C20.2853 143.106 19.9972 144.011 19.9972 144.052C19.9561 144.052 19.9561 144.094 20.0384 144.135C19.9972 144.135 19.9972 144.135 20.0384 144.135ZM20.9847 142.983C21.1082 143.024 21.1082 143.065 21.1082 143.106C21.1082 143.312 20.5733 143.764 20.203 143.97C20.2853 143.723 20.5321 143.188 20.8202 143.024C20.8613 142.983 20.9025 142.941 20.9847 142.983Z"
          fill={`${default_color}`}
        />
        <path
          d="M45.3427 144.958C45.7542 144.958 46.1657 144.917 46.3302 144.712C46.3714 144.629 46.4125 144.547 46.3302 144.382C46.2891 144.3 46.2068 144.218 46.0834 144.218C45.5485 144.053 44.3552 144.712 44.3141 144.753C44.2729 144.753 44.2729 144.794 44.2729 144.835C44.2729 144.876 44.3141 144.876 44.3552 144.917C44.5198 144.917 44.9313 144.958 45.3427 144.958ZM45.8776 144.341C45.9188 144.341 46.0011 144.341 46.0422 144.341C46.1245 144.341 46.1657 144.382 46.2068 144.465C46.248 144.547 46.248 144.629 46.2068 144.629C46.0422 144.835 45.2193 144.835 44.6021 144.753C44.8901 144.588 45.4662 144.341 45.8776 144.341Z"
          fill={`${default_color}`}
        />
        <path
          d="M44.3554 144.876C44.808 144.67 45.5897 143.971 45.5075 143.6C45.5075 143.518 45.4252 143.395 45.2194 143.395C45.0549 143.395 44.9314 143.436 44.7668 143.518C44.3554 143.847 44.2731 144.752 44.2319 144.793C44.2319 144.835 44.2319 144.835 44.2731 144.876C44.3142 144.876 44.3142 144.876 44.3554 144.876ZM45.1371 143.518H45.1783C45.3017 143.518 45.3429 143.6 45.3429 143.6C45.384 143.806 44.8491 144.382 44.4377 144.629C44.4788 144.382 44.6022 143.847 44.8903 143.6C44.9726 143.559 45.0548 143.518 45.1371 143.518Z"
          fill={`${default_color}`}
        />
        <path
          d="M40.6934 144.794H44.0262L44.3553 137.1H41.0225L40.6934 144.794Z"
          fill="#FFC3BD"
        />
        <path
          d="M16.376 143.394L19.5854 144.053L22.5067 136.688L19.2973 136.029L16.376 143.394Z"
          fill="#FFC3BD"
        />
        <path
          d="M19.8736 143.642L16.2116 142.86C16.0882 142.819 15.9647 142.901 15.8824 143.025L14.6069 145.782C14.4835 146.07 14.648 146.399 14.9772 146.481C16.2527 146.728 16.8699 146.81 18.4746 147.139C19.4621 147.345 21.684 147.88 23.0418 148.209C24.3585 148.497 24.8522 147.181 24.3585 146.934C21.972 145.864 20.9845 144.876 20.3262 144.012C20.2439 143.807 20.0793 143.683 19.8736 143.642Z"
          fill="#263238"
        />
        <path
          d="M43.985 144.424H40.3642C40.2407 144.424 40.1173 144.506 40.0761 144.671L39.4178 147.633C39.3355 147.921 39.5824 148.209 39.9116 148.209C41.2282 148.168 43.1209 148.127 44.7668 148.127C46.7006 148.127 47.3589 148.25 49.622 148.25C50.9798 148.25 51.3501 146.893 50.7741 146.728C48.1819 146.152 47.0298 146.111 44.8079 144.712C44.5199 144.506 44.273 144.424 43.985 144.424Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M44.3553 137.1L44.1496 141.091H40.8579L41.0225 137.1H44.3553Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M19.2973 136.029L22.5067 136.688L20.9843 140.514L17.7749 139.815L19.2973 136.029Z"
          fill="black"
        />
        <path
          d="M43.08 60.4043C43.08 60.4043 39.2946 85.7501 37.2373 95.2548C34.9743 105.665 22.8363 140.063 22.8363 140.063L16.8701 138.787C16.8701 138.787 25.3462 110.726 27.28 96.2012C28.7201 85.0095 31.6003 60.4043 31.6003 60.4043H43.08Z"
          fill="#263238"
        />
        <path
          opacity="0.1"
          d="M43.08 60.4043C43.08 60.4043 39.2946 85.7501 37.2373 95.2548C34.9743 105.665 22.8363 140.063 22.8363 140.063L16.8701 138.787C16.8701 138.787 25.3462 110.726 27.28 96.2012C28.7201 85.0095 31.6003 60.4043 31.6003 60.4043H43.08Z"
          fill="white"
        />
        <path
          d="M24.2351 138.086C24.2762 138.086 23.1241 140.226 23.1241 140.226L16.4585 138.786L17.0345 136.77L24.2351 138.086Z"
          fill="#263238"
        />
        <path
          opacity="0.3"
          d="M40.6935 69.4139C36.332 70.278 36.7846 86.4894 37.3195 94.842C38.595 88.9582 40.4054 77.5608 41.681 69.4962C41.3929 69.3728 41.0638 69.3316 40.6935 69.4139Z"
          fill="black"
        />
        <path
          d="M49.3754 60.4044C49.3754 60.4044 49.828 85.2154 49.4576 95.3372C49.0873 105.871 45.4254 140.31 45.4254 140.31H39.7884C39.7884 140.31 39.5826 106.447 39.3358 96.119C39.0478 84.8451 37.6899 60.3633 37.6899 60.3633H49.3754V60.4044Z"
          fill="#263238"
        />
        <path
          opacity="0.1"
          d="M49.3754 60.4044C49.3754 60.4044 49.828 85.2154 49.4576 95.3372C49.0873 105.871 45.4254 140.31 45.4254 140.31H39.7884C39.7884 140.31 39.5826 106.447 39.3358 96.119C39.0478 84.8451 37.6899 60.3633 37.6899 60.3633H49.3754V60.4044Z"
          fill="white"
        />
        <path
          d="M46.4949 138.088C46.536 138.088 46.1657 140.433 46.1657 140.433H39.3355L39.1709 138.335L46.4949 138.088Z"
          fill="#263238"
        />
        <path
          d="M49.8277 34.8518C50.486 38.4315 51.7615 48.4711 49.7865 63.0367L30.5714 62.7487C30.448 60.1976 32.4641 46.3726 30.6126 34.1112C30.4068 32.6299 31.4355 31.2721 32.8756 31.1075C33.8631 30.9841 34.974 30.8606 36.085 30.8195C38.348 30.6961 41.6396 30.6549 43.6146 30.8195C44.4376 30.9018 45.2605 31.0252 46.0011 31.1487C47.935 31.4367 49.4573 32.9591 49.8277 34.8518Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M50.3213 38.4316L47.0708 39.2546C46.8239 42.2582 48.7989 45.7145 50.815 48.3066C50.8562 44.1921 50.6093 40.9004 50.3213 38.4316Z"
          fill="black"
        />
        <path
          d="M49.0048 33.248C49.5397 34.2355 50.1569 35.3053 50.7329 36.2928C51.3501 37.3215 51.9673 38.309 52.5845 39.2965C53.86 41.2715 55.2178 43.1642 56.6991 44.7689C56.9048 44.9746 57.0694 45.1392 57.2751 45.3449L57.3985 45.4684L57.522 45.5506C57.6043 45.6329 57.6866 45.6741 57.7688 45.7564C58.1392 46.0444 58.5918 46.3736 59.0855 46.6616C60.0319 47.2376 61.1017 47.8548 62.1303 48.3897C64.2287 49.5418 66.4095 50.6527 68.5902 51.7637L67.7261 54.109C65.2985 53.5329 62.9121 52.7923 60.5668 51.9283C59.3735 51.4756 58.2215 51.023 57.0282 50.447C56.4522 50.159 55.835 49.8298 55.2178 49.4184C55.0532 49.2949 54.8886 49.2126 54.7241 49.0892L54.4772 48.8835L54.2714 48.7189C53.9834 48.5131 53.7366 48.2663 53.4897 48.0194C52.5022 47.1142 51.597 46.1267 50.7741 45.098C49.9511 44.1105 49.1694 43.0819 48.4699 42.0121C47.7293 40.9423 47.0709 39.9137 46.4126 38.8027C45.7543 37.6918 45.1371 36.622 44.561 35.4699L49.0048 33.248Z"
          fill="#263238"
        />
        <path
          d="M35.18 19.7927C36.0852 23.1255 36.4144 25.1417 38.5128 26.5406C41.6399 28.6391 45.5076 26.2115 45.6311 22.6729C45.7545 19.4636 44.2732 14.5261 40.6524 13.8677C37.0727 13.2094 34.2748 16.4188 35.18 19.7927Z"
          fill="#FFC3BD"
        />
        <path
          d="M42.1334 19.8338C42.9152 18.6406 43.2444 16.8713 42.6683 16.0072C43.2032 16.213 46.9475 16.501 45.4663 13.7854C43.985 11.1109 41.0225 10.3703 38.4303 11.1109C39.0475 11.2755 39.7058 11.4812 39.7058 11.4812C39.7058 11.4812 36.1673 11.2343 34.4803 12.6333C35.0152 12.6744 35.4678 12.6333 35.4678 12.6333C35.4678 12.6333 31.6824 14.4025 33.2871 15.7604C30.6538 17.7765 32.423 20.5744 36.7845 24.7302C37.5251 25.4296 39.7058 25.1828 40.1584 24.7713C39.9527 22.9609 41.6808 20.4921 42.1334 19.8338Z"
          fill="#263238"
        />
        <path
          d="M42.5861 17.5293C43.3679 18.1053 44.3554 19.7923 44.7669 20.6152C45.2195 21.4382 45.6721 21.3559 45.9189 21.685C46.1247 22.0142 47.071 27.6512 46.0835 28.5564C45.096 29.4616 42.0924 28.0215 41.7221 26.9928C41.3518 25.9642 42.0512 23.4132 42.257 22.8371C42.4627 22.2611 42.4215 20.821 42.0924 19.8335C41.7221 18.846 42.5861 17.5293 42.5861 17.5293Z"
          fill="#263238"
        />
        <path
          d="M40.8166 19.9566C41.0634 20.8207 41.6395 21.6024 42.339 21.9316C43.2442 22.3431 43.3676 21.2733 43.203 20.2858C43.0384 19.3806 42.7916 18.6399 42.0509 18.2696C41.1457 17.8582 40.5285 18.9691 40.8166 19.9566Z"
          fill="#FFC3BD"
        />
      </svg>
    </>
  );
};

export default PromiseToPaySvg;
