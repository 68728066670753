import React from "react";

const SortDown = ({ fill = "#D6D6D6", ...rest }) => {
  return (
    <svg
      {...rest}
      style={{ marginTop: "2px" }}
      width="9"
      height="5"
      viewBox="0 0 9 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.5 4.5L0 0L9 -1.57361e-06L4.5 4.5Z" fill={fill}></path>
    </svg>
  );
};

export default SortDown;
