import React, { useContext } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

import moment from "moment";

function DateStatus(props) {
  let { value, column, alignment, accessor, row, ...rest } = props;

  value = _.get(row.original, accessor, null);

  const [visible, setVisible] = React.useState(false);

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  let timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );

  //   let dateFormat = useSelector(
  //     (e) =>
  //       e && e.layoutReduceData && e.layoutReduceData?.organization.date_format
  //   );

  //   let date = value ? moment.utc(value).tz(timeZone).format(dateFormat) : "-";

  let dayDiff = moment
    .utc(value)
    .tz(timeZone)
    .diff(moment.tz(timeZone), "days", true);
  dayDiff = Math.ceil(dayDiff);


  return (
    <div
      style={{
        color: `${
          dayDiff == 0 ? "#B79C15" : dayDiff > 0 ? "#757575" : "#FD372A"
        }`,
        textAlign: `${alignment}`,
      }}
    >
      {dayDiff}
    </div>
  );
}

export default DateStatus;
