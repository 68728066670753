import lbMessages from "../locales/en_INSE297";

const lbLang = {
  messages: {
    ...lbMessages,
  },
  locale: "en_INSE297",
};

export default lbLang;
