import React from "react";

const RequestingCallSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8C16 10.1369 15.1695 12.1458 13.6614 13.6569C12.1534 15.1678 10.1483 16 8.0156 16C6.37536 16 4.79935 15.5053 3.4579 14.5694C2.56421 13.9458 1.81614 13.1575 1.24756 12.2481V13.9375H0V10.1875H3.74269V11.4375H2.21374C3.41115 13.4796 5.58746 14.75 8.0156 14.75C11.7303 14.75 14.7524 11.722 14.7524 8H16ZM7.9532 1.25C10.3814 1.25 12.5577 2.52044 13.7551 4.5625H12.2573V5.8125H15.9688V2.0625H14.7212V3.75191C14.1527 2.84247 13.4046 2.05412 12.5109 1.43062C11.1695 0.49472 9.5935 0 7.9532 0C5.82172 0 3.82175 0.83263 2.32168 2.34444C0.82455 3.85338 0 5.86188 0 8H1.24756C1.24756 4.27803 4.25569 1.25 7.9532 1.25ZM5.59389 4.40625L4.78743 5.20756C4.42695 5.56869 4.38883 6.07009 4.55339 6.57856C4.83821 7.4588 5.13765 8.1499 6.50941 9.5241C7.8811 10.8982 8.6079 11.1207 9.4619 11.4734C9.6584 11.5546 9.8441 11.5937 10.0182 11.5937C10.3275 11.5937 10.5998 11.47 10.8305 11.2389L11.6335 10.4312L9.5704 8.361L8.5065 9.4032C8.2245 9.1965 7.905 8.9194 7.5088 8.5226C7.1081 8.1211 6.80268 7.7802 6.57204 7.4761L7.6048 6.43278L5.59389 4.40625Z"
        fill="#6A7187"
      />
    </svg>
  );
};

export default RequestingCallSvg;
