import enLang from "../lang/enteries/en-US";
import frLang from "../lang/enteries/fr-FR";
import INSE225Lang from "../lang/enteries/en-INSE225";
import INSE297Lang from "../lang/enteries/en-INSE297";

const AppLocale = {
  // want to wright fallback there was any lang
  en: {
    US: enLang,
    INSE225: INSE225Lang,
    INSE223: INSE225Lang,
    INSE297: INSE297Lang,
  },
  fr: frLang,
};

export default AppLocale;
