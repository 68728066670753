import React from "react";
import { ParagraphMedium, LabelLarge, HeadingXSmall } from "baseui/typography";
import { FormattedDate, FormattedNumber } from "react-intl";
import moment from "moment";
import "moment-timezone";
import { useSelector } from "react-redux";

const CustomerNoteId = ({
  value,
  column,
  cell: {
    row: { original },
  },
  alignment,
  ...rest
}) => {

  let timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );

  let dateFormat = useSelector(
    (e) =>
      e && e.layoutReduceData && e.layoutReduceData?.organization?.date_format
  );

  let issuedDate = moment
    .utc(original && original?.invoice_due_date)
    .tz(timeZone)
    .format(dateFormat ? dateFormat : "");

  return (
    <>
      <div className="table-cell__type-duble-value">
        <ParagraphMedium $style={{ color: "#333860" }}>{value}</ParagraphMedium>
        <ParagraphMedium $style={{ color: "#ADADAD" }}>
          {issuedDate}
        </ParagraphMedium>
      </div>
    </>
  );
};

export default CustomerNoteId;
