import React from "react";

const PaymentsSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.087 12.9941C18.5238 12.6927 17.8437 12.7255 17.3121 13.0799L14.5223 14.9398C14.4331 14.0535 13.6829 13.3594 12.7734 13.3594H9.84375L9.46156 13.0727C8.70043 12.5019 7.7573 12.1875 6.80586 12.1875C6.00383 12.1875 5.21637 12.4056 4.52859 12.8182L4.37496 12.9104V12.3828C4.37496 12.0592 4.11262 11.7969 3.78902 11.7969H0.585938C0.262344 11.7969 0 12.0592 0 12.3828V19.4141C0 19.7377 0.262344 20 0.585938 20H3.78906C4.11266 20 4.375 19.7377 4.375 19.4141V19.2188H13.6038C14.2025 19.2188 14.7792 19.0386 15.2714 18.6978L19.2552 15.9398C19.7216 15.617 20 15.0856 20 14.5184C20 13.8796 19.6502 13.2955 19.087 12.9941ZM3.20312 18.8281H1.17188V12.9688H3.20312V18.8281ZM18.5882 14.9763L14.6043 17.7343C14.309 17.9388 13.963 18.0469 13.6038 18.0469H4.375V14.2771L5.13156 13.8231C5.63723 13.5198 6.21617 13.3594 6.80586 13.3594C7.50535 13.3594 8.19879 13.5905 8.7584 14.0102L9.29688 14.4141C9.39832 14.4901 9.52168 14.5312 9.64844 14.5312H12.7734C13.0965 14.5312 13.3594 14.7941 13.3594 15.1172C13.3594 15.4403 13.0965 15.7031 12.7734 15.7031H8.47656C8.15297 15.7031 7.89062 15.9655 7.89062 16.2891C7.89062 16.6127 8.15297 16.875 8.47656 16.875H13.0816C13.5071 16.875 13.9192 16.7503 14.2734 16.5142L17.9622 14.055C18.2127 13.8879 18.4455 13.98 18.534 14.0273C18.6225 14.0746 18.8282 14.2173 18.8282 14.5184C18.8281 14.7011 18.7384 14.8723 18.5882 14.9763Z"
        fill="#6A7187"
      />
      <path
        d="M8.86719 9.76563C11.2868 9.76563 13.3005 7.99648 13.6839 5.68391C14.1747 6.23398 14.5716 6.865 14.8516 7.54563H14.3359C14.1086 7.54563 13.9018 7.67715 13.8053 7.88305C13.7089 8.08895 13.7403 8.33203 13.8858 8.50668L16.4249 11.5536C16.5362 11.6871 16.7011 11.7644 16.875 11.7644C17.0489 11.7644 17.2138 11.6871 17.3251 11.5536L19.8642 8.50668C20.0097 8.33203 20.0411 8.08895 19.9447 7.88305C19.8483 7.67715 19.6414 7.54563 19.4141 7.54563H18.5612C18.0433 5.50031 16.8758 3.64938 15.2386 2.29356C13.4527 0.814531 11.19 0 8.86719 0C6.1748 0 3.98438 2.19043 3.98438 4.88281C3.98438 7.5752 6.1748 9.76563 8.86719 9.76563ZM12.9216 2.15863C13.4777 2.44594 14.0045 2.79305 14.4912 3.19613C16.047 4.48457 17.1224 6.27848 17.5193 8.24738C17.5744 8.52086 17.8147 8.71754 18.0936 8.71754H18.1631L16.875 10.2632L15.5869 8.7175H15.6697C15.8525 8.7175 16.0248 8.63223 16.1356 8.48695C16.2464 8.34168 16.2831 8.15297 16.2348 7.97672C15.8161 6.44828 14.9133 5.0677 13.6813 4.06395C13.6814 4.06402 13.6811 4.06387 13.6813 4.06395C13.5635 3.36977 13.2986 2.72465 12.9201 2.1625C12.9206 2.16117 12.9211 2.15992 12.9216 2.15863ZM8.86719 1.17188C10.9134 1.17188 12.5781 2.8366 12.5781 4.88281C12.5781 6.92902 10.9134 8.59375 8.86719 8.59375C6.82098 8.59375 5.15625 6.92902 5.15625 4.88281C5.15625 2.8366 6.82098 1.17188 8.86719 1.17188Z"
        fill="#6A7187"
      />
      <path
        d="M8.86719 5.46875C9.08258 5.46875 9.25781 5.64398 9.25781 5.85938C9.25781 6.07477 9.08258 6.25 8.86719 6.25C8.68219 6.25 8.52125 6.11883 8.48453 5.93809C8.42016 5.62098 8.1109 5.41617 7.79367 5.48047C7.47652 5.54488 7.27168 5.85418 7.33605 6.17133C7.44234 6.69461 7.80773 7.11418 8.28121 7.30699V7.42188C8.28121 7.74547 8.54355 8.00781 8.86715 8.00781C9.19074 8.00781 9.45309 7.74547 9.45309 7.42188V7.30746C10.0251 7.07516 10.4296 6.51379 10.4296 5.85938C10.4296 4.99781 9.72871 4.29688 8.86715 4.29688C8.65176 4.29688 8.47652 4.12164 8.47652 3.90625C8.47652 3.69086 8.65176 3.51562 8.86715 3.51562C9.05215 3.51562 9.21309 3.6468 9.24981 3.82754C9.31418 4.14465 9.62367 4.34953 9.94067 4.28516C10.2578 4.22074 10.4627 3.91145 10.3983 3.5943C10.292 3.07102 9.92664 2.65148 9.45313 2.45867V2.34375C9.45313 2.02016 9.19078 1.75781 8.86719 1.75781C8.54359 1.75781 8.28125 2.02016 8.28125 2.34375V2.45816C7.70926 2.69047 7.30469 3.25184 7.30469 3.90625C7.30469 4.76781 8.00563 5.46875 8.86719 5.46875Z"
        fill="#6A7187"
      />
    </svg>
  );
};

export default PaymentsSvg;
