export default {
  invoice_amount: "Amount",
  total: "Total",
  status: "Status",
  invoice_date: "Issued On",
  invoice_due_date: "Due On",
  search_invoices: "Search Invoices",
  pay_amnt_wish: "Pay the  Amount as your wish",
  due: "All Due",
  quick_pay: "Quick Pay",
  total_amount: "Total Amount",
  allocated_amount: "Allocated Amount",
  add_credit_notes: "Add Credit Notes",
  remarks: "Remarks",
  amount: "Amount",
  issue_date: "Issue Date",
  customer: "Customer",
  credit_note_id: "Credit Note Id",
  credit_note: "Credit Note",
  currency: "Cur",
  type: "Types of Notes",
  is_enable_alert_and_follow_up: "",
  domain_name: "Domain Name",
  user_name: "User Name",
  host_name: "Host Name",
  port: "Port",
  password: "Password",
  organisation_name: "Organisation Name",
  follow_up_date: "Followup-Date",
  notes: "User Notes",
  customer_setting: "Customer Setting",
  first_name: "First Name",
  second_name: "Second Name",
  last_name: "Last Name",
  role: "Role",
  phone: "Phone",
  add_contact_details: "   Add Contact Details",
  select: "Please Select",
  email: "Email",
  enter_number: "Enter Your Number",
  enter_email: "Enter Your Email",
  most_used_suggestion: "Most Used & Suggestion",
  address: "Address",
  zipCode: "Zip Code",
  state: "State",
  country: "Country",
  inv_number: "Invoice Number",
  inv_date: "Invoice Date",
  due_date: "Due Date",
  due_days: "Due Days",
  all_actions: "All Actions",
  co_inv_status: "Status",
  co_inv_amount: "Amount",
  co_inv_ccy: "CCY",
  customer_summary: "Customer Summary",
  customer_overview: "Customer Overview",
  adhoc_upload: "Adhoc Data Upload",
  communication_template: "Communication Template",
  workflow_maintenance: "Workflow Maintenance",
  cus_name: "Name",
  display_name: "Name",
  due_inv_count: "#Due",
  due_inv_amount: "Due Amt",
  overdue_inv_count: "#Overdue",
  overdue_inv_amount: "Overdue Amt",
  total_inv_count: "#Total",
  total_inv_amount: "Total Amt",
  last_action_date: "Last Action Date",
  last_action_name: "Last Action Name",
  last_action_comment: "Last Action Comment",
  next_action_date: "Next Action Date",
  sugg_next_action: "Suggested Next Action",
  workflow: "Workflow",
  rel_manager: "Relation Manager",
  max_payment_delay: "Max Payment Delay",
  avg_payment_delay: "Avg Payment Delay",
  last_response_date: "Last Response Date",
  days_after_last_response: "Days After Last Response",
  save_as_new_view: "Save As New View",
  create: "Create",
  default_view: "Default View",
  enable_for_organization_users: "Enable for organization users",
  description: "Description",
  name: "Name",
  required: "Required",
  view_name: "View Name",
  template_type: "Template Type",
  view_description: "View Description",
  template_name: "Template Name",
  template_description: "Template Description",
  template_organization: "Template Organization",
  template_languages: "Template Languages",
  workflow_name: "Workflow Name",
  workflow_description: "Workflow Description",
  workflow_max_contact_in_month: "Max Contact in Month",
  workflow_average_delay: "Avg Delay",
  workflow_enter_a_tag: "Enter a Tag Label",
  action_name: "Action Name",
  action_description: "Action Description",
  action_type: "Type",
  recipient_type: "Choose Recipient Type",
  choose_sms_template: "Choose Sms Template",
  choose_letter_template: "Choose Letter Template",
  choose_email_template: "Choose Email Template",
  sms_content_preview: "SMS Content Preview",
  subject_preview: "Subject Preview",
  email_content_preview: "Email Content Preview",
  instruction_to_user: "Instruction To User",
  postal_service: "Postal Service",
  postal_type: "Postal Type",
  letter_preview: "Letter Content Preview",
  last_modified: "Last Modified",
  most_used: "Most Used",
  all: "All",
  sms: "SMS",
  letter: "Letter",
  email: "Email",
  search_templates: "Search Templates",
  add_template: "Add Template",
  rows_per_page: "Rows per page",
  prev: "Prev",
  next: "Next",
  min: "Min",
  max: "Max",
  search_here: "Searchn Here",
  date_month_year: "MM/DD/YYYY - MM/DD/YYYY",
  select_view: "Select View",
  // cp-action lang
  capture_call_details: "Capture Call Details",
  reciever: "Reciever",
  call_outcome: "Call Outcome",
  promise_to_pay: "Promise to Pay",
  call_back_later: "Call back Later",
  meeting_request: " Meeting Request",
  promise: "Promise",
  callback: "Callback",
  dispute: "Dispute",
  unsuccessful: "Unsuccessful",
  promise_amount: "Promise Amount",
  hold_workflow_till_promise_date: "Hold Workflow till Promise Date",
  send_confirmation_message: "Send Confirmation Message",
  promise_to_pay_confirmation: "Promise to Pay Confirmation",
  add_notes_for_internal_reference_here:
    "Add notes for internal reference here (optional)... ",
  call_back_time: "Call Back Time",
  send_promise_follow_up_messag: "Send Promise Follow up Message",
  recipients: "Recipients",
  sms_content: "SMS Content",
  review_and_send_sms: "Review and Send SMS",
  review_and_send_letter: "Review and Send Letter",
  letter_remainder: "Letter Remainder",
  review_and_send_email: "Review and Send Email",
  cc: "CC",
  bcc: "BCC",
  subject: "Subject",
  select_date: "Select Date",
  send_promise_follow_up_message: "Send Promise Follow up Message",
  full_amount: "Full Amount",
  promise_type: "Promise Type",
  promise_date: "Promise Date",
  promise_to_follow_up_message: "Promise to Follow-up Message ",
  call_back_date: "Call Back Date",
  hold_workflow_till_call_back_date: "Hold workflow till Call-Back Date",
  call_back_confirmation: "Call Back Confirmation",
  call_back_reminder_message: "Call Back reminder Message",
  user_comments_here: "User Comments Here",
  meeting_address_with_google_address_map_enabled_here:
    "Meeting Address with Google address map enabled here",
  meeting_value: "Meeting Value",
  meeting_date: "Meeting Date",
  meeting_time: "Meeting Time",
  service_incomplete: "Service Incomplete",
  open: "Open",
  date_picker: "Date Picker",
  follow_up_call: "Follow-up Call",
  unsuccessful_reason: "Unsuccessful Reason",
  contact_unsuccessful: "Contact Unsuccessful",
  invoice_summary: "Invoice Summary",
  invoice_overview: "Invoice Overview",
  user: "Users",
  add_user: "Add User",
  payment_date: "Payment Date",
  payment_amount: "Payment Amount",
  payment_method: "Payment Method",
  payment_reference: "Payment Reference",
  payment_status: "Payment Status",
  expect_payment_status_date: "Expect Payment Status Date",
  user_notes: "User Notes",
  add_payments: "Add Payments",
  receiver: "Receiver",
  meeting_venue: "Meeting Venue",
  phone_type: "Phone Type",
  country_code: "Country Code",
  email_type: "Email Type",
  record_dispute: "Record Dispute",

  // CUSTOMER PORTAL

  invoice_number: "Loan Account #",
  invoice_outstanding: "Invoice Outstanding",
  credit_note_amount: "Credit Note Amount",
  available_amount: "Available Amount",
  all_due: "All Due",
  over_due: "Over Due",
  adhoc_payment: "Adhoc Payment",
  your_message_or_note: "Your message or Note",
  date_of_payment: "Date of Payment",
  preferred_call_back_date: "Preferred Call Back Date",
  preferred_call_back_time: "Preferred Call Back Time",
  amount: "Amount",
  your_message_or_note1:
    "Add any additional Notes on this Call back Request (optional)",

  your_message_or_note2:
    "Add any additional Notes on this Request Time (optional)",
  enter_the_amount: "Enter the Amount",
  overdue: "Overdue",
  paid_invoice: "Paid Accounts",
  invoice_numbers: "Account",
  invoice_description: "Description",
  instalation_count: "No of Installment",
  client_provider: "Client Provider",

  paid_invoice: "Paid Invoices",
  invoice_description: "Invoice Description",
  payment_amount: "Payment Amount",
  planned_installation_amount: "Planned Installment Amount",
  planned_start_date: "Planned Start Date",
  planned_frequency: "Planned Frequency",
  start_date: "Start Date",
  CANCELED: "Cancelled",
  IN_PROGRESS: "In Progress",
  KEPT: "Kept",
  BROKEN: "Broken",
  PARTIAL: "Partial",
  SINGLE: "Single",
  WEEKLY: "Weekly",
  FORTNIGHTLY: "Bi-Weekly",
  QUARTERLY: "Quarterly",
  MONTHLY: "Monthly",
  enter_your_phone_or_email: "Enter your Phone # or Email ID",
  preferred_call_back_number: "Preferred Call Back Number",
  code: "Code",
};
