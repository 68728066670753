import React from "react";
import { useSelector } from "react-redux";

const ChoosePlannedSvg = () => {
    const { default_color } = useSelector((s) => s.layoutReduceData);
    return (
        <>
            {/* <?xml version="1.0" encoding="utf-8"?>
            <!-- Generator: Adobe Illustrator 27.8.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->  viewBox="0 0 391.7 323.7" */}
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={339} height={222}
                viewBox="0 0 389 319" style={{ enableBackground: 'new 0 0 391.7 323.7' }} >

                <g id="Background_Complete">
                </g>
                <g id="Background_Simple" class="st0" style={{ display: 'none' }}>
                    <g class="st1" style={{ display: 'inline' }}>
                        <path class="st2" fill={default_color} d="M315.5,88.5C343,154.3,220.1,201.1,151.4,199c-87-2.6-140.6-43.7-147.6-115.7C-3.2,11.9,72.4-38.2,136.3-15.2
			c31.5,11.4,56.4,35.7,88,47.6C261.6,46.7,298.4,47.4,315.5,88.5z"/>
                        <path class="st3" style={{ opacity: 0.9, fill: '#FFFFFF' }} d="M315.5,88.5C343,154.3,220.1,201.1,151.4,199c-87-2.6-140.6-43.7-147.6-115.7C-3.2,11.9,72.4-38.2,136.3-15.2
			c31.5,11.4,56.4,35.7,88,47.6C261.6,46.7,298.4,47.4,315.5,88.5z"/>
                    </g>
                </g>
                <g id="Shadow_1_">
                </g>
                <g id="Smartphone">
                </g>
                <g id="Character_1_">
                    <ellipse id="_x3C_Path_x3E__316_" class="st4" fill='#F5F5F5' cx="194.7" cy="304.9" rx="193.9" ry="11.3" />
                    <path class="st5" fill='#263238' d="M342.8,304.9H226c-7,0-12.3-5.7-11.8-12.6l16.3-247.1c0.5-7,6.5-12.6,13.5-12.6h116.8c7,0,12.3,5.7,11.8,12.6
		l-16.3,247.1C355.8,299.2,349.7,304.9,342.8,304.9z"/>
                    <path class="st5" fill='#263238' d="M367.4,99h-14.1c-1.7,0-3-1.4-2.8-3.1l1.4-21.4c0.1-1.7,1.6-3.1,3.3-3.1h14.1c1.7,0,3,1.4,2.8,3.1l-1.4,21.4
		C370.5,97.6,369.1,99,367.4,99z"/>
                    <path class="st2" fill={default_color} d="M341,304.9H224.2c-7,0-12.3-5.7-11.8-12.6l16.3-247.1c0.5-7,6.5-12.6,13.5-12.6H359c7,0,12.3,5.7,11.8,12.6
		l-16.3,247.1C354,299.2,348,304.9,341,304.9z"/>
                    <path class="st6" fill="#FFFFFF" d="M346.3,276.1H222.7c-4,0-7-3.2-6.7-7.2l14.2-215.1c0.3-4,3.7-7.2,7.7-7.2h123.6c4,0,7,3.2,6.7,7.2L354,268.9
		C353.7,272.9,350.3,276.1,346.3,276.1z"/>
                    <polygon class="st7" fill="#FAFAFA" points="323.3,46.7 298.7,46.7 239.3,276.1 263.8,276.1 	" />
                    <polygon class="st7" fill="#FAFAFA" points="344.9,46.7 332.6,46.7 273.2,276.1 285.4,276.1 	" />
                    <path class="st8" fill="#F0F0F0" d="M328.6,205l-26.7-100.9c-1.5-5.6-7.2-8.9-12.8-7.5L226,113.3l-8.4,131.9l103.6-27.4
		C326.7,216.4,330.1,210.6,328.6,205z"/>
                    <path class="st9" style={{ opacity: 0.2, fill: '#FFFFFF' }} d="M291.6,287.7c-0.3,4.3-4,7.8-8.3,7.8s-7.6-3.5-7.3-7.8c0.3-4.3,4-7.8,8.3-7.8
		C288.6,279.9,291.8,283.4,291.6,287.7z"/>
                    <path class="st9" style={{ opacity: 0.2, fill: '#FFFFFF' }} d="M303.4,39.2c-0.1,1.8-1.7,3.2-3.4,3.2s-3.1-1.4-3-3.2c0.1-1.8,1.7-3.2,3.4-3.2C302.1,36,303.5,37.4,303.4,39.2
		z"/>
                    <path class="st9" style={{ opacity: 0.2, fill: '#FFFFFF' }} d="M301.9,39.2c-0.1,1-0.9,1.8-1.9,1.8c-1,0-1.7-0.8-1.7-1.8c0.1-1,0.9-1.8,1.9-1.8
		C301.2,37.4,302,38.2,301.9,39.2z"/>
                    <path class="st5" fill='#263238' d="M114.1,26.7c3.7-9.2,2.4-20.3-8.2-22.8C95.3,1.4,86.2,9.5,86.6,19.5s7,18.3-4.5,25.6
		c-11.6,7.2-10.3,29.1,12,24.9s10.2-15.7,10.2-15.7s-5.5,4.5-7.2-0.1C94.9,48.3,109.7,37.5,114.1,26.7z"/>
                    <path class="st5" fill='#263238' d="M74.8,56.8l-0.5-0.1c1.5-6.7,3.8-8.4,6.3-10.2c2.4-1.7,4.8-3.5,6.7-10.1c0.9-3.1,0.3-6.5-0.2-9.8
		c-0.7-4.2-1.4-8.6,0.7-12.5l0.4,0.2c-2.1,3.7-1.4,8-0.7,12.1c0.6,3.4,1.1,6.8,0.2,10c-1.9,6.7-4.5,8.6-6.9,10.4
		C78.5,48.6,76.3,50.3,74.8,56.8z"/>
                    <path class="st10" fill="#FFC3BD" d="M142.5,77.1c0.1,0.3,0.2,0.9,0.3,1.3l0.3,1.4c0.2,0.9,0.5,1.9,0.8,2.8c0.6,1.9,1.2,3.8,1.8,5.7
		c1.3,3.8,2.8,7.5,4.5,11.1l1.3,2.6c0.2,0.5,0.5,0.9,0.9,1.3c0.8,0.8,2.2,1.4,3.9,1.7c3.3,0.7,7.5,0.5,11.3-0.1l1.4,4.2
		c-1.1,0.5-2.1,0.9-3.2,1.2c-1.1,0.3-2.1,0.7-3.3,0.9c-2.2,0.5-4.5,0.8-7,0.6c-2.5-0.1-5.2-0.7-7.8-2.4c-1.3-0.9-2.4-2.1-3.2-3.4
		l-0.2-0.2l-0.1-0.2l-0.2-0.4l-0.4-0.7l-0.8-1.4c-2.1-3.8-3.9-7.8-5.5-11.8c-0.8-2-1.5-4.1-2.1-6.2c-0.3-1.1-0.6-2.1-0.9-3.2
		c-0.3-1.1-0.5-2.1-0.7-3.4L142.5,77.1z"/>
                    <path class="st2" fill={default_color} d="M134.7,63.1c10.1,4.5,10,19.6,10,19.6l-10.6,6.4c0,0-8-11.8-7.4-17.2C127.3,66.3,129,60.6,134.7,63.1z" />
                    <path class="st11" style={{ opacity: 0.2 }} d="M136.3,76c-2.8-2.8-5.2,0.4-7.4,4c2.2,4.7,5.2,9.1,5.2,9.1l4.4-2.6C139.3,83.2,139.1,78.8,136.3,76z" />
                    <path class="st10" fill="#FFC3BD" d="M166.5,105.5l7.1-3.5l0.1,9.3c0,0-5.3,0.9-8.8-2.8L166.5,105.5z" />
                    <polygon class="st10" fill="#FFC3BD" points="181.3,103.6 180.1,110.5 173.7,111.2 173.6,101.9 	" />
                    <polygon class="st10" fill="#FFC3BD" points="101.6,297.5 110.3,298.4 114.4,277.9 105.6,277.1 	" />
                    <polygon class="st10" fill="#FFC3BD" points="44,273.6 51.1,278.8 63,261.9 55.9,256.7 	" />
                    <path d="M53.7,275.5l-5.9-8.1c-0.2-0.3-0.6-0.4-0.9-0.2l-7,3.7c-0.7,0.4-0.9,1.4-0.4,2.1c2.1,2.8,3.3,4,5.8,7.6
		c1.6,2.2,4.8,7.2,7,10.2c2.2,2.9,5.3,1,4.6-0.5c-2.9-6.5-3-10.3-2.8-13.4C54.2,276.5,54,276,53.7,275.5z"/>
                    <path d="M111.5,295.7H101c-0.4,0-0.7,0.3-0.8,0.6l-2.3,8.3c-0.2,0.9,0.4,1.7,1.3,1.7c3.7,0,5.5,0,10.1,0c2.9,0,10.2,0,14.2,0
		c3.9,0,4.7-3.9,3.1-4.2c-7.1-1.6-11.3-3.8-13.5-5.8C112.6,295.9,112.1,295.7,111.5,295.7z"/>
                    <polygon class="st11" style={{ opacity: 0.2 }} points="114.4,277.9 112.3,288.5 103.4,288.5 105.6,277.1 	" />
                    <polygon class="st11" style={{ opacity: 0.2 }} points="55.9,256.7 63,261.9 56.9,270.6 49.7,265.4 	" />
                    <path class="st2" fill={default_color} d="M138.9,71.1c-0.6,6.7-2.9,20-9.4,45.9l-35.9-3.1c1.7-13.5,2.5-22.2-0.8-47.8c-0.5-3.6,2.2-6.8,5.8-7
		c2.6-0.1,5.7-0.2,8.8-0.1c6.1,0.2,11,0.5,16.3,1.4c3.2,0.6,6.5,1.4,9.3,2.1C136.7,63.5,139.3,67.1,138.9,71.1z"/>
                    <path class="st11" style={{ opacity: 0.2 }} d="M93.6,73.6c0.5,4,0.8,7.6,1,10.8c3.3-0.3,5.7-3.9,6-11.5C99.4,69.2,96.4,70.5,93.6,73.6z" />
                    <path d="M129.5,117c0,0,12.2,53.8,4,91.7c-5.4,25.4-17.5,76.7-17.5,76.7l-15.3-0.4c0,0,7.3-53.9,10.4-75
		c5.2-36.2-7.9-95.3-7.9-95.3L129.5,117z"/>
                    <path class="st11" style={{ opacity: 0.2 }} d="M115.5,127.8l-7.7,12.1c2,12.5,3.9,28.2,4.4,43.3C123,163.9,117.9,138.1,115.5,127.8z" />
                    <polygon class="st2" fill={default_color} points="118.2,286.2 99.2,285.7 100.3,279.9 119.7,280.8 	" />
                    <path d="M121.6,116.4c0,0-7.1,57.1-22.6,88.2c-12.5,25.2-38.6,65.1-38.6,65.1l-9.5-7.7c0,0,16.3-44.2,25.6-60.9
		c22.8-41,4.2-72.2,17.1-87.3L121.6,116.4z"/>
                    <g>
                        <path class="st2" fill={default_color} d="M319.7,181.2l-170,71.4c-5.3,2.2-11.5-0.3-13.7-5.6l-40.4-96.2c-2.2-5.3,0.3-11.5,5.6-13.7l170-71.4
			c5.3-2.2,11.5,0.3,13.7,5.6l40.4,96.2C327.6,172.8,325,178.9,319.7,181.2z"/>
                        <path class="st12" style={{ opacity: 0.1 }} d="M319.7,181.2l-170,71.4c-5.3,2.2-11.5-0.3-13.7-5.6l-40.4-96.2c-2.2-5.3,0.3-11.5,5.6-13.7l170-71.4
			c5.3-2.2,11.5,0.3,13.7,5.6l40.4,96.2C327.6,172.8,325,178.9,319.7,181.2z"/>
                    </g>
                    <polygon class="st9" style={{ opacity: 0.2, fill: '#FFFFFF' }} points="163.7,156.8 121.1,174.7 110.2,148.7 152.7,130.8 152.7,130.8 	" />
                    <path class="st13" style={{ opacity: 0.4, fill: '#FFFFFF' }} d="M175.4,208.1l-32.6,13.7c-1,0.4-2.1-0.1-2.5-1l-2.5-5.9c-0.4-1,0.1-2.1,1-2.5l32.6-13.7c1-0.4,2.1,0.1,2.5,1
		l2.5,5.9C176.8,206.6,176.3,207.7,175.4,208.1z"/>
                    <path class="st13" style={{ opacity: 0.4, fill: '#FFFFFF' }} d="M217.8,190.3L185.2,204c-1,0.4-2.1-0.1-2.5-1l-2.5-5.9c-0.4-1,0.1-2.1,1-2.5l32.6-13.7c1-0.4,2.1,0.1,2.5,1
		l2.5,5.9C219.2,188.8,218.8,189.9,217.8,190.3z"/>
                    <path class="st13" style={{ opacity: 0.4, fill: '#FFFFFF' }} d="M260.2,172.5l-32.6,13.7c-1,0.4-2.1-0.1-2.5-1l-2.5-5.9c-0.4-1,0.1-2.1,1-2.5l32.6-13.7c1-0.4,2.1,0.1,2.5,1
		l2.5,5.9C261.6,171,261.2,172.1,260.2,172.5z"/>
                    <path class="st13" style={{ opacity: 0.4, fill: '#FFFFFF' }} d="M302.6,154.7L270,168.4c-1,0.4-2.1-0.1-2.5-1l-2.5-5.9c-0.4-1,0.1-2.1,1-2.5l32.6-13.7c1-0.4,2.1,0.1,2.5,1
		l2.5,5.9C304.1,153.2,303.6,154.3,302.6,154.7z"/>
                    <polygon class="st2" fill={default_color} points="61.4,270.8 49.2,261.5 52.2,256.2 65.2,266.3 	" />
                    <g>
                        <path class="st2" fill={default_color} d="M93.2,111.7l-1.9,3.1c-0.1,0.2,0.1,0.5,0.5,0.6l37.7,3.3c0.3,0,0.6-0.1,0.6-0.3l0.6-3.2
			c0-0.2-0.2-0.4-0.6-0.5l-36.4-3.1C93.5,111.5,93.3,111.6,93.2,111.7z"/>
                        <path class="st14" style={{ opacity: 0.5 }} d="M93.2,111.7l-1.9,3.1c-0.1,0.2,0.1,0.5,0.5,0.6l37.7,3.3c0.3,0,0.6-0.1,0.6-0.3l0.6-3.2
			c0-0.2-0.2-0.4-0.6-0.5l-36.4-3.1C93.5,111.5,93.3,111.6,93.2,111.7z"/>
                    </g>
                    <path class="st2" fill={default_color} d="M97.7,116.2l-1-0.1c-0.2,0-0.3-0.1-0.3-0.3l0.8-4.1c0-0.1,0.2-0.2,0.4-0.2l1,0.1c0.2,0,0.3,0.1,0.3,0.3
		l-0.8,4.1C98.1,116.1,97.9,116.2,97.7,116.2z"/>
                    <path class="st2" fill={default_color} d="M126.8,118.7l-1-0.1c-0.2,0-0.3-0.1-0.3-0.3l0.8-4.1c0-0.1,0.2-0.2,0.4-0.2l1,0.1c0.2,0,0.3,0.1,0.3,0.3
		l-0.8,4.1C127.1,118.6,127,118.7,126.8,118.7z"/>
                    <path class="st2" fill={default_color} d="M112.2,117.5l-1-0.1c-0.2,0-0.3-0.1-0.3-0.3l0.8-4.1c0-0.1,0.2-0.2,0.4-0.2l1,0.1c0.2,0,0.3,0.1,0.3,0.3
		l-0.8,4.1C112.6,117.4,112.4,117.5,112.2,117.5z"/>
                    <path class="st2" fill={default_color} d="M56,280c-0.1,0-0.2,0-0.3,0c-1.1-0.2-2-2.1-2.5-3.2c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.2,0
		c0.3,0.1,3.3,1.3,3.5,2.4c0,0.2,0,0.4-0.2,0.7C56.5,279.8,56.2,279.9,56,280z M53.7,277.1c0.7,1.5,1.4,2.4,2.1,2.6
		c0.3,0.1,0.5,0,0.8-0.3c0.1-0.1,0.1-0.3,0.1-0.3C56.5,278.3,54.7,277.5,53.7,277.1z"/>
                    <path class="st2" fill={default_color} d="M56.1,277.2c-1,0.1-2.2,0-2.8-0.3c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0.1-0.2c0.1-0.1,2.1-1.6,3.4-1.3
		c0.3,0.1,0.6,0.3,0.7,0.5c0.2,0.4,0.1,0.7-0.1,0.9C57.1,277,56.6,277.1,56.1,277.2z M53.7,276.7c1,0.3,2.9,0.3,3.3-0.2
		c0.1-0.1,0.1-0.2,0-0.5c-0.1-0.2-0.3-0.3-0.5-0.4C55.7,275.5,54.3,276.3,53.7,276.7z"/>
                    <path class="st2" fill={default_color} d="M116.2,297.4c-1,0-2.1-0.2-2.9-0.4c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.2,4.3-2,5.2-1.3
		c0.1,0.1,0.3,0.2,0.2,0.5c0,0.4-0.2,0.7-0.5,1C117.7,297.3,117,297.4,116.2,297.4z M113.9,296.8c1.1,0.2,3.2,0.5,4.1-0.2
		c0.2-0.2,0.4-0.4,0.4-0.7c0-0.1,0-0.2-0.1-0.2C117.7,295.3,115.5,296.1,113.9,296.8z"/>
                    <path class="st2" fill={default_color} d="M113.3,297c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.2c0-0.2,0.9-3.9,3-3.7c0.5,0.1,0.7,0.3,0.7,0.5
		C117,294.6,114.5,296.6,113.3,297C113.4,297,113.4,297,113.3,297z M116,293.5c-1.3,0-2.1,2.2-2.4,3.1c1.2-0.7,3-2.2,2.9-2.8
		c0,0,0-0.2-0.4-0.2C116,293.5,116,293.5,116,293.5z"/>
                    <path class="st10" fill="#FFC3BD" d="M97.5,73.7L97,74.5l-0.6,0.9c-0.4,0.6-0.8,1.2-1.2,1.8c-0.8,1.2-1.4,2.5-2.1,3.7c-1.4,2.5-2.6,5.1-3.6,7.8
		c-1.1,2.7-1.9,5.4-2.6,8.2l-0.5,2c0-0.1-0.1,0.2,0.1,0.5c0.2,0.8,0.8,1.9,1.5,3c1.4,2.2,3.4,4.4,5.4,6.5c2,2.1,4.2,4.2,6.4,6.2
		c2.2,2,4.5,4,6.8,5.9c2.3,2,4.7,3.9,7,5.8l-2.5,3.7c-5.4-3.2-10.5-6.6-15.5-10.4c-2.5-1.9-4.9-3.9-7.3-6c-2.4-2.1-4.7-4.4-6.8-7.2
		c-1-1.4-2.1-2.9-2.8-5.1c-0.3-1.1-0.6-2.4-0.4-4l0-0.3l0-0.2l0.1-0.3l0.1-0.6l0.2-1.2c0.6-3.2,1.4-6.4,2.5-9.5
		c1.1-3.1,2.4-6.1,3.9-9c0.7-1.5,1.6-2.9,2.4-4.3c0.4-0.7,0.9-1.4,1.4-2.1l0.7-1c0.3-0.4,0.5-0.7,0.8-1.1L97.5,73.7z"/>
                    <path class="st2" fill={default_color} d="M96.6,59.6c-6.9,1.8-13.5,18.4-13.5,18.4l8.9,8.1c0,0,14-12.7,13.5-18.5C105.1,62.9,102.2,58.2,96.6,59.6z" />
                    <path class="st11" style={{ opacity: 0.2 }} d="M107.3,58.7c0,0-1.4,0.3-3.8,0.3c-0.3,4.9,2.9,9.7,10.7,16.1c1.9-5.8,6-7.1,6-7.1L107.3,58.7z" />
                    <path class="st11" style={{ opacity: 0.2 }} d="M123.6,60.2c0,0,0.9,0.1,3.1,0.6c1.4,2.2,3.3,9.1-0.9,14c-1.7-6-5.6-6.8-5.6-6.8L123.6,60.2z" />
                    <path class="st6" fill="#FFFFFF" d="M107.3,58.7c0,0-2,0-4.4,0c-1.4,2.2,3.5,8.4,11.3,14.8c1.9-5.8,6-5.5,6-5.5L107.3,58.7z" />
                    <path class="st6" fill="#FFFFFF" d="M123.6,60.2c0,0,0.9,0.1,3.1,0.6c1.4,2.2,4.6,8.3,0.3,13.1c-1.7-6-6.9-5.9-6.9-5.9L123.6,60.2z" />
                    <path class="st10" fill="#FFC3BD" d="M110.5,40.7c0.6,5.3,0.8,15.1-3.2,18.2c0,0,2.1,6,12.8,9.1c6.5-3.7,3.5-7.7,3.5-7.7
		c-5.7-1.9-5.2-6.2-3.8-10.2L110.5,40.7z"/>
                    <path class="st11" style={{ opacity: 0.2 }} d="M114.2,44.6l5.5,5.6c-0.3,0.9-0.6,1.9-0.8,2.9c-2.2-0.5-5.4-2.8-5.4-5.2C113.5,46.6,114,45.1,114.2,44.6z" />
                    <path class="st5" fill='#263238' d="M122.6,19.4c1.8-1.9,5.2,0.5,6.5,5.3c1.9,7-3.2,7.5-5.9,4C120.6,25.2,121.2,21,122.6,19.4z" />
                    <path class="st5" fill='#263238' d="M128,31.6c0,0,2.6,0.8,4.9,2.5C132.1,32.1,131.3,30.8,128,31.6z" />
                    <path class="st5" fill='#263238' d="M129.1,32c0,0,2.1-0.5,4.3-0.2C132,30.7,130.9,30.1,129.1,32z" />
                    <path class="st10" fill="#FFC3BD" d="M105.8,32.4c2.2,8.5,3,12.2,8.2,15.8c7.9,5.4,17.9,0.8,18.2-8.2c0.3-8.1-3.4-20.7-12.5-22.5
		C110.7,15.7,103.5,23.8,105.8,32.4z"/>
                    <path class="st6" fill="#FFFFFF" d="M109.9,21.1l-2.5,2.9c-1.5,1.8-4.2,2-6,0.4v0c-1.8-1.5-2-4.2-0.4-6l2.5-2.9c1.5-1.8,4.2-2,6-0.4l0,0
		C111.2,16.6,111.4,19.3,109.9,21.1z"/>
                    <path class="st2" fill={default_color} d="M112.1,24.3l-3,1.5c-2.6,1.3-5.7,0.2-6.9-2.4l0,0c-1.3-2.6-0.2-5.7,2.4-6.9l3-1.5c2.6-1.3,5.7-0.2,6.9,2.4l0,0
		C115.8,19.9,114.7,23.1,112.1,24.3z"/>
                    <path class="st5" fill='#263238' d="M108.3,38.8c0,0,6.2-1.4,3.5-10.4c-0.4-1.5,0.7-3,2.2-3c6.9-0.2,13.4-1.6,12.5-5.7c-1.2-5.1-18.4-9-21.6,1.2
		C98.9,22.3,98.6,34.3,108.3,38.8z"/>
                    <path class="st5" fill='#263238' d="M108.3,37c0,0,2.6,0.8,4.9,2.5C112.4,37.4,111.6,36.1,108.3,37z" />
                    <path class="st5" fill='#263238' d="M109.3,37.3c0,0,2.1-0.5,4.3-0.2C112.2,36,111.2,35.5,109.3,37.3z" />
                    <path class="st10" fill="#FFC3BD" d="M104.3,39.1c0.8,1.6,2.3,2.7,3.8,3.1c2.2,0.7,3.3-1.3,2.7-3.3c-0.5-1.9-2.2-4.4-4.4-4.2
		C104.3,34.9,103.4,37.2,104.3,39.1z"/>
                    <path class="st5" fill='#263238' d="M120.2,32.1c0.1,0.7,0.6,1.2,1.1,1.1c0.5-0.1,0.7-0.7,0.6-1.4c-0.1-0.7-0.6-1.2-1.1-1.1
		C120.3,30.7,120.1,31.4,120.2,32.1z"/>
                    <path class="st5" fill='#263238' d="M128.2,30.5c0.1,0.7,0.6,1.2,1.1,1.1c0.5-0.1,0.7-0.7,0.6-1.4c-0.1-0.7-0.6-1.2-1.1-1.1
		C128.3,29.2,128,29.8,128.2,30.5z"/>
                    <path class="st5" fill='#263238' d="M128.5,29.2l1.6-0.8C130.1,28.3,129.5,29.8,128.5,29.2z" />
                    <path class="st15" fill='#ED847E' d="M125.4,32.3c0,0,1.8,2.8,3.4,4c-0.8,1-2.4,1-2.4,1L125.4,32.3z" />
                    <path class="st5" fill='#263238' d="M123.1,40.6c-2.5,0-3.9-1.5-4-1.5c-0.1-0.1-0.1-0.2,0-0.2c0.1-0.1,0.2-0.1,0.2,0c0,0,1.9,1.9,5,1.3
		c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2C124,40.5,123.6,40.6,123.1,40.6z"/>
                    <path class="st5" fill='#263238' d="M115.4,29.3c-0.1,0-0.1,0-0.2-0.1c-0.2-0.1-0.2-0.3-0.1-0.5c1.2-2,3.1-2,3.2-2c0.2,0,0.4,0.1,0.4,0.3
		c0,0.2-0.1,0.4-0.3,0.4c-0.1,0-1.6,0.1-2.6,1.7C115.7,29.3,115.6,29.3,115.4,29.3z"/>
                    <path class="st5" fill='#263238' d="M130.4,26.5c-0.1,0-0.1,0-0.2,0c-1.7-0.9-3.1-0.2-3.1-0.2c-0.2,0.1-0.4,0-0.5-0.1c-0.1-0.2,0-0.4,0.1-0.5
		c0.1,0,1.8-0.9,3.8,0.2c0.2,0.1,0.2,0.3,0.1,0.5C130.6,26.5,130.5,26.5,130.4,26.5z"/>
                    <path class="st10" fill="#FFC3BD" d="M110.4,125.2l8,0.6l-3.6,8.5c0,0-6.7-2.1-7-7L110.4,125.2z" />
                    <polygon class="st10" fill="#FFC3BD" points="121.4,130.6 117.6,136.5 114.8,134.3 118.4,125.8 	" />
                    <path class="st5" fill='#263238' d="M120.6,30.7l1.6-0.8C122.2,29.9,121.6,31.4,120.6,30.7z" />
                </g>
            </svg>

        </>
    )
}
export default ChoosePlannedSvg