import CheckBox from "../CheckBox/CheckBox";

const SelectionCheckbox = (props) => {
  const { row, selectedFlatRows } = props;
  const { original } = row;

  let isOtherCurrencySelected = false;
  const selectedCurrency =
    selectedFlatRows && selectedFlatRows[0]
      ? selectedFlatRows[0].original.invoice_currency
      : null;

  if (selectedCurrency && selectedCurrency !== original.invoice_currency) {
    isOtherCurrencySelected = true;
  }

  return (
    <CheckBox
      {...row.getToggleRowSelectedProps()}
      disabled={isOtherCurrencySelected}
      disabledCheckbox={isOtherCurrencySelected}
    />
  );
};

export default SelectionCheckbox;
