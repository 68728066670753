import React, { useState } from "react";
import * as reactDropzone from "react-dropzone";
import upload from "../../assets/img/upload.png";
import Success from "../../assets/img/svg/Success";
import { ToastContainer, toast } from "react-toastify";
import { LabelSmall } from "baseui/typography";




const FileUpload = ({ setFiles, files, setIsCheck }) => {
  const fileSize = 3000000;
  const [isError, setIsError] = useState('')
  function uploadLogoValidation(file) {
    if (file.size > fileSize) {
      setIsError('File Size is Largen than 3MB');
      return {
        code: "file is too-large",
        message: `File Size is Largen than 3MB`,

      };
    }
  }


  const { getRootProps, getInputProps, acceptedFiles, fileRejections, open } =
    reactDropzone.useDropzone({
      maxFiles: 1,
      accept: {
        "image/*": [".jpg", ".png"],
        "text/*": [".pdf", ".csv"],
      },
      validator: uploadLogoValidation,
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });
  return (
    <>
      {files.length == 0 ? (
        <section className="container" style={{ marginTop: "10px" }}>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <img src={upload} alt="Upload File" />
            <p>
              Attach amount transfer receipt or Screenshot showing the Payment
              reference
            </p>
            {/* <ToastContainer /> */}
          </div>
          <div style={{ color: "red", marginTop: "10px", textAlign: "center" }}>
            <span style={{ color: "red", }}>{isError}</span>
          </div>

          {/* <aside style={thumbsContainer}>{thumbs}</aside> */}
        </section>
      ) : (
        <div className="dropzone" style={{ marginTop: "10px" }}>
          <Success />
          <p className="error_subheading">Successfully Attached </p>
          <p className="reupload_doc" onClick={open}>
            Reupload
          </p>
        </div>
      )}
    </>
  );
};

export default FileUpload;
