import React from "react";

const TickSvg = ({ color = "#516BEB" }) => {
  return (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.63071 8.88966L1.52417 5.85427C1.17502 5.51312 0.611012 5.51312 0.261862 5.85427C-0.0872874 6.19543 -0.0872874 6.74652 0.261862 7.08767L4.00403 10.7441C4.35318 11.0853 4.91719 11.0853 5.26634 10.7441L14.7381 1.48926C15.0873 1.14811 15.0873 0.597018 14.7381 0.255865C14.389 -0.0852883 13.825 -0.0852883 13.4758 0.255865L4.63071 8.88966Z"
        fill={color}
      />
    </svg>
  );
};

export default TickSvg;
