import React from "react";

const ArrowCircle = ({ size = 22, color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4987 20.6673C10.2459 20.6673 9.06189 20.4267 7.94661 19.9454C6.83134 19.4642 5.85738 18.8072 5.02474 17.9746C4.1921 17.142 3.53516 16.168 3.05391 15.0527C2.57266 13.9375 2.33203 12.7534 2.33203 11.5007C2.33203 10.2326 2.57266 9.04093 3.05391 7.92565C3.53516 6.81037 4.1921 5.84023 5.02474 5.01523C5.85738 4.19023 6.83134 3.53711 7.94661 3.05586C9.06189 2.57461 10.2459 2.33398 11.4987 2.33398C12.7668 2.33398 13.9584 2.57461 15.0737 3.05586C16.189 3.53711 17.1591 4.19023 17.9841 5.01523C18.8091 5.84023 19.4622 6.81037 19.9435 7.92565C20.4247 9.04093 20.6654 10.2326 20.6654 11.5007C20.6654 12.7534 20.4247 13.9375 19.9435 15.0527C19.4622 16.168 18.8091 17.142 17.9841 17.9746C17.1591 18.8072 16.189 19.4642 15.0737 19.9454C13.9584 20.4267 12.7668 20.6673 11.4987 20.6673ZM12.232 12.1882L11.0174 13.4027C10.8799 13.5402 10.8112 13.7007 10.8112 13.884C10.8112 14.0673 10.8799 14.2277 11.0174 14.3652C11.1549 14.5027 11.3154 14.5715 11.4987 14.5715C11.682 14.5715 11.8424 14.5027 11.9799 14.3652L14.3633 11.9819C14.5008 11.8444 14.5695 11.684 14.5695 11.5007C14.5695 11.3173 14.5008 11.1569 14.3633 11.0194L11.9799 8.63607C11.8424 8.49857 11.682 8.42982 11.4987 8.42982C11.3154 8.42982 11.1549 8.49857 11.0174 8.63607C10.8799 8.77357 10.8112 8.93398 10.8112 9.11732C10.8112 9.30065 10.8799 9.46107 11.0174 9.59857L12.232 10.8132H8.7487C8.55009 10.8132 8.38585 10.8781 8.25599 11.0079C8.12613 11.1378 8.0612 11.302 8.0612 11.5007C8.0612 11.6993 8.12613 11.8635 8.25599 11.9934C8.38585 12.1232 8.55009 12.1882 8.7487 12.1882H12.232Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowCircle;
