import React from "react";

const RequestCallSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1217 19.2752C16.0652 19.2752 13.9991 18.7971 11.9235 17.8407C9.84812 16.8844 7.94122 15.5354 6.20283 13.7938C4.46462 12.0519 3.11735 10.1449 2.16104 8.07279C1.20472 6.00083 0.726562 3.9366 0.726562 1.88011C0.726562 1.54963 0.835689 1.27427 1.05394 1.05401C1.2722 0.83376 1.54501 0.723633 1.8724 0.723633H5.43157C5.70712 0.723633 5.9502 0.813572 6.16081 0.99345C6.37143 1.17315 6.50538 1.39522 6.56267 1.65967L7.18824 4.87046C7.23153 5.16837 7.22244 5.42437 7.16096 5.63844C7.09931 5.85251 6.98872 6.0323 6.82922 6.1778L4.30893 8.63125C4.71452 9.37404 5.17794 10.0767 5.69921 10.7393C6.22029 11.4017 6.78447 12.0344 7.39177 12.6373C7.99051 13.2362 8.62708 13.7924 9.30149 14.3058C9.97589 14.8193 10.7041 15.2971 11.4862 15.7392L13.935 13.2691C14.1058 13.0914 14.3126 12.9669 14.5554 12.8954C14.798 12.8241 15.0502 12.8066 15.3119 12.843L18.3421 13.4601C18.6177 13.5329 18.8426 13.6735 19.0168 13.8819C19.191 14.0903 19.2781 14.3268 19.2781 14.5912V18.1294C19.2781 18.4568 19.168 18.7296 18.9478 18.9478C18.7275 19.1661 18.4521 19.2752 18.1217 19.2752ZM3.5344 7.08246L5.48204 5.21885C5.51696 5.19084 5.5397 5.15237 5.55025 5.10344C5.5608 5.05452 5.55907 5.00905 5.54506 4.96703L5.07063 2.52832C5.05663 2.47248 5.03217 2.43056 4.99725 2.40255C4.96233 2.37454 4.91686 2.36054 4.86084 2.36054H2.52716C2.48514 2.36054 2.45013 2.37454 2.42212 2.40255C2.3943 2.43056 2.38038 2.46557 2.38038 2.50759C2.43622 3.25329 2.55826 4.01081 2.7465 4.78016C2.93456 5.54968 3.1972 6.31712 3.5344 7.08246ZM13.0284 16.5135C13.7518 16.8507 14.5062 17.1085 15.2917 17.2869C16.0775 17.4652 16.8116 17.5711 17.4942 17.6048C17.5362 17.6048 17.5712 17.5908 17.5992 17.5627C17.6272 17.5347 17.6412 17.4997 17.6412 17.4577V15.162C17.6412 15.1059 17.6272 15.0605 17.5992 15.0255C17.5712 14.9906 17.5293 14.9662 17.4735 14.9522L15.1818 14.4862C15.1398 14.4722 15.103 14.4704 15.0716 14.481C15.0401 14.4915 15.0068 14.5143 14.9717 14.5492L13.0284 16.5135Z"
        fill="white"
      />
    </svg>
  );
};

export default RequestCallSvg;
