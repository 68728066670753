import React, { useState } from "react";
import { ParagraphMedium } from "baseui/typography";
import { FormattedDate } from "react-intl";
import moment from "moment";
import "moment-timezone";
import { useSelector } from "react-redux";
import { IconButton, KIND, SIZE } from "../IconButton";
import { invoiceDownload } from "../../services/customerPortal";

const CustomerInvoiceNumDate = ({
  value,
  column,
  cell: {
    row: { original },
  },
  alignment,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);
  let timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );

  let dateFormat = useSelector(
    (e) =>
      e && e.layoutReduceData && e.layoutReduceData?.organization?.date_format
  );

  let accountingBook = useSelector(
    (e) =>
      e &&
      e.layoutReduceData &&
      e.layoutReduceData?.organization?.accounting_book
  );


  let issuedDate = moment
    .utc(original && original?.invoice_date)
    .tz(timeZone)
    .format(dateFormat ? dateFormat : "");

  let dueDate = moment
    .utc(original && original?.invoice_due_date)
    .tz(timeZone)
    .format(dateFormat ? dateFormat : "");

  return (
    <div>
      <div className="table-cell__type-duble-value">
        <div
          style={{
            display: "flex",
            gap: "5px",
            flex: "1",
            alignItems: alignment,
          }}
        >
          <ParagraphMedium $style={{ color: "#333860" }}>
            {value}
          </ParagraphMedium>
          {accountingBook == "CSV" ? (
            <></>
          ) : (
            <IconButton
              kind={KIND.tertiary}
              size={SIZE.mini}
              isLoading={isLoading}
              disabled={isLoading}
              onClick={async () => {
                await setIsLoading(true);
                invoiceDownload({
                  invoiceId: original._id,
                }).then(() => setIsLoading(false));
              }}
            >
              <i className="mx-icon-downloadVector-1 font-icon-color" />
            </IconButton>
          )}
        </div>
        <div className="invoice-date__types">
          <ParagraphMedium $style={{ color: "#ADADAD" }}>
            Issued: {issuedDate}
          </ParagraphMedium>

          <ParagraphMedium $style={{ color: "#ADADAD" }}>
            Due: {dueDate}
          </ParagraphMedium>
        </div>
      </div>
    </div>
  );
};

export default CustomerInvoiceNumDate;
