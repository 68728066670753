import React from "react";
import Messages from "../../containers/Messages/Messages";
import Payment from "../../containers/Payment/Payment";
import Layout from "../../layouts";

const MessagesView = () => {
  const [messageWidth, setMessageWidth] = React.useState(window.innerWidth);

  function responsiveWidth() {
    setMessageWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener("resize", responsiveWidth);
    return () => window.removeEventListener("resize", responsiveWidth);
  }, [messageWidth]);

  return (
    <Layout sideBar={messageWidth > 412 ? true : false}>
      <Messages />
    </Layout>
  );
};

export default MessagesView;
