import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeView from "../../views/Home/HomeView";
import FAQ from "../../containers/FAQ/FAQ";

const PublickRouters = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeView />} />
      <Route path="/FAQ" element={<FAQ />} />
    </Routes>
  );
};

export default PublickRouters;
