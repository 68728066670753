import React from "react";
import { StyledSvg } from "baseui/icon";

const DownArrow = ({ size = "22px" }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" preserveAspectRatio>
      <path
        d="M14.5568 8.51593L11.0001 12.0726L7.44344 8.51593C7.08594 8.15843 6.50844 8.15843 6.15094 8.51593C5.79344 8.87343 5.79344 9.45093 6.15094 9.80843L10.3584 14.0159C10.7159 14.3734 11.2934 14.3734 11.6509 14.0159L15.8584 9.80843C16.2159 9.45093 16.2159 8.87343 15.8584 8.51593C15.5009 8.16759 14.9143 8.15843 14.5568 8.51593V8.51593Z"
        fill="#323232"
      />
    </svg>
  );
};

export default DownArrow;
