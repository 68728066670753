import * as React from "react";
// import { Input, SIZE } from "baseui/input";
import { StatefulPopover, Popover } from "baseui/popover";
import { DatePicker, StatefulCalendar } from "baseui/datepicker";
import { Label2 } from "baseui/typography";
import { Button, KIND } from "baseui/button";
import { Input } from "baseui/input";
import { TextBox, SIZE } from "../../TextBox";
import { FilterInputBox } from "../../FilterInputBox";
import { FormControl } from "baseui/form-control";
// Date Calculation
const wtd = (x = 0) => {
  var d = new Date(); // today!
  return new Date(d.setDate(d.getDate() - x));
};
let WTD = wtd(7);
let FTD = wtd(14);

const td = (x = 0) => {
  var m = new Date();
  return new Date(m.setMonth(m.getMonth() - x));
};
let MTD = td(1);
let QTD = td(3);
let HTD = td(6);
let YTD = td(12);

function formatDate({ date, range }) {
  let formattedDate = "";
  if (!range) {
    formattedDate = isNaN(date) ? " / / " : new Date(date).toLocaleDateString();
  } else {
    let [startDate, endDate] = date;
    let formattedStartDate = isNaN(startDate)
      ? " / / "
      : new Date(startDate).toLocaleDateString();
    let formattedEndDate = isNaN(endDate)
      ? " / / "
      : new Date(endDate).toLocaleDateString();
    formattedDate = `${formattedStartDate} - ${formattedEndDate}`;
  }

  return formattedDate;
}

function CustomCalendar({
  range,
  setValue,
  close,
  currentValue = null,
  filterValue,
  setFilter,
}) {
  if (currentValue === null) {
    currentValue = range ? [] : null;
  }

  const [localState, setLocalState] = React.useState(currentValue);
  const [quickSelected, setQuickSelected] = React.useState(null);

  return (
    <StatefulCalendar
      triggerType="click"
      range={range}
      onChange={({ date }) => {
        // setFilter(date);
        setLocalState(date);
      }}
      quickSelect
      quickSelectOptions={[
        {
          id: "Today",
          beginDate: new Date(),
        },
        {
          id: "Week to date",
          beginDate: WTD,
        },
        {
          id: "Fortnight to date",
          beginDate: FTD,
        },
        {
          id: "Month to date",
          beginDate: MTD,
        },
        {
          id: "Quarterly to date",
          beginDate: QTD,
        },
        {
          id: "Half Yearly to date",
          beginDate: HTD,
        },
        {
          id: "Year to date",
          beginDate: YTD,
        },
      ]}
      initialState={{ value: localState }}
      overrides={{
        Root: (props) => {
          return (
            <div
              style={{
                display: "flex",
                backgroundColor: "white",
              }}
            >
              <div>{props.children[3]}</div>
              <div>
                {props.children[0]}
                <div
                  style={{
                    paddingBottom: "20px",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Button
                    $style={{
                      marginLeft: "30%",
                      borderRadius: "5px",
                      color: "#333860",
                      fontSize: "14px",
                      fontWeight: "700",
                      ":hover": {
                        backgroundColor: "#F0F3F8",
                        color: "#516beb",
                      },
                    }}
                    kind={KIND.minimal}
                    size={SIZE.mini}
                    onClick={() => {
                      setValue("");
                      setFilter(null);
                      close();
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    $style={{
                      marginLeft: "1%",
                      width: "100px",
                      height: "30px",
                      boxShadow: "0px 4px 4px 0px #d6d6d6",
                      backgroundColor: "#516beb",
                      borderRadius: "5px",
                      ":hover": {
                        boxShadow: "inset 0px 4px 4px 0px #00000025",
                        backgroundColor: "#0348C0",
                      },
                    }}
                    size={SIZE.mini}
                    onClick={() => {
                      let formattedDate = formatDate({
                        date: localState,
                        range,
                      });

                      const startDate =
                        localState[0] && new Date(localState[0])
                          ? new Date(
                              localState[0].toLocaleDateString()
                            ).getTime()
                          : null;
                      const endDate =
                        localState[1] && new Date(localState[1])
                          ? new Date(
                              localState[1].toLocaleDateString()
                            ).getTime()
                          : null;
                      const filterData = [startDate, endDate];

                      setValue(formattedDate);
                      setFilter(filterData);
                      close();
                    }}
                  >
                    Set Date
                  </Button>
                </div>
              </div>
            </div>
          );
        },
        MonthYearSelectPopover: {
          style: () => ({
            // zIndex: "99",
          }),
        },
        MonthYearSelectButton: {
          style: () => ({ color: "black" }),
        },
        PrevButton: {
          style: () => ({ color: "black" }),
        },
        NextButton: {
          style: () => ({
            color: "black",
          }),
        },
        onDayMouseOver: {
          style: () => ({
            color: "red",
          }),
        },
        // isHovered
        Input: {
          props: {
            overrides: {
              Root: {
                style: () => ({
                  borderRadius: "3px",
                  background: "#EEEEEE",
                }),
              },
              Input: {
                style: () => ({
                  //   paddingTop: "2px",
                  //   paddingBottom: "2px",
                  //   fontSize: "13px",
                  //   borderRadius: "3px",
                  //   color: "#757575",
                  //   paddingLeft: "5px",
                }),
              },
            },
          },
        },
        QuickSelectFormControl: {
          props: {
            overrides: {
              Label: () => (
                <Label2
                  style={{
                    paddingLeft: "0px",
                    marginTop: "25px",
                    marginBottom: "10px",
                  }}
                >
                  Quick Date Range
                </Label2>
              ),
            },
          },
        },
        QuickSelect: ({ onChange, ...rest }) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                paddingTop: "20px",
              }}
            >
              {rest.options.map((e) => (
                <Button
                  $style={{
                    color: "#516BEB",
                    borderRadius: "5px",
                  }}
                  kind={KIND.minimal}
                  size={SIZE.compact}
                  onClick={(btn) => {
                    setQuickSelected(e.id);
                    onChange({
                      option: e,
                      value: [e],
                    });
                  }}
                >
                  {e.id}
                </Button>
              ))}
            </div>
          );
        },
      }}
    />
  );
}

function reverseFormattedDate({ date, range }) {
  let formattedDate = "";
  if (!range) {
    formattedDate = new Date(date);
  } else {
    let [startDate, endDate] = date ? date.split(" - ") : [null, null];
    let formattedStartDate =
      startDate == null ? new Date() : new Date(startDate);
    let formattedEndDate = endDate == null ? new Date() : new Date(endDate);
    formattedDate = [formattedStartDate, formattedEndDate];
  }

  return formattedDate;
}

// const DateRangeFilterThree = (props) => {
//   let {
//     // column: { filterValue = {}, setFilter },
//     column,
//     isQuickDateRange = false,
//     error = "",
//     value = "",
//     label = "",
//   } = props;
//   const [valueDate, setValue] = React.useState(null);

//   const range = true;
//   // const obj = {
//   //   startDate: filterValue[0] ? filterValue[0] : null,
//   //   endDate: filterValue[1] ? filterValue[1] : null,
//   //   value: "WTD",
//   // };

//   return (
//     <>
//       {isQuickDateRange ? (
//         <FormControl
//           error={error}
//           overrides={{
//             Caption: {
//               style: ({ $theme }) => ({
//                 marginTop: "0px",
//                 marginBottom: "0px",
//                 marginLeft: "12px",
//                 textAlign: "left",
//               }),
//             },
//           }}
//         >
//           <DatePicker
//             value={value}
//             onChange={({ date }) =>
//               setValue(Array.isArray(date) ? date : [date])
//             }
//           />
//         </FormControl>
//       ) : (
//         <StatefulPopover
//         // isOpen={state}
//         overrides={{
//           Body: {
//             style: ({ $theme }) => ({
//               borderRadius: "10px",
//               overflow: "hidden",
//               //   zIndex: "99",
//             }),
//           },
//         }}
//         content={({ close }) => {
//           let currentValue = reverseFormattedDate({ date: valueDate, range });
//           return (
//             <CustomCalendar
//               filterValue={filterValue}
//               setFilter={setFilter}
//               close={close}
//               range={range}
//               setValue={setValue}
//               currentValue={currentValue}
//             />
//           );
//         }}
//         >
//           <div>
//             <FilterInputBox
//               size={SIZE.compact}
//               value={valueDate}
//               placeholder="MM/DD/YYYY - MM/DD/YYYY"
//               clearable={false}
//             />
//           </div>
//         </StatefulPopover>
//       )}
//     </>
//   );
// };

const DateRangeFilterThree = (props) => {
  let {
    column: { filterValue = {}, setFilter },
    column,
    isQuickDateRange = false,
    error = "",
    value = "",
    label = "",
  } = props;
  const [valueDate, setValue] = React.useState(null);

  const range = true;
  // const obj = {
  //   startDate: filterValue[0] ? filterValue[0] : null,
  //   endDate: filterValue[1] ? filterValue[1] : null,
  //   value: "WTD",
  // };

  return (
    <>
      {isQuickDateRange ? (
        <FormControl
          error={error}
          overrides={{
            Caption: {
              style: ({ $theme }) => ({
                marginTop: "0px",
                marginBottom: "0px",
                marginLeft: "12px",
                textAlign: "left",
              }),
            },
          }}
        >
          <DatePicker
            value={value}
            onChange={({ date }) =>
              setValue(Array.isArray(date) ? date : [date])
            }
          />
        </FormControl>
      ) : (
        <StatefulPopover
          // isOpen={state}
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                borderRadius: "10px",
                overflow: "hidden",
                //   zIndex: "99",
              }),
            },
          }}
          content={({ close }) => {
            let currentValue = reverseFormattedDate({ date: valueDate, range });
            return (
              <CustomCalendar
                filterValue={filterValue}
                setFilter={setFilter}
                close={close}
                range={range}
                setValue={setValue}
                currentValue={currentValue}
              />
            );
          }}
        >
          <div>
            <FilterInputBox
              size={SIZE.compact}
              value={valueDate}
              placeholder="MM/DD/YYYY - MM/DD/YYYY"
              clearable={false}
            />
          </div>
        </StatefulPopover>
      )}
    </>
  );
};

export default DateRangeFilterThree;
