import React from 'react';
import { useSelector } from 'react-redux';

const CalendarSvg = ({ st0 = `'#2B3990'`, st1 = '#FFFFFF',
        st2 = '#AFCDFB', st3 = '#FFB27D', st4 = '#233862',
        st5 = '#2B478B', st6 = '#ED985F', st7 = '#2C3A64'
}) => {

        return (
                <>
                        {/* <?xml version="1.0" encoding="utf-8"?>
                        <!-- Generator: Adobe Illustrator 27.8.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --> */}
                        <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 2236.6 1081" style={{ enableBackground: 'new 0 0 2236.6 1081' }} >

                                <g>
                                        <path class="st0" fill={st0} d="M450.5,107.5l-52.6,894.2c-2,33.7,24.8,62,58.5,62h257.1L450.5,107.5z" />
                                        <path class="st1" fill={st1} d="M1679.5,1063.8H665.7c-25.3,0-47-18.1-51.6-43L459.9,177.1c-7-38.3,22.4-73.6,61.4-73.6h1001.9
		c25.3,0,47,18.1,51.6,43l156.4,855.4C1737,1034.1,1712.2,1063.8,1679.5,1063.8z"/>
                                        <path class="st0" fill={st0} d="M480,286.7h1121l-28.7-159.3c-2.5-13.8-14.5-23.9-28.6-23.9H457.8c-5.9,0-10.4,5.4-9.3,11.2L480,286.7z" />
                                        <g>
                                                <g>
                                                        <g>
                                                                <path class="st2" fill={st2} d="M506.2,55.6c-26.8,0-48.6,21.8-48.6,48.6h9.3c0-21.7,17.6-39.3,39.3-39.3s39.3,17.6,39.3,39.3
					c0,4.3-0.7,8.5-2,12.4c-3.9,11.5-12.9,20.7-24.4,24.7c-3.4,1.2-6.9,1.9-10.6,2.1v9.3c4.3-0.2,8.5-0.9,12.4-2.2
					c15.8-5,28.1-17.8,32.3-33.9c1-4,1.6-8.1,1.6-12.4C554.8,77.4,533,55.6,506.2,55.6z"/>
                                                        </g>
                                                        <path class="st2" fill={st2} d="M521.2,147.8c0,1-0.1,1.9-0.4,2.8c-1.2,4.5-5.3,7.7-10.1,7.7c-5.8,0-10.5-4.7-10.5-10.5
				c0-5.8,4.7-10.5,10.5-10.5c3.4,0,6.4,1.6,8.3,4.1C520.4,143.1,521.2,145.3,521.2,147.8z"/>
                                                </g>
                                                <g>
                                                        <g>
                                                                <path class="st2" fill={st2} d="M680.4,55.6c-26.8,0-48.6,21.8-48.6,48.6h9.3c0-21.7,17.6-39.3,39.3-39.3c21.7,0,39.3,17.6,39.3,39.3
					c0,4.3-0.7,8.5-2,12.4c-3.9,11.5-12.9,20.7-24.4,24.7c-3.4,1.2-6.9,1.9-10.6,2.1v9.3c4.3-0.2,8.5-0.9,12.4-2.2
					c15.8-5,28.1-17.8,32.3-33.9c1-4,1.6-8.1,1.6-12.4C729,77.4,707.2,55.6,680.4,55.6z"/>
                                                        </g>
                                                        <path class="st2" fill={st2} d="M695.4,147.8c0,1-0.1,1.9-0.4,2.8c-1.2,4.5-5.3,7.7-10.1,7.7c-5.8,0-10.5-4.7-10.5-10.5
				c0-5.8,4.7-10.5,10.5-10.5c3.4,0,6.4,1.6,8.3,4.1C694.6,143.1,695.4,145.3,695.4,147.8z"/>
                                                </g>
                                                <g>
                                                        <g>
                                                                <path class="st2" fill={st2} d="M854.7,55.6c-26.8,0-48.6,21.8-48.6,48.6h9.3c0-21.7,17.6-39.3,39.3-39.3c21.7,0,39.3,17.6,39.3,39.3
					c0,4.3-0.7,8.5-2,12.4c-3.9,11.5-12.9,20.7-24.4,24.7c-3.4,1.2-6.9,1.9-10.6,2.1v9.3c4.3-0.2,8.5-0.9,12.4-2.2
					c15.8-5,28.1-17.8,32.3-33.9c1-4,1.6-8.1,1.6-12.4C903.3,77.4,881.5,55.6,854.7,55.6z"/>
                                                        </g>
                                                        <path class="st2" fill={st2} d="M869.7,147.8c0,1-0.1,1.9-0.4,2.8c-1.2,4.5-5.3,7.7-10.1,7.7c-5.8,0-10.5-4.7-10.5-10.5
				c0-5.8,4.7-10.5,10.5-10.5c3.4,0,6.4,1.6,8.3,4.1C868.9,143.1,869.7,145.3,869.7,147.8z"/>
                                                </g>
                                                <g>
                                                        <g>
                                                                <path class="st2" fill={st2} d="M1028.9,55.6c-26.8,0-48.6,21.8-48.6,48.6h9.3c0-21.7,17.6-39.3,39.3-39.3c21.7,0,39.3,17.6,39.3,39.3
					c0,4.3-0.7,8.5-2,12.4c-3.9,11.5-12.9,20.7-24.4,24.7c-3.4,1.2-6.9,1.9-10.6,2.1v9.3c4.3-0.2,8.5-0.9,12.4-2.2
					c15.8-5,28.1-17.8,32.3-33.9c1-4,1.6-8.1,1.6-12.4C1077.5,77.4,1055.7,55.6,1028.9,55.6z"/>
                                                        </g>
                                                        <path class="st2" fill={st2} d="M1044,147.8c0,1-0.1,1.9-0.4,2.8c-1.2,4.5-5.3,7.7-10.1,7.7c-5.8,0-10.5-4.7-10.5-10.5
				c0-5.8,4.7-10.5,10.5-10.5c3.4,0,6.4,1.6,8.3,4.1C1043.1,143.1,1044,145.3,1044,147.8z"/>
                                                </g>
                                                <g>
                                                        <g>
                                                                <path class="st2" fill={st2} d="M1203.2,55.6c-26.8,0-48.6,21.8-48.6,48.6h9.3c0-21.7,17.6-39.3,39.3-39.3c21.7,0,39.3,17.6,39.3,39.3
					c0,4.3-0.7,8.5-2,12.4c-3.9,11.5-12.9,20.7-24.4,24.7c-3.3,1.2-6.9,1.9-10.6,2.1v9.3c4.3-0.2,8.5-0.9,12.4-2.2
					c15.8-5,28.1-17.8,32.3-33.9c1.1-4,1.6-8.1,1.6-12.4C1251.8,77.4,1230,55.6,1203.2,55.6z"/>
                                                        </g>
                                                        <path class="st2" fill={st2} d="M1218.2,147.8c0,1-0.1,1.9-0.4,2.8c-1.2,4.5-5.3,7.7-10.1,7.7c-5.8,0-10.5-4.7-10.5-10.5
				c0-5.8,4.7-10.5,10.5-10.5c3.4,0,6.4,1.6,8.3,4.1C1217.4,143.1,1218.2,145.3,1218.2,147.8z"/>
                                                </g>
                                                <g>
                                                        <g>
                                                                <path class="st2" fill={st2} d="M1377.4,55.6c-26.8,0-48.6,21.8-48.6,48.6h9.3c0-21.7,17.6-39.3,39.3-39.3c21.7,0,39.3,17.6,39.3,39.3
					c0,4.3-0.7,8.5-2,12.4c-3.9,11.5-12.9,20.7-24.4,24.7c-3.3,1.2-6.9,1.9-10.6,2.1v9.3c4.3-0.2,8.5-0.9,12.4-2.2
					c15.8-5,28.1-17.8,32.3-33.9c1-4,1.6-8.1,1.6-12.4C1426,77.4,1404.2,55.6,1377.4,55.6z"/>
                                                        </g>
                                                        <path class="st2" fill={st2} d="M1392.5,147.8c0,1-0.1,1.9-0.4,2.8c-1.2,4.5-5.3,7.7-10.1,7.7c-5.8,0-10.5-4.7-10.5-10.5
				c0-5.8,4.7-10.5,10.5-10.5c3.4,0,6.4,1.6,8.3,4.1C1391.6,143.1,1392.5,145.3,1392.5,147.8z"/>
                                                </g>
                                                <g>
                                                        <g>
                                                                <path class="st2" fill={st2} d="M1551.7,55.6c-26.8,0-48.6,21.8-48.6,48.6h9.3c0-21.7,17.6-39.3,39.3-39.3c21.7,0,39.3,17.6,39.3,39.3
					c0,4.3-0.7,8.5-2,12.4c-3.9,11.5-12.9,20.7-24.4,24.7c-3.4,1.2-6.9,1.9-10.6,2.1v9.3c4.3-0.2,8.5-0.9,12.4-2.2
					c15.8-5,28.1-17.8,32.3-33.9c1-4,1.6-8.1,1.6-12.4C1600.3,77.4,1578.5,55.6,1551.7,55.6z"/>
                                                        </g>
                                                        <path class="st2" fill={st2} d="M1566.7,147.8c0,1-0.1,1.9-0.4,2.8c-1.2,4.5-5.3,7.7-10.1,7.7c-5.8,0-10.5-4.7-10.5-10.5
				c0-5.8,4.7-10.5,10.5-10.5c3.4,0,6.4,1.6,8.3,4.1C1565.9,143.1,1566.7,145.3,1566.7,147.8z"/>
                                                </g>
                                        </g>
                                        <g>
                                                <path class="st2" fill={st2} d="M1128.2,585.9h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1139.1,580.6,1134.8,585.9,1128.2,585.9z"/>
                                                <path class="st2" fill={st2} d="M1271.2,585.9h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1282.1,580.6,1277.8,585.9,1271.2,585.9z"/>
                                                <path class="st2" fill={st2} d="M1414.2,585.9h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1425.1,580.6,1420.8,585.9,1414.2,585.9z"/>
                                                <path class="st2" fill={st2} d="M699.2,585.9h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C710.1,580.6,705.8,585.9,699.2,585.9z"/>
                                                <path class="st0" fill={st0} d="M637.1,415.8h-24.4c-2.8,0-5.6-2.3-6.1-5.1l-4.7-24.4c-0.5-2.8,1.3-5.1,4.1-5.1h24.4c2.8,0,5.6,2.3,6.1,5.1
			l4.7,24.4C641.8,413.5,639.9,415.8,637.1,415.8z"/>
                                                <path class="st2" fill={st2} d="M842.2,585.9h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C853.1,580.6,848.8,585.9,842.2,585.9z"/>
                                                <path class="st2" fill={st2} d="M985.2,585.9h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C996.1,580.6,991.8,585.9,985.2,585.9z"/>
                                                <path class="st2" fill={st2} d="M1557.2,585.9h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1568.1,580.6,1563.8,585.9,1557.2,585.9z"/>
                                                <path class="st2" fill={st2} d="M1104.5,462.8h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1115.4,457.5,1111,462.8,1104.5,462.8z"/>
                                                <path class="st0" fill={st0} d="M1247.5,462.8h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1258.4,457.5,1254,462.8,1247.5,462.8z"/>
                                                <path class="st2" fill={st2} d="M1390.5,462.8h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1401.4,457.5,1397,462.8,1390.5,462.8z"/>
                                                <path class="st2" fill={st2} d="M1533.5,462.8h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1544.4,457.5,1540.1,462.8,1533.5,462.8z"/>
                                                <path class="st2" fill={st2} d="M1153.4,716.4h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1164.3,711,1160,716.4,1153.4,716.4z"/>
                                                <path class="st2" fill={st2} d="M1296.4,716.4h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1307.3,711,1303,716.4,1296.4,716.4z"/>
                                                <path class="st2" fill={st2} d="M1439.4,716.4h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1450.3,711,1446,716.4,1439.4,716.4z"/>
                                                <path class="st2" fill={st2} d="M724.4,716.4h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C735.3,711,730.9,716.4,724.4,716.4z"/>
                                                <path class="st2" fill={st2} d="M867.4,716.4h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C878.3,711,874,716.4,867.4,716.4z"/>
                                                <path class="st2" fill={st2} d="M1010.4,716.4h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1021.3,711,1017,716.4,1010.4,716.4z"/>
                                                <path class="st2" fill={st2} d="M1582.4,716.4h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1593.3,711,1589,716.4,1582.4,716.4z"/>
                                                <path class="st2" fill={st2} d="M1178.6,846.8h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1189.4,841.5,1185.1,846.8,1178.6,846.8z"/>
                                                <path class="st2" fill={st2} d="M1321.6,846.8h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1332.4,841.5,1328.1,846.8,1321.6,846.8z"/>
                                                <path class="st2" fill={st2} d="M1464.6,846.8h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1475.4,841.5,1471.1,846.8,1464.6,846.8z"/>
                                                <path class="st2" fill={st2} d="M749.6,846.8h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9H734
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C760.4,841.5,756.1,846.8,749.6,846.8z"/>
                                                <path class="st2" fill={st2} d="M892.6,846.8h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9H877
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C903.4,841.5,899.1,846.8,892.6,846.8z"/>
                                                <path class="st2" fill={st2} d="M1035.6,846.8h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1046.4,841.5,1042.1,846.8,1035.6,846.8z"/>
                                                <path class="st2" fill={st2} d="M1607.6,846.8h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1618.4,841.5,1614.1,846.8,1607.6,846.8z"/>
                                                <path class="st2" fill={st2} d="M1202.3,969.9h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1213.2,964.6,1208.9,969.9,1202.3,969.9z"/>
                                                <path class="st2" fill={st2} d="M1345.3,969.9h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1356.2,964.6,1351.9,969.9,1345.3,969.9z"/>
                                                <path class="st2" fill={st2} d="M1488.3,969.9h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1499.2,964.6,1494.9,969.9,1488.3,969.9z"/>
                                                <path class="st2" fill={st2} d="M773.3,969.9h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C784.2,964.6,779.9,969.9,773.3,969.9z"/>
                                                <path class="st2" fill={st2} d="M916.3,969.9h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C927.2,964.6,922.9,969.9,916.3,969.9z"/>
                                                <path class="st2" fill={st2} d="M1059.3,969.9h-56.7c-6.6,0-12.9-5.4-14.2-11.9l-10.9-56.7c-1.3-6.6,3.1-11.9,9.6-11.9h56.7
			c6.6,0,12.9,5.4,14.2,11.9l10.9,56.7C1070.2,964.6,1065.9,969.9,1059.3,969.9z"/>
                                                <path class="st2" fill={st2} d="M808.9,393.4H658.8c-2.2,0-4-1.8-4-4l0,0c0-2.2,1.8-4,4-4h150.1c2.2,0,4,1.8,4,4l0,0
			C812.9,391.6,811.1,393.4,808.9,393.4z"/>
                                                <path class="st2" fill={st2} d="M813.5,411.6H663.3c-2.2,0-4-1.8-4-4l0,0c0-2.2,1.8-4,4-4h150.1c2.2,0,4,1.8,4,4l0,0
			C817.5,409.8,815.7,411.6,813.5,411.6z"/>
                                        </g>
                                        <g>
                                                <path class="st2" fill={st2} d="M647.9,258h-51.5c-4.2,0-7.7-3.5-7.7-7.7l0,0c0-4.2,3.5-7.7,7.7-7.7h51.5c4.2,0,7.7,3.5,7.7,7.7l0,0
			C655.5,254.6,652.1,258,647.9,258z"/>
                                                <path class="st2" fill={st2} d="M789.2,258h-51.5c-4.2,0-7.7-3.5-7.7-7.7l0,0c0-4.2,3.5-7.7,7.7-7.7h51.5c4.2,0,7.7,3.5,7.7,7.7l0,0
			C796.9,254.6,793.5,258,789.2,258z"/>
                                                <path class="st2" fill={st2} d="M930.6,258h-51.5c-4.2,0-7.7-3.5-7.7-7.7l0,0c0-4.2,3.5-7.7,7.7-7.7h51.5c4.2,0,7.7,3.5,7.7,7.7l0,0
			C938.3,254.6,934.8,258,930.6,258z"/>
                                                <path class="st2" fill={st2} d="M1072,258h-51.5c-4.2,0-7.7-3.5-7.7-7.7l0,0c0-4.2,3.5-7.7,7.7-7.7h51.5c4.2,0,7.7,3.5,7.7,7.7l0,0
			C1079.6,254.6,1076.2,258,1072,258z"/>
                                                <path class="st2" fill={st2} d="M1213.3,258h-51.5c-4.2,0-7.7-3.5-7.7-7.7l0,0c0-4.2,3.5-7.7,7.7-7.7h51.5c4.2,0,7.7,3.5,7.7,7.7l0,0
			C1221,254.6,1217.6,258,1213.3,258z"/>
                                                <path class="st2" fill={st2} d="M1354.7,258h-51.5c-4.2,0-7.7-3.5-7.7-7.7l0,0c0-4.2,3.5-7.7,7.7-7.7h51.5c4.2,0,7.7,3.5,7.7,7.7l0,0
			C1362.4,254.6,1358.9,258,1354.7,258z"/>
                                                <path class="st2" fill={st2} d="M1496.1,258h-51.5c-4.2,0-7.7-3.5-7.7-7.7l0,0c0-4.2,3.5-7.7,7.7-7.7h51.5c4.2,0,7.7,3.5,7.7,7.7l0,0
			C1503.7,254.6,1500.3,258,1496.1,258z"/>
                                        </g>
                                        <g>
                                                <path class="st0" fill={st0} d="M1170.2,464c-11.4,0-19.7-3.6-24.2-10.6c-8.7-13.4-2-32.3,19.9-56c16-17.3,34.6-31,34.8-31.2
			c0.7-0.5,1.8-0.4,2.3,0.4c0.5,0.7,0.4,1.8-0.4,2.3c-0.7,0.5-72.5,53.7-53.8,82.7c5.9,9.2,18.6,9.8,28.2,8.7
			c17.8-2.1,41-11.6,62-25.6c21.8-14.5,37.2-30.8,42.1-44.8c3.3-9.2,2.3-15.7-3-19.3c-6.8-4.7-21-4.7-38,0
			c-16.3,4.5-32.6,12.6-42.7,21.1c-0.7,0.6-1.7,0.5-2.3-0.2c-0.6-0.7-0.5-1.7,0.2-2.3c10.4-8.8,27.3-17.2,43.9-21.8
			c18.2-5,33-4.8,40.7,0.5c4.6,3.2,9,9.7,4.2,23.1c-5.2,14.6-21,31.5-43.4,46.4c-21.5,14.3-45.2,24-63.5,26.2
			C1174.9,463.8,1172.5,464,1170.2,464z"/>
                                        </g>
                                        <g>
                                                <path class="st3" fill={st3} d="M1467.7,370.5l-5.8-11c0,0,2.9-19.7-21.7-32.1c0,0-9-3.7-14.1,3.7c-4.7,6.8,8.9,5.5,8.2,17.1
			c-0.7,11.6,4.8,18.4,12.4,22.1l6.3,7.3L1467.7,370.5z"/>
                                                <path class="st4" fill={st4} d="M1700.5,344.2c0,0-21.2,103.8-137.6,137.8c-36.3,10.6-118.9-109.1-118.9-109.1s2.9-11,21.1-8.5
			c0,0,80.4,53.2,90.5,59c9,5.1,68-65.9,95-77.4C1677.5,334.5,1700.5,344.2,1700.5,344.2z"/>
                                                <g>

                                                        <rect x="1413" y="278.7" transform="matrix(-0.1821 -0.9833 0.9833 -0.1821 1348.0206 1807.6152)" fill={st0} class="st0" width="25.6" height="128.9" />
                                                        <polygon class="st3" fill={st3} points="1333,360.4 1364.8,367.5 1360.1,342.3 			" />
                                                        <path class="st3" fill={st3} d="M1503.1,324.5l1.5,8c0.9,4.9-2.3,9.6-7.2,10.5l-5.9,1.1l-4.7-25.2l6.4-1.2
				C1497.8,316.8,1502.2,319.9,1503.1,324.5z"/>
                                                </g>
                                                <g>
                                                        <path class="st0" fill={st0} d="M1839.5,996.9c0,0,14.4,13.2,11.6,22.5c-2.8,9.4-15.9,15.6-17.6,27.1c-1.8,11.4-8.8,17.7-15.1,17.7
				c-6.3,0-30.2,0-30.2,0s-4.7-8.1,6.6-10.5c11.3-2.4,15.5-15.9,14.8-28.4s1.1-29.1,1.1-29.1l24-5.5L1839.5,996.9z"/>
                                                        <path class="st0" fill={st0} d="M1662.3,1031c0,0,2.6,30-3.7,33.1l-81.1-0.2c0,0,0-9.4,13-11.4s35.9-9.7,39.8-26.7
				C1634.1,1008.8,1662.3,1031,1662.3,1031z"/>
                                                        <path class="st0" fill={st0} d="M1593.8,607.3c0,0,4.3,52.6,9.3,115.8c6.8,85.9,15.1,191.3,15.9,211.3c1.4,34.7,7,90.2,7,90.2
				s13.4,13.2,40.2,7.6c0,0,23.9-79.2,30.7-163c0.1-1.4,0.2-2.8,0.3-4.1c6.3-85.3,16.2-265,16.2-265L1593.8,607.3z"/>
                                                        <path class="st0" fill={st0} d="M1593.8,607.3c0,0,4.3,52.6,9.3,115.8l93.8,146.1c0.1-1.4,0.2-2.8,0.3-4.1c6.3-85.3,16.2-265,16.2-265
				L1593.8,607.3z"/>
                                                        <path class="st0" fill={st0} d="M1613.4,613.5c0,0,77.1,190.4,127.9,279.9c50.7,89.5,66.6,114.5,66.6,114.5s29.1-11.4,31.7-19.3
				c0,0-19.4-172.8-53.4-214.9c-19.2-23.8-41.7-95.2-42.8-120.2c-1.1-25-6.9-74.4-6.9-74.4L1613.4,613.5z"/>
                                                </g>
                                                <path class="st3" fill={st3} d="M1714.9,320.1c0,0-52.2,25.2-82.3,15.8c0,0,7.4-9.3,20.4-16.3c-1-6-1.9-11.3-2.3-15.7
			c-1.3-10.3-1.3-16.1-1.3-16.1l20.1-15.8l19.1-15.2C1687.1,299.4,1714.9,320.1,1714.9,320.1z"/>
                                                <path class="st5" fill={st5} d="M1590.5,611.6c0.9,0.8,2,1.2,3.1,1.2c11.3-0.2,44.3,6,72.2,3.2c23.6-2.3,44.4-9.1,66.8-18.9
			c3-1.4,9.8-5,9.7-8.2c-0.1-3.6-0.5-8.8-0.9-15.4c-0.9-13-2.3-31.5-2.7-53.4c-0.3-16.2-0.1-34.4,1-53.6c0.1-1.3,0.1-2.6,0.2-3.9
			c0.3-7,0.5-14.1,0.5-21.1c0.1-22.3-1.5-43.6-4.7-62.7c-5.7-33.1-16.3-58.9-32.1-70.4c-2.1-1.6-15.1,4.7-27.1,10.5
			c-11,5.4-22,5.9-32.4,3.9l-10.4,9.1c-12.6,11.5-26.5,27.3-32.6,51.3c-5.1,20.3-8.1,57-9.7,95.1c-0.8,17.3-1.4,34.6-1.6,51.2v0.6
			c-0.1,1.7-0.1,3.4-0.1,5.1C1589.1,577.4,1589.7,611.1,1590.5,611.6z"/>
                                                <path class="st6" d="M1688.9,273.1c0,0-13.2,24-38.3,30.8c-1.3-10.3-1.3-16.1-1.3-16.1l20.1-15.8L1688.9,273.1z" />
                                                <path class="st3" fill={st3} d="M1640.2,287.1c0,0,49.8,8.1,54.5-22.5c4.7-30.6,14.2-50.2-17.3-56.7c-31.6-6.5-39.6,4.3-43.7,14.1
			C1629.6,231.9,1627.3,284.2,1640.2,287.1z"/>
                                                <path class="st7" d="M1712.7,204.3c0.5-0.5,1.5-0.9,3.8-1.1c0,0-1.9-1.2-4.3-1.2c-0.5-3.2-2.2-8.9-7.8-10.3c0,0,3,1.6,3.4,5
			c-6.4-6.6-20.2-11.4-58.4-8.4c-50.3,4-33.7,36.2-27.2,40c6.5,3.8,22.9-6.7,35-5.7c12.1,1,14.4,18.5,18.6,20.9
			c4.1,2.4,2.5-1.8,8.1-4.4c5.6-2.5,6.1,6.7,4.5,12.7c-1.6,6,6,13.9,6,13.9s0,0,7.7-8.3c7.7-8.3,15-43.6,11.5-51.1
			C1713.4,205.7,1713.1,205,1712.7,204.3z"/>
                                                <path class="st3" fill={st3} d="M1461.7,359c0,0,1.7-6.8-3-9.8c-4.7-3-9-11.3-11-10.2c-4.1,2.3,2.2,9.5,1.1,18.5
			C1448.5,360.7,1455.8,368.9,1461.7,359z"/>
                                                <path class="st4" fill={st4} d="M1738.8,520.1c-0.3-16.2-0.1-34.4,1-53.6c0.1-1.3,0.1-2.6,0.2-3.9c-4.7-23.5-11.9-44.3-22.4-44.9
			C1717.6,417.7,1686.2,467.1,1738.8,520.1z"/>
                                                <path class="st3" fill={st3} d="M1780.3,605.2c0,0-2.6,25.4-5.5,30.3c-2.9,4.8-15.1,20.2-20.3,23.2c-5.2,3,3.2-20.3,2.1-24.8
			c-1.1-4.5-4.3,8.3-6.6,8.6c-2.3,0.3-1.7-11.2-1.8-15.4c-0.1-4.2,11.2-14.5,15.1-23.5C1767.4,594.7,1780.3,605.2,1780.3,605.2z"/>
                                                <path class="st5" fill={st5} d="M1703.7,308.2c0,0,31.5,14.9,46.6,37.9c23.4,35.6,50.6,165.2,51.1,177.3c0.5,12-19.8,96.8-19.8,96.8
			s-20.6-1.4-25.9-11.8c0,0,4.1-87.1,1.5-92.7c-2.6-5.6-85.9-116.8-76.6-140.6C1690,351.3,1703.7,308.2,1703.7,308.2z"/>
                                                <polygon class="st0" fill={st0} points="1349.3,349.5 1350.9,364.4 1333,360.4 		" />
                                        </g>
                                        <path class="st0" fill={st0} d="M2231,1070H0.1c-1.7,0-3.1-1.4-3.1-3.1v-1c0-1.7,1.4-3.1,3.1-3.1H2231c1.7,0,3.1,1.4,3.1,3.1v1
		C2234,1068.6,2232.7,1070,2231,1070z"/>
                                </g>
                        </svg>


                </>
        )
}
export default CalendarSvg
