import React from 'react'
import { useSelector } from 'react-redux'

const DownloadLogoSvg = () => {
    
  const { default_color } = useSelector((s) => s.layoutReduceData)
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 14.1272C10.8895 14.1272 10.7867 14.1096 10.6915 14.0743C10.5963 14.0391 10.5058 13.9785 10.42 13.8927L7.56956 11.0423C7.43323 10.906 7.36596 10.7465 7.36773 10.5637C7.36949 10.381 7.43676 10.2185 7.56956 10.0763C7.71176 9.93411 7.87511 9.86066 8.05961 9.85595C8.24412 9.85125 8.40748 9.92 8.54968 10.0622L10.3125 11.825V4.8125C10.3125 4.61742 10.3783 4.45407 10.5099 4.32245C10.6415 4.19082 10.8049 4.125 11 4.125C11.1951 4.125 11.3584 4.19082 11.49 4.32245C11.6216 4.45407 11.6875 4.61742 11.6875 4.8125V11.825L13.4503 10.0622C13.5866 9.92588 13.7485 9.8586 13.9359 9.86035C14.1234 9.86213 14.2882 9.93411 14.4304 10.0763C14.5632 10.2185 14.6319 10.3795 14.6366 10.5593C14.6413 10.7391 14.5726 10.9001 14.4304 11.0423L11.5799 13.8927C11.4941 13.9785 11.4037 14.0391 11.3085 14.0743C11.2133 14.1096 11.1105 14.1272 11 14.1272ZM5.78206 17.875C5.31902 17.875 4.92708 17.7145 4.60625 17.3937C4.28542 17.0729 4.125 16.6809 4.125 16.2179V14.4198C4.125 14.2248 4.19082 14.0614 4.32245 13.9298C4.45407 13.7982 4.61742 13.7324 4.8125 13.7324C5.00758 13.7324 5.17093 13.7982 5.30255 13.9298C5.43417 14.0614 5.49998 14.2248 5.49998 14.4198V16.2179C5.49998 16.2884 5.52936 16.3531 5.58811 16.4118C5.64689 16.4706 5.71154 16.5 5.78206 16.5H16.2179C16.2884 16.5 16.3531 16.4706 16.4118 16.4118C16.4706 16.3531 16.5 16.2884 16.5 16.2179V14.4198C16.5 14.2248 16.5658 14.0614 16.6974 13.9298C16.829 13.7982 16.9924 13.7324 17.1875 13.7324C17.3825 13.7324 17.5459 13.7982 17.6775 13.9298C17.8091 14.0614 17.875 14.2248 17.875 14.4198V16.2179C17.875 16.6809 17.7145 17.0729 17.3937 17.3937C17.0729 17.7145 16.6809 17.875 16.2179 17.875H5.78206Z" fill={default_color}/>
    </svg>
    
    
  )
}

export default DownloadLogoSvg