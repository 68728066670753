import React, { useEffect, useState } from "react";
import {
  HeadingXLarge,
  LabelLarge,
  LabelMedium,
  ParagraphLarge,
  ParagraphMedium,
} from "baseui/typography";
import _, { isError } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { TextBox } from "../../components/TextBox";
import { useIntl } from "react-intl";
import { Avatar } from "baseui/avatar";
import { useMutation, useQuery } from "react-query";
import { TextButton } from "../../components/TextButton";
import {
  getAuthDtl,
  getConfigField,
  getHomePage,
} from "../../services/customerPortal";
import { toast } from "react-toastify";
import * as Yup from "yup";
import InputComponent from "../AuthorizeDownload/InputComponent";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { StyledLoadingSpinner } from "baseui/button";
import {
  createTheme,
  BaseProvider,
  lightThemePrimitives,
  ThemeProvider,
  darkThemePrimitives,
  DarkTheme,
} from "baseui";
import { useDispatch } from "react-redux";
import { defaultColor } from "../../redux/Layout/action";
import { Helmet } from "react-helmet";
import ChevronUp from "../../assets/img/svg/ChevronUp";
import ChevronDown from "../../assets/img/svg/ChevronDown";

let faqJSON = [
  {
    title: "Why am I being asked to repay tuition assistance?",
    sub_title:
      "Under the TAB program, faculty and staff who receive tuition assistance are required to remain employed with USC for a minimum of two years following the completion of their degree. If this requirement is not met, under the terms expressly provided in the TAB policy, the university has the right to recover the costs of tuition assistance provided.",
  },
  {
    title: "What is TAB balance owed",
    sub_title:
      "If you received notice that you have a TAB balance owed, this is because you were identified as a recipient of TAB coverage that did not fulfill the above mentioned program requirement. As a result, you are responsible for repaying the tuition assistance that was provided to you.",
  },
  {
    title: "How much do I owe and how was the amount I owe calculated?",
    sub_title:
      "The amount you owe, otherwise known as your TAB balance, was provided in written and email communications sent to the addresses we have on file from your time working at USC.  It represents the total tuition assistance provided to you minus any applicable deductions.",
  },
  {
    title: "Can I pay in installments?",
    sub_title:
      "Yes, you can request an installment plan to spread out the repayment over a period of time by contacting tuitionassistancebalance@usc.edu or select the repayment options on your personalized web link.",
  },
  {
    title: "What if I’m facing financial hardship?",
    sub_title:
      "If you are experiencing financial difficulties, please reach out to tuitionassistancebalance@usc.edu to discuss alternative repayment arrangements.",
  },
  {
    title: "Can I appeal the repayment request?",
    sub_title:
      "You may submit a written appeal explaining your circumstances to tuitionassistancebalance@usc.edu.. However, the final decision will be based on the terms of the USC & TAB policies. Appeals are not typically granted, but you may submit a request for consideration.",
  },
  {
    title: "Is the repayment requirement a standard policy?",
    sub_title:
      "Yes, this requirement is standard for all former employees who received tuition assistance and did not fulfill the two-year employment obligation.",
  },
  {
    title: "Will this affect my credit score?",
    sub_title:
      "If the repayment is referred to a collections agency, it could potentially affect your credit score.",
  },
  {
    title: "How long do I have to repay the tuition?",
    sub_title:
      "Within 30 days of receiving the repayment notice, you will be expected to arrange a payment plan or appeal the repayment request.",
  },
  {
    title: "Can I repay online?",
    sub_title:
      "Yes, you can make payments online through the provided payment portal link in the communication.",
  },
  {
    title: "What forms of payment are accepted?",
    sub_title:
      "USC accepts various forms of payment, including credit/debit cards and electronic checks. Details will be provided upon setting up your payment plan.",
  },
  {
    title: "I no longer live in the state; do I still need to repay?",
    sub_title:
      "Yes, regardless of your current residence, the repayment requirement applies.",
  },
  {
    title: "What if I already paid some of the tuition costs out of pocket?",
    sub_title:
      "If you have already made payments, please provide documentation, and your total repayment amount will be adjusted accordingly.",
  },
  {
    title: "What documentation do I need to provide for financial hardship?",
    sub_title:
      "You may be asked to provide documents such as income statements or proof of financial obligations to support your hardship claim. Decisions are made on a case by case basis.",
  },
  {
    title: "Are there any penalties or interest on the repayment?",
    sub_title:
      "No, there are no penalties or interest if you comply with the repayment terms. However, delayed payments may result in additional actions. If you miss a payment, please contact us immediately to avoid further action. Consistent failure to pay may result in changes to any negotiated payment plans.",
  },
  {
    title: "What if I am still employed by USC but in a different capacity?",
    sub_title:
      "If you are still employed by USC, please inform us of your continued employment status. Be prepared to share your current employee ID, department and the name of your manager.",
  },
  {
    title: "How do I know if I qualify for an exemption?",
    sub_title:
      "Exemptions are rare and usually apply in extraordinary circumstances. Contact our office to discuss your situation.",
  },
  {
    title: "What if I have already made arrangements with another department?",
    sub_title:
      "Please provide written details of any prior arrangements, and we will coordinate with the relevant department.",
  },
  {
    title: "How long does it take to process my repayment?",
    sub_title:
      "Processing times may vary, but you will receive confirmation once your payment has been applied. You will receive a receipt via email from our payment processor. ",
  },
  {
    title: "Can I make a partial payment?",
    sub_title:
      "Partial payments are only accepted as part of an approved installment plan unless otherwise negotiated with a representative. ",
  },
];

const FrequentlyAskedQuestion = (props) => {
  const [isState, setState] = useState(false);
  function openClose() {
    setState(!isState);
  }
  // ${isState ? "active_effect" : ""}
  return (
    <div onClick={() => openClose()} className={`faq_container `}>
      <div className="faq_title_container">
        <LabelLarge>{props.title}</LabelLarge>
        {isState ? <ChevronUp /> : <ChevronDown />}
      </div>
      {isState ? <ParagraphLarge>{props.sub_title}</ParagraphLarge> : <></>}
    </div>
  );
};

const HomeContent = ({ orgLogo, headerColor, orgName }) => {
  const [isState, setState] = useState(false);

  function hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
      );
    }
    throw new Error("Bad Hex");
  }

  function getTextColor(rgba) {
    rgba = rgba.match(/\d+/g);
    if (rgba[0] * 0.299 + rgba[1] * 0.587 + rgba[2] * 0.114 > 186) {
      return "#08083a";
    } else {
      return "white";
    }
  }

  const rgba = hexToRgbA(headerColor);

  const textColor = getTextColor(rgba);

  return (
    <>
      <Helmet>
        <title>{orgName}</title>
        <link rel="icon" href={orgLogo} />
      </Helmet>
      <div
        className="header_home_page_payabel"
        style={{
          backgroundColor: headerColor,
        }}
      >
        <h4
          className="welcome_home_header"
          style={{ color: textColor ? textColor : "#ffffff" }}
        >
          USC Tution Assistace FAQ
        </h4>
      </div>
      <div className="customize-ui-main-container">
        <div className={"customize-ui-container"}>
          <div style={{ padding: "20px 20px 0px" }}>
            {orgLogo ? (
              <img
                src={orgLogo}
                style={{
                  width: "200px",
                  height: "180px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <Avatar
                src={orgLogo}
                size="100%"
                overrides={{
                  Root: {
                    style: () => ({
                      width: "150px",
                      height: "150px",
                      // background: "#000000",
                    }),
                  },
                  Initials: {
                    style: ({ $theme }) => ({
                      fontSize: "50px",
                    }),
                  },
                }}
                name={orgName}
              />
            )}
          </div>
          <div className="usc-landing-container">
            {/* start content */}
            <HeadingXLarge>USC Tuition Assistance Balance</HeadingXLarge>
            <ParagraphLarge>
              This page is designed to support you through the process of
              repaying tuition assistance received under the University of
              Southern California’s Tuition Assistance Benefit (TAB) program. If
              you have received notice that a repayment is due, please review
              the information below carefully.
            </ParagraphLarge>
            <br />
            <ParagraphLarge>
              For questions or to discuss your repayment options, please reach
              out to{" "}
              <span
                style={{
                  fontSize: "16px",
                  textDecoration: "underline",
                  color: "#516beb",
                }}
                onClick={() => {
                  window.location.assign(
                    `mailto:tuitionassistancebalance@usc.edu.`
                  );
                }}
              >
                {" "}
                tuitionassistancebalance@usc.edu.
              </span>
            </ParagraphLarge>
            {/* end content */}

            {/* start content */}
            <HeadingXLarge $style={{ textAlign: "center" }}>
              Understanding Your Obligation
            </HeadingXLarge>
            <HeadingXLarge>Why Repayment is Required</HeadingXLarge>
            <ParagraphLarge>
              Under the USC TAB program policy, faculty and staff who receive
              tuition assistance are required to remain employed with USC for a
              minimum of two years following the completion of their degree.
              This commitment ensures that the benefits provided by the
              university contribute to the long-term development and service of
              our institution. If this requirement is not met, the university
              has the right to recover the costs of tuition assistance provided.
            </ParagraphLarge>
            <br />

            {/* end content */}

            {/* start content */}
            <HeadingXLarge>How Repayment Amount is Calculated</HeadingXLarge>
            <ParagraphLarge>
              Repayment amount – or your tuition assistance balance – is the
              total tuition assistance provided during your enrollment, minus
              any applicable deductions or payments made directly by you. This
              amount will have been detailed in the communication you received.
            </ParagraphLarge>
            <br />

            {/* end content */}

            {/* start content */}
            <HeadingXLarge>Exemptions and Waivers</HeadingXLarge>
            <ParagraphLarge>
              In some cases, exemptions from repayment may be granted due to
              extenuating circumstances. These will be considered on a
              case-by-case basis and require substantial documentation. There is
              no guarantee that an exemption will be granted. If you believe you
              qualify for an exemption, please contact
              tuitionassistancebalance@usc.edu with supporting documentation for
              review.
            </ParagraphLarge>
            <br />

            {/* end content */}

            <HeadingXLarge $style={{ textAlign: "center" }}>
              Repayment Options
            </HeadingXLarge>
            <HeadingXLarge>Flexible Payment Plans</HeadingXLarge>
            <ParagraphLarge>
              We understand that repayment may present financial challenges,
              which is why USC offers flexible payment options:
            </ParagraphLarge>

            <ul className="usc_list_ul">
              <li>
                <b>Lump Sum Payment:</b>The full amount due can be paid in a
                single payment. This option may be preferable if you wish to
                settle the debt quickly.
              </li>
              <li>
                <b>Installment Plans:</b>We offer the option to spread the
                repayment over a period of time, typically ranging from 6 to 24
                months, depending on the amount owed. Installment plans are
                interest-free and are designed to minimize financial strain.
              </li>
              <li>
                <b>Financial Hardship Consideration:</b>If you are experiencing
                financial hardship, you may qualify for an alternative repayment
                plan. Please contact our office to discuss your situation. We
                may require documentation of your financial status to assess
                your eligibility for special arrangements.
              </li>
            </ul>
            <br />

            <HeadingXLarge>How to Make a Payment</HeadingXLarge>
            <ParagraphLarge>
              Payments can be made through the following methods:
            </ParagraphLarge>

            <ul className="usc_list_ul">
              <li>
                <b>Online Payment Portal:</b>Securely pay via credit/debit card
                or electronic check through our online portal. [Link to Payment
                Portal]
              </li>
              <li>
                <b>Mail:</b>You may send a check or money order to the address
                provided in your communication. Please include your name and
                reference number on the payment.
              </li>
            </ul>
            <br />

            <HeadingXLarge $style={{ textAlign: "center" }}>
              FAQs and Support
            </HeadingXLarge>
            <HeadingXLarge>Frequently Asked Questions</HeadingXLarge>
            <ParagraphLarge>
              We have compiled a list of frequently asked questions (FAQs) that
              address common concerns related to the repayment process. Please
              review these before contacting our office, as they may provide the
              information you need. [Link to FAQs]
            </ParagraphLarge>
            <br />

            <HeadingXLarge>Contact Us</HeadingXLarge>
            <ParagraphLarge>
              If you have additional questions or need assistance, our support
              team is here to help. You can reach us via:
            </ParagraphLarge>

            <ul className="usc_list_ul">
              <li>
                <b>Email:</b>Securely pay via credit/debit card or electronic
                check through our online portal. [Link to Payment Portal]
              </li>
              <li>
                <b>Mail:</b>
                <span
                  style={{
                    fontSize: "16px",
                    textDecoration: "underline",
                    color: "#516beb",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.location.assign(
                      `mailto:tuitionassistancebalance@usc.edu.`
                    );
                  }}
                >
                  {" "}
                  tuitionassistancebalance@usc.edu.
                </span>
              </li>
            </ul>
            <br />
            <ParagraphLarge>
              Thank you for your attention to this matter. We value your
              contribution to USC and are committed to working with you to
              resolve this situation in a fair and respectful manner.
            </ParagraphLarge>
            <ParagraphLarge>
              <b>University of Southern California</b>
            </ParagraphLarge>
            <ParagraphLarge>
              <b>Email:</b>
              <span
                style={{
                  fontSize: "16px",
                  textDecoration: "underline",
                  color: "#516beb",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.location.assign(
                    `mailto:tuitionassistancebalance@usc.edu.`
                  );
                }}
              >
                {" "}
                tuitionassistancebalance@usc.edu.
              </span>
            </ParagraphLarge>
            <br />
            <HeadingXLarge $style={{ textAlign: "center" }}>
              Frequently Asked Questions (FAQs)
            </HeadingXLarge>

            <div className="faq_main_cointainer">
              {faqJSON.map((e) => {
                return <FrequentlyAskedQuestion {...e} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const FAQ = () => {
  const dispatch = useDispatch();

  const { data, isError, isFetched, isLoading } = useQuery(
    [`GET_ORG_DTL_AUTH`],
    async () => {
      return await getAuthDtl({
        domain:
          window.location.host.search(/localhost/) >= 0
            ? "dummy.mxfy.io"
            : window.location.host,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StyledLoadingSpinner />
      </div>
    );
  }

  return (
    <HomeContent
      headerColor={_.get(
        data,
        "data.doc.organization.customer_portal_ui.header_color"
      )}
      orgId={_.get(data, "data.doc.organization._id", "")}
      orgName={_.get(data, "data.doc.organization.name", "")}
      timeZone={_.get(data, "data.doc.organization.time_zone", "")}
      email={_.get(data, "data.doc.organization.email", "")}
      orgLogo={_.get(data, "data.doc.organization.customer_portal_ui.logo", "")}
      initialConfig={_.get(data, "data.doc.organization.hm_pg_conf", [])}
      headerCont={_.get(data, "data.doc.organization.prtl_hd_cont", "")}
    />
  );
};

export default FAQ;
