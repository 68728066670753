import DocFileType from "../../assets/img/svg/DocFIleType";

const DocumentType = ({ type }) => {
  switch (type) {
    case "pdf":
      return (
        <div className="file_wraper_icon">
          <DocFileType />
          <p
            className={`file_text_wraper --file_name_position ${
              type.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingTop: "5px" }}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "jpg":
    case "jpeg":
    case "png":
    case "bmp":
      return (
        <div className="file_wraper_icon --doc_purple">
          <DocFileType />
          <p
            className={`file_text_wraper --file_name_position ${
              type.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingTop: "5px" }}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "csv":
    case "xls":
    case "xlsx":
      return (
        <div className="file_wraper_icon --doc_green">
          <DocFileType />
          <p
            className={`file_text_wraper --file_name_position ${
              type.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingTop: "5px" }}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "doc":
    case "docx":
      return (
        <div className="file_wraper_icon --doc_blue">
          <DocFileType />
          <p
            className={`file_text_wraper --file_name_position ${
              type.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingTop: "5px" }}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "txt":
    case "plain":
      return (
        <div className="file_wraper_icon --doc_grey">
          <DocFileType />
          <p
            className={`file_text_wraper --file_name_position ${
              type.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingLeft: "2px", paddingTop: "5px" }}
          >
            {type === "plain" ? "TXT" : type.toUpperCase()}

            {/* {type.toUpperCase()} */}
          </p>
        </div>
      );

    case "TEMPLATE":
    case "DOCUMENT":
      return (
        <div className="file_wraper_icon">
          <DocFileType />
          <p
            className={`file_text_wraper --file_name_position`}
            style={{ paddingTop: "5px" }}
          >
            PDF
          </p>
        </div>
      );

    default:
      return (
        <div className="file_wraper_icon">
          <DocFileType />
          <p
            className={`file_text_wraper --file_name_position ${
              type?.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingTop: "5px" }}
          >
            {type ? type.toUpperCase() : "PDF"}
          </p>
        </div>
      );
  }
};
export default DocumentType;
