import React from "react";
import { Paragraph2 } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import { Link, useNavigate } from "react-router-dom";

function CustomerHyperlink({
  value,
  column,
  cell: {
    row: { original },
  },
  alignment,
  ...rest
}) {
  const navigate = useNavigate();

  const [visible, setVisible] = React.useState(false);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  return (
    <>
      {visible ? (
        <StatefulTooltip accessibilityType={"tooltip"} content={value}>
          <Paragraph2
            ref={ref}
            onClick={() => {
              navigate(`/customers/${original._id}`);
            }}
            $style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
              width: "100%",
              textAlign: alignment,
              margin: "5px",
            }}
          >
            <div className="elip" ref={ref}>
              {value}
            </div>
          </Paragraph2>
        </StatefulTooltip>
      ) : (
        <Paragraph2
          ref={ref}
          onClick={() => {
            navigate(`/customers/${original._id}`);
          }}
          $style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
            width: "100%",
            textAlign: alignment,
            margin: "5px",
          }}
        >
          {value}
        </Paragraph2>
      )}
    </>
  );
}
export default CustomerHyperlink;
