import React from "react";

function SortUp({ fill = "#D6D6D6", ...rest }) {
  return (
    <svg
      {...rest}
      width="9"
      height="5"
      viewBox="0 0 9 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 -3.57746e-08L9 4.5L7.15256e-07 4.5L4.5 -3.57746e-08Z"
        fill={fill}
      />
    </svg>
  );
}

export default SortUp;
