import { SET_CUSTOMIZE_UI } from "./action";

const initialState = { popUp: false, selectedInvoice: [], data: null };

const customizeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CUSTOMIZE_UI:
      return {
        ...state,
        data: payload, // Store the fetched data in the Redux state
      };
    default:
      return state;
  }
};

export default customizeReducer;
