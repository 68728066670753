import React from "react";
import RequestingTime from "../../containers/RequestingTime/RequestingTime";
import Layout from "../Layout/Layout";

const RequestingTimeView = () => {
  return (
    // <Layout>
      <RequestingTime />
    // </Layout>
  );
};

export default RequestingTimeView;
