export const download = ({ fileName, fileData, fileOptions = {} }) => {
  const url = window.URL.createObjectURL(
    new Blob([fileData], { ...fileOptions })
  );

  const link = document.createElement("a");

  link.href = url;

  link.setAttribute("download", fileName);

  document.body.appendChild(link);

  link.click();

  link.parentNode.removeChild(link);
};
