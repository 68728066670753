import AlphaNumeric from "./AlphaNumeric/AlphaNumeric";
import Currency from "./Currency/Currency";
import Date from "./Date/Date";
import CustomerHyperlink from "./CustomerHyperlink";
import CustomerInvoiceNumDate from "./CustomerInvoiceNumDate";
import CustomerInvoiceOutstanding from "./CustomerInvoiceOutstanding";
import AvailableAmount from "./AvailableAmount";
import CustomerNoteId from "./CustomerNoteId";
import CreditNoteAmount from "./CreditNoteAmount";
import DateOrgTZ from "./DateOrgTZ/Date";
import InvoiceDownload from "./InvoiceDownload/InvoiceDownload";
import InvoiceStatus from "./InvoiceStatus/InvoiceStatus";
import DateStatus from "./DateStatus/DateStatus";
import DayDifference from "./DayDifference/DayDifference";
import DownloadInvoice from "./DownloadInvoice.js/DownloadInvoice";
import ReferenceValue from "./ReferenceValue/ReferenceValue";
import User from "./User";
import HyperLink from "./HyperLink/HyperLink";

const tableCellFilter = ({ filterType, alignment, accessor, rdName }) => {
  switch (filterType) {
    case "ALPHANUMERIC":
      return (props) => AlphaNumeric({ ...props, alignment, accessor });
    case "HYPERLINK":
      return (props) => HyperLink({ ...props, alignment, accessor });
    case "USER":
      return (props) => User({ ...props, alignment, accessor });
    case "DATE":
      return (props) => Date({ ...props, alignment, accessor });

    case "DATEORGTZ":
      return (props) => DateOrgTZ({ ...props, alignment, accessor });

    case "CURRENCY":
      return (props) => Currency({ ...props, alignment, accessor });

    case "CUSTOMER_HYPERLINK":
      return (props) => CustomerHyperlink({ ...props, alignment, accessor });

    case "CUSTOMER_INVOICE_NUM_DATE":
      return (props) =>
        CustomerInvoiceNumDate({ ...props, alignment, accessor });

    case "CUSTOMER_INVOICE_OUTSTANDING":
      return (props) =>
        CustomerInvoiceOutstanding({ ...props, alignment, accessor });

    case "CREDIT_NOTE_ID":
      return (props) => CustomerNoteId({ ...props, alignment, accessor });

    case "AVAILABLE_AMOUNT":
      return (props) => AvailableAmount({ ...props, alignment, accessor });

    case "CREDIT_NOTE_AMOUNT":
      return (props) => CreditNoteAmount({ ...props, alignment, accessor });

    case "INVOICE_DOWNLOAD":
      return (props) => InvoiceDownload({ ...props, alignment, accessor });

    case "INVOICE_STATUS":
      return (props) => InvoiceStatus({ ...props, alignment, accessor });

    case "DATE_STATUS":
      return (props) => DateStatus({ ...props, alignment, accessor });

    case "DAY_DIFFERENCE":
      return (props) => DayDifference({ ...props, alignment, accessor });
    case "DOWNLOAD_INVOICE":
      return (props) => DownloadInvoice({ ...props, alignment, accessor });
    case "REFERENCE_VALUE":
      return (props) =>
        ReferenceValue({ ...props, alignment, accessor, rdName });
    default:
      return (props) => AlphaNumeric({ ...props, alignment, accessor });
  }
};

export default tableCellFilter;
