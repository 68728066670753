import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import powerBy from "./pw-by.png";
import "./sideBar.css";
import CreditNoteSvg from "../../assets/img/svg/CreditNoteSvg";
import InvoicesSvg from "../../assets/img/svg/InvoicesSvg";
import MessagesSvg from "../../assets/img/svg/MessagesSvg";
import PaymentsSvg from "../../assets/img/svg/PaymentsSvg";
import RequestingCallSvg from "../../assets/img/svg/RequestingCallSvg";
import RequestingTimeSvg from "../../assets/img/svg/RequestingTimeSvg";
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip";
import {
  HeadingXSmall,
  LabelLarge,
  LabelMedium,
  LabelXSmall,
  ParagraphLarge,
} from "baseui/typography";
import More from "../../assets/img/svg/More";
import { useQuery } from "react-query";
import { getLayout } from "../../services/customerPortal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLayoutData } from "../../redux/Layout/action";
// import { getLayoutData } from "../../redux/Layout/action";
import { Avatar } from "baseui/avatar";

function IconCompo({ Icon, path, name, secondName }) {
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        isActive ? "icon-parent hoverclass" : "icon-parent"
      }
    >
      <div className="icon-child">
        <Icon />
        <LabelMedium $style={{ color: "#6A7187" }} className="icon">
          {name}
        </LabelMedium>
      </div>
    </NavLink>
  );
}

const SideBar = (props) => {
  // let { hash } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const web = process.env.REACT_APP_WEB_LINK;

  const hash = location.pathname.split("/")[1];


  let organizationData = useSelector((e) => e.layoutReduceData.organization);
  let accessValue = useSelector((e) => e.layoutReduceData.accessData);
  const { default_color } = useSelector((s) => s.layoutReduceData);

  return (
    <>
      <div className="sidebar-wrapper desktop-view">
        <div>
          <div className="sidebar-top">
            {organizationData && organizationData.logo ? (
              <div className="logo-align">
                <img src={organizationData?.logo} alt="" />
              </div>
            ) : (
              <div
                style={{
                  textAlign: "left",
                  width: "100%",
                  paddingLeft: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    width: "155px",
                  }}
                >
                  <Avatar
                    overrides={{
                      Avatar: {
                        style: ({ $theme }) => ({
                          borderTopLeftRadius: $theme.borders.radius100,
                          borderTopRightRadius: $theme.borders.radius100,
                          borderBottomRightRadius: $theme.borders.radius100,
                          borderBottomLeftRadius: $theme.borders.radius100,
                        }),
                      },
                      Root: {
                        style: ({ $theme }) => ({
                          borderTopLeftRadius: $theme.borders.radius100,
                          borderTopRightRadius: $theme.borders.radius100,
                          borderBottomRightRadius: $theme.borders.radius100,
                          borderBottomLeftRadius: $theme.borders.radius100,
                          backgroundColor: "#F8F8FB",
                        }),
                      },
                      Initials: {
                        style: () => ({
                          color: default_color,
                          fontSize: "14px",
                          fontWeight: "700",
                        }),
                      },
                    }}
                    name={organizationData?.name}
                    src={
                      organizationData &&
                        organizationData.customer_portal_ui &&
                        organizationData.customer_portal_ui.logo
                        ? organizationData.customer_portal_ui.logo
                        : null
                    }
                    size="scale1200"
                  />
                  <LabelLarge $style={{ color: "#F8F8FB" }}>
                    {organizationData?.name}
                  </LabelLarge>
                </div>
              </div>
            )}
          </div>

          <div className="sidebar-middle">
            {accessValue && accessValue
              ? [
                {
                  Icon: InvoicesSvg,
                  path: `/${hash}/invoices`,
                  name: "Invoices",
                  isAccess: accessValue.is_invoice,
                },
                {
                  Icon: CreditNoteSvg,
                  path: `/${hash}/credit-notes`,
                  name: "Credit Note",
                  isAccess: accessValue.is_credit_note,
                },
                {
                  Icon: PaymentsSvg,
                  path: `/${hash}/payments`,
                  name: "Payment",
                  isAccess: accessValue.is_payment,
                },
                {
                  Icon: MessagesSvg,
                  path: `/${hash}/messages`,
                  name: "Messages",
                  isAccess: accessValue.is_message,
                },
                {
                  Icon: RequestingCallSvg,
                  path: `/${hash}/requesting-calls`,
                  name: "Call Back",
                  isAccess: accessValue.is_callback,
                },
                {
                  Icon: RequestingTimeSvg,
                  path: `/${hash}/requesting-times`,
                  name: "Req Time",
                  isAccess: accessValue.is_request_back,
                },
              ].map((props) => {
                return (
                  props.isAccess && (
                    <div className="sidebar-middle__menu">
                      <IconCompo {...props} />
                    </div>
                  )
                );
              })
              : ""}
          </div>
        </div>
        <div className="sidebar-bottom">
          <div className="sidebar-footer__pw-by">
            <HeadingXSmall $style={{ color: "#6A7187" }}>
              Powered by{" "}
            </HeadingXSmall>
            <HeadingXSmall $style={{ width: "50px", paddingTop: "6px" }}>
              {" "}
              <a href={web} target="_blank" className="credit_href--web">
                <img src={powerBy} />
              </a>
            </HeadingXSmall>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
