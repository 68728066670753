import React from "react";

const DiscountTag = ({ size = 18, color = "#0FB158" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7112 2.61523C15.0557 2.61523 15.3793 2.74938 15.6226 2.99299C15.8659 3.23588 16 3.55958 16 3.90403V8.8119C16 9.15619 15.8659 9.47988 15.6224 9.72335L9.10817 16.2376C8.86465 16.4811 8.54098 16.6152 8.19675 16.6152C7.85249 16.6152 7.52882 16.4811 7.28541 16.2375L2.37764 11.3297C2.13415 11.0864 2 10.7627 2 10.4185C2 10.0743 2.13409 9.75059 2.37764 9.50706L8.89188 2.99282C9.13535 2.74933 9.45905 2.61523 9.80333 2.61523H14.7112ZM10.2888 7.12996C10.2888 6.41932 9.71066 5.84117 9.00003 5.84117C8.28939 5.84117 7.71123 6.41932 7.71123 7.12996C7.71123 7.8406 8.28939 8.41875 9.00003 8.41875C9.71066 8.41875 10.2888 7.8406 10.2888 7.12996ZM7.71123 12.1005C7.71123 12.8111 8.28939 13.3892 9.00003 13.3892C9.71066 13.3892 10.2888 12.8111 10.2888 12.1005C10.2888 11.3898 9.71066 10.8117 9.00003 10.8117C8.28939 10.8117 7.71123 11.3898 7.71123 12.1005ZM6.36412 10.0254H11.6359C11.8625 10.0254 12.0461 9.84172 12.0461 9.61521C12.0461 9.38869 11.8625 9.20505 11.6359 9.20505H6.36412C6.1376 9.20505 5.95396 9.38869 5.95396 9.61521C5.95396 9.84172 6.1376 10.0254 6.36412 10.0254ZM12.9831 5.222C12.9831 5.4484 13.1668 5.63215 13.3932 5.63215C13.6196 5.63215 13.8034 5.4484 13.8034 5.222C13.8034 4.99559 13.6196 4.81184 13.3932 4.81184C13.1668 4.81184 12.9831 4.99559 12.9831 5.222ZM8.99997 7.59907C9.25871 7.59907 9.46846 7.38932 9.46846 7.13059C9.46846 6.87186 9.25871 6.66211 8.99997 6.66211C8.74124 6.66211 8.53149 6.87186 8.53149 7.13059C8.53149 7.38932 8.74124 7.59907 8.99997 7.59907ZM8.99997 12.5698C9.25871 12.5698 9.46846 12.36 9.46846 12.1013C9.46846 11.8426 9.25871 11.6328 8.99997 11.6328C8.74124 11.6328 8.53149 11.8426 8.53149 12.1013C8.53149 12.36 8.74124 12.5698 8.99997 12.5698Z"
        fill={color}
      />
    </svg>
  );
};

export default DiscountTag;
