import { HeadingXLarge } from "baseui/typography";
import React, { useState } from "react";
import { KIND, SIZE } from "baseui/button";
import {
  NavLink,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { TextButton } from "../../components/TextButton";

import { DownloadTextBox } from "../../components/DocDownload/DowloadTextBox";
import { useEffect } from "react";
import axios from "axios";
import { download } from "../../utils/downloadFile";
import { isExpired } from "react-jwt";
import { useQuery } from "react-query";
import _ from "lodash";
const DocumentDowload = ({ handleTokenExpired }) => {
  const location = useLocation();
  const hash = location.pathname.split("/")[1];
  const navigate = useNavigate();
  const HOST = process.env.REACT_APP_HOST_AUTH;
  const [isLoadings, setIsLoadings] = useState(false);
  const authorizeDocAuthToken = localStorage.getItem("AuthorizationToken");

  const headers = { Authorization: `Bearer ${authorizeDocAuthToken}` };
  const getDocument = async ({ documentId, type, fileName }) => {
    try {
      await axios
        .post(
          `${HOST}customer-portal/document/${documentId}`,
          {},
          { headers, responseType: "arraybuffer" }
        )
        .then((res) => {
          download({
            fileName: fileName, // You may want to use the actual file name here
            fileData: res.data,
            fileOptions: {
              type: type,
            },
          });
        });
      refetch();
    } catch (error) {
      handleTokenExpired();
      console.error("Error downloading document:", error);
    }
  };

  const { data, status, refetch } = useQuery("DocumentList", async () => {
    const headers = {
      Authorization: `Bearer ${authorizeDocAuthToken}`,
    };

    const response = await axios.get(`${HOST}customer-portal/document/`, {
      headers,
    });
    refetch();
    return response.data;
  });

  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let showActiveUser = addSearchParams.get("i");
  useEffect(() => {
    if (authorizeDocAuthToken === null || isExpired(authorizeDocAuthToken)) {
      handleTokenExpired();
    }
  }, []);

  return (
    <div className="documents-for-download-container">
      <div className="rq__call__form__wraper --modifier">
        {status === "loading" && <p>Loading...</p>}
        {status === "error" && <p>Error fetching data</p>}

        {status === "success" && (
          <div
            className="req__show_d"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="--request-call">
                <NavLink to={`/${hash}`}>
                  <div
                    style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                      border: "0.5px solid #cdced9",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <i className="mx-icon-Group-16969" />
                  </div>
                </NavLink>
              </div>
              <div style={{ textAlign: "center" }}>
                <HeadingXLarge>Documents for Download</HeadingXLarge>
              </div>
            </div>

            <div className="document-form-container">
              {_.get(data, "docs", []).length > 0 ? (
                _.get(data, "docs", []).map((item) => (
                  <DownloadTextBox
                    {...item}
                    item={item}
                    onDownload={() => {
                      setIsLoadings(true);
                      getDocument({
                        documentId: item._id,
                        fileName: item.file_name,
                        type: item.mime_type,
                      })
                        .then(() => setIsLoadings(false))
                        .catch((err) => console.error(err));
                    }}
                    key={item._id}
                    lastDownloadDate={item?.ldt}
                    fileName={item.file_name}
                    type={item.mime_type?.split("/")[1]}
                  />
                ))
              ) : (
                <div
                  style={{
                    paddingTop: "10px",
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  No Records Found
                </div>
              )}
            </div>
            {_.get(data, "docs", []).length > 0 && (
              <div
                onClick={() =>
                  navigate(
                    `/${hash}${showActiveUser ? `?i=${showActiveUser}` : ""}`
                  )
                }
              >
                <TextButton
                  type="button"
                  kind={KIND.secondary}
                  size={SIZE.compact}
                  fullWidth
                  class="document-button-gohome"
                >
                  <div className="document_button_gohome_text">Go To Home</div>
                </TextButton>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentDowload;
