import React from "react";

const RightArrow=({width=7,height=11,color='#fff'})=>{
return(
    <svg width={width} height={height} viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.14618 5.47695L1.08993 1.4207C0.952426 1.2832 0.887495 1.12279 0.895134 0.939453C0.902773 0.75612 0.975343 0.595703 1.11284 0.458203C1.25034 0.320703 1.41458 0.251953 1.60555 0.251953C1.79652 0.251953 1.96076 0.320703 2.09826 0.458203L6.63576 4.9957C6.71215 5.07209 6.76562 5.14848 6.79618 5.22487C6.82673 5.30126 6.84201 5.38529 6.84201 5.47695C6.84201 5.56862 6.82673 5.65265 6.79618 5.72904C6.76562 5.80543 6.71215 5.88181 6.63576 5.9582L2.07534 10.5186C1.93784 10.6561 1.77743 10.721 1.59409 10.7134C1.41076 10.7058 1.25034 10.6332 1.11284 10.4957C0.975343 10.3582 0.906593 10.194 0.906593 10.003C0.906593 9.81202 0.975343 9.64779 1.11284 9.51029L5.14618 5.47695Z" fill={color}/>
</svg>

)
}
export default RightArrow