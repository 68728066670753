import React from "react";
import { StyledSvg } from "baseui/icon";

const DownArrow = ({ size = "22px" }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" preserveAspectRatio>
      <path
        d="M10.3487 7.98419L6.14117 12.1917C5.78367 12.5492 5.78367 13.1267 6.14117 13.4842C6.49867 13.8417 7.07617 13.8417 7.43367 13.4842L10.9995 9.92752L14.5562 13.4842C14.9137 13.8417 15.4912 13.8417 15.8487 13.4842C16.2062 13.1267 16.2062 12.5492 15.8487 12.1917L11.6412 7.98419C11.2928 7.62669 10.7062 7.62669 10.3487 7.98419Z"
        fill="#333860"
      />
    </svg>
  );
};

export default DownArrow;
