import {
  HeadingXLarge,
  HeadingXSmall,
  LabelMedium,
  ParagraphMedium,
  LabelSmall,
  LabelLarge,
  LabelXSmall,
  DisplayLarge,
} from "baseui/typography";
import React, { useState, useMemo, useEffect } from "react";
import MOCK_DATA from "../Invoices/MOCK_DATA.json";
import { COLUMNS } from "../Invoices/columns";
import TextButton from "../../components/TextButton/TextButton";
import { SIZE } from "baseui/button";
import CircleTick from "../../assets/img/svg/CircleTick";
import MessageHeaderSvg from "../../assets/img/svg/MessageHeaderSvg";
import { TextBox } from "../../components/TextBox";
import { useIntl } from "react-intl";
import PaymentType from "./PaymentType";
import { TotalAmount } from "./PaymentType";
import { useQuery } from "react-query";
import { getPayment, postTransferMade } from "../../services/customerPortal";
import axios from "axios";
import { FormattedNumber, FormattedMessage } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import CopySvg from "../../assets/img/svg/CopySvg";
import CopyToClipboard from "react-copy-to-clipboard";
import { IconButton, KIND } from "../../components/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Info from "../../assets/img/svg/Info";
import { refetachLayoutData } from "../../redux/Layout/action";
import { useNavigate, useParams } from "react-router-dom";
import OfflinePayment from "./OfflinePayment";

const Payment = ({
  offlineDatas,
  outComeType,
  paymentId,
  setIsOutcome,
  promiseToPay,
  reset,
  isText,
  setFiles,
}) => {
  const [copied, setCopied] = useState(false);
  let { selectedInvoice } = useSelector((s) => s.invoiceReducer);
  let paymentData = useSelector((e) => e.layoutReduceData?.payment);
  let customerData = useSelector((e) => e.layoutReduceData?.customer);
  let orgName = useSelector((e) => e.layoutReduceData?.organization.name);
  let dispatch = useDispatch();
  // const {
  //   control,
  //   register,
  //   handleSubmit,
  //   reset,
  //   watch,
  //   setValue,
  //   setError,
  //   formState: { errors, isSubmitting },
  // } = useForm({
  //   mode: "onBlur",
  //   reValidateMode: "onChange",
  //   defaultValues: {},
  // });
  // const onSubmit = (data) => {
  // };
  let navigator = useNavigate();
  const { hash } = useParams();
  const postTransfer = useMutation((data) => postTransferMade(data), {
    onError: (error, variables, context) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;

        // if (typeof data.error === "object") {
        //   errorData = Object.keys(data.error).map(
        //     (e) => `${e}: ${data.error[e]}`
        //   );
        //   errorData = errorData.toString();
        // }
        toast.error(errorData);
      }
    },
    onSuccess: (data, variables, context) => {
      toast.success(data.data.message);
      setIsOutcome("OfflinePayment");
      setFiles(data);
      // dispatch(refetachLayoutData());
      // reset();
    },
  });
  return (
    <>
      <>
        {/* <div
          onClick={() => {
            setIsOutcome("PAY_VIA");
          }}
        >
          Back
        </div> */}

        <div
          className=""
          //  onSubmit={handleSubmit(onSubmit)}
        >
          <div className="invoices-header-container --payment__modifer ">
            {/* <div className="invoices-header___message-conatiner">
          <HeadingXLarge>{customerData?.name}</HeadingXLarge>
          <MessageHeaderSvg />
        </div> */}
            {/* <div className="invoices-title">
          <HeadingXLarge>
            Your Payables for{" "}
            <span className="invoices-title__color">
              {orgName ? orgName : " "}
            </span>
          </HeadingXLarge>
        </div> */}
            <div className="payment_list_container --for_scroll_payment">
              {offlineDatas.map((e, i) => (
                <div className="payment-text__conatiner">
                  <div className="payment_list_wraper">
                    <ParagraphMedium $style={{ color: "#ADADAD" }}>
                      {e.label}:
                    </ParagraphMedium>
                    <div className="payment_list_value">
                      <ParagraphMedium $style={{ color: "#333860" }}>
                        {e.value}
                      </ParagraphMedium>
                    </div>
                  </div>

                  <div
                    style={{ display: "flex" }}
                    className="payment-number__conatiner --payment_dtl_hide"
                  >
                    <ParagraphMedium
                      $style={{ marginTop: "3px", color: "#333860" }}
                    >
                      {e.value}
                    </ParagraphMedium>
                    <CopyToClipboard
                      text={e.value}
                      onCopy={() => setCopied(true)}
                    >
                      <IconButton
                        kind={KIND.tertiary}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <CopySvg />
                      </IconButton>
                    </CopyToClipboard>
                  </div>
                </div>
              ))}
              {/* <div className="payment-text__conatiner">
                  <ParagraphMedium
                    $style={{ color: "#ADADAD", width: "125px" }}
                  >
                    Routing Number:
                  </ParagraphMedium>
                  <div
                    style={{ display: "flex" }}
                    className="payment-number__conatiner"
                  >
                    <ParagraphMedium>
                      {paymentData?.account_routing_no}
                    </ParagraphMedium>
                    <CopyToClipboard
                      text={paymentData && paymentData?.account_routing_no}
                      onCopy={() => setCopied(true)}
                    >
                      <IconButton
                        kind={KIND.tertiary}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <CopySvg />
                      </IconButton>
                    </CopyToClipboard>
                  </div>
                </div> */}
              {/* <div className="payment-text__conatiner">
                  <ParagraphMedium
                    $style={{ color: "#ADADAD", width: "125px" }}
                  >
                    Account Number:
                  </ParagraphMedium>
                  <div
                    style={{ display: "flex" }}
                    className="payment-number__conatiner"
                  >
                    <ParagraphMedium>{paymentData?.account_no}</ParagraphMedium>
                    <CopyToClipboard
                      text={paymentData && paymentData?.account_no}
                      onCopy={() => setCopied(true)}
                    >
                      <IconButton
                        kind={KIND.tertiary}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <CopySvg />
                      </IconButton>
                    </CopyToClipboard>
                  </div>
                </div> */}
              {/* <div className="payment-text__conatiner">
                  <ParagraphMedium
                    $style={{ color: "#ADADAD", width: "125px" }}
                  >
                    Transfer Description:
                  </ParagraphMedium>
                  <div
                    style={{ display: "flex" }}
                    className="payment-number__conatiner"
                  >
                    <ParagraphMedium>{customerData?.name}</ParagraphMedium>
                    <CopyToClipboard
                      text={customerData && customerData?.name}
                      onCopy={() => setCopied(true)}
                    >
                      <IconButton
                        kind={KIND.tertiary}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <CopySvg />
                      </IconButton>
                    </CopyToClipboard>
                  </div>
                </div> */}

              {paymentId && paymentId.instruction ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      marginTop: "12px",
                      width: "100%",
                    }}
                  >
                    <div style={{ marginTop: "4px" }}>
                      <Info />
                    </div>
                    <ParagraphMedium $style={{ color: "#ADADAD" }}>
                      {paymentId.instruction}
                    </ParagraphMedium>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div style={{ width: "250px", paddingTop: "20px" }}>
                <TextButton
                  size={SIZE.compact}
                  fullWidth
                  type="button"
                  onClick={async () => {
                    // let values = {
                    //   payment_id: paymentId._id,
                    //   ...outComeType,
                    //   type: outComeType.type === "due" ? "due" : "overdue",
                    // };
                    let selectedInvoiceID = selectedInvoice.map((i) => i._id);
                    let values = {
                      payment_id: paymentId._id,
                      // type: "adhoc",
                      allocated_to: selectedInvoiceID,
                      amount: isText,
                      currency:
                        selectedInvoice &&
                        selectedInvoice[0] &&
                        selectedInvoice[0].invoiceCurrency,
                    };
                    // reset();
                    await postTransfer.mutateAsync(values);
                  }}
                  disabled={postTransfer.isLoading}
                  isLoading={postTransfer.isLoading}
                >
                  I've made a transfer
                </TextButton>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Payment;
