import React from "react";
import { Button, KIND } from "baseui/button";
import { useSelector } from "react-redux";
import shade from "../../utils/LightenDarken";
import Color from "color";

function hexToRgbA(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex?.substring(1)?.split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    );
  }
  throw new Error("Bad Hex");
}

function TextButton({
  children,
  kind = KIND.primary,
  fullWidth = false,
  type = "",
  ...props
}) {
  const { default_color } = useSelector((s) => s.layoutReduceData);
  const LightenDarkenColor = shade;
  const sampleColor = Color(hexToRgbA(default_color));

  return (
    <Button
      className={`icon-button ${kind}`}
      $style={(styleProps) => {
        const { $kind, $theme, $disabled } = styleProps;

        switch ($kind) {
          case KIND.primary:
            return {
              // color: $theme.colors.buttonPrimarfill,
              borderBottomRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              boxShadow: $disabled ? "" : "0px 4px 4px 0px #516BEB25",
              width: fullWidth ? "100%" : "auto",
              ":hover": {
                boxShadow: $disabled ? "" : "inset 0px 4px 4px 0px #00000025",
                color: $theme.colors.buttonPrimarHover,
                // backgroundColor: `${default_color`${"025"}`}`,
                // backgroundColor: LightenDarkenColor(default_color, -30),
                backgroundColor: sampleColor.darken(0.2).rgb().string(),
              },
              color: $theme.colors.buttonTextColor,
              backgroundColor: default_color,
            };

          case KIND.secondary:
            return {
              // color: type === "error" ? "#FD372A" : $theme.colors.primary,
              color: type === "error" ? "#FD372A" : default_color,

              borderBottomRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              border:
                type === "error"
                  ? "1px solid #FD372A"
                  : `1px solid ${default_color}`,
              boxShadow: $disabled ? "" : "0px 4px 4px 0px #eeeeee25",
              width: fullWidth ? "100%" : "auto",
              ":hover": {
                // boxShadow: "inset 0px 4px 4px 0px #00000025",
                // backgroundColor: $theme.colors.buttonSecondaryHover,
                // backgroundColor: LightenDarkenColor(default_color, 210),
                backgroundColor: $theme.colors.buttonTertiaryHover,
                // backgroundColor: sampleColor.lighten(2.1).rgb().string(),
              },
            };
          case KIND.tertiary:
            return {
              // color: $theme.colors.buttonTertiaryText,
              color: default_color,

              borderBottomRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              width: fullWidth ? "100%" : "auto",
              ":hover": {
                // boxShadow: "inset 0px 4px 4px 0px #00000025",
                // backgroundColor: "#516BEB",
                background: $theme.colors.buttonTertiaryHover,
                // color: $theme.colors.buttonTertiaryTextHover,
                // backgroundColor: LightenDarkenColor(default_color, 150),
                // backgroundColor: sampleColor.lighten(2.1).rgb().string(),
                color: default_color,
              },
            };

          default:
            return {
              backgroundColor: "#eeeeee",
              borderBottomRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              boxShadow: $disabled ? "" : "0px 4px 4px 0px #eeeeee25",
              ":hover": {
                boxShadow: $disabled ? "" : "inset 0px 4px 4px 0px #00000025",
                backgroundColor: "#516BEB",
              },
            };
        }
      }}
      {...props}
      kind={kind}
      fullWidth={fullWidth}
      type={type}
    >
      {children}
    </Button>
  );
}

export default TextButton;
