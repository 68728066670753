import React from "react";
import Invoices from "../../containers/Invoices/Invoices";
import Layout from "../Layout/Layout";

const InvoiceView = ({ setFooter, isFooter }) => {
  console.log(setFooter, "sdshkjdhsjkdhskj");
  return (
    // <Layout>
    <Invoices setFooter={setFooter} isFooter={isFooter} />
    // </Layout>
  );
};

export default InvoiceView;
