import React from "react";

const Tick = () => {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9608 0.775391L6.59148 9.14474L2.03917 4.59245L0 6.63162L6.59148 13.2231L17 2.81459L14.9608 0.775391Z"
        fill="#333860"
      />
    </svg>
  );
};
export default Tick;
