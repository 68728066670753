export const FOOTER_POP_UP_CLOSE = "FOOTER_POP_UP_CLOSE";
export const SELECTED_INVOICES = "SELECTED_INVOICES";
// export const MODAL_OPEN = "MODAL_OPEN";

export const footerPopupClose = (data) => {
  return {
    type: FOOTER_POP_UP_CLOSE,
    payload: data,
  };
};

export const getSelectedInvoice = (data) => {
  return {
    type: SELECTED_INVOICES,
    payload: data,
  };
};

// export const modalSetFn = (data) => {
//   return {
//     type: MODAL_OPEN,
//     payload: data,
//   };
// };
